import React from 'react';

interface QuestionTextAreaProps {
  value: string;
  onChange: (value: string) => void;
}

const QuestionTextArea: React.FC<QuestionTextAreaProps> = ({
  value,
  onChange,
}) => {
  return (
    <textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Type your question here..."
      className="w-full p-4 border border-gray-200 rounded-2xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-all bg-white text-primary-contrast shadow-sm resize-none"
      rows={3}
    />
  );
};

export default QuestionTextArea;
