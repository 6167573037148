import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { FiArrowRight } from 'react-icons/fi';
import PaymentCard from './PaymentCard.tsx';
import Navbar from '../../Navbar/Navbar.tsx';
import SquareButton from '../../Buttons/SquareButton.tsx';
import { useAuth } from '../../Authentication/AuthProvider.tsx';
import { fetchPayments } from '../../../utils/firestore.ts';

const Payments: React.FC = () => {
  const { user } = useAuth();
  const [payments, setPayments] = useState<any>([]);

  useEffect(() => {
    const getPayments = async () => {
      try {
        if (user) {
          const paymentsData = await fetchPayments(user.uid);
          setPayments(paymentsData);
        }
      } catch (error) {
        console.error('Error fetching payments:', error);
        // Handle the error appropriately (e.g., show an error message)
      }
    };
    getPayments();
  }, [user]);

  const handleContactSupport = () => {
    navigator.clipboard.writeText('savannah@talktoprobe.com');
    toast.success('savannah@talktoprobe.com copied to clipboard!');
  };

  return (
    <div className="bg-primary">
      <div className="max-w-2xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-medium text-primary-contrast mb-8 mt-4">
          Payment History
        </h1>
        <div className="space-y-4">
          {payments.length > 0 ? (
            payments.map((payment) => (
              <PaymentCard
                key={payment.id}
                productName={`${payment.quantity} Completed Interview${
                  payment.quantity !== 1 ? 's' : ''
                }`}
                amount={payment.amount}
                timestamp={payment.timestamp}
              />
            ))
          ) : (
            <p className="text-gray-600">No payments found.</p>
          )}
        </div>
        <div className="mt-8 opacity-70">
          <SquareButton
            text="Email Support"
            onClick={handleContactSupport}
            icon={<FiArrowRight />}
            iconPosition="right"
          />
        </div>
      </div>
    </div>
  );
};

export default Payments;
