import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';

interface PaymentSuccessModalProps {
  onClose: () => void;
}

const PaymentSuccessModal: React.FC<PaymentSuccessModalProps> = ({
  onClose,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-4 xl:ml-44">
      <div className="bg-primary p-8 rounded-2xl shadow-lg max-w-md w-full mx-auto">
        <div className="flex items-center justify-center mb-6">
          <FiCheckCircle className="text-black text-6xl" />
        </div>
        <h2 className="text-3xl font-semibold mb-4 text-center text-primary-contrast">
          Payment Successful
        </h2>
        <p className="text-gray-600 text-lg text-center mb-8">
          Check back in a few hours to view the completed interviews.
        </p>
        <button
          className="bg-primary-contrast text-primary px-6 py-2.5 text-lg font-semibold rounded-xl w-full hover:bg-gray-800 transition-colors duration-200"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;
