import React from 'react';
import lopsiiProfilePic from '../../assets/landing/lopsii-profile-pic.jpg';
import sabitProfilePic from '../../assets/landing/sabit-profile-pic.png';
import addyProfilePic from '../../assets/landing/addy-profile-pic.jpg';
import defaultProfile from '../../assets/landing/default-profile.jpg';

interface TestimonialCardProps {
  quote: string;
  name: string;
  position: string;
  company: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  quote,
  name,
  position,
  company,
}) => {
  let profileImage;
  switch (name) {
    case 'Lopsii Olagoke':
      profileImage = lopsiiProfilePic;
      break;
    case 'Sabit Ali':
      profileImage = sabitProfilePic;
      break;
    case 'Addy Bhatia':
      profileImage = addyProfilePic;
      break;
    default:
      profileImage = defaultProfile;
  }

  return (
    <div className="w-full sm:w-[25%] h-[263px] sm:h-[400px] md:h-[430px] lg:h-[340px] p-5 my-5 sm:my-0 bg-white border-2 border-black rounded-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] text-left flex flex-col transition duration-300 ease-in-out hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
      <div className="relative flex-grow overflow-hidden">
        <svg
          className="absolute top-0 left-0 h-8 w-8 text-black opacity-10"
          fill="currentColor"
          viewBox="0 0 32 32"
          aria-hidden="true"
        >
          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
        </svg>
        <p className="text-gray-800 text-base sm:text-lg leading-relaxed pl-10 pt-2 italic">
          {quote}
        </p>
      </div>
      <div className="mt-4 pt-4 border-t border-gray-200">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img
              className="h-12 w-12 rounded-full border-2 border-black"
              src={profileImage}
              alt={name}
            />
          </div>
          <div className="ml-4">
            <p className="text-sm font-bold text-black">{name}</p>
            <p className="text-xs text-gray-600">
              {position}
              {company ? ` • ${company}` : ''}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
