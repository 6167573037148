import React from 'react';
import { Clock, AlertCircle } from 'lucide-react';

interface EstimatedInterviewTimeProps {
  interviewType: InterviewType | null;
  estimatedTime: number;
  MAX_INTERVIEW_TIME: number;
}

const EstimatedInterviewTime: React.FC<EstimatedInterviewTimeProps> = ({
  interviewType,
  estimatedTime,
  MAX_INTERVIEW_TIME,
}) => {
  const minutes = Math.floor(estimatedTime / 60);
  const seconds = estimatedTime % 60;
  const isOverTime =
    interviewType === 'B2C' && estimatedTime > MAX_INTERVIEW_TIME;

  return (
    <div
      className={`flex items-center px-4 py-2 rounded-lg ${
        isOverTime ? 'bg-red-50' : 'bg-gray-500 bg-opacity-5'
      }`}
    >
      <Clock
        size={18}
        className={`mr-2 ${
          isOverTime ? 'text-red-500' : 'text-primary-contrast'
        }`}
      />
      <span className="text-sm font-medium text-primary-contrast mr-2">
        Estimated Length:
      </span>
      <span className="text-sm font-bold text-primary-contrast">
        {minutes} min {seconds} sec
      </span>
      {isOverTime && (
        <span className="text-xs text-primary-contrast flex items-center ml-2">
          <AlertCircle size={12} className="mr-1 text-primary-contrast" />
          Exceeds {MAX_INTERVIEW_TIME / 60} min limit
        </span>
      )}
    </div>
  );
};

export default EstimatedInterviewTime;
