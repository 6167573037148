import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import {
  createB2BInterviewOnFirestore,
  createCustomInterview,
} from 'src/utils/firestore';

type CreateFirebaseInterviewRequest = {
  userId?: string;
  anonymousId?: string;
};

const useCreateFirebaseInterview = (
  options?: Partial<
    UseMutationOptions<any, Error, CreateFirebaseInterviewRequest>
  >
) => {
  return useMutation<
    CreateFirebaseInterviewRequest,
    Error,
    CreateFirebaseInterviewRequest
  >({
    mutationFn: async (data: CreateFirebaseInterviewRequest) => {
      if (data.userId) {
        return createB2BInterviewOnFirestore(data.userId);
      } else if (data.anonymousId) {
        return createCustomInterview(data.anonymousId);
      }
      throw new Error('Either userId or anonymousId must be provided');
    },
    ...options,
  });
};

export default useCreateFirebaseInterview;
