import React from 'react';
import { FaRocket, FaChartLine, FaHandshake } from 'react-icons/fa';
import Subheader from '../Text/Subheader.tsx';
import { useNavigate } from 'react-router-dom';
import TestimonialCard from './TestimonialCard.tsx';

const AffiliateOverview: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/auth');
  };

  return (
    <div className="bg-white text-black px-4 py-16">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 flex items-center justify-center">
            Get paid to grow Probe
            <FaRocket className="ml-3 h-8 w-8 text-secondary-accent" />
          </h1>
          <Subheader
            text="Earn money for every new person you invite to Probe."
            className="text-xl font-medium text-gray-600 mb-8"
          />
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <FaChartLine className="text-secondary-accent text-4xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">High Commission</h3>
            <p className="text-gray-600">
              Earn{' '}
              <span className="text-secondary-accent font-semibold">40%</span>{' '}
              commission on all referrals
            </p>
          </div>
          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <FaHandshake className="text-secondary-accent text-4xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">Flexible Payments</h3>
            <p className="text-gray-600">
              Receive payments via PayPal or bank transfer
            </p>
          </div>
          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <FaRocket className="text-secondary-accent text-4xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">Support Innovation</h3>
            <p className="text-gray-600">
              Help founders validate their ideas and grow
            </p>
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center">
            What Our Users Say
          </h2>
          <div className="flex flex-col md:flex-row gap-6 justify-center">
            <TestimonialCard
              quote="Just gave it a try, amazing results!"
              name="Benjamin F."
              position="Founder"
              company=""
            />
            <TestimonialCard
              quote="Probe is pretty amazing. I used it to conduct 75 customer interviews with people in my ICP."
              name="Lopsii Olagoke"
              position="Co-Founder"
              company="Ooreva"
            />
            <TestimonialCard
              quote="Probe saved me a ton of time, energy and money to interview several people."
              name="Sabit Ali"
              position="Founder"
              company=""
            />
          </div>
        </div>

        <div className="text-center">
          <button
            onClick={handleClick}
            className="bg-black hover:bg-gray-800 text-white rounded-2xl py-3 px-8 text-lg font-semibold transition-all duration-300 transform hover:scale-105 flex items-center justify-center mx-auto mb-6"
          >
            <FaRocket className="mr-2" />
            Become an Affiliate
          </button>
          <p className="text-gray-600 text-sm">
            Any questions? Email
            <a
              href="mailto:savannah@talktoprobe.com"
              className="text-secondary-accent hover:underline"
            >
              savannah@talktoprobe.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AffiliateOverview;
