import React, { useRef, useEffect } from 'react';
import { CheckCircle, Check } from 'lucide-react';

interface OtherInputProps {
  disabled: boolean;
  selectedChoices: string | string[];
  onSelect: (value: string) => void;
  isOtherActive: boolean;
  setIsOtherActive: (active: boolean) => void;
  otherValue: string;
  setOtherValue: (value: string) => void;
  isMultiSelect: boolean;
}

const OtherInput: React.FC<OtherInputProps> = ({
  disabled,
  selectedChoices,
  onSelect,
  isOtherActive,
  setIsOtherActive,
  otherValue,
  setOtherValue,
  isMultiSelect,
}) => {
  const otherInputRef = useRef<HTMLInputElement>(null);

  const handleOtherClick = () => {
    if (!disabled) {
      setIsOtherActive(true);
      otherInputRef.current?.focus();
    }
  };

  const handleOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setOtherValue(newValue);
    onSelect(newValue);
  };

  const handleOtherBlur = () => {
    if (!otherValue.trim()) {
      setIsOtherActive(false);
    }
  };

  const handleOtherInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleOtherClick();
  };

  useEffect(() => {
    if (isOtherActive) {
      otherInputRef.current?.focus();
    }
  }, [isOtherActive]);

  const isOtherSelected = Array.isArray(selectedChoices)
    ? selectedChoices.some((c) => c.startsWith('Other:'))
    : selectedChoices.startsWith('Other:');

  return (
    <div
      className={`w-full px-6 py-4 text-left text-base font-medium border-2 rounded-2xl transition-all duration-300 ease-in-out flex items-center justify-between
        ${
          otherValue.trim()
            ? 'bg-primary-contrast text-primary border-primary-contrast'
            : 'bg-primary text-primary-contrast border-primary-muted hover:border-primary-contrast hover:shadow-md'
        }
        ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
      `}
      onClick={handleOtherClick}
    >
      <input
        ref={otherInputRef}
        type="text"
        value={otherValue}
        onChange={handleOtherChange}
        onBlur={handleOtherBlur}
        placeholder="Other"
        className={`w-full bg-transparent outline-none ${
          otherValue.trim()
            ? 'text-primary placeholder-primary/50'
            : 'text-primary-contrast placeholder-primary-contrast/60'
        }`}
        onClick={handleOtherInputClick}
      />
      {isOtherSelected && isMultiSelect ? (
        <Check className="h-6 w-6 text-primary" />
      ) : (
        <CheckCircle className="h-6 w-6 text-primary" />
      )}
    </div>
  );
};

export default OtherInput;
