import React, { useState } from 'react';
import { Users, Building2, ArrowUpRight, UserPlus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { createB2CInterview, createCustomInterview } from 'src/utils/firestore';
import AuthProvider from '../Authentication/AuthProvider';
import LoadingRing from '../Loading/LoadingRing';
import Button from '../Buttons/Button';
import { useAuth } from '../Authentication/AuthProvider';

type InterviewTypeValue = 'B2C' | 'B2B' | 'CUSTOM';

interface InterviewTypeOption {
  type: InterviewTypeValue;
  title: string;
  description: string;
  icon: React.ElementType;
  benefits?: string[];
  tag?: string;
}

const interviewTypeOptions: InterviewTypeOption[] = [
  {
    type: 'CUSTOM',
    title: 'Your Participants',
    description: 'Interview your own users or find participants.',
    icon: UserPlus,
  },
  {
    type: 'B2C',
    title: 'B2C Participants',
    description: 'Recruit from the general population.',
    icon: Users,
    benefits: [
      'Filter by hundreds of characteristics.',
      'Reach diverse demographics.',
    ],
    tag: 'Most Popular',
  },
  {
    type: 'B2B',
    title: 'B2B Participants',
    description: 'Recruit specialized professionals.',
    icon: Building2,
    benefits: [
      'Target by job title, seniority and more.',
      'Access to decision-makers and experts.',
    ],
    // Removed the 'tag' property for B2B
  },
];

const InterviewType: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSelection = async (type: InterviewTypeValue) => {
    setLoading(true);

    if (user === null) return;

    try {
      let interviewId: string | undefined;

      if (type === 'B2B') {
        window.open('https://cal.com/savannahfeder/probe-demo', '_blank');
      } else if (type === 'B2C') {
        interviewId = (await createB2CInterview(user.uid)) || undefined;
        navigate(`/builder/${interviewId}`);
      } else if (type === 'CUSTOM') {
        interviewId = (await createCustomInterview(user.uid)) || undefined;
        navigate(`/builder/${interviewId}`);
      }
    } catch (error) {
      console.error('Error creating interview:', error);
      // You might want to show an error message to the user here
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingRing />;
  }

  return (
    <div className="md:ml-12 bg-white min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="mb-16">
          <h1 className="text-4xl font-bold text-primary-contrast mb-4">
            Select Your Interview Audience
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl">
            Choose the type of participants that best fits your research needs.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {interviewTypeOptions.map((option) => (
            <div key={option.type} className="relative group">
              <div className="bg-white rounded-xl p-6 transition-all duration-500 flex flex-col h-full border-2 border-gray-200 hover:border-primary-contrast hover:shadow-lg">
                {option.tag && (
                  <div
                    className={`absolute top-0 right-0 transform translate-x-2 -translate-y-1/2 px-3 py-1 text-xs font-semibold rounded-full
                    ${
                      option.type === 'B2C'
                        ? 'bg-primary-contrast text-white'
                        : 'bg-white text-primary-contrast border border-primary-contrast'
                    }`}
                  >
                    {option.type === 'B2C' ? 'Most Popular' : option.tag}
                  </div>
                )}
                <div className="flex items-center mb-4">
                  <option.icon
                    size={28}
                    className="text-primary-contrast mr-3"
                  />
                  <h2 className="text-xl font-bold text-primary-contrast">
                    {option.title}
                  </h2>
                </div>
                <p className="text-sm mb-4 text-gray-600">
                  {option.description}
                </p>
                <ul className="space-y-2 mb-6 text-sm flex-grow">
                  {option.benefits &&
                    option.benefits.map((benefit, index) => (
                      <li key={index} className="flex items-start">
                        <ArrowUpRight
                          size={16}
                          className="text-primary-contrast mr-2 mt-1 flex-shrink-0"
                        />
                        <span className="text-gray-700">{benefit}</span>
                      </li>
                    ))}
                </ul>
                <div className="mt-auto">
                  {option.type === 'B2B' && (
                    <p className="text-xs text-gray-500 mb-2 text-center">
                      Schedule a demo for B2B features
                    </p>
                  )}
                  <Button
                    label={
                      option.type === 'CUSTOM'
                        ? 'Select'
                        : option.type === 'B2B'
                        ? 'Schedule B2B'
                        : `Select ${option.type}`
                    }
                    onClick={() => handleSelection(option.type)}
                    buttonType="secondary"
                    fullWidth
                    icon={<ArrowUpRight size={18} />}
                    iconPosition="right"
                    rounding="rounded-xl"
                    className="bg-white border-2 border-primary-contrast text-primary-contrast hover:bg-primary-contrast hover:text-white transition-all duration-500"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InterviewType;
