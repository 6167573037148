import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthProvider';
import {
  FiList,
  FiPlus,
  FiLogOut,
  FiCreditCard,
  FiUser,
  FiMessageSquare,
} from 'react-icons/fi';
import { FaBolt } from 'react-icons/fa';
import ProbeLogo from '../Interview/Brand/ProbeLogo';

interface SidebarLightProps {
  isOpen: boolean;
  onClose: () => void;
}

const SidebarLight: React.FC<SidebarLightProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const navigateTo = (path: string) => {
    navigate(path);
    onClose();
  };

  const handleSignUp = () => {
    navigateTo('/auth');
  };

  const renderContent = () => {
    if (user && !user.isAnonymous) {
      return (
        <>
          <nav className="mt-6">
            <div className="px-4 py-2 text-gray-500 text-xs font-medium uppercase">
              Interviews
            </div>
            <ul className="mt-1">
              <li>
                <a
                  onClick={() => navigateTo('/dashboard')}
                  className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 cursor-pointer transition-colors duration-200"
                >
                  <FiList className="mr-3 h-4 w-4" />
                  My Interviews
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigateTo('/interview-type')}
                  className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 cursor-pointer transition-colors duration-200"
                >
                  <FiPlus className="mr-3 h-4 w-4" />
                  New Interview
                </a>
              </li>
            </ul>
          </nav>
          <nav className="mt-6">
            <div className="px-4 py-2 text-gray-500 text-xs font-medium uppercase">
              Account
            </div>
            <ul className="mt-1">
              <li>
                <a
                  onClick={() => navigateTo('/settings/payments')}
                  className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 cursor-pointer transition-colors duration-200"
                >
                  <FiCreditCard className="mr-3 h-4 w-4" />
                  My Purchases
                </a>
              </li>
              {/* <li>
                <a
                  onClick={() => navigateTo('/settings/affiliate')}
                  className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 cursor-pointer transition-colors duration-200"
                >
                  <FaBolt className="mr-3 h-4 w-4" />
                  Go Affiliate
                </a>
              </li> */}
            </ul>
          </nav>
          <nav className="mt-6">
            <div className="px-4 py-2 text-gray-500 text-xs font-medium uppercase">
              Feedback
            </div>
            <ul className="mt-1">
              <li>
                <a
                  onClick={() => navigateTo('/feedback')}
                  className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 cursor-pointer transition-colors duration-200"
                >
                  <FiMessageSquare className="mr-3 h-4 w-4" />
                  Give Feedback
                </a>
              </li>
            </ul>
          </nav>
        </>
      );
    }
    return null;
  };

  return (
    <div
      className={`fixed left-0 top-0 bottom-0 w-64 bg-white border-r border-gray-200 z-50 transition-transform duration-300 ease-in-out transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } xl:translate-x-0 flex flex-col`}
    >
      <div className="px-5 py-4 border-b border-gray-200">
        <ProbeLogo
          onClick={() => navigateTo('/')}
          iconSize="w-4 h-4"
          textSize="text-xl"
        />
      </div>
      <div className="flex-grow overflow-y-auto">{renderContent()}</div>
      <div className="px-4 py-4 border-t border-gray-200">
        {user && !user.isAnonymous && (
          <div className="flex flex-col">
            <div className="text-sm text-gray-600 truncate mb-2">
              {user.email}
            </div>
            <button
              onClick={logout}
              className="flex items-center text-gray-600 hover:text-gray-800 focus:outline-none transition-colors duration-200"
            >
              <FiLogOut className="mr-2 h-4 w-4" />
              Sign Out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarLight;
