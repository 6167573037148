import React, { ReactNode } from 'react';

interface GradientButtonProps {
  label: string;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
  className?: string;
  rounding?: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
}

const GradientButton = ({
  label,
  onClick,
  color,
  disabled,
  className,
  rounding = 'rounded-full',
  icon,
  iconPosition = 'right',
}: GradientButtonProps) => {
  const buttonColor = 'bg-primary-contrast';
  const disabledStyle = disabled
    ? 'bg-gray-400 border-none text-gray-600 bg-gray-700 opacity-40'
    : '';

  return (
    <button
      onClick={onClick}
      className={`btn h-12 w-full text-primary bg-primary-contrast text-lg text-normal hover:opacity-80 ${className} ${rounding} ${
        disabled ? disabledStyle : buttonColor
      }`}
    >
      <div
        className={`flex items-center justify-center ${
          icon ? 'space-x-2' : ''
        }`}
      >
        {icon && iconPosition === 'left' && <span>{icon}</span>}
        <p className={`normal-case text-lg font-normal`}>{label}</p>
        {icon && iconPosition === 'right' && <span>{icon}</span>}
      </div>
    </button>
  );
};

export default GradientButton;
