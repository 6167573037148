import { Question, QuestionType } from '@functions/common/types';

export function processRawQuestions(rawQuestions: any[]): Question[] {
  return rawQuestions.map((q, index) => {
    const questionType = q.questionType || q.type;
    if (!questionType || typeof questionType !== 'string') {
      console.error(`Question ${index} has an invalid questionType:`, q);
      throw new Error(`Question ${index} has an invalid questionType`);
    }

    const baseQuestion = {
      id: q.id || `question-${Date.now()}-${index}`,
      type: questionType as QuestionType,
      question: q.question || '',
      image: q.image || null,
      allowSkip: q.allowSkip ?? false,
    };

    try {
      switch (questionType) {
        case 'open-ended':
        case 'ai-follow-ups':
          return {
            ...baseQuestion,
            type: questionType,
            aiFollowUps: questionType === 'ai-follow-ups',
            aiInstructions: q.aiInstructions || '',
            maxFollowUps: q.maxFollowUps || null,
          };
        case 'multiple-choice':
          return {
            ...baseQuestion,
            type: 'multiple-choice',
            options: q.options || [],
            includeOther: q.includeOther || false,
          };
        case 'single-select':
          return {
            ...baseQuestion,
            type: 'single-select',
            options: q.options || [],
            includeOther: q.includeOther || false,
          };
        case 'rating':
          return {
            ...baseQuestion,
            type: 'rating',
            maxRating: q.maxRating || 10,
          };
        case 'text-block':
          return {
            ...baseQuestion,
            type: 'text-block',
          };
        default:
          console.error(`Unsupported question type for question ${index}:`, q);
          throw new Error(`Unsupported question type: ${questionType}`);
      }
    } catch (error) {
      console.error(`Error processing question ${index}:`, q, error);
      throw error;
    }
  });
}
