import React, { useState, useEffect } from 'react';
import { db, auth, provider } from '../../firebaseConfig';
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import Header from '../Text/Header.tsx';
import Subheader from '../Text/Subheader.tsx';
import InterviewOnboardingContainer from '../Containers/InterviewOnboardingContainer.tsx';
import GoogleButton from '../Buttons/GoogleButton.tsx';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import InputFieldIcon from '../../InputFields/InputFieldIcon.tsx';
import { Mail, Lock } from 'lucide-react';
import { validateEmail } from '../../utils/general.ts';
import { useAuth } from './AuthProvider.tsx';
import GradientButton from '../Buttons/GradientButton.tsx';
import ProbeLogo from '../Interview/Brand/ProbeLogo.tsx';
import {
  transferAnonymousInterviewToUser,
  updateInterviewUserId,
  linkUserAndInterview,
} from '../../utils/firestore';

interface UserData {
  uid: string;
  email: string | null;
  credits?: number;
  name?: string;
  referrer?: string;
  interviewIds?: string[];
}

const createUserData = (
  user: User,
  interviewId?: string,
  affiliateId?: string
): UserData => {
  const userData: UserData = {
    uid: user.uid,
    email: user.email,
  };

  if (interviewId) {
    userData.interviewIds = [interviewId];
  }

  if (affiliateId) {
    userData.referrer = affiliateId;
  }

  if (user.displayName) {
    userData.name = user.displayName;
  }

  return userData;
};

const Authentication = () => {
  const { user, setUser } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const mode = location.state?.mode;
  const [showSignIn, setShowSignIn] = useState<boolean>(
    mode == 'SignIn' ? true : false
  );
  const { affiliateId, interviewId } = useParams<{
    affiliateId: string;
    interviewId: string;
  }>();
  const { action } = (location.state as { action: string }) || {};

  useEffect(() => {
    if (user && !user.isAnonymous) {
      navigate('/start');
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirm(e.target.value);
  };

  const signUpWithEmailPassword = async () => {
    if (password !== passwordConfirm) {
      toast('Passwords do not match.');
      return;
    }

    if (password.length < 6) {
      toast('Password must be at least 6 characters long.');
      return;
    }

    if (!validateEmail(email)) {
      toast('Please enter a valid email.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        toast('An account with this email already exists.');
        return;
      } else {
        const userData = createUserData(user, interviewId, affiliateId);
        await setDoc(userRef, userData);
        if (interviewId) {
          await linkUserAndInterview(user.uid, interviewId);
        }
        handleNavigation(user);
      }
    } catch (error: any) {
      console.error(error);
      toast(error.message);
    }
  };

  const signInWithEmailPassword = async () => {
    if (!validateEmail(email)) {
      toast('Please enter a valid email.');
      return;
    }

    if (password.length === 0) {
      toast('Please enter your password.');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (interviewId) {
        await linkUserAndInterview(user.uid, interviewId);
      }

      handleNavigation(user);
    } catch (error) {
      console.error(error);
      toast('Please check your email and password and try again.');
    }
  };

  const authenticateWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        const userData = createUserData(user, interviewId, affiliateId);
        await setDoc(userRef, userData);
      }

      if (interviewId) {
        await linkUserAndInterview(user.uid, interviewId);
      }

      handleNavigation(user);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigation = async (user: User) => {
    if (user) {
      navigate('/start');
    } else {
      throw new Error('User is not authenticated.');
    }
  };

  const googleButtonText = showSignIn
    ? 'Log in with Google'
    : 'Sign up with Google';
  const headerText = showSignIn ? 'Welcome back 👋' : 'Welcome to Probe 👋';
  const subheaderText = showSignIn
    ? 'Please log in to continue.'
    : 'Please sign up to continue.';
  const switchAuthScreenQuestionText = showSignIn
    ? "Don't have an account?"
    : 'Already have an account?';
  const switchAuthScreenButtonText = showSignIn ? 'Sign Up' : 'Log In';

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    try {
      let userCredential: { user: User };
      if (showSignIn) {
        userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
      } else {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
      }
      setUser(userCredential.user);

      const userRef = doc(db, 'users', userCredential.user.uid);
      const userData = createUserData(
        userCredential.user,
        interviewId,
        affiliateId
      );
      await setDoc(userRef, userData, { merge: true });

      if (interviewId) {
        await linkUserAndInterview(userCredential.user.uid, interviewId);
      }

      if (interviewId) {
        await transferAnonymousInterviewToUser(
          interviewId,
          userCredential.user.uid
        );
      }

      if (action === 'share') {
        navigate(`/interview/${interviewId}`);
      } else if (affiliateId) {
        navigate('/');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Authentication error:', error);
      toast.error('An error occurred during authentication. Please try again.');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (showSignIn) {
        signInWithEmailPassword();
      } else {
        signUpWithEmailPassword();
      }
    }
  };

  return (
    <div className="pt-2 sm:pt-14 bg-primary min-h-screen">
      <InterviewOnboardingContainer>
        {/* <div className="mx-auto w-24">
          <ProbeLogo />
        </div> */}
        <div className="text-center max-w-md mx-auto text-primary-contrast">
          <div className="mt-4">
            <Header text={headerText} />
            <Subheader text={subheaderText} />
          </div>
          <div className="mt-10 mx-2">
            {showSignIn ? (
              <div>
                <InputFieldIcon
                  icon={<Mail className="w-4 h-4 text-gray-600" />}
                  placeholder="Email"
                  handleChange={handleEmailChange}
                  value={email}
                  onKeyPress={handleKeyPress}
                />
                <InputFieldIcon
                  icon={<Lock className="w-4 h-4 text-gray-600" />}
                  placeholder="Password"
                  handleChange={handlePasswordChange}
                  value={password}
                  isPassword={true}
                  onKeyPress={handleKeyPress}
                />
              </div>
            ) : (
              <div>
                <InputFieldIcon
                  icon={<Mail className="w-4 h-4 text-gray-600" />}
                  placeholder="Enter your email"
                  handleChange={handleEmailChange}
                  value={email}
                  onKeyPress={handleKeyPress}
                />
                <InputFieldIcon
                  icon={<Lock className="w-4 h-4 text-gray-600" />}
                  placeholder="Password"
                  handleChange={handlePasswordChange}
                  value={password}
                  isPassword={true}
                  onKeyPress={handleKeyPress}
                />
                <InputFieldIcon
                  icon={<Lock className="w-4 h-4 text-gray-600" />}
                  placeholder="Confirm password"
                  handleChange={handlePasswordConfirmChange}
                  value={passwordConfirm}
                  isPassword={true}
                  onKeyPress={handleKeyPress}
                />
              </div>
            )}
          </div>
          <div className="mx-auto flex flex-col justify-center items-center mt-10">
            <div className="w-full">
              <GradientButton
                label={showSignIn ? 'Log In' : 'Sign Up'}
                onClick={
                  showSignIn ? signInWithEmailPassword : signUpWithEmailPassword
                }
                rounding="rounded-2xl"
              />
            </div>
            <div className="mt-3 w-full">
              <GoogleButton
                label={googleButtonText}
                onClick={authenticateWithGoogle}
                rounding="rounded-2xl"
              />
            </div>
            <div className="pt-10 text-center">
              <p className="text-sm text-primary-contrast">
                {switchAuthScreenQuestionText}
                <span
                  className="text-primary-contrast cursor-pointer ml-1 font-medium"
                  onClick={() => setShowSignIn((prev) => !prev)}
                >
                  {switchAuthScreenButtonText}
                </span>
              </p>
            </div>
          </div>
        </div>
      </InterviewOnboardingContainer>
      <Toaster />
    </div>
  );
};

export default Authentication;
