import React from 'react';
import { Link } from 'react-router-dom';
import { Share2, ExternalLink } from 'lucide-react';
import SaveStatus from './SaveStatus';

interface InterviewHeaderProps {
  interviewId: string;
  saveStatus: 'saved' | 'saving' | 'unsaved';
  onShareClick: () => void;
  isLoggedIn: boolean;
  isAnonymous: boolean;
  interviewType: 'CUSTOM' | 'B2C' | 'B2B';
}

const InterviewHeader: React.FC<InterviewHeaderProps> = ({
  interviewId,
  saveStatus,
  onShareClick,
  isLoggedIn,
  isAnonymous,
  interviewType,
}) => {
  const isAuthenticatedUser = isLoggedIn && !isAnonymous;
  const shareButtonText = isAuthenticatedUser ? 'Share' : 'Sign up to Share';
  const showShareButton = interviewType === 'CUSTOM';

  return (
    <div>
      <div className="mx-auto px-4 md:px-8 lg:px-12 xl:pl-10 xl:pr-16 2xl:px-36 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <SaveStatus status={saveStatus} />
            <div className="h-6 w-px bg-gray-300"></div>
            <div className="flex items-center">
              <Link
                to={`/preview/${interviewId}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`pr-2 text-blue-600 hover:text-blue-500 transition-colors focus:outline-none flex items-center ${
                  saveStatus !== 'saved' ? 'pointer-events-none opacity-50' : ''
                }`}
                aria-disabled={saveStatus !== 'saved'}
                title="Preview interview"
              >
                <ExternalLink size={18} className="mr-2" />
                <span className="text-sm">Preview</span>
              </Link>
            </div>
          </div>
          {showShareButton && (
            <div className="flex items-center space-x-4">
              <button
                onClick={onShareClick}
                className="px-4 py-2 bg-white text-gray-800 border border-gray-300 rounded-md flex items-center justify-center transition-all duration-300 ease-in-out text-sm font-medium hover:bg-gray-50 hover:border-gray-400"
              >
                <Share2 size={14} className="mr-2" /> {shareButtonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterviewHeader;
