import React from 'react';
import searchIcon from '../../../assets/icons/searchIcon.svg';

const SearchInput = ({
  searchQuery,
  handleSearchChange,
  placeholder,
  placeholderImage,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e.target.value);
  };

  return (
    <div className="flex items-center border border-gray-300 rounded-xl mt-4 bg-primary">
      <div className="pl-2">
        {!searchQuery && (
          <img src={searchIcon} alt="Search" className="w-5 h-5 ml-1" />
        )}
      </div>
      <div className="relative w-full">
        <input
          type="text"
          value={searchQuery}
          onChange={handleInputChange}
          className="w-full p-2 rounded-lg focus:outline-none bg-transparent placeholder:text-gray-500 text-gray-700"
          placeholder={placeholder}
        />
        {placeholderImage && !searchQuery && (
          <img
            src={placeholderImage}
            alt="Placeholder"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5"
          />
        )}
      </div>
    </div>
  );
};

export default SearchInput;
