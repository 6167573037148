import React from 'react';
import { ChatMessage } from '../../../../common/types.ts';
import Animate from '../../ui/Animate.tsx';
import TextLoader from '../../ui/TextLoading.tsx';

interface Props {
  chatHistory: ChatMessage[];
  isLoading: boolean;
}

const ScreenerInterface: React.FC<Props> = ({ chatHistory, isLoading }) => {
  const renderMessageContent = (content: string | React.ReactNode) => {
    if (typeof content === 'string') {
      return content.split('\n\n').map((paragraph, index) => (
        <p key={index} className="mb-4 last:mb-0">
          {paragraph}
        </p>
      ));
    } else {
      return content;
    }
  };

  const effectiveChatHistory = isLoading
    ? [...chatHistory, { type: 'system', content: <TextLoader /> }]
    : chatHistory;

  return (
    <div className="space-y-6 font-light text-xl">
      {effectiveChatHistory.map((message, index) => {
        const isLastMessage = index === effectiveChatHistory.length - 1;
        const shouldBeWhite =
          isLastMessage ||
          (isLoading && index === effectiveChatHistory.length - 2);

        return (
          <div key={index} className="flex mb-6">
            <div
              className={`w-20 ${
                shouldBeWhite ? 'text-white' : 'text-gray-400'
              }`}
            >
              {message.type === 'system' ? 'Probe' : 'You'}
            </div>
            <div
              className={`flex-1 ${
                shouldBeWhite ? 'text-white' : 'text-gray-400'
              }`}
            >
              <Animate>{renderMessageContent(message.content)}</Animate>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScreenerInterface;
