import React from 'react';

interface SectionHeaderProps {
  title: string;
  subtitle?: string;
  isLong?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  isLong = false,
}) => {
  const titleClass = isLong
    ? 'text-3.8xl sm:text-4.8xl lg:text-5.8xl font-semibold mb-4'
    : 'text-4.2xl sm:text-5.2xl lg:text-6.2xl font-semibold mb-4';

  return (
    <div className="text-center mb-10 mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
      <h2 className={`${titleClass} leading-tight`}>{title}</h2>
      {subtitle && (
        <p className="text-lg sm:text-xl lg:text-2xl text-primary-light font-light mx-auto mt-4 max-w-3xl">
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default SectionHeader;
