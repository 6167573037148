import {
  CheckIcon,
  Combobox,
  ComboboxItem,
  Group,
  Input,
  MultiSelectProps,
  Pill,
  PillsInput,
  useCombobox,
} from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import LoadingRing from '../Loading/LoadingRing';
import { flushSync } from 'react-dom';

export interface SearchSelectProps extends MultiSelectProps {
  maxValues?: number;
  search: string;
  setSearch: (value: string) => void;
  initialOptions?: ComboboxItem[];
  loading: boolean;
  options: ComboboxItem[];
  onSelectionChange: (value: ComboboxItem[]) => void;
}

export default function SearchSelect({
  maxValues,
  search,
  setSearch,
  loading,
  options,
  onSelectionChange,
  placeholder,
  label,
  initialOptions,
  ...props
}: SearchSelectProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [selectedOptions, setSelectedOptions] = useState<ComboboxItem[]>([]);

  useEffect(() => {
    if (initialOptions) {
      setSelectedOptions(initialOptions);
    }
  }, [initialOptions]);

  const handleValueSelect = (val: ComboboxItem) => {
    const newValues = selectedOptions.find((v) => v.value === val.value)
      ? selectedOptions.filter((v) => v.value !== val.value)
      : [...selectedOptions, val];
    setSelectedOptions(newValues);
    onSelectionChange(newValues);
  };

  const handleValueRemove = (val: ComboboxItem) => {
    const newOptions = selectedOptions.filter((v) => v !== val);
    setSelectedOptions(newOptions);
    onSelectionChange(newOptions);
  };

  const values = selectedOptions.map((item) => (
    <Pill
      key={item.value}
      withRemoveButton
      onRemove={() => handleValueRemove(item)}
    >
      {item.label}
    </Pill>
  ));

  const selectedValues = useMemo(
    () => selectedOptions.map((item) => item.value),
    [selectedOptions]
  );

  const optionList = options.map((item) => (
    <Combobox.Option
      onClick={() => handleValueSelect(item)}
      value={item.value}
      key={item.value}
      disabled={maxValues !== undefined && selectedOptions.length >= maxValues}
      active={selectedValues.includes(item.value)}
    >
      <Group gap="sm">
        {selectedValues.includes(item.value) ? <CheckIcon size={12} /> : null}
        <span>{item.label}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <Combobox store={combobox} withinPortal={false}>
      <div>
        {label && <Input.Label>{label}</Input.Label>}
        <Combobox.DropdownTarget>
          <PillsInput
            error={props.error}
            onClick={() => combobox.openDropdown()}
          >
            <Pill.Group>
              {values}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  className=""
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder="Search values"
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(
                        selectedOptions[selectedOptions.length - 1]
                      );
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>
      </div>

      <Combobox.Dropdown className="max-h-40 overflow-auto">
        {loading ? (
          <LoadingRing />
        ) : (
          <Combobox.Options className="h-full">
            {optionList.length > 0 ? (
              optionList
            ) : (
              <Combobox.Empty>
                {search.length === 0 ? placeholder : 'Nothing Found'}
              </Combobox.Empty>
            )}
          </Combobox.Options>
        )}
      </Combobox.Dropdown>
    </Combobox>
  );
}
