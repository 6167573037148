import { useState } from 'react';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface ClaudeResponse {
  response: string;
}

export const useClaude = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const isDevelopment = process.env.NODE_ENV === 'development';
  const apiUrl = isDevelopment
    ? 'http://127.0.0.1:5001/joinprobe/us-central1/getClaudeResponse'
    : 'https://us-central1-joinprobe.cloudfunctions.net/getClaudeResponse';

  const askClaude = async (
    systemPrompt: string,
    messages: Message[]
  ): Promise<string | null> => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          systemPrompt,
          messages,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data: ClaudeResponse = await response.json();
      setLoading(false);
      return data.response;
    } catch (err) {
      setLoading(false);
      setError('Error communicating with Claude');
      console.error('Error in askClaude:', err);
      return null;
    }
  };

  return { askClaude, loading, error };
};
