import React from 'react';
import { Check, CircleDashed } from 'lucide-react';

interface SaveStatusProps {
  status: 'saved' | 'saving' | 'unsaved';
}

const SaveStatus: React.FC<SaveStatusProps> = ({ status }) => {
  let content;
  const rotateAnimation = {
    animation: 'spin 5s linear infinite',
  };

  const keyframes = `
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `;

  const customCheckStyle = `
    .custom-check-circle {
      background-color: #4A5568;
      border-radius: 50%;
      color: white;
    }
  `;

  switch (status) {
    case 'saving':
    case 'unsaved':
      content = (
        <>
          <style>{keyframes}</style>
          <CircleDashed
            className="mr-2 h-3 w-3 text-gray-700"
            style={rotateAnimation}
          />
          <span className="text-gray-700">Saving...</span>
        </>
      );
      break;
    case 'saved':
      content = (
        <>
          <Check className="mr-1.5 h-4 w-4 mt-0.5 text-gray-700" />
          <span className="text-gray-700">Saved</span>
        </>
      );
      break;
  }

  return <div className="flex items-center text-sm">{content}</div>;
};

export default SaveStatus;
