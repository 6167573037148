import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  createInterviewInstanceOnFirestore,
  getInterviewInstanceByRespondentId,
} from '../utils/firestore';

type CreateOrGetInterviewByRespondentIdRequest = {
  interviewId: string;
  respondentId: string;
};

const createOrGetInterviewInstanceWithRespondentId = async ({
  interviewId,
  respondentId,
}: CreateOrGetInterviewByRespondentIdRequest) => {
  const existingInstance = await getInterviewInstanceByRespondentId(
    interviewId!,
    respondentId
  );

  console.log('here2');

  let newInstanceId;
  if (existingInstance) {
    newInstanceId = existingInstance.id;
  } else {
    const newInstance = await createInterviewInstanceOnFirestore(
      interviewId!,
      false,
      false,
      respondentId,
      ''
    );
    newInstanceId = newInstance.id;
  }

  return newInstanceId;
};

const useCreateOrGetInterviewInstanceByRespondentId = (
  options?: Partial<
    UseMutationOptions<any, Error, CreateOrGetInterviewByRespondentIdRequest>
  >
) => {
  return useMutation<string, Error, CreateOrGetInterviewByRespondentIdRequest>({
    mutationFn: async (data: CreateOrGetInterviewByRespondentIdRequest) => {
      const interviewInstanceId =
        await createOrGetInterviewInstanceWithRespondentId({
          interviewId: data.interviewId,
          respondentId: data.respondentId,
        });
      return interviewInstanceId;
    },
    ...options,
  });
};

export default useCreateOrGetInterviewInstanceByRespondentId;
