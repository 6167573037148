import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar.tsx';
import Header from '../Text/Header.tsx';
import Button from '../Buttons/Button.tsx';
import { useAuth } from '../Authentication/AuthProvider.tsx';
import LoadingRing from '../Loading/LoadingRing.tsx';
import { useParams } from 'react-router-dom';
import ParticipantCounter from '../Study/ParticipantCounter.tsx';

const PricingSection = ({ plan, price, interviews, weeksSaved, onSelect }) => {
  let originalPrice;
  if (interviews === 10) {
    originalPrice = 158;
  } else if (interviews === 35) {
    originalPrice = 308;
  }

  return (
    <div className="border border-violet-700 border-opacity-30 shadow-md p-6 flex flex-col items-center rounded-2xl bg-opacity-10 bg-gray-800 transition duration-300 ease-in-out transform md:scale-105 hover:scale-110 hover:shadow-lg hover:bg-opacity-20">
      <h2 className="text-lg font-light mb-5 text-gray-400">{plan}</h2>
      <p className="text-2.8xl font-normal mb-2 text-gray-300">
        {interviews} interviews
      </p>
      <div className="flex items-center mb-6">
        <span className="text-gray-500 text-lg line-through mr-2 mt-0.5">
          ${originalPrice}
        </span>
        <p className="text-1.5xl text-gray-300 font-medium">
          ${price} <span className="text-sm">USD</span>
        </p>
      </div>
      <p className="text-base text-gray-400 mb-4">
        You're saving{' '}
        <span className="font-semibold text-gray-200">{weeksSaved}</span>{' '}
        {weeksSaved > 1 ? 'weeks' : 'week'}! 🎉
      </p>
      <div className="w-9/12 mt-3">
        <button
          onClick={() => onSelect(plan)}
          className="btn border border-gray-600 w-full bg-gradient-to-b from-[#683ffe] to-[#683ffea1] opacity-70 text-white rounded-full text-normal hover:opacity-80"
        >
          <p className="normal-case text-1.5md font-normal">Select Plan</p>
        </button>
      </div>
    </div>
  );
};

const Pricing = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showCustomPlan, setShowCustomPlan] = useState(false);
  const { interviewId, studyId } = useParams();

  const generatePaymentLink = async (
    selectedPlan: string,
    quantity?: string
  ) => {
    try {
      if (user) {
        const idToken = await user.getIdToken();

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idToken,
            quantity,
            interviewId,
            studyId,
            selectedPlan,
          }),
        };

        const emulatorUrl =
          'http://127.0.0.1:5001/joinprobe/us-central1/createCheckoutSession';
        const productionUrl =
          'https://us-central1-joinprobe.cloudfunctions.net/createCheckoutSession';

        const response = await fetch(productionUrl, requestOptions);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result);

        return result.url;
      } else {
        console.error('User not authenticated');
      }
    } catch (error) {
      console.error('Error calling function:', error);
    }
  };

  const handleStarterPlan = async () => {
    setLoading(true);
    const link = await generatePaymentLink('starter');
    window.location.href = link;
  };

  const handleExpensivePlan = async () => {
    setLoading(true);
    const link = await generatePaymentLink('expensive');
    window.location.href = link;
  };

  const handleCustomPlan = () => {
    setShowCustomPlan(true);
  };

  const handleCloseCustomPlan = () => {
    setShowCustomPlan(false);
  };

  const handleCustomPlanSubmit = async (selectedPlan, quantity) => {
    setLoading(true);
    const link = await generatePaymentLink(selectedPlan, quantity);
    window.location.href = link;
  };

  if (loading) return <LoadingRing />;

  if (showCustomPlan) {
    return (
      <ParticipantCounter
        onClose={handleCloseCustomPlan}
        onSubmit={handleCustomPlanSubmit}
      />
    );
  }

  return (
    <div>
      <Navbar />
      <div className="container mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <Header text="Your interview's set. Next, choose a plan to share it." />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 max-w-2xl mx-auto">
          <PricingSection
            plan="Test a Hypothesis"
            price={99}
            interviews={10}
            weeksSaved={1}
            onSelect={handleStarterPlan}
          />
          <PricingSection
            plan="Startup Validation"
            price={189}
            interviews={35}
            weeksSaved={3}
            onSelect={handleExpensivePlan}
          />
        </div>
        <p
          className="text-center text-1.5sm text-gray-300 font-extralight mt-12 hover:underline cursor-pointer"
          onClick={handleCustomPlan}
        >
          Purchase Individual Interviews for $15 Each
        </p>
        <p className="text-center text-sm text-gray-500 mt-5">
          Interviews send on payment completion.
        </p>
      </div>
    </div>
  );
};

export default Pricing;
