import React, { useEffect, useState } from 'react';
import { useAuth } from '../Authentication/AuthProvider.tsx';
import { Navigate } from 'react-router-dom';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import LoadingRing from '../Loading/LoadingRing.tsx';

const Redirect = () => {
  const { user } = useAuth();
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleNavigation = async () => {
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          const interviewIds =
            userSnap.exists() && userSnap.data().interviewIds;

          if (interviewIds && interviewIds.length === 1) {
            const interviewId = interviewIds[0];
            const interviewRef = doc(db, 'interviews', interviewId);
            const interviewSnap = await getDoc(interviewRef);
            const studyCollectionRef = collection(
              db,
              'interviews',
              interviewId,
              'studies'
            );
            const studySnap = await getDocs(studyCollectionRef);

            const isUserAnonymous = user.isAnonymous;
            const interviewData = interviewSnap.data();
            const isCustomInterview = interviewData?.interviewType === 'CUSTOM';
            const hasNoTitle = !interviewData?.title;
            const hasNoResponses = studySnap.empty;

            if (
              isUserAnonymous &&
              isCustomInterview &&
              hasNoTitle &&
              hasNoResponses
            ) {
              setNavigateTo(`/builder/${interviewId}`);
            } else if (isCustomInterview) {
              setNavigateTo(`/dashboard`);
            } else if (studySnap.size > 0 && interviewData?.paid === true) {
              setNavigateTo(`/dashboard/${interviewId}`);
            } else if (studySnap.size > 0 && !interviewData?.paid) {
              setNavigateTo(`/filters/${interviewId}`);
            } else if (interviewData?.topic && studySnap.empty) {
              setNavigateTo(`/builder/${interviewId}`);
            } else if (!interviewData?.topic) {
              setNavigateTo(`/overview/${interviewId}`);
            } else {
              setNavigateTo('/interview-type');
            }
          } else if (interviewIds && interviewIds.length > 1) {
            setNavigateTo('/dashboard');
          } else {
            setNavigateTo('/interview-type');
          }
        } catch (error) {
          console.error(error);
          setNavigateTo('/home');
        }
      } else {
        setNavigateTo('/home');
      }
      setLoading(false);
    };

    handleNavigation();
  }, [user]);

  if (loading) return <LoadingRing />;

  return navigateTo ? <Navigate to={navigateTo} replace /> : null;
};

export default Redirect;
