import React, { useState } from 'react';
import { ChevronRight, Star, ChevronDown } from 'lucide-react';

const GoalsHeaderBlock = ({ MAX_QUESTIONS, totalQuestions }) => {
  const [showTips, setShowTips] = useState(false);
  const [showTip1, setShowTip1] = useState(false);
  const [showTip2, setShowTip2] = useState(false);
  const [showTip3, setShowTip3] = useState(false);
  const [showTip4, setShowTip4] = useState(false);

  const ToggleIcon = ({ isOpen }) => (
    <ChevronRight
      size={16}
      className={`transition-transform duration-200 ${
        isOpen ? 'transform rotate-90' : ''
      }`}
    />
  );

  return (
    <div className="pt-3 pb-2 px-5 mt-9 md:mx-2 xl:mx-auto bg-gradient-to-b from-[#7b3ffe0f] to-[#b08cff02] rounded-xl border border-primary-muted">
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-center">
            <span className="text-3xl text-primary-contrast">
              {totalQuestions}
            </span>
            <span className="text-md font-extralight text-primary-contrast">
              /{MAX_QUESTIONS}
            </span>
          </p>
          <p className="text-xs font-extralight text-primary-contrast">
            questions
          </p>
        </div>
        <div
          onClick={() => setShowTips(!showTips)}
          className="flex items-center text-1.5sm font-light text-primary-contrast cursor-pointer mt-1.5"
        >
          <Star size={14} className="opacity-60" />
          <span className="mx-1">Tips for Writing Effective Goals</span>
          <ChevronDown size={14} className="opacity-60" />
        </div>
      </div>

      {showTips && (
        <div className="text-primary-contrast">
          <div
            onClick={() => setShowTip1(!showTip1)}
            className="flex items-center cursor-pointer mt-4"
          >
            <ToggleIcon isOpen={showTip1} />
            <div className="ml-2">
              <span className="font-medium">
                Include only one goal or outcome in each input field.
              </span>
              <span className="text-md font-extralight"> Examples:</span>
            </div>
          </div>
          {showTip1 && (
            <ul className="list-disc pl-8 mt-2">
              <li>
                <span className="font-semibold">Good:</span> Understand pain
                points interviewee experiences when managing multiple online
                subscriptions.
              </li>
              <li>
                <span className="font-semibold">Bad:</span> Understand pain
                points, problems, and workflows interviewee experience when
                managing multiple online subscriptions.
              </li>
            </ul>
          )}

          <div
            onClick={() => setShowTip2(!showTip2)}
            className="flex items-center cursor-pointer mt-4"
          >
            <ToggleIcon isOpen={showTip2} />
            <div className="ml-2">
              <span className="font-medium">
                Include only one sentence in each input field.
              </span>
              <span className="text-md font-extralight"> Examples:</span>
            </div>
          </div>
          {showTip2 && (
            <ul className="list-disc pl-8 mt-2">
              <li>
                <span className="font-semibold">Good:</span> Ask about the last
                time the interviewee noticed a subscription charge they didn't
                recognize.
              </li>
              <li>
                <span className="font-semibold">Bad:</span> Ask about the last
                time the interviewee noticed a subscription charge they didn't
                recognize. What did they do about it? What was the outcome?
              </li>
            </ul>
          )}

          <div
            onClick={() => setShowTip3(!showTip3)}
            className="flex items-center cursor-pointer mt-4"
          >
            <ToggleIcon isOpen={showTip3} />
            <div className="ml-2">
              <span className="font-medium">
                Be as specific with your goals as possible.
              </span>
              <span className="text-md font-extralight"> Examples:</span>
            </div>
          </div>
          {showTip3 && (
            <ul className="list-disc pl-8 mt-2">
              <li>
                <span className="font-semibold">Good:</span> Identify whether
                the interviewee has ever purchased an app that consolidates and
                manages all your online subscriptions.
              </li>
              <li>
                <span className="font-semibold">Bad:</span> Identify whether the
                interviewee would be interested in my product.
              </li>
            </ul>
          )}

          <div
            onClick={() => setShowTip4(!showTip4)}
            className="flex items-center cursor-pointer mt-4"
          >
            <ToggleIcon isOpen={showTip4} />
            <div className="ml-2">
              <span className="font-medium">Start each goal with a verb.</span>
              <span className="text-md font-extralight"> Examples:</span>
            </div>
          </div>
          {showTip4 && (
            <ul className="list-disc pl-8 mt-2">
              <li>
                <span className="font-semibold">Good:</span> Ask about the
                challenges faced with managing multiple online subscriptions.
              </li>
              <li>
                <span className="font-semibold">Bad:</span> Difficulties with
                subscription management.
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default GoalsHeaderBlock;
