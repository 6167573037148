import React from 'react';

const InterviewOnboardingContainer = ({ children }) => {
  return (
    <div className="pt-14 pb-20 px-5 sm:pt-10 sm:w-10/12 lg:w-6/12 sm:m-auto">
      {children}
    </div>
  );
};

export default InterviewOnboardingContainer;
