import React, { useState } from 'react';
// Comment out GIF imports
// import createInterviewGif from '../../assets/landing/create-interview.gif';
// import selectAudienceGif from '../../assets/landing/select-audience.gif';
// import interviewReviewGif from '../../assets/landing/interview-review.gif';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import SectionHeader from './SectionHeader';

const HowItWorks = () => {
  const [activeSection, setActiveSection] = useState(1);

  const toggleSection = (sectionNumber: number) => {
    setActiveSection(activeSection === sectionNumber ? 0 : sectionNumber);
  };

  // Comment out getGifSource function
  // const getGifSource = () => {
  //   switch (activeSection) {
  //     case 1:
  //       return createInterviewGif;
  //     case 2:
  //       return selectAudienceGif;
  //     case 3:
  //       return interviewReviewGif;
  //     default:
  //       return createInterviewGif;
  //   }
  // };

  const steps = [
    {
      title: 'Create an interview',
      description:
        'AI asks unbiased questions like a trained interviewer, based on the specific instructions you provided.',
    },
    {
      title: 'AI interviews real people',
      description:
        'Probe has access to 200,000 verified participants, making it easy to find your ideal interview candidates.',
    },
    {
      title: 'Insights delivered this afternoon',
      description:
        'Get completed transcripts and summaries of key insights within hours.',
    },
  ];

  return (
    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
      <SectionHeader
        title="Customer interviews, done this afternoon."
        subtitle="Probe uses AI to interview dozens of verified participants in hours, not weeks."
        isLong={true}
      />
      <div className="flex justify-center">
        <div className="w-full max-w-2.5xl space-y-4 bg-white rounded-2xl p-8 shadow-lg border-2 border-black">
          {steps.map((step, index) => (
            <div
              key={index}
              className="border-b border-gray-300 last:border-b-0"
            >
              <div
                className="py-6 cursor-pointer flex items-center justify-between group"
                onClick={() => toggleSection(index + 1)}
              >
                <h3 className="text-2xl sm:text-3xl font-medium text-black group-hover:text-gray-600 transition-colors duration-300">
                  {step.title}
                </h3>
                {activeSection === index + 1 ? (
                  <FaChevronUp className="text-black group-hover:text-gray-600 transition-colors duration-300" />
                ) : (
                  <FaChevronDown className="text-black group-hover:text-gray-600 transition-colors duration-300" />
                )}
              </div>
              <div
                className={`overflow-hidden transition-all duration-500 ${
                  activeSection === index + 1
                    ? 'max-h-40 opacity-100 mb-6'
                    : 'max-h-0 opacity-0'
                }`}
              >
                <p className="text-xl text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
