import React from 'react';
import SelectedFilter from './SelectedFilter.tsx';
import GradientButton from '../../Buttons/GradientButton.tsx';
import { ArrowUpRight } from 'lucide-react';

const SelectedFilters = ({
  filtersSelected,
  handleCheckboxToggle,
  toggleAllChoices,
  handleSliderChange,
  setSliderValue,
  deleteFilterFromFiltersSelected,
  openModalWithFilter,
  handleSubmit,
  isLandingPage,
}) => {
  if (filtersSelected.length === 0) {
    return (
      <p className="text-gray-500 font-light mt-3">No one selected yet.</p>
    );
  }

  return (
    <div className="text-gray-700">
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {filtersSelected.map((filter) => {
          const filterDataInSelected = filtersSelected.find(
            (f) => f.filter_id === filter.filter_id
          );
          const currentCheckedState =
            filterDataInSelected?.choicesSelected ?? [];
          const currentSliderValue =
            filterDataInSelected?.sliderSelections ?? [];
          const filterWithCurrentFilterStructure = {
            filter_id: filter.filter_id,
            title: filter.filterData.title,
            question: filter.filterData.question,
            type: filter.filterData.type,
            choices: filter.filterData.choices,
            min: filter.filterData.min,
            max: filter.filterData.max,
          };
          return (
            <div key={filter.filter_id}>
              <SelectedFilter
                currentFilter={filterWithCurrentFilterStructure ?? {}}
                toggleCheckbox={handleCheckboxToggle}
                toggleAllChoices={toggleAllChoices}
                changesSaved={null}
                handleCheckboxToggle={handleCheckboxToggle}
                checkedState={currentCheckedState}
                sliderValue={currentSliderValue}
                handleSliderChange={handleSliderChange}
                setSliderValue={setSliderValue}
                deleteFilter={deleteFilterFromFiltersSelected}
                openModalWithFilter={openModalWithFilter}
              />
            </div>
          );
        })}
      </div>
      {!isLandingPage && (
        <div className="mt-10 flex justify-center">
          <GradientButton
            onClick={handleSubmit}
            label="Confirm and Finish Setup"
            className="w-auto px-6 text-base hover:scale-105 bg-primary-contrast text-primary h-13 "
            rounding="rounded-2xl"
            icon={<ArrowUpRight className="w-5 mt-1" />}
          />
        </div>
      )}
    </div>
  );
};

export default SelectedFilters;
