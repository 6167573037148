import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import Animate from '../ui/Animate.tsx';
import Button from '../Buttons/Button.tsx';
import CommandButton from '../Buttons/CommandButton.tsx';

interface EmailCaptureProps {
  userEmail: string;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  handleSubmitEmail: () => void;
  handleSkipEmail: () => void;
  handleEmailFieldKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => void;
}

const EmailCapture: ForwardRefRenderFunction<
  HTMLInputElement,
  EmailCaptureProps
> = (
  {
    userEmail,
    setUserEmail,
    handleSubmitEmail,
    handleSkipEmail,
    handleEmailFieldKeyDown,
  },
  ref
) => {
  return (
    <>
      <Animate className="flex flex-col">
        <input
          ref={ref}
          type="text"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder="Type email here..."
          className="w-full mb-4 text-shadow-lg antialiased bg-transparent outline-none resize-none focus:outline-none mt-9"
          onKeyDown={handleEmailFieldKeyDown}
        />
      </Animate>
      <Animate>
        <div className="mt-4 sm:flex">
          <CommandButton onClick={handleSubmitEmail} primary={true} />
          <div className="mt-4 sm:mt-0">
            <Button
              label="Skip"
              onClick={handleSkipEmail}
              buttonType="tertiary"
            />
          </div>
        </div>
      </Animate>
    </>
  );
};

export default forwardRef(EmailCapture);
