import React from 'react';

interface SubheaderProps {
  text: string;
  className?: string;
}

const Subheader = ({ text, className }: SubheaderProps) => {
  return (
    <div
      className={`text-md sm:text-lg text-gray-700 mt-2 font-light sm:mt-3 2xl:font-light ${className}`}
    >
      {text}
    </div>
  );
};

export default Subheader;
