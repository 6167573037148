import React from 'react';
import { Calendar, Users, ChevronRight } from 'lucide-react';

interface InterviewCardProps {
  title: string;
  createdAt: string;
  participants: number;
  onClick: () => void;
}

const InterviewCard: React.FC<InterviewCardProps> = ({
  title,
  createdAt,
  participants,
  onClick,
}) => {
  return (
    <div
      className="w-full sm:w-[calc(50%-1rem)] lg:w-1/3 2xl:w-1/4 p-3 cursor-pointer transition duration-300 transform hover:-translate-y-1 group"
      onClick={onClick}
    >
      <div className="bg-white rounded-2xl shadow-lg overflow-visible relative border border-black p-4 h-full flex flex-col justify-between">
        <div>
          <span className="inline-block text-gray-600 text-xs px-2 py-0.5 rounded-md mb-2 border border-gray-600 font-medium">
            Interview
          </span>
          <h2 className="text-xl font-bold mb-2 line-clamp-2 overflow-ellipsis">
            {title}
          </h2>
          <div className="flex items-center text-gray-600 text-sm mb-3">
            <Calendar className="h-4 w-4 mr-1 flex-shrink-0" />
            <span className="truncate">Created {createdAt}</span>
          </div>
          <div className="flex items-center text-gray-600 text-sm">
            <Users className="h-4 w-4 mr-1 flex-shrink-0" />
            <span>
              {participants} Participant{participants !== 1 ? 's' : ''}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-end mt-4">
          <span className="text-sm font-medium mr-1">View Details</span>
          <ChevronRight className="h-4 w-4 text-black" />
        </div>
      </div>
    </div>
  );
};

export default InterviewCard;
