import React, { useState, useEffect } from 'react';
import {
  Image as ImageIcon,
  X as XIcon,
  Maximize2,
  Loader,
} from 'lucide-react';
import toast from 'react-hot-toast';

interface ImageUploaderProps {
  onUpload: (file: File) => Promise<string>;
  initialImage?: string | null;
  onDelete?: () => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onUpload,
  initialImage,
  onDelete,
}) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(
    initialImage || null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setUploadedImage(initialImage || null);
  }, [initialImage]);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Check file type
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/svg+xml',
        'image/webp',
      ];
      if (!allowedTypes.includes(file.type)) {
        toast.error(
          'Invalid file type. Please upload an image (JPEG, PNG, GIF, SVG, or WebP).',
          {
            position: 'bottom-right',
          }
        );
        return;
      }

      // Check file size (max 20MB)
      const maxSize = 20 * 1024 * 1024; // 20MB in bytes
      if (file.size > maxSize) {
        toast.error(
          'File is too large. Please upload an image smaller than 20MB.',
          {
            position: 'bottom-right',
          }
        );
        return;
      }

      setIsLoading(true);
      try {
        const imageUrl = await onUpload(file);
        setUploadedImage(imageUrl);
        toast.success('Image uploaded successfully!', {
          position: 'bottom-right',
        });
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image. Please try again.', {
          position: 'bottom-right',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDelete = () => {
    setUploadedImage(null);
    if (onDelete) {
      onDelete();
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-64 border-2 border-gray-300 rounded-lg">
        <Loader className="w-8 h-8 animate-spin text-gray-500" />
        <span className="ml-2 text-gray-500">Uploading image...</span>
      </div>
    );
  }

  if (uploadedImage) {
    return (
      <>
        <div className="relative w-full h-64 border-2 border-gray-300 rounded-lg overflow-hidden">
          <img
            src={uploadedImage}
            alt="Uploaded preview"
            className="w-full h-full object-cover cursor-pointer"
            onClick={toggleModal}
          />
          <button
            onClick={handleDelete}
            className="absolute top-2 right-2 p-1 bg-gray-400 rounded-full text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            <XIcon className="w-5 h-5" />
          </button>
          <button
            onClick={toggleModal}
            className="absolute bottom-2 right-2 p-1 bg-gray-400 rounded-full text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            <Maximize2 className="w-5 h-5" />
          </button>
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="max-w-4xl max-h-4xl relative">
              <img
                src={uploadedImage}
                alt="Full size preview"
                className="max-w-full max-h-[90vh] object-contain"
              />
              <button
                onClick={toggleModal}
                className="absolute top-2 right-2 p-1 bg-gray-500 rounded-full text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              >
                <XIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="flex items-center justify-center w-full">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <ImageIcon className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            JPEG, PNG, GIF, SVG, or WebP (Max 20MB)
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          onChange={handleUpload}
          accept="image/*"
        />
      </label>
    </div>
  );
};

export default ImageUploader;
