import React from 'react';

interface CheckboxRightProps {
  isChecked: boolean;
  handleCheckboxToggle: (filterId: string, value: number) => void;
  text: string;
  choiceNumber: number;
  filterId: string;
}

const CheckboxRight = ({
  isChecked,
  handleCheckboxToggle,
  text,
  choiceNumber,
  filterId,
}: CheckboxRightProps) => {
  const handleOnChange = () => {
    isChecked = !isChecked;
    handleCheckboxToggle(filterId, choiceNumber);
  };

  return (
    <div className="flex items-center">
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text text-black mr-3">{text}</span>
          <input
            type="checkbox"
            checked={isChecked}
            className="checkbox border-gray-400"
            onChange={handleOnChange}
          />
        </label>
      </div>
    </div>
  );
};

export default CheckboxRight;
