import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ChatInterface from './SurveyInterface.tsx';
import AnswerInput from './AnswerInput.tsx';
import FeedbackDisplay from './FeedbackDisplay.tsx';
import { FilterSelected, ChatMessage } from '../../../../common/types.ts';
import { getFiltersSelectedFromFirestore } from '../../../utils/firestoreStudy.ts';
import TextLoader from '../../ui/TextLoading.tsx';

interface State {
  filters: FilterSelected[];
  currentFilterIndex: number;
  chatHistory: ChatMessage[];
  screeningStatus: 'in progress' | 'passed' | 'failed';
  messageHistory: Array<{
    role: 'system' | 'user' | 'assistant';
    content: string;
  }>;
  isLoading: boolean;
  followUpHistory: Array<{
    question: string;
    answer: string;
  }>;
  loadingMessage: React.ReactNode | null;
}

type Action =
  | { type: 'SET_FILTERS'; payload: FilterSelected[] }
  | { type: 'NEXT_QUESTION' }
  | { type: 'UPDATE_CHAT'; payload: ChatMessage }
  | { type: 'SET_SCREENING_STATUS'; payload: 'passed' | 'failed' }
  | { type: 'SET_LOADING'; payload: boolean }
  | {
      type: 'UPDATE_MESSAGE_HISTORY';
      payload: { role: 'system' | 'user' | 'assistant'; content: string };
    }
  | { type: 'RESET_FOLLOW_UP_HISTORY' }
  | { type: 'ADD_FOLLOW_UP'; payload: { question: string; answer: string } }
  | { type: 'SET_LOADING_MESSAGE'; payload: React.ReactNode | null };

const initialState: State = {
  filters: [],
  currentFilterIndex: -1,
  chatHistory: [],
  screeningStatus: 'in progress',
  messageHistory: [],
  isLoading: false,
  followUpHistory: [],
  loadingMessage: null,
};

const SurveyScreener: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { interviewId, studyId } = useParams();
  const navigate = useNavigate();
  const isDevelopment = process.env.NODE_ENV === 'development';
  const [redirectCountdown, setRedirectCountdown] = useState<number | null>(
    null
  );
  const [shouldAskNextQuestion, setShouldAskNextQuestion] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);

  const handleScreenOut = useCallback(() => {
    dispatch({
      type: 'UPDATE_CHAT',
      payload: {
        type: 'system',
        content: `We noticed your answers don't quite line up with your profile information. To make sure you get surveys that are a good fit for you, please take a moment to double-check your profile. We'd appreciate it if you could return this study. Thank you for your time and participation!`,
      },
    });
    setRedirectCountdown(10);
  }, []);

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'SET_FILTERS':
        return { ...state, filters: action.payload };
      case 'NEXT_QUESTION':
        return {
          ...state,
          currentFilterIndex: state.currentFilterIndex + 1,
        };
      case 'UPDATE_CHAT':
        if (action.payload.content === '') {
          return { ...state, chatHistory: state.chatHistory.slice(0, -1) };
        }
        return {
          ...state,
          chatHistory: [...state.chatHistory, action.payload],
        };
      case 'SET_SCREENING_STATUS':
        return { ...state, screeningStatus: action.payload };
      case 'SET_LOADING':
        return { ...state, isLoading: action.payload };
      case 'UPDATE_MESSAGE_HISTORY':
        return {
          ...state,
          messageHistory: [...state.messageHistory, action.payload],
        };
      case 'RESET_FOLLOW_UP_HISTORY':
        return {
          ...state,
          followUpHistory: [],
        };
      case 'ADD_FOLLOW_UP':
        return {
          ...state,
          followUpHistory: [...state.followUpHistory, action.payload],
        };
      default:
        return state;
    }
  }

  const callAnthropicAPI = async (
    prompt: string,
    message: string
  ): Promise<any> => {
    const url = isDevelopment
      ? 'http://127.0.0.1:5001/joinprobe/us-central1/getAnthropicResponse'
      : 'https://us-central1-joinprobe.cloudfunctions.net/getAnthropicResponse';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt, message }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Network response was not ok: ${response.status} ${response.statusText}. ${errorText}`
        );
      }

      const data = await response.json();
      return data.response;
    } catch (error) {
      console.error('Error calling Anthropic API:', error);
      throw error;
    }
  };

  const callChatGPT = async (prompt: string): Promise<any> => {
    const developmentUrl =
      'http://127.0.0.1:5001/joinprobe/us-central1/getChatGPT4JSONResponse';
    const productionUrl =
      'https://us-central1-joinprobe.cloudfunctions.net/getChatGPT4JSONResponse';
    const url = isDevelopment ? developmentUrl : productionUrl;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Network response was not ok: ${response.status} ${response.statusText}. ${errorText}`
        );
      }

      const data = await response.json();

      if (typeof data.response === 'string') {
        try {
          return JSON.parse(data.response);
        } catch (parseError) {
          console.error('Error parsing JSON response:', parseError);
          return data.response;
        }
      } else if (typeof data.response === 'object') {
        return data.response;
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error calling ChatGPT:', error);
      throw error;
    }
  };

  const formatIdealResponse = (filter: FilterSelected): string => {
    if (filter.choicesSelected && Array.isArray(filter.choicesSelected)) {
      if (filter.filterData && filter.filterData.choices) {
        const selectedChoices = filter.choicesSelected.map(
          (index) => `"${filter.filterData.choices[index]}"`
        );
        return selectedChoices.join(' OR ');
      }
      return 'Invalid choices data';
    } else if (
      filter.sliderSelections &&
      Array.isArray(filter.sliderSelections)
    ) {
      return `"${filter.sliderSelections[0]}-${filter.sliderSelections[1]}"`;
    }
    return 'Invalid filter data';
  };

  const rephraseQuestion = async (filter: FilterSelected): Promise<string> => {
    const prompt = `
Given a question, title, description, and a set of potential responses, your task is to generate a new question that is clear, specific, and makes sense without needing the extra context provided by the description or the responses. The new question should be self-explanatory, straightforward to answer on its own, and clearly indicate the type of response expected.
IMPORTANT: The new question should make sense completely on its own, without having any selectable options. It should be specific enough to elicit responses that align with the intended options, even if the reader has no additional context. For example:
BAD QUESTION: "Are you employed in any of the following roles?" This is bad because it requires the options to be listed.
GOOD QUESTION: "What is your current professional role in your organization?" This clearly indicates the type of response without being vague.
BAD QUESTION: "What are your active interests?" This is too vague and could lead to unrelated responses.
GOOD QUESTION: "In what ways are you an active creator of content on the internet?" This clearly indicates the type of response expected, focusing on online content creation.
Return the new question in the following JSON format: {"question": "insert_question_here"}.
Example 1:
Input:
{
"question": "What is your date of birth?",
"title": "Age",
"description": "Participants were asked their date of birth. Please specify a current age range between min and max.",
"options": "max 100, min 18"
}
Output:
{"question": "What is your age?"}
Example 2:
Input:
{
"question": "Which of the following describes your function in your organization?",
"title": "Function in Organization",
"description": "",
"options": [
"0 Account Management",
"1 Administration/ Personal Assistant",
"2 Chemical / Mechanical / Electrical / Civil Engineering",
"3 CX / Customer Experience / Support"
]
}
Output:
{"question": "What is your job function or profession in your organization?"}
Example 3:
Input:
{
"question": "Do you actively engage as any of the following?",
"title": "Active interests",
"description": "",
"options": [
"Influencer",
"Blogger",
"Video content creator",
"Musician",
"Artist"
]
}
Output:
{"question": "In what ways are you an active creator of content on the internet?"}
Below is the input data. Your task is to return the new question in the specified output format.

INPUT:
`;

    try {
      const response = await callAnthropicAPI(
        prompt,
        JSON.stringify({
          question: filter.filterData.question,
          title: filter.filterData.title,
          description: filter.filterData.description,
          options: filter.filterData.choices || filter.sliderSelections,
        })
      );

      try {
        const parsedResponse = JSON.parse(response);
        return parsedResponse.question;
      } catch (parseError) {
        console.error('Error parsing JSON response:', parseError);
        return filter.filterData.question; // Fallback to original question if parsing fails
      }
    } catch (error) {
      console.error('Error rephrasing question:', error);
      return filter.filterData.question; // Fallback to original question if there's an error
    }
  };

  const askNextQuestion = useCallback(async () => {
    if (state.currentFilterIndex >= state.filters.length) {
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    dispatch({
      type: 'UPDATE_CHAT',
      payload: {
        type: 'system',
        content: <TextLoader />,
      },
    });

    const nextFilter = state.filters[state.currentFilterIndex];
    if (nextFilter && nextFilter.filterData) {
      const rephrasedQuestion = await rephraseQuestion(nextFilter);
      dispatch({
        type: 'UPDATE_CHAT',
        payload: {
          type: 'system',
          content: '',
        },
      });
      dispatch({
        type: 'UPDATE_CHAT',
        payload: {
          type: 'system',
          content: rephrasedQuestion,
        },
      });
      dispatch({ type: 'RESET_FOLLOW_UP_HISTORY' });
      dispatch({
        type: 'ADD_FOLLOW_UP',
        payload: { question: rephrasedQuestion, answer: '' },
      });
    } else {
      dispatch({
        type: 'UPDATE_CHAT',
        payload: {
          type: 'system',
          content: 'Error: Unable to load the next question.',
        },
      });
    }
    dispatch({ type: 'SET_LOADING', payload: false });
  }, [state.currentFilterIndex, state.filters, rephraseQuestion]);

  const handleUserAnswer = async (answer: string) => {
    dispatch({
      type: 'UPDATE_CHAT',
      payload: { type: 'user', content: answer },
    });

    // Show loading indicator
    dispatch({ type: 'SET_LOADING', payload: true });
    dispatch({
      type: 'UPDATE_CHAT',
      payload: {
        type: 'system',
        content: <TextLoader />,
      },
    });

    const currentFilter = state.filters[state.currentFilterIndex];
    if (!currentFilter || !currentFilter.filterData) {
      console.error('Invalid filter data:', currentFilter);
      dispatch({ type: 'SET_SCREENING_STATUS', payload: 'failed' });
      dispatch({ type: 'SET_LOADING', payload: false });
      return;
    }

    const idealResponse = formatIdealResponse(currentFilter);
    const inputData = {
      original_question: currentFilter.filterData.question,
      ideal_response: idealResponse,
      actual_response: answer,
      follow_up_history: state.followUpHistory,
    };

    const systemPrompt = `
  You are an expert in determining if participants are a good fit for a survey based on their responses to screener questions. Your task is to evaluate whether the actual response meets the criteria specified by the ideal response. You should return a JSON object with a "result" key, where the value can be one of three options:

    1. "true" if the actual response clearly meets the criteria
    2. "false" if the actual response clearly does not meet the criteria
    3. "needs_clarification" if the response is ambiguous, partially matches, or requires more information to make a definitive decision

Guidelines for evaluation:

1. Return "true" if:
    - The actual response exactly matches the ideal response.
    - The actual response is clearly within the category specified by the ideal response.
    Example: Ideal "Sales & Marketing", Actual "Marketing Manager" -> true
2. Return "false" if:
    - The actual response clearly does not meet the criteria specified in the ideal response.
    Example: Ideal "18-55", Actual "70" -> false
3. Return "needs_clarification" if:
    - The actual response is too vague or general to determine if it meets the criteria.
    Example: Ideal "SMEs or large private enterprises", Actual "Big size" -> needs_clarification
    - The actual response might be related to the ideal response, but more information is needed.
    - The actual response is a broader category that may include the ideal response.
    Example: Ideal "Northern Ireland", Actual "Ireland" -> needs_clarification

IMPORTANT: RETURN ONLY THE JSON OBJECT, WITHOUT ANY EXPLANATION OR ADDITIONAL TEXT.

For money related questions, if an actual_response is in a currency that is different from  the ideal_response, convert the currency to the ideal response currency, without asking for further clarification. Rough estimates are okay.

Here are some examples of proper responses given particular inputs:

Example 1:
{
"question": "What is your current age?",
"ideal_response": "18-55",
"actual_response": "70"
}
Expected Output:
{
"result": "false"
}

Example 2:
{
"question": "What is your profession?",
"ideal_response": "Sales & Marketing OR Data Analytics",
"actual_response": "Marketing Manager"
}
Expected Output:
{
"result": "true"
}

Example 3:
{
"question": "What region do you live in?",
"ideal_response": "Northern Ireland",
"actual_response": "Ireland"
}
Expected Output:
{
"result": "needs_clarification"
}

Example 4:
{
"question": "What is your level within a company?",
"ideal_response": "Upper Management",
"actual_response": "I'm a VP"
}
Expected Output:
{
"result": "true"
}

Example 5:
{
"question": "Which software development techniques are you knowledgeable about?",
"ideal_response": "Cloud computing OR database technologies",
"actual_response": "I'm a senior full-stack developer, so whatever they typically know, I probably do."
}
Expected Output:
{
"result": "true"
}

Use the provided format and guidelines to assess the response and return the appropriate JSON object.

Your task:
Given the following inputs, determine whether the actual response meets the criteria specified by the ideal response and return the appropriate JSON object with a "result" key containing either "true", "false", or "needs_clarification".`;

    const chatMessage = `INPUT:
  ${JSON.stringify(inputData)}`;

    try {
      const response = await callAnthropicAPI(systemPrompt, chatMessage);
      console.log('Claude response:', response);

      // Remove loading indicator
      dispatch({
        type: 'UPDATE_CHAT',
        payload: {
          type: 'system',
          content: '',
        },
      });

      let result;
      try {
        const parsedResponse = JSON.parse(response);
        result = parsedResponse.result;
      } catch (parseError) {
        console.error('Error parsing JSON response:', parseError);
        const lowerResponse = response.toLowerCase();
        if (
          lowerResponse.includes('true') &&
          !lowerResponse.includes('false')
        ) {
          result = 'true';
        } else if (
          lowerResponse.includes('false') &&
          !lowerResponse.includes('true')
        ) {
          result = 'false';
        } else if (lowerResponse.includes('needs_clarification')) {
          result = 'needs_clarification';
        } else {
          result = 'false';
        }
      }

      if (result === 'true') {
        if (state.currentFilterIndex === state.filters.length - 1) {
          dispatch({ type: 'SET_SCREENING_STATUS', payload: 'passed' });
          dispatch({
            type: 'UPDATE_CHAT',
            payload: {
              type: 'system',
              content:
                "Great news! You're a perfect fit for this interview. From this point on, please provide deep, detailed answers to the questions asked.",
            },
          });
          setRedirectCountdown(5);
        } else {
          dispatch({ type: 'NEXT_QUESTION' });
          dispatch({ type: 'RESET_FOLLOW_UP_HISTORY' });
          setShouldAskNextQuestion(true);
        }
      } else if (result === 'false') {
        dispatch({ type: 'SET_SCREENING_STATUS', payload: 'failed' });
        handleScreenOut();
      } else if (result === 'needs_clarification') {
        await handleClarification(inputData);
      }
    } catch (error) {
      console.error('Error in handleUserAnswer:', error);
      dispatch({ type: 'SET_SCREENING_STATUS', payload: 'failed' });
      handleScreenOut();
    } finally {
      // Ensure loading is set to false after all operations
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const handleClarification = async (inputData: any) => {
    const clarificationPrompt = `
    You are an expert interviewer tasked with clarifying ambiguous responses during a screening process. Your goal is to ask follow-up questions that will help determine whether a participant meets the criteria for a survey. You should formulate clear, specific questions that address the ambiguity in the original response.

    Input:
    You will receive the following information:

    1. original_question: The initial screening question
    2. ideal_response: The criteria for an acceptable response
    3. actual_response: The participant's original answer
    4. previous_follow_ups: An array of previous follow-up questions and responses (if any)

    Output:
    You should return a JSON object with the following structure:
    {
    "follow_up_question": "Your follow-up question here"
    }

    Guidelines:

    1. Focus on the specific aspect of the response that needs clarification.
    2. Ask open-ended questions that encourage detailed responses.
    3. Avoid leading questions that suggest a desired answer.
    4. Keep the questions concise and easy to understand.
    5. If multiple clarifications are needed, prioritize the most critical one.

    IMPORTANT: RETURN ONLY THE JSON OBJECT, WITHOUT ANY EXPLANATION OR ADDITIONAL TEXT.

    Examples:

    Example 1:
    Input:
    {
    "original_question": "What region do you live in?",
    "ideal_response": "Northern Ireland",
    "actual_response": "Ireland",
    "previous_follow_ups": []
    }
    Output:
    {
    "follow_up_question": "Could you please specify which part of Ireland you live in? Are you in the Republic of Ireland or Northern Ireland?"
    }

    Example 2:
    Input:
    {
    "original_question": "What size of company do you work for?",
    "ideal_response": "SMEs or large private enterprises",
    "actual_response": "Big size",
    "previous_follow_ups": []
    }
    Output:
    {
    "follow_up_question": "Could you provide more details about your company's size? For example, how many employees does it have, or what's its annual revenue range?"
    }

    Example 3:
    Input:
    {
    "original_question": "What are your active interests or roles?",
    "ideal_response": "Creating videos online OR making music",
    "actual_response": "I'm into digital stuff",
    "previous_follow_ups": []
    }
    Output:
    {
    "follow_up_question": "Could you elaborate on what you mean by 'digital stuff'? Are you involved in creating any specific type of digital content?"
    }

    Example 4:
    Input:
    {
    "original_question": "What is your profession?",
    "ideal_response": "Sales & Marketing OR Data Analytics",
    "actual_response": "I work with numbers",
    "previous_follow_ups": [
    {
    "question": "Could you be more specific about your role? What kind of work do you do with numbers?",
    "response": "I analyze data for the marketing team"
    }
    ]
    }
    Output:
    {
    "follow_up_question": "Thank you for clarifying. Is your primary role focused on data analysis, or do you also have responsibilities in sales or marketing?"
    }

    Your task:
    Given the input information, formulate an appropriate follow-up question to clarify the participant's response. Return your response in the specified JSON format with only the follow_up_question field.
    `;

    dispatch({ type: 'SET_LOADING', payload: true });
    dispatch({
      type: 'UPDATE_CHAT',
      payload: {
        type: 'system',
        content: <TextLoader />,
      },
    });

    try {
      const response = await callAnthropicAPI(
        clarificationPrompt,
        JSON.stringify(inputData)
      );
      console.log('Clarification response:', response);

      // Remove loading indicator
      dispatch({
        type: 'UPDATE_CHAT',
        payload: {
          type: 'system',
          content: '',
        },
      });

      let followUpQuestion;
      try {
        const parsedResponse = JSON.parse(response);
        followUpQuestion = parsedResponse.follow_up_question;
      } catch (parseError) {
        console.error('Error parsing JSON response:', parseError);
        followUpQuestion = response;
      }

      if (followUpQuestion) {
        dispatch({
          type: 'UPDATE_CHAT',
          payload: { type: 'system', content: followUpQuestion },
        });
        dispatch({
          type: 'ADD_FOLLOW_UP',
          payload: { question: followUpQuestion, answer: '' },
        });
      } else {
        throw new Error('Invalid clarification response');
      }
    } catch (error) {
      console.error('Error in handleClarification:', error);
      dispatch({ type: 'SET_SCREENING_STATUS', payload: 'failed' });
      handleScreenOut();
    } finally {
      // Ensure loading is set to false after all operations
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [state.chatHistory]);

  useEffect(() => {
    const fetchFilters = async () => {
      if (interviewId && studyId) {
        try {
          const filters = await getFiltersSelectedFromFirestore(
            interviewId,
            studyId
          );
          if (!Array.isArray(filters) || filters.length === 0) {
            throw new Error('No filters found');
          }
          dispatch({ type: 'SET_FILTERS', payload: filters });

          dispatch({
            type: 'UPDATE_CHAT',
            payload: {
              type: 'system',
              content: `Hi! I'm Probe, your AI interviewing assistant. \n\n Before we dive in, I have a few quick questions to make sure this survey is a good fit for you. \n\n This section should take less than a minute, and just a few words for each answer is perfect.`,
            },
          });

          dispatch({ type: 'NEXT_QUESTION' });
          setShouldAskNextQuestion(true);
        } catch (error) {
          console.error('Error fetching filters:', error);
          dispatch({ type: 'SET_SCREENING_STATUS', payload: 'failed' });
        }
      }
    };

    fetchFilters();
  }, [interviewId, studyId]);

  useEffect(() => {
    if (shouldAskNextQuestion) {
      askNextQuestion();
      setShouldAskNextQuestion(false);
    }
  }, [shouldAskNextQuestion, askNextQuestion]);

  useEffect(() => {
    if (redirectCountdown !== null && redirectCountdown > 0) {
      const timer = setTimeout(() => {
        setRedirectCountdown(redirectCountdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else if (redirectCountdown === 0) {
      if (state.screeningStatus === 'passed') {
        navigate(`/voiceinterview/${interviewId}/${studyId}`);
      } else {
        // Redirect to Prolific to return the study
        window.location.href = `https://app.prolific.com/submissions/complete?cc=INVALID_FILTERS`;
      }
    }
  }, [
    redirectCountdown,
    navigate,
    interviewId,
    studyId,
    state.screeningStatus,
  ]);

  return (
    <div className="min-h-screen w-11/12 md:w-6/12 mx-auto pt-14 pb-24 flex flex-col">
      <div className="flex-grow">
        <ChatInterface chatHistory={state.chatHistory} />
        <div ref={bottomRef} />
      </div>
      {state.screeningStatus === 'in progress' && (
        <div className="fixed bottom-4 left-0 right-0 w-11/12 md:w-6/12 mx-auto">
          <AnswerInput onSubmit={handleUserAnswer} disabled={state.isLoading} />
        </div>
      )}
      {(state.screeningStatus === 'failed' ||
        state.screeningStatus === 'passed') &&
        redirectCountdown !== null && (
          <div className="fixed bottom-0 left-0 right-0 w-full">
            <FeedbackDisplay
              message={`Redirecting in ${redirectCountdown} seconds...`}
            />
          </div>
        )}
    </div>
  );
};

export default SurveyScreener;
