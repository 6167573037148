import React from 'react';

interface StartingScreenProps {
  onStartInterview: () => void;
}

const StartingScreen: React.FC<StartingScreenProps> = ({
  onStartInterview,
}) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-100 to-gray-200 p-8">
      <div className="bg-white rounded-2xl shadow-2xl p-10 max-w-2xl w-full">
        <h1 className="text-3xl font-bold mb-8 text-gray-900 leading-tight">
          Welcome to an AI-moderated voice interview.
        </h1>
        <div className="space-y-6 text-gray-700 mb-10 text-lg">
          <p>
            You're about to begin a 7-minute voice interview with an AI
            assistant.
          </p>
          <p>
            This will be a relaxed conversation, so feel free to speak naturally
            and share your thoughts openly.
          </p>
          <p>
            Don't worry about perfect phrasing - you can always clarify or
            correct yourself if needed.
          </p>
          <p>
            The audio recording is not saved, but the transcripts will be used
            for reseach purposes.
          </p>
          <p className="text-gray-800">
            <span className="font-semibold">
              When your browser requests microphone access, please click
              'Allow'.
            </span>{' '}
            If you accidentally reject it, you can refresh the page to try
            again.
          </p>
        </div>
        <button
          onClick={onStartInterview}
          className="w-full bg-black text-white hover:bg-gray-800 font-bold py-4 px-8 rounded-full transition-all duration-300 ease-in-out text-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transform hover:scale-105"
        >
          Start Interview
        </button>
      </div>
    </div>
  );
};

export default StartingScreen;
