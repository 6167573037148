import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import createQueryHook from '../queries/createQueryHook';

interface InterviewSummaryData {
  overview: any;
  questions: SummaryQuestion[];
}

export interface SummaryQuestion {
  question: string;
  image: null;
  aiSummary: AISummary;
  isAIGenerated: boolean;
  maxFollowUps: number;
  id: string;
  type: string;
  allowSkip: boolean;
  aiFollowUps: boolean;
  aiInstructions: string;
}

export interface AISummary {
  notableQuotes: string[];
  summary: string;
}

const fetchInterviewSummary = async (
  interviewId: string
): Promise<InterviewSummaryData> => {
  const docRef = doc(db, 'interviews', interviewId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as InterviewSummaryData;
  } else {
    throw new Error('No such document!');
  }
};

const useInterviewSummary = createQueryHook(
  fetchInterviewSummary,
  (interviewId: string) => ['interviewSummary', interviewId]
);

export default useInterviewSummary;
