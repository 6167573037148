import React from 'react';
import googleLogo from '../../assets/icons/googleLogo.svg';

interface GoogleButtonProps {
  label: string;
  onClick: () => void;
  isPrimary?: boolean;
  rounding?:
    | 'rounded-none'
    | 'rounded-sm'
    | 'rounded'
    | 'rounded-md'
    | 'rounded-lg'
    | 'rounded-xl'
    | 'rounded-2xl'
    | 'rounded-3xl'
    | 'rounded-full';
}

const GoogleButton = ({
  label,
  onClick,
  isPrimary,
  rounding = 'rounded-full',
}: GoogleButtonProps) => {
  const additionalContainerStyling = isPrimary
    ? 'bg-[#3F53FE] text-primary-contrast hover:bg-[#3F53FEBB]'
    : 'bg-transparent text-primary-contrast border border-gray-600 hover:bg-gray-100';

  return (
    <button
      className={`btn gap-2 flex py-2 w-full ${additionalContainerStyling} ${rounding}`}
      onClick={onClick}
    >
      <img src={googleLogo} alt="google logo" className="w-6 h-6" />
      <p className="normal-case text-lg font-normal">{label}</p>
    </button>
  );
};

export default GoogleButton;
