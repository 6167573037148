import { ProbeScreenerQuestion } from '@functions/common/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { saveScreenerData } from 'src/utils/firestore';

type SaveScreenerDataRequest = {
  interviewId: string;
  screenerQuestions: ProbeScreenerQuestion[];
};

const useSaveScreenerData = (
  options?: Partial<UseMutationOptions<any, Error, SaveScreenerDataRequest>>
) => {
  return useMutation<SaveScreenerDataRequest, Error, SaveScreenerDataRequest>({
    // @ts-ignore
    mutationFn: (data: SaveScreenerDataRequest) => {
      return saveScreenerData(data.interviewId, data.screenerQuestions);
    },
    ...options,
  });
};

export default useSaveScreenerData;
