import React from 'react';

interface DropdownProps {
  children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ children }) => {
  return (
    <div className="absolute right-0 mt-2 w-64 bg-primary rounded-md border border-gray-600 shadow-lg z-10">
      <div className="py-1">{children}</div>
    </div>
  );
};

export default Dropdown;
