import React from 'react';
import Animate from './ui/Animate.tsx';
import StyledProgressBar from './ui/StyledProgressBar.tsx';

interface PromptScreenProps {
  children: React.ReactNode;
  percentComplete?: number;
  isDemo?: boolean;
  isLightMode?: boolean;
}

export default function PromptScreen({
  children,
  percentComplete,
  isDemo = false,
  isLightMode = false,
}: PromptScreenProps) {
  const conditionalPadding = isDemo ? '' : 'p-[20px]';
  const conditionalMargin = isDemo ? '' : 'sm:mt-20';

  return (
    <div
      className={`flex items-center justify-center flex-col text-xl text-shadow-lg antialiased ${conditionalPadding}`}
    >
      <div className="w-full flex flex-1 flex-col overflow-y-auto">
        <div className="w-11/12 sm:w-3/5 md:w-2/5 mx-auto">
          {percentComplete !== undefined && (
            <StyledProgressBar
              percentComplete={percentComplete}
              isLightMode={isLightMode}
            />
          )}
        </div>
        <div className={`my-auto pt-6 pb-32 sm:pb-6 ${conditionalMargin}`}>
          {children}
        </div>
      </div>
    </div>
  );
}
