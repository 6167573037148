import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

interface StyledProgressBarProps {
  percentComplete: number;
  isLightMode?: boolean;
}

const StyledProgressBar = ({
  percentComplete,
  isLightMode = false,
}: StyledProgressBarProps) => {
  const percentCompleteMax100 = Math.min(percentComplete, 100);

  return (
    <ProgressBar
      completed={percentCompleteMax100}
      width="100%"
      bgColor={
        isLightMode ? 'rgba(0, 0, 0, 0.07)' : 'rgba(255, 255, 255, 0.07)'
      }
      baseBgColor={
        isLightMode ? 'rgba(0, 0, 0, 0.07)' : 'rgba(255, 255, 255, 0.07)'
      }
      labelColor={
        isLightMode ? 'rgba(0, 0, 0, 0.28)' : 'rgba(255, 255, 255, 0.28)'
      }
      height="14px"
      labelSize="10px"
      animateOnRender={true}
    />
  );
};

export default StyledProgressBar;
