import React from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { ChartContainer } from './Chart';

interface CustomBarProps {
  data: { value: number; count: number }[];
  maxVal: number;
  title: string;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 border border-gray-200 rounded shadow">
        <p className="text-sm font-semibold mb-1">{payload[0].payload.value}</p>
        <p className="text-sm">{`Count: ${payload[0].payload.count}`}</p>
      </div>
    );
  }
  return null;
};

export function CustomBar({ data, maxVal, title }: CustomBarProps) {
  const chartData = React.useMemo(() => {
    return Array.from({ length: maxVal }, (_, i) => i + 1).map((value) => {
      const dataPoint = data.find((d) => d.value === value);
      return { value, count: dataPoint ? dataPoint.count : 0 };
    });
  }, [data, maxVal]);

  return (
    <ChartContainer config={{}}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <XAxis dataKey="value" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
