import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

type QueryFn<TParams, TResponse> = (params: TParams) => Promise<TResponse>;

function createQueryHook<TParams, TResponse>(
  queryFn: QueryFn<TParams, TResponse>,
  getQueryKey: (params: TParams) => unknown[]
) {
  return (
    params: TParams,
    options?: Omit<
      UseQueryOptions<TResponse, Error, TResponse>,
      'queryKey' | 'queryFn'
    >
  ): UseQueryResult<TResponse, Error> => {
    return useQuery<TResponse, Error>({
      queryKey: getQueryKey(params),
      queryFn: () => queryFn(params),
      ...options,
    });
  };
}

export default createQueryHook;
