import {
  Question,
  Answer,
  AnswerWithQuestion,
  InterviewQuestions,
} from '@functions/common/types';
import {
  OpenEndedAnswer,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  RatingAnswer,
} from '@functions/common/types';

export function combineAnswerWithQuestion(
  answer: Answer,
  questions: InterviewQuestions
): AnswerWithQuestion {
  const originalQuestion = questions.find((q) => q.id === answer.questionId);
  if (!originalQuestion) {
    throw new Error(`Question not found for answer: ${answer.questionId}`);
  }

  // Create a flattened version of the answer with question data
  const flattenedAnswer = {
    questionId: answer.questionId,
    timestamp: answer.timestamp,
    type: answer.type,
    questionText: originalQuestion.question,
    questionType: originalQuestion.type,
  };

  // Add type-specific answer data
  switch (answer.type) {
    case 'open-ended':
    case 'ai-follow-ups':
      return {
        ...flattenedAnswer,
        answer: (answer as OpenEndedAnswer).answer,
      };
    case 'multiple-choice':
      return {
        ...flattenedAnswer,
        selectedOptions: (answer as MultipleChoiceAnswer).selectedOptions,
      };
    case 'single-select':
      return {
        ...flattenedAnswer,
        selectedOption: (answer as SingleChoiceAnswer).selectedOption,
      };
    case 'rating':
      return {
        ...flattenedAnswer,
        selectedRating: (answer as RatingAnswer).selectedRating,
      };
    default:
      throw new Error(`Unsupported answer type: ${answer.type}`);
  }
}

export function combineAllAnswersWithQuestions(
  answers: Record<string, string | string[] | number>,
  questions: InterviewQuestions
): AnswerWithQuestion[] {
  return questions.reduce((acc: AnswerWithQuestion[], question) => {
    const answerValue = answers[question.id];
    if (answerValue !== undefined && answerValue !== '') {
      let answer: Answer;
      switch (question.type) {
        case 'open-ended':
        case 'ai-follow-ups':
          answer = {
            questionId: question.id,
            timestamp: new Date().toISOString(),
            type: question.type,
            answer: answerValue as string,
          };
          break;
        case 'multiple-choice':
          answer = {
            questionId: question.id,
            timestamp: new Date().toISOString(),
            type: question.type,
            selectedOptions: answerValue as string[],
          };
          break;
        case 'single-select':
          if (typeof answerValue === 'string' && answerValue.trim() !== '') {
            answer = {
              questionId: question.id,
              timestamp: new Date().toISOString(),
              type: question.type,
              selectedOption: answerValue,
            };
          } else {
            return acc; // Skip this answer if it's an empty string
          }
          break;
        case 'rating':
          answer = {
            questionId: question.id,
            timestamp: new Date().toISOString(),
            type: question.type,
            selectedRating: answerValue as number,
          };
          break;
        default:
          throw new Error(`Unsupported question type: ${question.type}`);
      }
      acc.push({
        ...answer,
        originalQuestion: question,
      });
    }
    return acc;
  }, []);
}
