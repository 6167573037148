import React from 'react';
import { FaChevronRight } from 'react-icons/fa';

interface FilterProps {
  title: string;
  question: string;
  onArrowClick: () => void;
  isSelected: boolean;
}

const Filter = ({ title, question, onArrowClick, isSelected }: FilterProps) => {
  return (
    <div
      className={`bg-primary rounded-3xl p-6 h-52 flex flex-col justify-between ${
        isSelected ? 'border-2 border-[#7C3FFE]' : 'border border-gray-300'
      }`}
    >
      <div className="flex-1 overflow-hidden">
        <h2 className="text-1.5lg font-semibold text-gray-800 truncate">
          {title}
        </h2>
        <p className="text-gray-600 text-base mt-2 overflow-hidden overflow-ellipsis line-clamp-2">
          {question}
        </p>
      </div>
      <div className="mt-4">
        <button
          className={`font-semibold py-2 px-4 rounded-full flex items-center justify-center focus:outline-none transition-colors duration-300 ease-in-out ${
            isSelected
              ? 'bg-[#7C3FFE] text-white hover:bg-opacity-90'
              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
          }`}
          onClick={onArrowClick}
        >
          {isSelected ? 'Edit Filter' : 'Open Filter'}{' '}
          <FaChevronRight className="ml-2 transition-colors duration-300 ease-in-out group-hover:text-white" />
        </button>
      </div>
    </div>
  );
};

export default Filter;
