import React, { useState } from 'react';
import Sidebar from './Sidebar.tsx';
import SidebarLight from './SidebarLight.tsx';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex">
      <SidebarLight
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
      />
      <div className="flex-1 xl:ml-48 xl:mr-8">
        <button
          className="fixed top-4 left-4 z-50 text-gray-600 focus:outline-none xl:hidden"
          onClick={handleSidebarToggle}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
