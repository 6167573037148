import React, { useEffect, useState } from 'react';
import { Check } from 'lucide-react';

interface RatingProps {
  maxRating: number;
  selectedRating: number | undefined;
  onSelect: (rating: number) => void;
  disabled: boolean;
  show: boolean;
  isTransitioning: boolean;
}

const Rating: React.FC<RatingProps> = ({
  maxRating,
  selectedRating,
  onSelect,
  disabled,
  show,
  isTransitioning,
}) => {
  const [showCheckmark, setShowCheckmark] = useState(false);

  const handleSelect = (rating: number) => {
    if (!disabled) {
      onSelect(rating);
      setShowCheckmark(true);
    }
  };

  useEffect(() => {
    if (!isTransitioning) {
      setShowCheckmark(!!selectedRating);
    }
  }, [selectedRating, isTransitioning]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const key = parseInt(event.key);
      if (!isNaN(key) && key >= 1 && key <= maxRating && !disabled) {
        event.preventDefault();
        handleSelect(key);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [maxRating, disabled]);

  return (
    <div
      className={`space-y-4 transition-opacity duration-500 ease-in-out ${
        show ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="flex items-end">
        {Array.from({ length: maxRating }, (_, i) => i + 1).map((num) => (
          <div key={num} className="flex flex-col items-center mr-2 last:mr-0">
            <button
              disabled={disabled}
              onClick={() => handleSelect(num)}
              className={`w-9 h-9 rounded-md flex items-center justify-center text-md font-semibold mb-1 transition-all duration-300 ease-in-out
                ${
                  disabled
                    ? 'opacity-50 cursor-not-allowed'
                    : selectedRating === num
                    ? 'bg-primary-contrast text-primary border-primary-contrast'
                    : 'bg-gray-100 text-gray-700 border border-gray-300 hover:bg-gray-200'
                }
                ${
                  selectedRating === num
                    ? 'bg-primary-contrast text-primary border-primary-contrast'
                    : 'bg-gray-100 text-gray-700 border border-gray-300'
                }
              `}
            >
              {showCheckmark && selectedRating === num ? (
                <Check className="w-5 h-5 text-primary animate-scale" />
              ) : (
                num
              )}
            </button>
            <div className="h-5 flex items-center">
              {num === 1 && (
                <span className="text-xs text-gray-500">Lowest</span>
              )}
              {num === maxRating && (
                <span className="text-xs text-gray-500">Highest</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rating;
