import React, { forwardRef, Ref } from 'react';
import { TypeAnimation } from 'react-type-animation';

interface QuestionProps {
  currentQuestion: string;
  setisQuestionRendered: (rendered: boolean) => void;
  hasUserResponded?: boolean;
  isDemo?: boolean;
  isLightMode?: boolean;
}

const Question: React.ForwardRefRenderFunction<
  HTMLDivElement,
  QuestionProps
> = (
  {
    currentQuestion,
    setisQuestionRendered,
    hasUserResponded = false,
    isDemo,
    isLightMode = false,
  },
  ref
) => {
  const CURSOR_CLASS_NAME = 'custom-type-animation-cursor';
  const animationSpeed = 82;
  const questionClass = hasUserResponded
    ? 'text-gray-500'
    : 'text-primary-contrast';
  const questionClassLightMode = hasUserResponded
    ? 'text-gray-500'
    : 'text-primary-contrast';
  const demoAdditionalClass = isDemo ? 'text-xl sm:text-2xl' : '';

  const questionStyle = {
    transition: 'color 0.6s ease',
  };

  const addedFocus = (el: HTMLElement) => {
    el.classList.remove(CURSOR_CLASS_NAME);
    if (ref && 'current' in ref && ref.current) {
      ref.current.focus();
    }
    setisQuestionRendered(true);
  };

  const handleAddCursorAnimation = (el: HTMLElement) => {
    if (el) {
      el.classList.add(CURSOR_CLASS_NAME);
    }
  };

  return (
    <div
      className={`${
        isLightMode ? questionClassLightMode : questionClass
      } ${demoAdditionalClass}`}
      style={questionStyle}
    >
      <TypeAnimation
        ref={ref}
        cursor={false}
        sequence={[
          ``,
          (el: HTMLElement) => handleAddCursorAnimation(el),
          currentQuestion,
          500,
          addedFocus,
          () => setisQuestionRendered(true),
        ]}
        speed={animationSpeed}
        style={{ whiteSpace: 'pre-line' }}
      />
    </div>
  );
};

export default forwardRef(Question);
