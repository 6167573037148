import { useState, useEffect, useRef } from 'react';
import {
  getFiltersSelectedFromFirestore,
  addFiltersSelectedToStudyOnFirestore,
} from '../utils/firestoreStudy.ts';
import {
  FiltersSelected,
  CheckedStateType,
  RelevantFilterGroup,
} from '../../common/types.ts';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

export const useFilters = (interviewId: string, studyId: string) => {
  const [filters, setFilters] = useState<any[]>([]);
  const [filtersSelected, setFiltersSelected] = useState<FiltersSelected>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredFilters, setFilteredFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [changesSaved, setChangesSaved] = useState(false);
  const [vectorSearchCompleted, setVectorSearchCompleted] = useState(false);
  const pineconeSearchTimeoutIdRef = useRef<NodeJS.Timeout | undefined>(
    undefined
  );
  const [filtersSearchQuery, setFiltersSearchQuery] = useState('');
  const [filterSearchCompleted, setFilterSearchCompleted] = useState(false);
  const [filterGroupsSearchCompleted, setFilterGroupsSearchCompleted] =
    useState(false);
  const [relevantFilterGroups, setRelevantFilterGroups] = useState<
    RelevantFilterGroup[]
  >([]);
  const [loadingFilterGroups, setLoadingFilterGroups] = useState(false);
  const [loadingVectorSearch, setLoadingVectorSearch] =
    useState<boolean>(false);
  const isDevelopment = process.env.NODE_ENV === 'development';
  const isLandingPage = useLocation().pathname.includes('home');

  useEffect(() => {
    if (searchQuery) {
      setFilteredFilters(filters.filter(filterMatchesQuery));
    }
  }, [searchQuery]);

  useEffect(() => {
    const fetchFilters = async () => {
      const localFilters = await getFilters();
      reorderFilters(localFilters);
      const cleanedFilters = cleanFilters(localFilters);

      setFilters(cleanedFilters);
      setFilteredFilters(cleanedFilters);
      setLoading(false);

      // !!! Uncomment this to update Pinecone database with newly cleaned filters
      // try {
      //   await updatePineconeDatabase(cleanedFilters);
      //   console.log('Pinecone database updated successfully');
      // } catch (error) {
      //   console.error('Failed to update Pinecone database:', error);
      // }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    if (!isLandingPage) {
      const fetchFiltersSelected = async () => {
        const localSelectedFilters = await getFiltersSelectedFromFirestore(
          interviewId,
          studyId
        );
        if (localSelectedFilters) {
          setFiltersSelected(localSelectedFilters);
        }
      };
      fetchFiltersSelected();
    }
  }, [interviewId, studyId, isLandingPage]);

  const cleanFilters = (filters: any) => {
    const prolificFreeFilters = removeFiltersThatMentionProlific(filters);
    const relevantFilters = removeIrrelevantFilters(prolificFreeFilters);
    const clearFilters = removeFiltersThatBreakScreener(relevantFilters);

    const b2bFreeFilters = removeFilterByIds(clearFilters, [
      'industry-role',
      'work-function',
      'function-in-organization',
      'company-size',
      'business-role',
      'industry',
      'employment-role',
      'employment-sector-deprecated',
      'dentistry',
      'employment-sector-within-medicinehealthcare',
      'employment-sector',
      'work-role',
      'past-employment-sectors',
      'employment-sector-within-business-management-and-administration',
      'employer-type',
      'years-of-work-experience',
    ]);

    return b2bFreeFilters;
  };

  const removeFiltersThatBreakScreener = (filters: any) => {
    return filters.filter((filter: any) => filter.id !== 'industry-role');
  };

  const removeFilterByIds = (filters: any, filterIds: any) => {
    const result = filters.filter((filter: any) => {
      const shouldKeep = !filterIds.includes(filter.filter_id);
      return shouldKeep;
    });

    return result;
  };

  const removeChecklistItem = (
    filters: any[],
    filterId: string,
    checklistItemId: string // Changed to string
  ): any[] => {
    console.log(
      `Attempting to remove item ${checklistItemId} from filter ${filterId}`
    );

    let filterFound = false;
    const modifiedFilters = filters.map((filter) => {
      if (
        filter.filter_id.trim().toLowerCase() === filterId.trim().toLowerCase()
      ) {
        filterFound = true;
        console.log('Matching filter found:', filter.filter_id);

        // Parse the filter if it's a string
        const parsedFilter =
          typeof filter === 'string' ? JSON.parse(filter) : filter;

        console.log('Parsed filter:', parsedFilter);

        if (!parsedFilter.choices) {
          console.log('Warning: choices is undefined for this filter');
          return filter;
        }

        console.log('Original choices:', parsedFilter.choices);

        const updatedFilter = {
          ...parsedFilter,
          choices: Object.fromEntries(
            Object.entries(parsedFilter.choices).map(([key, value]) => {
              if (key === checklistItemId) {
                // Direct string comparison
                console.log(`Replacing item ${checklistItemId}`);
                return [key, ''];
              }
              return [key, value];
            })
          ),
          choicesSelected: parsedFilter.choicesSelected
            ? parsedFilter.choicesSelected.filter((id: any) => {
                if (id.toString() === checklistItemId) {
                  // Convert to string for comparison
                  console.log(
                    `Removing ${checklistItemId} from choicesSelected`
                  );
                  return false;
                }
                return true;
              })
            : parsedFilter.choicesSelected,
        };

        console.log('Updated choices:', updatedFilter.choices);
        console.log('Updated choicesSelected:', updatedFilter.choicesSelected);

        return updatedFilter;
      }
      return filter;
    });

    if (!filterFound) {
      console.log(`Filter with ID ${filterId} not found`);
    }

    const modifiedCount = modifiedFilters.filter(
      (f, i) => f !== filters[i]
    ).length;
    console.log(`Number of modified filters: ${modifiedCount}`);

    return modifiedFilters;
  };

  const handleVectorSearch = async (searchQuery: any) => {
    setLoadingVectorSearch(true);
    const results = await searchFiltersPinecone(searchQuery);
    setFilteredFilters(results);
    setLoadingVectorSearch(false);
    setVectorSearchCompleted(true);
  };

  useEffect(() => {
    setVectorSearchCompleted(false);
  }, [searchQuery]);

  const getFilterDataGivenId = (filterId: string) => {
    const filterData = filters.find((filter) => filter.filter_id === filterId);
    return filterData;
  };

  const searchFiltersPinecone = async (searchQuery: string) => {
    console.log('Running vector search!');
    try {
      const searchEmbedding = await getSearchEmbedding(searchQuery);

      const pineconeMatches = await queryPineconeFilters(searchEmbedding, 8);

      const normalizedPineconeMatches = pineconeMatches
        .filter((match: any) => {
          const filterId = match.id;
          const normalizedFilter = getFilterDataGivenId(filterId);
          return normalizedFilter; // Keep only matches where normalizedFilter is truthy
        })
        .map((match: any) => {
          // At this point, we know that normalizedFilter must exist.
          const filterId = match.id;
          return getFilterDataGivenId(filterId);
        });

      const combinedResults = [
        ...filteredFilters,
        ...normalizedPineconeMatches,
      ];

      const uniqueResults = removeDuplicates(combinedResults);

      return uniqueResults;
    } catch (error) {
      console.error('Error searching filters with Pinecone:', error);
      setLoadingVectorSearch(false);
    }
  };

  const removeDuplicates = (filters: any) => {
    const uniqueIds = new Set();
    return filters.filter((filter: any) => {
      if (uniqueIds.has(filter.filter_id)) {
        return false;
      }
      uniqueIds.add(filter.filter_id);
      return true;
    });
  };

  const handleFiltersSearch = async () => {
    await getRelevantFilterGroups(filtersSearchQuery);
  };

  const handleRegularSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setFilterSearchCompleted(true);
  };

  const getRelevantFilterGroups = async (
    searchQuery: string
  ): Promise<RelevantFilterGroup[]> => {
    setLoadingFilterGroups(true);
    setFilterGroupsSearchCompleted(false);

    try {
      const productionUrl =
        'https://us-central1-joinprobe.cloudfunctions.net/recommendRelevantFilters';
      const developmentUrl =
        'http://127.0.0.1:5001/joinprobe/us-central1/recommendRelevantFilters';
      const url = isDevelopment ? developmentUrl : productionUrl;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ description: searchQuery }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const processedRelevantFilters = processRelevantFilters(data.response);
      setRelevantFilterGroups(processedRelevantFilters);
      setFilterGroupsSearchCompleted(true);
      return processedRelevantFilters;
    } catch (error) {
      console.error('Error fetching relevant filters:', error);
      toast.error('Failed to fetch relevant filters. Please try again.');
      return [];
    } finally {
      setLoadingFilterGroups(false);
    }
  };

  const processRelevantFilters = (response: any): RelevantFilterGroup[] => {
    console.log('Processing response:', response);
    const relevantFilterGroups: RelevantFilterGroup[] = [];

    for (const [characteristic, filters] of Object.entries(response)) {
      const processedFilters = (filters as any[])
        .map((filter) => ({
          ...filter,
          ...getFilterDataGivenId(filter.id),
        }))
        .filter((filter) => filter.title); // Only keep filters that exist in our local data

      if (processedFilters.length > 0) {
        relevantFilterGroups.push({
          characteristic,
          filters: processedFilters,
        });
      }
    }

    return relevantFilterGroups;
  };

  const getSearchEmbedding = async (searchQuery: string) => {
    const productionUrl =
      'https://us-central1-joinprobe.cloudfunctions.net/generateSearchEmbedding';
    const developmentUrl =
      'http://127.0.0.1:5001/joinprobe/us-central1/generateSearchEmbedding';

    const url = isDevelopment ? developmentUrl : productionUrl;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchQuery }),
    });
    const { embedding } = await response.json();
    return embedding;
  };

  const queryPineconeFilters = async (
    searchEmbedding: number[],
    topK: number
  ) => {
    const productionUrl =
      'https://us-central1-joinprobe.cloudfunctions.net/queryPineconeFilters';
    const developmentUrl =
      'http://127.0.0.1:5001/joinprobe/us-central1/queryPineconeFilters';

    const url = isDevelopment ? developmentUrl : productionUrl;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchEmbedding, topK }),
    });
    const { matches } = await response.json();
    return matches;
  };

  const updateFiltersSelectedGivenUpdatedFilter = async (
    filterId: string,
    filterData: {},
    type: 'choicesSelected' | 'sliderSelections',
    choicesSelected?: CheckedStateType,
    sliderSelections?: [number, number]
  ): Promise<void> => {
    setFiltersSelected((prevSelected) => {
      const newFiltersSelected = choicesSelected
        ? updateFiltersSelectedState(
            filterId,
            filterData,
            choicesSelected,
            type
          )
        : updateFiltersSelectedState(
            filterId,
            filterData,
            sliderSelections as any,
            type
          );

      if (!isLandingPage) {
        saveFilterData(newFiltersSelected).then((isSuccessfullySaved) => {
          setChangesSaved(isSuccessfullySaved);
        });
      }

      return newFiltersSelected;
    });
  };

  const getFilters = async () => {
    const emulatorUrl =
      'http://127.0.0.1:5001/joinprobe/us-central1/getFilters';
    const productionUrl =
      'https://us-central1-joinprobe.cloudfunctions.net/getFilters';

    const url = isDevelopment ? emulatorUrl : productionUrl;

    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error retrieving filters:', error);
    }
  };

  const reorderFilters = (filters: any) => {
    const ageFilter = filters?.find(
      (filter: any) => filter.filter_id === 'age'
    );
    const currentCountryOfResidenceFilter = filters?.find(
      (filter: any) => filter.filter_id === 'current-country-of-residence'
    );
    const ageFilterIndex = filters?.indexOf(ageFilter);
    const currentCountryOfResidenceFilterIndex = filters?.indexOf(
      currentCountryOfResidenceFilter
    );
    filters?.splice(currentCountryOfResidenceFilterIndex, 1);
    filters?.splice(ageFilterIndex, 1);
    filters?.unshift(currentCountryOfResidenceFilter);
    filters?.unshift(ageFilter);
    return filters;
  };

  const searchFilters = async (searchQuery: string) => {
    try {
      const textMatchedFilters = filters
        ? filters.filter(filterMatchesQuery)
        : [];

      setFilteredFilters(textMatchedFilters);
    } catch (error) {
      console.error('Error searching filters:', error);
    }
  };

  const filterMatchesQuery = (filter: any) => {
    const searchInString = (string: any) =>
      string && string.toLowerCase().includes(searchQuery);
    const searchInChoices = (choices: any) => {
      if (typeof choices === 'object' && choices !== null) {
        return Object.values(choices).some(searchInString);
      }
      return false;
    };

    return (
      searchInString(filter.filter_id) ||
      searchInString(filter.title) ||
      searchInChoices(filter.choices) ||
      searchInString(filter.question)
    );
  };

  const removeFiltersThatMentionProlific = (filters: any) => {
    return filters?.filter((filter: any) => {
      const title = filter.title;
      const question = filter.question;
      const filterHasTitleAndQuestion = title && question;

      if (filterHasTitleAndQuestion) {
        return (
          !title.toLowerCase().includes('prolific') &&
          !question.toLowerCase().includes('prolific')
        );
      } else {
        return true;
      }
    });
  };

  const removeIrrelevantFilters = (filters: any) => {
    const titlesToRemove = [
      'Approval Rate',
      'Number of previous submissions',
      'Custom Allowlist',
      'Custom Blocklist',
      'Joined Between',
      'Exclude participants from previous studies',
      'Include participants from previous studies',
      'Include participants from a participant group.',
      'Exclude participants from a participant group.',
      'Exclude participants from other studies',
      'Include participants from other studies',
    ];

    return filters?.filter((filter: any) => {
      const title = filter.title;
      return !titlesToRemove.includes(title);
    });
  };

  const saveFilterData = async (filtersSelected: FiltersSelected) => {
    if (isLandingPage) return true;

    try {
      await addFiltersSelectedToStudyOnFirestore(
        interviewId,
        studyId,
        filtersSelected
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  function updateFiltersSelectedState(
    filterId: string,
    filterData: {},
    thirdArgument: CheckedStateType | [number, number],
    type: 'choicesSelected' | 'sliderSelections'
  ): FiltersSelected {
    const selectionsKey = type;
    const selectionsValue = thirdArgument;

    const existingFilterIndex = filtersSelected.findIndex(
      (filter) => filter.filter_id === filterId
    );

    const filterExists = existingFilterIndex > -1;
    let newFiltersSelected = [...filtersSelected];

    if (filterExists) {
      const existingFilter = newFiltersSelected[existingFilterIndex];
      const updatedFilter = {
        ...existingFilter,
        filterData,
        [selectionsKey]: selectionsValue,
      };

      if (!selectionsValue || selectionsValue.length === 0) {
        newFiltersSelected.splice(existingFilterIndex, 1);
      } else {
        newFiltersSelected[existingFilterIndex] = updatedFilter;
      }
    } else if (selectionsValue && selectionsValue.length > 0) {
      const newFilter = {
        filter_id: filterId,
        filterData,
        [selectionsKey]: selectionsValue,
      };
      newFiltersSelected.push(newFilter);
    }

    return newFiltersSelected;
  }

  const updatePineconeDatabase = async (cleanedFilters: any) => {
    console.log('Updating Pinecone database with cleaned filters...');

    const url =
      'http://127.0.0.1:5001/joinprobe/us-central1/generateFilterEmbeddings';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filters: cleanedFilters }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Pinecone database updated successfully:', data);
      return data;
    } catch (error) {
      console.error('Error updating Pinecone database:', error);
      throw error;
    }
  };

  return {
    filters,
    filtersSelected,
    searchQuery,
    filteredFilters,
    loading,
    setSearchQuery,
    updateFiltersSelectedGivenUpdatedFilter,
    setLoading,
    setFilteredFilters,
    changesSaved,
    setChangesSaved,
    loadingVectorSearch,
    handleVectorSearch,
    vectorSearchCompleted,
    filtersSearchQuery,
    setFiltersSearchQuery,
    filterGroupsSearchCompleted,
    relevantFilterGroups,
    handleFiltersSearch,
    filterSearchCompleted,
    getRelevantFilterGroups,
    handleRegularSearch,
    setFilterSearchCompleted,
    loadingFilterGroups,
    setLoadingFilterGroups,
  };
};
