import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { createContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FindUserId from './components/Admin/FindUserId.tsx';
import Authentication from './components/Authentication/Authentication.tsx';
import AuthProvider from './components/Authentication/AuthProvider.tsx';
import ProtectedRoute from './components/Authentication/ProtectedRoute.tsx';
import InterviewBuilder from './components/Onboarding/InterviewBuilder/InterviewBuilder.tsx';
import ScreenerQuestions from './components/Onboarding/InterviewBuilder/Screener/ScreenerQuestions.tsx';
import InterviewType from './components/Onboarding/InterviewType.tsx';
import Affiliate from './components/Dashboard/Affiliate/Affiliate.tsx';
import Dashboard from './components/Dashboard/Dashboard.tsx';
import InterviewInstanceReview from './components/Dashboard/InterviewInstanceReview.tsx';
import InterviewPage from './components/Dashboard/InterviewPage.tsx';
import PageNotFound from './components/ErrorPages/PageNotFound.tsx';
import InterviewInterface from './components/Interview/InterviewInterface.tsx';
import Interview from './components/Interview/QuestionnaireInterview/Interview.tsx';
import ThankYouPage from './components/Interview/QuestionnaireInterview/InterviewQuestion/ThankYouPage.tsx';
import SurveyScreener from './components/Interview/Screener/SurveyScreener.tsx';
import VoiceInterview from './components/Interview/VoiceInterview/VoiceInterview.tsx';
import InterviewConfirm from './components/InterviewConfirm/InterviewConfirm.tsx';
import InterviewOverview from './components/InterviewDetails/InterviewOverview.tsx';
import InterviewGoals from './components/InterviewGoals/InterviewGoals.tsx';
import AffiliateOverview from './components/LandingPage/AffiliateOverview.tsx';
import LandingPage from './components/LandingPage/LandingPage.tsx';
import WelcomeScreen from './components/Onboarding/WelcomeScreen.tsx';
import Pricing from './components/Pricing/Pricing.tsx';
import Redirect from './components/Routing/Redirect.tsx';
import Payments from './components/Settings/Payment/Payments.tsx';
import Layout from './components/Sidebar/Layout.tsx';
import LayoutLight from './components/Sidebar/LayoutLight.tsx';
import Filters from './components/Study/Filters/Filters.tsx';
import FiltersRedirect from './components/Study/Filters/FiltersRedirect.tsx';
import ParticipantCounter from './components/Study/ParticipantCounter.tsx';
import { queryClient } from './queries/queryClient.ts';
import InterviewInstanceReviewController from './components/Dashboard/InterviewInstanceReviewController.tsx';
import B2BDemographics from './components/B2BOverview/B2BDemographics.tsx';
import B2BOverview from './components/B2BOverview/B2BOverview.tsx';
import ParticipantIdCapture from './components/Interview/ParticipantIdCapture.tsx';
import InterviewSummary from './components/Dashboard/InterviewSummary.tsx';
import B2BInterview from './components/Interview/QuestionnaireInterview/B2Binterview.tsx';

export const AppContext = createContext(null);

const MantineTheme = createTheme({
  fontFamily: 'Inter',
  primaryColor: 'black',
  colors: {
    black: [
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
    ],
  },
});

const App = () => {
  return (
    <div className="App min-h-screen h-full w-screen bg-primary font-merriweather">
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={MantineTheme}>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="/" element={<Redirect />} />
                <Route path="/start" element={<Redirect />} />
                <Route path="/home" element={<LandingPage />} />
                <Route path="/auth" element={<Authentication />} />
                <Route path="/auth/:affiliateId" element={<Authentication />} />
                <Route
                  path="/auth/interview/:interviewId"
                  element={<Authentication />}
                />
                <Route
                  path="/b2b_overview"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <B2BOverview />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/b2b_demographics/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <B2BDemographics />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/share/:affiliateId" element={<LandingPage />} />
                <Route path="/affiliate" element={<AffiliateOverview />} />
                <Route
                  path="/welcome"
                  element={
                    <Layout>
                      <WelcomeScreen />
                    </Layout>
                  }
                />

                <Route
                  path="/overview/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewOverview />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/overview/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewOverview />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/edit/overview/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewOverview />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/goals/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewGoals />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="voiceinterview/:interviewId/:studyId"
                  element={<VoiceInterview />}
                />
                <Route
                  path="interview/:interviewId/:studyId"
                  element={<InterviewInterface />}
                />
                <Route
                  path="/builder/:interviewId"
                  element={
                    <ProtectedRoute>
                      <LayoutLight>
                        <InterviewBuilder />
                      </LayoutLight>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/builder"
                  element={
                    <LayoutLight>
                      <InterviewBuilder />
                    </LayoutLight>
                  }
                />
                {/* Regular Interview */}
                <Route path="/chat/:interviewId" element={<Interview />} />
                {/* Respondent Interview */}
                <Route
                  path="/chat/:interviewId/:interviewInstanceId"
                  element={<B2BInterview />}
                />
                {/* Prolific Interview */}
                <Route
                  path="/chat/study/:interviewId/:studyId"
                  element={<Interview />}
                />
                {/* Preview Interview */}
                <Route path="/preview/:interviewId" element={<Interview />} />
                <Route
                  path="/completed/:interviewId"
                  element={<ThankYouPage />}
                />
                <Route
                  path="/overview"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewOverview />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/overview/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewOverview />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/goals/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewGoals />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="voiceinterview/:interviewId/:studyId"
                  element={<VoiceInterview />}
                />
                <Route
                  path="interview/:interviewId/:studyId"
                  element={<InterviewInterface />}
                />
                <Route
                  path="/builder/:interviewId"
                  element={
                    <ProtectedRoute>
                      <LayoutLight>
                        <InterviewBuilder />
                      </LayoutLight>
                    </ProtectedRoute>
                  }
                />
                <Route path="/chat/:interviewId" element={<Interview />} />
                <Route
                  path="/completed/:interviewId"
                  element={<ThankYouPage />}
                />

                <Route
                  path="/confirm/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewConfirm />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/edit/goals/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewGoals />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings/payments"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Payments />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings/affiliate"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Affiliate />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/interview/mock/:interviewId"
                  element={<InterviewInterface />}
                />
                <Route
                  path="/interview/:interviewId"
                  element={<InterviewInterface />}
                />
                <Route
                  path="/validation/:interviewId/:studyId"
                  element={<SurveyScreener />}
                />
                <Route
                  path="/study/:interviewId/:studyId"
                  element={<InterviewInterface />}
                />
                <Route
                  path="/dashboard/"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Dashboard />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewPage />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard/:interviewId/:interviewNumber"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewInstanceReviewController />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/filters"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Filters />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/filters/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <FiltersRedirect />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/filters/:interviewId/:studyId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Filters />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/respondents/:interviewId/:studyId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <ParticipantCounter />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/payment/:interviewId/:studyId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Pricing />
                      </Layout>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/interview-type"
                  element={
                    <Layout>
                      <ProtectedRoute>
                        <InterviewType />
                      </ProtectedRoute>
                    </Layout>
                  }
                />
                <Route
                  path="/qualification/:interviewId"
                  element={
                    <Layout>
                      <ProtectedRoute>
                        <ScreenerQuestions />
                      </ProtectedRoute>
                    </Layout>
                  }
                />

                <Route path="/findUserId/:email" element={<FindUserId />} />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
                <Route
                  path="/respondentId/:interviewId"
                  element={<ParticipantIdCapture />}
                />
                <Route
                  path="/feedback"
                  element={
                    <Layout>
                      <ProtectedRoute>
                        <Interview interviewId="6IMaFbjEHy6U6k2unWFO" />
                      </ProtectedRoute>
                    </Layout>
                  }
                />
                <Route
                  path="/summary/:interviewId"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <InterviewSummary />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </MantineProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
