import React, { ChangeEvent } from 'react';
import { Trash2, ToggleLeft, ToggleRight } from 'lucide-react';

interface InputGoalProps {
  index: number;
  placeholder: string;
  goalValue: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>, key: number) => void;
  handleDelete: (key: number) => void;
  isAskingFollowUps: boolean;
  toggleFollowUp: (key: number) => void;
}

const InputGoal = ({
  index,
  placeholder,
  goalValue,
  handleChange,
  isAskingFollowUps,
  handleDelete,
  toggleFollowUp,
}: InputGoalProps) => {
  return (
    <div className="w-full mx-auto font-extralight mt-9">
      <div className="flex">
        <div className="text-xl mr-4 hidden md:block text-primary-contrast">
          {index + 1}.
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full items-center">
            <div className="w-11/12 flex items-center">
              <input
                type="text"
                value={goalValue}
                onChange={(e) => handleChange(e, index)}
                className="w-10/12 md:w-11/12 bg-transparent text-sm sm:text-lg focus:outline-none text-primary-contrast"
                placeholder={placeholder}
              />
              <button
                onClick={() => toggleFollowUp(index)}
                className="ml-2 focus:outline-none"
                aria-label={
                  isAskingFollowUps ? 'Disable follow-ups' : 'Enable follow-ups'
                }
              >
                {isAskingFollowUps ? (
                  <ToggleRight className="text-blue-600 w-6 h-6" />
                ) : (
                  <ToggleLeft className="text-gray-400 w-6 h-6" />
                )}
              </button>
            </div>
            <button
              onClick={() => handleDelete(index)}
              className="ml-1 md:ml-4 text-gray-400 hover:text-red-500 transition duration-300"
              aria-label="Delete goal"
            >
              <Trash2 size={18} />
            </button>
          </div>
          <hr className="border-primary-muted mt-3 w-11/12" />
        </div>
      </div>
    </div>
  );
};

export default InputGoal;
