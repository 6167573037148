import React, { useState, useEffect } from 'react';
import { TextBlockQuestion as TextBlockQuestionType } from '@functions/common/types';
import BaseQuestion, { BaseQuestionProps } from './BaseQuestion';
import ToggleSwitch from './Shared/ToggleSwitch';
import ImageUploader from './Shared/ImageUploader';
import { handleImageUpload } from '../../../../utils/general';

type TextBlockQuestionProps = BaseQuestionProps<TextBlockQuestionType> & {
  interviewId: string;
};

const TextBlockQuestionComponent: React.FC<TextBlockQuestionProps> = (
  props
) => {
  const { question, onUpdate, interviewId } = props;
  const [showImageUploader, setShowImageUploader] = useState(!!question.image);

  useEffect(() => {
    setShowImageUploader(!!question.image);
  }, [question.image]);

  const handleToggleImage = () => {
    if (showImageUploader) {
      onUpdate({ image: null });
    }
    setShowImageUploader(!showImageUploader);
  };

  const handleImageDelete = () => {
    onUpdate({ image: null });
  };

  return (
    <BaseQuestion {...props}>
      <div className="space-y-4">
        <textarea
          value={question.question}
          onChange={(e) => onUpdate({ question: e.target.value })}
          placeholder="Enter the content you want to display to the user. This can be instructions, information, or any text you want to show before proceeding to the next question."
          className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-shadow bg-primary text-primary-contrast resize-none"
          rows={3}
        />
        <div className="flex items-center space-x-8 mb-4">
          <ToggleSwitch
            label="Include Image"
            checked={showImageUploader}
            onChange={handleToggleImage}
          />
        </div>
        {showImageUploader && (
          <ImageUploader
            onUpload={handleImageUpload<TextBlockQuestionType>(
              interviewId,
              question.id,
              onUpdate
            )}
            initialImage={question.image || ''}
            onDelete={handleImageDelete}
          />
        )}
      </div>
    </BaseQuestion>
  );
};

export default TextBlockQuestionComponent;
