import { PencilIcon } from '@heroicons/react/24/outline';
import { Tabs, Text, Tooltip } from '@mantine/core';
import { jsPDF } from 'jspdf';
import { ArrowLeft } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  FiCheck,
  FiDownload,
  FiEdit,
  FiPlus,
  FiX,
  FiUpload,
} from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import {
  AnswerWithQuestion,
  InterviewInstanceWithQuestions,
  InterviewResponse,
  InterviewType,
  OpenEndedQuestion,
} from '@functions/common/types';
import { Badge } from '@mantine/core';
import useGetInterview from 'src/hooks/useGetInterview.ts';
import useGetInterviewDashboardCards from 'src/hooks/useGetInterviewDashboardCards.ts';
import useGetInterviewInstances from 'src/hooks/useGetInterviewInstances.ts';
import { capitalizeWords } from 'src/utils/general.ts';
import {
  getFullInterviewDataForExport,
  getInterviewTypeFromFirestore,
  updateInterviewInFirestore,
} from '../../utils/firestore.ts';
import SquareButton from '../Buttons/SquareButton.tsx';
import PageNotFound from '../ErrorPages/PageNotFound.tsx';
import LoadingRing from '../Loading/LoadingRing.tsx';
import Dropdown from '../ui/Dropdown.tsx';
import { InterviewList } from './InterviewList.tsx';
import InterviewSummary from './InterviewSummary';
import InterviewTable from './InterviewTable.tsx';
import usePublishB2BInterview from '../../hooks/usePublishB2BInterview.ts';
import toast from 'react-hot-toast';

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const calculateTotalQuestions = (
  interview: InterviewResponse | undefined
): number => {
  if (!interview || !interview.questions) return 0;

  return interview.questions.reduce((total, question) => {
    if (
      'type' in question &&
      (question.type === 'open-ended' || question.type === 'ai-follow-ups')
    ) {
      const openEndedQuestion = question as OpenEndedQuestion;
      const followUps = openEndedQuestion.aiFollowUps
        ? openEndedQuestion.maxFollowUps || 0
        : 0;
      return total + 1 + followUps;
    }
    return total + 1;
  }, 0);
};

const InterviewPage: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const { interviewId } = useParams<string>();
  const navigate = useNavigate();

  const {
    data: interviewData,
    isLoading: interviewDataLoading,
    refetch: refetchInterviewData,
  } = useGetInterview(interviewId!, {
    enabled: !!interviewId,
  });

  const {
    data: interviewInstancesData,
    isLoading: interviewInstancesDataLoading,
  } = useGetInterviewInstances(interviewId!, {
    enabled: !!interviewId,
  });

  const { data: interviewCardsData, isLoading: interviewCardsDataLoading } =
    useGetInterviewDashboardCards(interviewId!, {
      enabled: !!interviewId,
    });

  const publishB2BInterviewMutation = usePublishB2BInterview({
    onSuccess: (data) => {
      if (data.url) {
        window.open(data.url, '_blank');
      }
    },
    onError: (error) => {
      toast.error('Error publishing interview');
    },
  });

  const [interviewType, setInterviewType] = useState<InterviewType | null>(
    null
  );

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');

  const [activeTab, setActiveTab] = useState<string>('summary');

  useEffect(() => {
    const fetchInterviewType = async () => {
      if (interviewId) {
        try {
          const type = await getInterviewTypeFromFirestore(interviewId);
          setInterviewType(type);
        } catch (error) {
          console.error('Error fetching interview type:', error);
        }
      }
    };
    fetchInterviewType();
  }, [interviewId]);

  const loading =
    interviewDataLoading ||
    interviewCardsDataLoading ||
    interviewInstancesDataLoading ||
    interviewType === null;

  const filteredCards = useMemo(() => {
    return (
      interviewCardsData?.cards
        ?.filter((card: any) => !!card.id)
        ?.sort((a: any, b: any) => {
          return (
            new Date(b.dateAndTimeCreated).getTime() -
            new Date(a.dateAndTimeCreated).getTime()
          );
        })
        .map((card: any, index: number) => ({
          ...card,
          title:
            card.title ||
            `Interview #${(interviewCardsData?.cards?.length ?? 0) - index}`,
        })) ?? []
    );
  }, [interviewCardsData, interviewData?.studyTitle]);

  const numInterviewInstances = interviewInstancesData?.instances.length ?? 0;

  const totalQuestions = useMemo(
    () => calculateTotalQuestions(interviewData),
    [interviewData]
  );

  const handleBack = () => {
    navigate('/dashboard');
  };

  const handleInterview = () => {
    navigate(`/builder/${interviewId}`);
  };

  const handleAddResponses = () => {
    navigate(`/filters/${interviewId}`);
  };

  const handleExport = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const removeEmojis = (text: string) => {
    return text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    );
  };

  const formatAnswer = (answer: AnswerWithQuestion): string => {
    switch (answer.type) {
      case 'open-ended':
      case 'ai-follow-ups':
        return answer.answer || '';
      case 'multiple-choice':
        return answer.selectedOptions?.join(', ') || answer.otherAnswer || '';
      case 'single-select':
        return answer.selectedOption || answer.otherAnswer || '';
      case 'rating':
        return answer.selectedRating?.toString() || '';
      default:
        return '';
    }
  };

  const handleExportPDF = async () => {
    if (!interviewId) return;

    console.log('Starting PDF export for interviewId:', interviewId);

    try {
      const interviewInstancesData =
        await getFullInterviewDataForExport(interviewId);
      console.log('Received interview instances data:', interviewInstancesData);

      if (!interviewInstancesData || interviewInstancesData.length === 0) {
        console.error('No interview instances data received');
        return;
      }

      const doc = new jsPDF();
      let yPosition = 20;

      interviewInstancesData.forEach(
        (instance: InterviewInstanceWithQuestions, index: number) => {
          console.log(`Processing instance ${index}:`, instance);

          if (!instance || !instance.answers) {
            console.error(
              `Invalid instance data for index ${index}:`,
              instance
            );
            return;
          }

          if (yPosition > doc.internal.pageSize.height - 20) {
            doc.addPage();
            yPosition = 20;
          }

          doc.setFontSize(16);
          doc.text(
            `Interview #${interviewInstancesData.length - index}`,
            20,
            yPosition
          );
          yPosition += 10;

          instance.answers.forEach((answer: AnswerWithQuestion) => {
            console.log(`Processing answer:`, answer);

            doc.setFontSize(12);

            const questionText = removeEmojis(answer.originalQuestion.question);
            const answerText = removeEmojis(formatAnswer(answer));

            const questionLines = doc.splitTextToSize(
              `Probe: ${questionText}`,
              180
            );
            doc.text(questionLines, 20, yPosition);
            yPosition += questionLines.length * 7;

            const answerLines = doc.splitTextToSize(
              `Interviewee: ${answerText}`,
              180
            );
            doc.text(answerLines, 20, yPosition);
            yPosition += answerLines.length * 7;

            yPosition += 5;

            if (yPosition > doc.internal.pageSize.height - 20) {
              doc.addPage();
              yPosition = 20;
            }
          });

          yPosition += 10;
        }
      );

      doc.save('interviews.pdf');
      console.log('PDF export completed successfully');
    } catch (error) {
      console.error('Error during PDF export:', error);
    }
  };

  const handleExportTXT = async () => {
    if (!interviewId) return;

    const interviewInstancesData =
      await getFullInterviewDataForExport(interviewId);
    let txtContent = '';

    interviewInstancesData.forEach(
      (instance: InterviewInstanceWithQuestions, index: number) => {
        txtContent += `## Interview #${
          interviewInstancesData.length - index
        }\n`;

        instance.answers.forEach((answer: AnswerWithQuestion) => {
          txtContent += `Probe: ${answer.originalQuestion.question}\n`;
          txtContent += `Interviewee: ${formatAnswer(answer)}\n\n`;
        });

        txtContent += '\n';
      }
    );

    const element = document.createElement('a');
    const file = new Blob([txtContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'interviews.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const exportTableData = async (
    separator: string,
    fileType: string,
    fileExtension: string,
    isExcel: boolean = false
  ) => {
    if (interviewId) {
      const interviewInstancesData =
        await getFullInterviewDataForExport(interviewId);

      const headers = [
        'Date',
        ...interviewInstancesData[0].answers.map(
          (a) => a.originalQuestion.question
        ),
      ];
      const data = [headers];

      interviewInstancesData.forEach(
        (instance: InterviewInstanceWithQuestions) => {
          const row = [
            new Date(instance.dateAndTimeCreated).toLocaleDateString(),
            ...instance.answers.map((answer) => formatAnswer(answer)),
          ];
          data.push(row);
        }
      );

      if (isExcel) {
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Interview Data');
        XLSX.writeFile(workbook, `interview_data.${fileExtension}`);
      } else {
        let content = data.map((row) => row.join(separator)).join('\n');

        const element = document.createElement('a');
        const file = new Blob([content], {
          type: `${fileType};charset=utf-8;`,
        });
        element.href = URL.createObjectURL(file);
        element.download = `interview_data.${fileExtension}`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
  };

  const handleExportTSV = async () => {
    await exportTableData('\t', 'text/tab-separated-values', 'tsv');
  };

  const handleExportCSV = async () => {
    if (!interviewId || !interviewData) return;

    const interviewInstancesData =
      await getFullInterviewDataForExport(interviewId);
    const questions = interviewData.questions;

    // Prepare headers
    const headers = ['Interview Date'];
    questions.forEach((question) => {
      headers.push(question.question);
      if (question.type === 'open-ended' || question.type === 'ai-follow-ups') {
        const maxFollowUps = question.maxFollowUps || 0;
        for (let i = 1; i <= maxFollowUps; i++) {
          headers.push(`${question.question} - Follow-up ${i}`);
        }
      }
    });

    const csvData = [headers];

    interviewInstancesData.forEach(
      (instance: InterviewInstanceWithQuestions) => {
        const row = [
          new Date(instance.dateAndTimeCreated).toLocaleDateString(),
        ];

        questions.forEach((question) => {
          const answer = instance.answers.find(
            (a) => a.questionId === question.id
          );
          if (answer) {
            row.push(formatAnswer(answer));
            if (
              question.type === 'open-ended' ||
              question.type === 'ai-follow-ups'
            ) {
              const maxFollowUps = question.maxFollowUps || 0;
              const followUps = answer.aiFollowUps || [];
              for (let i = 0; i < maxFollowUps; i++) {
                row.push(followUps[i]?.answer || '');
              }
            }
          } else {
            row.push('');
            if (
              question.type === 'open-ended' ||
              question.type === 'ai-follow-ups'
            ) {
              const maxFollowUps = question.maxFollowUps || 0;
              for (let i = 0; i < maxFollowUps; i++) {
                row.push('');
              }
            }
          }
        });

        csvData.push(row);
      }
    );

    // Convert csvData to a string
    const csvContent = csvData.map((row) => row.join(',')).join('\n');

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a download link and trigger the download
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute(
        'download',
        `interview_data_${interviewId}_${new Date().toISOString()}.csv`
      );
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleExportXLSX = async () => {
    await exportTableData(
      ',',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'xlsx',
      true
    );
  };

  const handleEditTitle = () => {
    setEditedTitle(interviewTitle);
    setIsEditingTitle(true);
  };

  const handleSaveTitle = async () => {
    if (interviewId && editedTitle.trim() !== '') {
      try {
        await updateInterviewInFirestore(interviewId, {
          title: editedTitle.trim(),
        });

        // Refetch the interview data to get the updated title
        await refetchInterviewData();

        setIsEditingTitle(false);
      } catch (error) {
        console.error('Error updating interview title:', error);
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditingTitle(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSaveTitle();
    }
  };

  const interviewTitle = useMemo(() => {
    if (interviewData?.title) {
      return capitalizeWords(interviewData.title);
    } else if (interviewData?.studyTitle) {
      return capitalizeWords(interviewData.studyTitle);
    } else {
      return numInterviewInstances > 0
        ? `Interview #${numInterviewInstances}`
        : 'Interview';
    }
  }, [interviewData, numInterviewInstances]);

  const handlePublish = () => {
    if (!interviewId) {
      toast.error('Interview ID is not available');
      return;
    }
    publishB2BInterviewMutation.mutate({ interviewId });
  };

  const renderHeaderButtons = () => {
    return (
      <div className="flex flex-wrap justify-end items-center gap-2">
        {interviewData && interviewData.isB2B && !isB2BInterviewPublished && (
          <SquareButton
            className="mb-0"
            text="Publish"
            onClick={handlePublish}
            icon={<FiUpload />}
          />
        )}
        <SquareButton
          className="mb-0"
          text="Edit Questions"
          onClick={handleInterview}
          icon={<FiEdit />}
        />
        {interviewType === 'B2C' && (
          <SquareButton
            className="mb-0"
            text="Add Responses"
            onClick={handleAddResponses}
            icon={<FiPlus />}
          />
        )}
        <div className="relative">
          <SquareButton
            className="mb-0"
            text="Export"
            onClick={handleExport}
            icon={<FiDownload />}
          />
          {isDropdownOpen && (
            <Dropdown>
              <div className="py-2">
                <div className="px-4 text-primary-contrast text-sm uppercase tracking-wide mb-2">
                  Interview Transcripts
                </div>
                <button
                  className="flex items-center w-full text-left px-4 py-2 text-primary-contrast hover:bg-primary-muted text-1.5sm"
                  onClick={handleExportPDF}
                >
                  <FiDownload className="mr-2" />
                  Export as PDF
                </button>
                <button
                  className="flex items-center w-full text-left px-4 py-2 text-primary-contrast hover:bg-primary-muted text-1.5sm"
                  onClick={handleExportTXT}
                >
                  <FiDownload className="mr-2" />
                  Export as TXT
                </button>
                <button
                  className="flex items-center w-full text-left px-4 py-2 text-primary-contrast hover:bg-primary-muted text-1.5sm"
                  onClick={handleExportCSV}
                >
                  <FiDownload className="mr-2" />
                  Export as CSV
                </button>
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return <LoadingRing />;
  }

  if (!interviewData) {
    return <PageNotFound />;
  }

  const isB2BInterviewPublished = interviewData.publishState === 'PUBLISHED';

  return (
    <div className="pt-14 pb-20 sm:pt-10 mx-auto px-2 sm:px-4 lg:px-6 lg:w-11/12 md:ml-20">
      <div className="flex flex-col mb-4">
        <div className="flex items-center mb-2">
          <ArrowLeft
            className="text-primary-contrast mr-4 cursor-pointer"
            onClick={handleBack}
          />
          <div className="flex-1">
            {isEditingTitle ? (
              <div className="flex items-center">
                <input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="text-2.5xl sm:text-3xl font-medium text-primary-contrast bg-transparent focus:outline-none border-b border-gray-300 mr-2 py-1 w-full"
                />
                <button
                  onClick={handleSaveTitle}
                  className="text-primary-contrast hover:text-gray-400 transition-colors duration-200 p-1"
                  aria-label="Save"
                >
                  <FiCheck className="w-5 h-4" />
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="text-primary-contrast hover:text-gray-400 transition-colors duration-200 p-1 ml-1"
                  aria-label="Cancel"
                >
                  <FiX className="w-5 h-4" />
                </button>
              </div>
            ) : (
              <div className="flex items-center">
                <Tooltip
                  label={interviewTitle}
                  position="top-start"
                  withArrow
                  styles={{
                    tooltip: {
                      backgroundColor: '#F3F3F3',
                      color: '#000000',
                      borderRadius: '8px',
                      padding: '8px 12px',
                    },
                    arrow: {
                      backgroundColor: '#F3F3F3',
                    },
                  }}
                >
                  <h1 className="text-2.5xl sm:text-3xl font-medium text-primary-contrast cursor-pointer">
                    {truncateText(interviewTitle, 40)}
                  </h1>
                </Tooltip>
                <button
                  onClick={handleEditTitle}
                  className="ml-2 text-primary-contrast hover:text-gray-400 transition-colors duration-200 p-1"
                  aria-label="Edit title"
                >
                  <PencilIcon className="w-5 h-5" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center gap-2">
            {interviewData.isB2B && isB2BInterviewPublished && (
              <Badge className="order-first">Published</Badge>
            )}
            <div className="flex items-center h-9">
              {`${numInterviewInstances} interview${
                numInterviewInstances !== 1 ? 's' : ''
              }`}
            </div>
          </div>
          <div className="flex justify-between items-center">
            {renderHeaderButtons()}
          </div>
        </div>
      </div>

      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="summary">Summary</Tabs.Tab>
          <Tabs.Tab value="details">All Interviews</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="details">
          <div>
            <div className="">
              {interviewType === 'B2C' ||
              interviewType === 'B2B' ||
              interviewType === 'CUSTOM' ? (
                filteredCards.length > 0 ? (
                  <InterviewList
                    interviews={filteredCards}
                    totalQuestions={totalQuestions}
                  />
                ) : (
                  <p className="text-center text-primary-contrast">
                    No interviews available.
                  </p>
                )
              ) : interviewType === 'OLD_TYPE' ? (
                <InterviewTable />
              ) : null}
            </div>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="summary">
          {interviewId ? (
            <InterviewSummary />
          ) : (
            <Text>No interview ID available</Text>
          )}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default InterviewPage;
