import React from 'react';
import { CalendarIcon, MessageSquare, ArrowUpRight } from 'lucide-react';
import { useLocation, Link } from 'react-router-dom';

interface InterviewCardProps {
  id: string;
  dateAndTimeCreated: string;
  link: string;
  firstQuestion: string;
  firstAnswer: string;
  index?: number;
  answeredQuestions: number;
  totalQuestions: number;
}

const InterviewInstanceCard: React.FC<InterviewCardProps> = ({
  id,
  index,
  dateAndTimeCreated,
  link,
  firstQuestion,
  firstAnswer,
  answeredQuestions,
  totalQuestions,
}) => {
  const formattedDate = new Date(dateAndTimeCreated).toLocaleDateString();
  const location = useLocation();
  const href = `${location.pathname}/${index}`;

  const progressPercentage = (answeredQuestions / totalQuestions) * 100;

  return (
    <Link
      to={href}
      className="block bg-white rounded-2xl shadow-lg overflow-hidden border-2 border-black hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
    >
      <div className="p-6 flex flex-col h-full">
        <div className="flex justify-between items-center mb-4">
          <span className="inline-block text-white text-xs px-3 py-1 rounded-full bg-black font-semibold">
            Interview #{index}
          </span>
          <div className="flex items-center text-gray-600 text-sm">
            <CalendarIcon className="h-4 w-4 mr-1 flex-shrink-0" />
            <span>{formattedDate}</span>
          </div>
        </div>
        <h3 className="text-xl font-bold mb-3 line-clamp-2 overflow-ellipsis text-black">
          {firstQuestion}
        </h3>
        <p className="text-gray-600 mb-4 line-clamp-3 flex-grow">
          "{firstAnswer}"
        </p>
        <div className="flex items-center justify-between text-sm mb-4">
          <div className="flex items-center text-gray-600">
            <MessageSquare className="h-4 w-4 mr-1 flex-shrink-0" />
            <span>
              {answeredQuestions} out of {totalQuestions} questions answered
            </span>
          </div>
          <span className="flex items-center text-black font-medium">
            <span className="mr-1">View Interview</span>
            <ArrowUpRight className="h-4 w-4" />
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-1.5">
          <div
            className="bg-black h-1.5 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>
    </Link>
  );
};

export default InterviewInstanceCard;
