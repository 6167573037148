import React from 'react';

interface AIFollowUpsProps {
  aiInstructions: string;
  setAiInstructions: (value: string) => void;
  maxFollowUps: number;
  setMaxFollowUps: (value: number) => void;
}

const AIFollowUps: React.FC<AIFollowUpsProps> = ({
  aiInstructions,
  setAiInstructions,
  maxFollowUps,
  setMaxFollowUps,
}) => {
  return (
    <div className="space-y-4 mb-4">
      <textarea
        value={aiInstructions}
        onChange={(e) => setAiInstructions(e.target.value)}
        placeholder="Optional: Add instructions here for the AI to generate follow-up questions. If you leave this blank, the AI will do its best to dig deeper on their previous response."
        className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-shadow bg-primary text-primary-contrast resize-none"
        rows={2}
      />
      <div className="flex items-center space-x-2">
        <label htmlFor="maxFollowUps" className="text-1.5sm text-gray-600">
          Max Follow-Up Questions:
        </label>
        <select
          id="maxFollowUps"
          value={maxFollowUps}
          onChange={(e) => setMaxFollowUps(Number(e.target.value))}
          className="p-2 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-shadow bg-gray-50 text-gray-800"
        >
          {[1, 2, 3, 4, 5].map((num) => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default AIFollowUps;
