import React from 'react';
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ChartContainer } from './Chart.tsx';

interface CustomPieProps {
  data: { [key: string]: number } | null | undefined;
  title: string;
  showLegend?: boolean;
  totalResponses: number;
}

const generateColorShades = (numShades: number) => {
  const baseColor = [49, 0, 85];
  const shades = [];

  for (let i = 0; i < numShades; i++) {
    const shade = baseColor.map((channel) =>
      Math.round(channel + (255 - channel) * (i / (numShades + 1)))
    );
    shades.push(`rgb(${shade.join(',')})`);
  }

  return shades;
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 border border-gray-200 rounded shadow">
        <p className="text-sm font-semibold mb-1">{payload[0].name}</p>
        <p className="text-sm">{`Count: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

export function CustomPie({
  data,
  title,
  showLegend = false,
  totalResponses,
}: CustomPieProps) {
  const chartData = React.useMemo(() => {
    if (!data) return [];
    return Object.entries(data).map(([name, value]) => ({ name, value }));
  }, [data]);

  const COLORS = React.useMemo(
    () => generateColorShades(chartData.length),
    [chartData]
  );

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul
        style={{
          listStyle: 'none',
          padding: 0,
          fontSize: '0.75rem',
          color: '#6B7280',
        }}
      >
        {chartData.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}
          >
            <span
              style={{
                display: 'inline-block',
                width: 8,
                height: 8,
                backgroundColor: COLORS[index],
                marginRight: 4,
              }}
            ></span>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {entry.name}: {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  if (chartData.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <ChartContainer config={{}}>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Tooltip content={<CustomTooltip />} />
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius="55%"
            outerRadius="80%"
            paddingAngle={2}
            strokeWidth={1}
            stroke="#fff"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
            <Label
              content={({ viewBox }) => {
                if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy ?? 0 - 10}
                        className="fill-gray-800 text-2xl font-bold"
                      >
                        {totalResponses.toLocaleString()}
                      </tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy ?? 0) + 15}
                        className="fill-gray-500 text-sm"
                      >
                        Responses
                      </tspan>
                    </text>
                  );
                }
              }}
            />
          </Pie>
          {showLegend && (
            <Legend
              content={renderLegend}
              verticalAlign="middle"
              align="right"
              layout="vertical"
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
