import React from 'react';
import { FiX } from 'react-icons/fi';
import { getDisplayName } from '../../../utils/general.ts';
import { QuestionType } from '@functions/common/types.ts';

interface QuestionTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectType: (type: QuestionType) => void;
}

const QuestionTypeModal: React.FC<QuestionTypeModalProps> = ({
  isOpen,
  onClose,
  onSelectType,
}) => {
  if (!isOpen) return null;

  const questionTypes: QuestionType[] = [
    'text-block',
    'open-ended',
    'multiple-choice',
    'single-select',
    'ai-follow-ups',
    'rating',
  ];

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[9999]"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-3xl p-8 w-full max-w-md relative z-[10000]">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
          aria-label="Close modal"
        >
          <FiX size={24} />
        </button>
        <h2 className="text-2xl font-semibold mb-6 text-black pr-8">
          Select Question Type
        </h2>
        <div className="grid grid-cols-1 gap-4">
          {questionTypes.map((type) => (
            <button
              key={type}
              onClick={() => onSelectType(type)}
              className="px-6 py-4 bg-gray-50 text-black rounded-2xl hover:bg-gray-100 transition-colors text-lg font-medium"
            >
              {getDisplayName(type)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionTypeModal;
