import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { FirebaseFunctionsClient } from '../utils/firebaseFunctionsClient';

type MarkInterviewAttendedRequest = {
  interviewId: string;
  screenerResponseId: string;
};

const useMarkInterviewAsAttended = (
  options?: Partial<
    UseMutationOptions<any, Error, MarkInterviewAttendedRequest>
  >
) => {
  return useMutation<
    MarkInterviewAttendedRequest,
    Error,
    MarkInterviewAttendedRequest
  >({
    // @ts-ignore
    mutationFn: (data: MarkInterviewAttendedRequest) =>
      new FirebaseFunctionsClient().markInterviewAsAttended({
        interviewId: data.interviewId,
        screenerResponseId: data.screenerResponseId,
      }),
    ...options,
  });
};

export default useMarkInterviewAsAttended;
