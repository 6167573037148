import React, { useState } from 'react';
import toast from 'react-hot-toast';

const PayoutModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (paymentMethod: string, currency: string, email: string) => void;
  balance: number;
}> = ({ isOpen, onClose, onSubmit, balance }) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [currency, setCurrency] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form fields
    if (balance < 1) {
      toast.error('You must have a balance to request a payout.');
      return;
    }

    if (!paymentMethod) {
      toast.error('Please select a payment method.');
      return;
    }

    if (!currency) {
      toast.error('Please select a currency.');
      return;
    }

    if (!email) {
      toast.error('Please enter an email address.');
      return;
    }

    if (email !== confirmEmail) {
      toast.error('Email addresses do not match.');
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    onSubmit(paymentMethod, currency, email);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto md:ml-40">
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div
          className="fixed inset-0 transition-opacity"
          onClick={onClose}
        ></div>
        <div className="inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-primary-contrast mb-4">
              Request a Payout
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="paymentMethod"
                  className="block text-sm font-medium text-primary-contrast mb-1"
                >
                  Payment Method
                </label>
                <select
                  id="paymentMethod"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-primary-contrast"
                >
                  <option value="">Select Payment Method</option>
                  <option value="paypal">PayPal</option>
                  <option value="etransfer">E-Transfer</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="currency"
                  className="block text-sm font-medium text-primary-contrast mb-1"
                >
                  Currency
                </label>
                <select
                  id="currency"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-primary-contrast"
                >
                  <option value="">Select Currency</option>
                  <option value="cad">CAD</option>
                  <option value="usd">USD</option>
                </select>
              </div>
              {paymentMethod && (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-primary-contrast mb-1"
                    >
                      {paymentMethod === 'paypal'
                        ? 'PayPal Email'
                        : 'E-Transfer Email'}
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 py-1.5 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-full bg-white text-primary-contrast"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="confirmEmail"
                      className="block text-sm font-medium text-primary-contrast mb-1"
                    >
                      {paymentMethod === 'paypal'
                        ? 'Confirm PayPal Email'
                        : 'Confirm E-Transfer Email'}
                    </label>
                    <input
                      type="email"
                      id="confirmEmail"
                      value={confirmEmail}
                      onChange={(e) => setConfirmEmail(e.target.value)}
                      className="mt-1 py-1.5 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-full bg-white text-primary-contrast"
                    />
                  </div>
                </>
              )}
              <div className="mt-6">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 bg-primary-contrast text-base font-medium text-primary hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  Submit
                </button>
              </div>
            </form>
            <p className="mt-4 text-center text-sm text-gray-600">
              Your payout request will be processed within the next 7 days.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutModal;
