import React, { useState, KeyboardEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowUpRight } from 'lucide-react';
import { createInterviewInstanceOnFirestore } from '../../utils/firestore';

const ParticipantIdCapture: React.FC = () => {
  const [respondentId, setRespondentId] = useState('');
  const { interviewId } = useParams<{ interviewId: string }>();
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (respondentId.trim() && interviewId) {
      createInterviewInstanceOnFirestore(interviewId, false, true, respondentId)
        .then((interviewInstanceRef) => {
          navigate(`/chat/${interviewId}/${interviewInstanceRef.id}`);
        })
        .catch((error) => {
          console.error('Error creating interview instance:', error);
        });
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && respondentId.trim()) {
      handleSubmit();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-primary text-primary-contrast p-4">
      <div className="w-full max-w-md text-center">
        <h1 className="text-4xl sm:text-5xl font-extrabold mb-6 whitespace-nowrap">
          Welcome
        </h1>
        <p className="text-xl sm:text-2xl mb-8 font-medium">
          Please enter your Respondent Participant ID to begin.
        </p>
        <div className="mb-6">
          <input
            type="text"
            value={respondentId}
            onChange={(e) => setRespondentId(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter Respondent Participant ID"
            className="w-full px-4 py-3 rounded-2xl bg-white text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-secondary-accent text-lg border border-gray-300"
            required
          />
        </div>
        <button
          onClick={handleSubmit}
          className="bg-black text-white px-8 py-4 rounded-2xl font-medium text-xl flex items-center justify-center hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg w-full"
        >
          Start Interview
          <ArrowUpRight className="ml-2 h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default ParticipantIdCapture;
