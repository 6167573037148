// SingleSelect.tsx
import React, { useEffect, useState } from 'react';
import { CheckCircle } from 'lucide-react';
import OtherInput from './Shared/OtherInput.tsx';

interface SingleSelectProps {
  choices: string[];
  show: boolean;
  selectedChoice: string | undefined;
  onSelect: (choice: string, isOther: boolean) => void;
  disabled: boolean;
  includeOther: boolean;
  setIsOtherFieldActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const SingleSelect: React.FC<SingleSelectProps> = ({
  choices,
  show,
  selectedChoice,
  onSelect,
  disabled,
  includeOther,
  setIsOtherFieldActive,
}) => {
  const [isOtherActive, setIsOtherActive] = useState(false);
  const [otherValue, setOtherValue] = useState('');

  const handleSelect = (choice: string) => {
    console.log('handleSelect called', choice);
    if (!disabled) {
      onSelect(choice, false);
      setOtherValue('');
    }
  };

  const handleOtherChange = (value: string) => {
    console.log('handleOtherChange called', value);
    setOtherValue(value);
    onSelect(`Other: ${value.trim()}`, true);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      console.log('Key pressed', event.key, 'isOtherActive:', isOtherActive);
      if (isOtherActive) return;
      const key = event.key.toLowerCase();
      const index = key.charCodeAt(0) - 97; // Convert a-z to 0-25
      if (
        index >= 0 &&
        index < choices.length &&
        !disabled &&
        key.length === 1
      ) {
        event.preventDefault();
        handleSelect(choices[index]);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [choices, disabled, isOtherActive, handleSelect]);

  useEffect(() => {
    if (selectedChoice?.startsWith('Other: ')) {
      setOtherValue(selectedChoice.slice(7));
    } else {
      setOtherValue('');
    }
  }, [selectedChoice]);

  useEffect(() => {
    setIsOtherFieldActive(isOtherActive);
  }, [isOtherActive, setIsOtherFieldActive]);

  return (
    <div
      className={`space-y-4 transition-opacity duration-500 ease-in-out ${
        show ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {choices.map((choice, index) => (
        <button
          key={index}
          disabled={disabled}
          className={`w-full px-6 py-4 text-left text-base font-medium border-2 rounded-2xl transition-all duration-300 ease-in-out flex items-center justify-between
            ${
              selectedChoice === choice
                ? 'bg-primary-contrast text-primary border-primary-contrast'
                : 'bg-primary text-primary-contrast border-gray-200 hover:border-primary-contrast hover:shadow-md'
            }
            ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
          `}
          onClick={() => handleSelect(choice)}
        >
          <span>
            <span className="mr-3 font-bold">
              {String.fromCharCode(65 + index)}
            </span>
            {choice}
          </span>
          {selectedChoice === choice && (
            <CheckCircle className="h-6 w-6 text-primary" />
          )}
        </button>
      ))}
      {includeOther && (
        <OtherInput
          disabled={disabled}
          selectedChoices={selectedChoice ? [selectedChoice] : []}
          onSelect={handleOtherChange}
          index={choices.length}
          isOtherActive={isOtherActive}
          setIsOtherActive={setIsOtherActive}
          otherValue={otherValue}
          setOtherValue={setOtherValue}
          isMultiSelect={false}
        />
      )}
    </div>
  );
};

export default SingleSelect;
