import React, { useState } from 'react';
import SidebarLight from './SidebarLight.tsx';
import { useAuth } from '../Authentication/AuthProvider';

interface LayoutLightProps {
  children: React.ReactNode;
}

const LayoutLight: React.FC<LayoutLightProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user } = useAuth();

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex bg-primary">
      <SidebarLight
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        isAuthenticated={!!user}
      />
      <div className="flex-1 xl:ml-64">
        {(user || isSidebarOpen) && (
          <button
            className="fixed top-4 left-4 z-50 text-gray-800 focus:outline-none xl:hidden"
            onClick={handleSidebarToggle}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default LayoutLight;
