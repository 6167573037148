import React from 'react';
import { format, parseISO } from 'date-fns';

interface TimelineMessageProps {
  speaker: string;
  content: string;
  timestamp: string | null;
}

const TimelineMessage: React.FC<TimelineMessageProps> = ({
  speaker,
  content,
  timestamp,
}) => {
  let formattedTime = null;
  if (timestamp) {
    try {
      const date = parseISO(timestamp);
      formattedTime = format(date, 'h:mm a');
    } catch (error) {
      console.error('Error formatting time:', error);
    }
  }

  const isParticipant = speaker === 'Participant';

  return (
    <div className="mb-10">
      <div className="flex items-baseline mb-2">
        <span className="text-1.5md font-normal text-primary-contrast mr-2">
          {speaker}
        </span>
        {formattedTime && (
          <span className="text-sm text-gray-500">{formattedTime}</span>
        )}
      </div>
      <div
        className={`p-4 rounded-lg ${
          isParticipant
            ? 'bg-gray-50 border border-primary-muted'
            : 'bg-primary border border-primary-muted'
        }`}
      >
        <p className="text-lg text-primary-contrast">{content}</p>
      </div>
    </div>
  );
};

interface InterviewMessage {
  type: 'question' | 'answer';
  content: string;
  timestamp: string | null;
}

interface InterviewQADisplayProps {
  interviewMessages: InterviewMessage[];
}

const InterviewQADisplay: React.FC<InterviewQADisplayProps> = ({
  interviewMessages,
}) => {
  return (
    <div className="mx-auto py-4 bg-primary rounded-lg">
      {interviewMessages.map((message, index) => (
        <TimelineMessage
          key={index}
          speaker={message.type === 'question' ? 'Probe' : 'Participant'}
          content={message.content}
          timestamp={message.timestamp}
        />
      ))}
    </div>
  );
};

export default InterviewQADisplay;
