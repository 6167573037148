import React, { useEffect, useState, useRef } from 'react';
import logoFull from '../../assets/icons/logoFull.svg';
import defaultProfile from '../../assets/icons/defaultProfile.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthProvider.tsx';
import { getCreditsFromFirestoreGivenUserId } from '../../utils/firestore.ts';
import keyIconLight from '../../assets/icons/keyIconLight.svg';
import hamburgerMenuIcon from '../../assets/icons/hamburgerMenuIcon.svg';
import DropdownMenu from '../DropdownMenu/DropdownMenu.tsx';

interface NavbarProps {
  showBuyButton?: boolean;
}

const Navbar = ({ showBuyButton }: NavbarProps) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [credits, setCredits] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const navigateHome = () => {
    navigate('/');
  };

  const navigateTo404 = () => {
    navigate('/404');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close the dropdown
      }
    };

    // Only attach the event listener when the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup function (modified)
    return () => {
      // Remove the listener if the dropdown was open
      if (isDropdownOpen) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [isDropdownOpen]); // Dependency on isDropdownOpen

  useEffect(() => {
    const getCredits = async () => {
      if (user === null) return;
      const credits = await getCreditsFromFirestoreGivenUserId(user.uid);
      setCredits(credits);
    };
    getCredits();
  }, []);

  return (
    <div className="navbar">
      {' '}
      <div className="flex-1">
        <img
          src={logoFull}
          alt="Probe logo"
          className="btn btn-ghost"
          onClick={navigateHome}
        />
      </div>
      <div className="flex-none mr-4">
        <button
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          className="btn btn-ghost"
        >
          <img src={hamburgerMenuIcon} alt="menu icon" className="w-7" />
        </button>
        <div className="mt-6" ref={dropdownRef}>
          {isDropdownOpen && <DropdownMenu />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
