import React from 'react';
import Button from '../Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Home } from 'lucide-react';

const PageNotFound = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <div className="bg-primary min-h-screen flex items-center justify-center">
      <div className="text-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto">
          <h1 className="text-3xl sm:text-5xl font-bold text-primary-contrast">
            Whoops! This page got lost in conversation.
          </h1>
          <p className="mt-4 text-xl text-gray-700">
            We can't find the page you're looking for. Not to worry, you can go
            back or head back home.
          </p>
          <div className="mt-8 flex justify-center">
            <Button
              label="Head Back Home"
              buttonType="primary"
              onClick={navigateHome}
              rounding="rounded-full"
              icon={<Home className="w-4 h-4 mt-0.5 mr-1" />}
              iconPosition="left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
