import React from 'react';
import enterIcon from '../../assets/icons/enterIcon.svg';

const CommandButton = ({ onClick, primary = false, isLightMode = false }) => {
  const additionalContainerStyling = primary
    ? 'text-white border border-white'
    : 'text-grey-400';

  const lightModeStyling = isLightMode ? 'border-gray-300 text-gray-600' : '';

  return (
    <button
      className={`btn rounded-full bg-transparent mr-4 text-grey-400 hover:text-white border border-gray-700 w-full sm:w-auto text-lg font-normal normal-case ${additionalContainerStyling} ${lightModeStyling}`}
      onClick={onClick}
    >
      <p>Shift + Enter</p>
      <img src={enterIcon} alt="enter icon" className="w-4 h-4" />
      <p>to Submit</p>
    </button>
  );
};

export default CommandButton;
