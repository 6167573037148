import React from 'react';
import { ArrowUpRight } from 'lucide-react';

interface PricingCardProps {
  tag?: string; // Make tag optional
  price: number;
  features: string[];
  buttonText: string;
  handleClick: () => void;
  startingAt?: boolean;
  isB2B?: boolean;
  highlightedBorder?: boolean;
  highlightedTag?: boolean;
  subtext?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  tag,
  price,
  features,
  buttonText,
  handleClick,
  startingAt = false,
  isB2B = false,
  highlightedBorder = false,
  highlightedTag = false,
  subtext,
}) => {
  const borderClass = highlightedBorder
    ? 'border-2 border-black'
    : 'border border-gray-200';

  const tagClass = highlightedTag
    ? 'text-black font-semibold border border-black'
    : 'text-gray-700';

  const buttonClass = isB2B
    ? 'bg-black text-white hover:bg-gray-800'
    : 'bg-white text-black border-2 border-black hover:bg-gray-100';

  return (
    <div
      className={`bg-white rounded-2xl shadow-lg w-full max-w-sm overflow-visible relative ${borderClass} p-6 mt-4 flex flex-col`}
    >
      {tag && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
          <span
            className={`inline-block ${tagClass} text-xs px-4 py-2 rounded-full bg-white`}
          >
            {tag}
          </span>
        </div>
      )}
      <div className="text-center mb-8 mt-2">
        <div>
          <div className="h-6 mb-2">
            {startingAt && (
              <div className="text-sm font-light text-gray-500">
                Starting at
              </div>
            )}
            {subtext && (
              <div className="text-sm font-light text-gray-500">{subtext}</div>
            )}
          </div>
          <div className="flex items-baseline justify-center">
            <span className="text-4xl font-bold">${price}</span>
            <span className="text-gray-500 ml-1">/ Interview</span>
          </div>
        </div>
      </div>
      <ul className="space-y-3 mb-6 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <svg
              className="w-5 h-5 text-green-500 mr-2 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <span className="text-sm sm:text-base">{feature}</span>
          </li>
        ))}
      </ul>
      <button
        onClick={handleClick}
        className={`relative inline-flex items-center justify-center px-6 py-3 w-full text-1.5xl font-medium rounded-2xl transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg ${buttonClass}`}
      >
        <span className="relative flex items-center">
          {buttonText}
          <ArrowUpRight className="ml-2 h-5 w-5 animate-pulse" />
        </span>
      </button>
    </div>
  );
};

export default PricingCard;
