import React, { useEffect, useState } from 'react';
import Header from '../../Text/Header.tsx';
import Subheader from '../../Text/Subheader.tsx';
import SearchInput from './SearchInput.tsx';
import FilterModal from './FilterModal.tsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import LoadingRing from '../../Loading/LoadingRing.tsx';
import LoadingFiltersAI from '../../Loading/LoadingFiltersAI.tsx';
import SelectedFilters from './SelectedFilters.tsx';
import FilterList from './FilterList.tsx';
import { useFilters } from '../../../hooks/useFilters.ts';
import starsIconWhite from '../../../assets/icons/starsIconWhite.svg';
import AIButton from '../../Buttons/AIButton.tsx';
import FiltersSearchBar from './FiltersSearchBar.tsx';
import FilterGroup from './FilterGroup.tsx';

const Filters = () => {
  const navigate = useNavigate();
  const [currentFilter, setCurrentFilter] = useState(null);
  const [sliderValue, setSliderValue] = useState<number[]>([0, 100]);
  const [populationSize, setPopulationSize] = useState(150000);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [loadingPopulationSize, setLoadingPopulationSize] = useState(true);
  const [showFilterGroups, setShowFilterGroups] = useState(true);
  const location = useLocation();
  const isLandingPage = location.pathname.toLowerCase().includes('home');
  const urlParameters = useParams();

  let interviewId, studyId;
  if (isLandingPage) {
    interviewId = 'lKil6NWWT2pHaACoahkv';

    // generates random 10-digit id
    studyId = Array.from(
      { length: 10 },
      () =>
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[
          Math.floor(Math.random() * 62)
        ]
    ).join('');
  } else {
    interviewId = urlParameters.interviewId;
    studyId = urlParameters.studyId;
  }

  const {
    filters,
    filtersSelected,
    searchQuery,
    filteredFilters,
    loading,
    setSearchQuery,
    updateFiltersSelectedGivenUpdatedFilter,
    loadingVectorSearch,
    changesSaved,
    setChangesSaved,
    getRelevantFilterGroups,
    vectorSearchCompleted,
    filtersSearchQuery,
    setFiltersSearchQuery,
    filterSearchCompleted,
    setFilterSearchCompleted, // Add this if it's not already in useFilters
    filterGroupsSearchCompleted,
    relevantFilterGroups,
    handleRegularSearch,
    handleVectorSearch,
    loadingFilterGroups,
    setLoadingFilterGroups,
  } = useFilters(interviewId!, studyId!);
  const selectedFilterIds = filtersSelected.map((f) => f.filter_id);

  const isDevelopment = process.env.NODE_ENV === 'development';

  const handleFilterGroupsSearch = async () => {
    await getRelevantFilterGroups(filtersSearchQuery);
    setShowFilterGroups(true); // Show filter groups after search is completed
    setShowAllFilters(false); // Hide the manual filter search
  };

  const handleSubmit = async () => {
    const MAX_POPULATION_SIZE = 200;

    if (populationSize <= MAX_POPULATION_SIZE) {
      toast(
        `Your audience size is too small. Please increase your audience to at least ${MAX_POPULATION_SIZE} by removing one or more filters.`
      );
      return;
    }

    navigate(`/respondents/${interviewId}/${studyId}`);
  };

  const handleSliderChange = async (
    filterId: string,
    newSliderValue: [number, number]
  ) => {
    setSliderValue(newSliderValue);
    const filterData = getFilterDataGivenId(filterId);

    updateFiltersSelectedGivenUpdatedFilter(
      filterId,
      filterData,
      'sliderSelections',
      newSliderValue
    );
  };

  const handleCheckboxToggle = async (
    filterId: string,
    checkboxValue: number
  ) => {
    const filterData = getFilterDataGivenId(filterId);
    const existingFilter = filtersSelected.find(
      (f) => f.filter_id === filterId
    );

    const filterType = existingFilter?.choicesSelected
      ? 'choicesSelected'
      : 'sliderSelections';

    let newChoicesSelected;
    if (existingFilter && filterType === 'choicesSelected') {
      const isCheckboxValueSelected =
        existingFilter.choicesSelected.includes(checkboxValue);

      if (isCheckboxValueSelected) {
        // Remove the checkbox value from the array
        newChoicesSelected = existingFilter.choicesSelected.filter(
          (val) => val !== checkboxValue
        );
      } else {
        // Add the checkbox value to the array
        newChoicesSelected = [...existingFilter.choicesSelected, checkboxValue];
        console.log('new choices selected :', newChoicesSelected);
      }
    } else {
      // If the filter doesn't exist or doesn't have choicesSelected, initialize with the checkboxValue
      newChoicesSelected = [checkboxValue];
    }

    await updateFiltersSelectedGivenUpdatedFilter(
      filterId,
      filterData,
      'choicesSelected',
      newChoicesSelected
    );
  };

  const deleteFilterFromFiltersSelected = (
    filterId: string,
    type: 'choicesSelected' | 'sliderSelections'
  ) => {
    updateFiltersSelectedGivenUpdatedFilter(filterId, {}, type, []);
  };

  const toggleAllChoices = (
    filterId: string,
    choices: { [key: string]: string },
    action: 'select' | 'deselect'
  ) => {
    let allChoicesSelected: number[] = [];
    const filterData = getFilterDataGivenId(filterId);

    if (action === 'deselect') {
      allChoicesSelected = [];
    } else {
      const choicesLength = Object.keys(choices).length;
      for (let i = 0; i < choicesLength; i++) {
        allChoicesSelected.push(i);
      }
    }

    updateFiltersSelectedGivenUpdatedFilter(
      filterId,
      filterData,
      'choicesSelected',
      allChoicesSelected
    );
  };

  const handleCloseModal = () => {
    setCurrentFilter(null);
    setChangesSaved(false);
  };

  const countParticipants = async (filters) => {
    const emulatorUrl =
      'http://127.0.0.1:5001/joinprobe/us-central1/countParticipants';
    const productionUrl =
      'https://us-central1-joinprobe.cloudfunctions.net/countParticipants';

    const url = isDevelopment ? emulatorUrl : productionUrl;

    const updatedFilters = filters?.map((filter) =>
      convertFilterToRangeOrSelectFilter(filter)
    );

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filters: updatedFilters }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          `Response Error: ${response.status} ${response.statusText}`,
          errorText
        );
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.count;
    } catch (error) {
      console.error('Error counting participants:', error);
      throw error;
    }
  };

  type SelectFilter = {
    filter_id: string;
    selected_values: string[]; // numbers as strings, eg. ['0', '1', '2']
  };

  type RangeFilter = {
    filter_id: string;
    selected_range: {
      lower: number;
      upper: number;
    };
  };

  const convertNumbersInArrayToStrings = (array: number[]) => {
    return array.map((number) => number.toString());
  };

  const convertFilterToRangeOrSelectFilter = (filter: {}) => {
    const filterId = filter.filter_id;
    const choicesSelected = filter.choicesSelected;
    const sliderSelections = filter.sliderSelections;

    const choicesSelectedAsStrings = choicesSelected
      ? convertNumbersInArrayToStrings(choicesSelected)
      : choicesSelected;

    if (choicesSelected) {
      const selectFilter: SelectFilter = {
        filter_id: filterId,
        selected_values: choicesSelectedAsStrings,
      };
      return selectFilter;
    } else {
      const rangeFilter: RangeFilter = {
        filter_id: filterId,
        selected_range: {
          lower: sliderSelections[0],
          upper: sliderSelections[1],
        },
      };
      return rangeFilter;
    }
  };

  const handleSearchChange = (query) => {
    handleRegularSearch(query);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFilterGroupsSearch();
    }
  };

  const openModalWithFilter = (filter) => {
    setCurrentFilter(filter);
  };

  const getFilterDataGivenId = (filterId: string) => {
    const filterData = filters.find((filter) => filter.filter_id === filterId);
    return filterData;
  };

  const formatNumberWithComma = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleFiltersSearchChange = (query) => {
    setFiltersSearchQuery(query);
  };

  const toggleShowAllFilters = () => {
    setShowAllFilters((prev) => !prev);
    if (!showAllFilters) {
      // When showing all filters, hide the filter groups and ensure the filter list is displayed
      setShowFilterGroups(false);
      setFilterSearchCompleted(true);
      handleRegularSearch(''); // This will fetch all filters
    } else {
      // When hiding all filters, show the filter groups again if there are any
      setShowFilterGroups(
        filterGroupsSearchCompleted && relevantFilterGroups.length > 0
      );
    }
  };

  useEffect(() => {
    const countParticipantsAndSetPopulationSize = async () => {
      setLoadingPopulationSize(true);
      const count = await countParticipants(filtersSelected);
      setPopulationSize(count);
      setLoadingPopulationSize(false);
    };
    countParticipantsAndSetPopulationSize();
  }, [filtersSelected]);

  return (
    <div>
      <div className="py-20 px-5 sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-10/12 2xl:w-7/12 sm:m-auto">
        {!isLandingPage && (
          <>
            {' '}
            <div className="text-2.5xl sm:text-3.5xl md:text-4xl lg:text-4.5xl font-bold text-center text-black">
              Find your interview participants
            </div>
            <p className="text-gray-700 text-1.5lg lg:text-1.5xl font-normal mb-2 text-center mt-2.5 lg:mt-3.5">
              Describe your ideal participant to find relevant audience filters.
            </p>
          </>
        )}
        <div className="xl:w-11/12 xl:mx-auto">
          <FiltersSearchBar
            searchQuery={filtersSearchQuery}
            onChange={setFiltersSearchQuery}
            onClick={handleFilterGroupsSearch}
            onKeyDown={handleKeyDown}
            isLandingPage={isLandingPage}
          />
        </div>

        <p className="text-center text-sm mt-9 font-light text-gray-600">
          {!showAllFilters && 'Or' + ' '}
          <span
            className="underline cursor-pointer"
            onClick={toggleShowAllFilters}
          >
            {showAllFilters ? 'Hide filters' : 'Find Filters Manually'}
          </span>
        </p>

        {loadingFilterGroups && (
          <div className="flex justify-center mt-10">
            <LoadingFiltersAI />
          </div>
        )}

        {filtersSelected.length > 0 && (
          <div className="mt-10">
            <div className="flex justify-between">
              <h2 className="text-lg md:text-1.5xl font-light -mt-1 text-gray-700">
                Your Audience
              </h2>
              <div className="text-center text-gray-700">
                <p className="text-sm">
                  {loadingPopulationSize && (
                    <span className="loading loading-spinner loading-xs opacity-80"></span>
                  )}
                  {!loadingPopulationSize &&
                    `${formatNumberWithComma(populationSize)} people`}{' '}
                </p>
                <p className="text-2xs font-light text-gray-600 mt-0.5">
                  {!loadingPopulationSize && `Population Size`}
                </p>
              </div>
            </div>

            <SelectedFilters
              filtersSelected={filtersSelected}
              handleCheckboxToggle={handleCheckboxToggle}
              toggleAllChoices={toggleAllChoices}
              handleSliderChange={handleSliderChange}
              setSliderValue={setSliderValue}
              deleteFilterFromFiltersSelected={deleteFilterFromFiltersSelected}
              openModalWithFilter={openModalWithFilter}
              handleSubmit={handleSubmit}
              isLandingPage={isLandingPage}
            />
          </div>
        )}

        {showFilterGroups &&
          filterGroupsSearchCompleted &&
          relevantFilterGroups.length > 0 && (
            <div className="mt-10">
              {relevantFilterGroups.map((group, index) => (
                <FilterGroup
                  key={index}
                  group={group}
                  openModalWithFilter={openModalWithFilter}
                  selectedFilters={selectedFilterIds}
                />
              ))}
            </div>
          )}

        {showAllFilters && (
          <div className="mt-10">
            <SearchInput
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
              placeholder={'Search for filters...'}
            />

            {loading && (
              <div className="-mt-20">
                <LoadingRing />
              </div>
            )}

            {!loading && (
              <FilterList
                filteredFilters={filteredFilters}
                openModalWithFilter={openModalWithFilter}
              />
            )}

            {loadingVectorSearch && (
              <div className="flex justify-center">
                <LoadingFiltersAI />
              </div>
            )}

            {searchQuery && !loadingVectorSearch && !vectorSearchCompleted && (
              <div className="flex justify-center w-full">
                <AIButton
                  onClick={() => handleVectorSearch(searchQuery)}
                  label="Find more results"
                  className="border border-[#ADADAD] border-opacity-50"
                />
              </div>
            )}
          </div>
        )}

        {currentFilter && (
          <FilterModal
            onClose={handleCloseModal}
            currentFilter={currentFilter}
            handleCheckboxToggle={handleCheckboxToggle}
            checkedState={
              filtersSelected.find(
                (f) => f.filter_id === currentFilter.filter_id
              )?.choicesSelected ?? []
            }
            toggleAllChoices={toggleAllChoices}
            sliderValue={sliderValue}
            handleSliderChange={handleSliderChange}
            changesSaved={changesSaved}
            setSliderValue={setSliderValue}
          />
        )}
      </div>
    </div>
  );
};

export default Filters;
