import React, { useEffect, useState } from 'react';
import { createStudyOnFirestore } from '../../../utils/firestoreStudy.ts';
import { validateInterviewBelongsToUser } from '../../../utils/firestore.ts';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingRing from '../../Loading/LoadingRing.tsx';
import { useAuth } from '../../Authentication/AuthProvider.tsx';
import toast from 'react-hot-toast';

const FiltersRedirect = () => {
  const { interviewId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const createStudyAndNavigate = async () => {
      let studyId;
      const interviewBelongsToUser = await validateInterviewBelongsToUser(
        user?.uid,
        interviewId
      );
      if (interviewId && interviewBelongsToUser) {
        studyId = await createStudyOnFirestore(interviewId);
        navigate(`/filters/${interviewId}/${studyId}`);
      } else {
        navigate('/home');
        toast('Sorry, there was an error. Please try again.');
      }
    };

    createStudyAndNavigate();
  }, [interviewId, navigate, user]);

  return <LoadingRing />;
};

export default FiltersRedirect;
