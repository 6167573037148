import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface FooterProps {
  scrollToPricing: () => void;
}

const Footer = ({ scrollToPricing }: FooterProps) => {
  const navigate = useNavigate();
  const handleContact = () => {
    navigator.clipboard.writeText('savannah@talktoprobe.com');
    toast.success('savannah@talktoprobe.com copied to clipboard!');
  };

  const navigateToAffiliate = () => {
    navigate('/affiliate');
  };

  return (
    <footer className="footer footer-center p-10 text-primary-contrast rounded">
      <nav className="grid grid-flow-col gap-4">
        <a className="link link-hover" onClick={handleContact}>
          Contact
        </a>
        <a className="link link-hover" onClick={scrollToPricing}>
          Pricing
        </a>
        {/* <a className="link link-hover" onClick={navigateToAffiliate}>
          Become an Affiliate
        </a> */}
      </nav>
      <p className="-mt-5">Copyright © 2024 - All rights reserved by Probe</p>
    </footer>
  );
};

export default Footer;
