import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  User,
  signInAnonymously,
} from 'firebase/auth';
import { auth, db, provider } from '../../firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import LoadingRing from '../Loading/LoadingRing.tsx';

const AuthContext = createContext<AuthContextType>({
  user: null,
  authenticateWithGoogle: () => {},
  logout: () => {},
  signInAnonymously: () => Promise.resolve(null),
  setUser: () => {},
});

export const useAuth = () => useContext(AuthContext);

type AuthContextType = {
  user: User | null;
  authenticateWithGoogle: () => void;
  logout: () => void;
  signInAnonymously: () => Promise<User | null>;
  setUser: (user: User | null) => void;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe; // Unsubscribe on unmount
  }, []);

  const authenticateWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        await setDoc(userRef, {
          uid: user.uid,
          email: user.email,
          name: user.displayName,
        });
      }

      setUser(user);
    } catch (error) {
      console.error(error);
      toast(
        "I'm sorry, something went wrong with your sign in. Please try again."
      );
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error(error);
      toast('Sorry, there was a problem logging out. Please try again.');
    }
  };

  const signInAnonymouslyHandler = async (): Promise<User | null> => {
    try {
      const result = await signInAnonymously(auth);
      setUser(result.user);
      return result.user;
    } catch (error) {
      console.error('Error signing in anonymously:', error);
      return null;
    }
  };

  const value: AuthContextType = {
    user,
    authenticateWithGoogle,
    logout,
    signInAnonymously: signInAnonymouslyHandler,
    setUser,
  };

  if (loading) {
    return <LoadingRing />;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
      <Toaster />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
