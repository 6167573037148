import { getFunctions, httpsCallable } from 'firebase/functions';
import { Question } from '@functions/common/types';

interface GenerateQuestionsParams {
  description: string;
  style: string;
  length: string;
  category: string | null;
}

interface GenerateQuestionsResult {
  questions: Question[];
}

export const generateInterviewQuestions = async (
  params: GenerateQuestionsParams
): Promise<GenerateQuestionsResult> => {
  const functions = getFunctions();
  const generateQuestionsFunction = httpsCallable<
    GenerateQuestionsParams,
    GenerateQuestionsResult
  >(functions, 'generateInterviewQuestions');

  try {
    const result = await generateQuestionsFunction(params);
    return result.data;
  } catch (error) {
    console.error('Error generating questions:', error);
    throw error;
  }
};
