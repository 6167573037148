import React, { useState, useEffect, useCallback } from 'react';
import { Expand } from 'lucide-react';

interface QuestionImageProps {
  src: string | File;
  alt: string;
  setIsImageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionImage: React.FC<QuestionImageProps> = ({
  src,
  alt,
  setIsImageModalOpen,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imageSrc = typeof src === 'string' ? src : URL.createObjectURL(src);

  const openModal = () => {
    setIsModalOpen(true);
    setIsImageModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsImageModalOpen(false);
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen, handleKeyDown]);

  return (
    <>
      <div className="relative inline-block">
        <img
          src={imageSrc}
          alt={alt}
          className="max-w-full max-h-128 object-contain rounded-lg shadow-md cursor-pointer"
          onClick={openModal}
        />
        <button
          onClick={openModal}
          className="absolute top-2 right-2 p-1 bg-white bg-opacity-70 rounded-full shadow-sm hover:bg-opacity-100 transition-opacity"
          aria-label="Expand image"
        >
          <Expand className="w-4 h-4 text-gray-600" />
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-[90vw] max-h-[90vh] overflow-hidden">
            <div className="p-2 flex justify-end">
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700"
                aria-label="Close modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="overflow-auto max-h-[calc(90vh-40px)]">
              <img
                src={imageSrc}
                alt={alt}
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionImage;
