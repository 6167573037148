import React from 'react';
import { FaCheck, FaTimes, FaBolt, FaRocket } from 'react-icons/fa';
import SectionHeader from './SectionHeader';

const TimeItem = ({ icon: Icon, time, description, isNegative = false }) => (
  <li className="flex items-center space-x-3">
    <div
      className={`p-1 rounded-full ${
        isNegative ? 'bg-red-500 bg-opacity-20' : 'bg-green-500 bg-opacity-20'
      }`}
    >
      <Icon
        className={`text-lg ${isNegative ? 'text-red-400' : 'text-green-400'}`}
      />
    </div>
    <span className="font-light text-primary-contrast">
      <span className="font-medium">{time}</span> {description}
    </span>
  </li>
);

const BeforeAfter = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-20 sm:py-32">
      <SectionHeader
        title="Research shouldn't be a roadblock."
        subtitle="See how Probe accelerates your research process."
        isLong={true}
      />
      <div className="grid md:grid-cols-2 gap-8 md:gap-12 xl:max-w-5xl xl:mx-auto">
        <div className="bg-white rounded-lg p-8 transition-all duration-300 border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
          <div className="flex items-center mb-6">
            <FaBolt className="text-3xl text-primary-contrast mr-4" />
            <h3 className="text-2xl font-light text-primary-contrast">
              Old Approach
            </h3>
          </div>
          <p className="text-2xl font-semibold text-primary-contrast mb-6">
            43 Hours of Headaches 🌩️
          </p>
          <ul className="space-y-4">
            <TimeItem
              icon={FaTimes}
              time="8 hours"
              description="sourcing candidates"
              isNegative
            />
            <TimeItem
              icon={FaTimes}
              time="30 hours"
              description="interviewing"
              isNegative
            />
            <TimeItem
              icon={FaTimes}
              time="5 hours"
              description="reviewing notes"
              isNegative
            />
          </ul>
        </div>
        <div className="bg-white rounded-lg p-8 transition-all duration-300 border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] relative">
          <div className="absolute top-0 right-0 bg-primary-contrast text-primary text-sm font-semibold px-4 py-2 rounded-tr-md rounded-bl-lg">
            14x Faster 🚀
          </div>
          <div className="flex items-center mb-6">
            <FaRocket className="text-3xl text-primary-contrast mr-4" />
            <h3 className="text-2xl font-medium text-primary-contrast">
              Using Probe
            </h3>
          </div>
          <p className="text-2xl font-bold text-primary-contrast mb-6">
            Complete in
            <span className="bg-primary-light bg-opacity-30 px-2 py-1 rounded-md ml-2">
              3 Hours
            </span>
          </p>
          <ul className="space-y-4">
            <TimeItem icon={FaCheck} time="10 minutes" description="setup" />
            <TimeItem
              icon={FaCheck}
              time="2 hours"
              description="wait for results"
            />
            <TimeItem
              icon={FaCheck}
              time="30 minutes"
              description="reviewing summaries"
            />
          </ul>
        </div>
      </div>
      <div className="mt-8 text-center">
        <span className="inline-block bg-primary-light bg-opacity-10 text-primary-contrast text-sm font-medium px-4 py-2 rounded-full">
          *Average time to complete 40 interviews
        </span>
      </div>
    </div>
  );
};

export default BeforeAfter;
