import React, { useEffect, useState } from 'react';
import Header from '../Text/Header';
import InterviewCard from './InterviewCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthProvider';
import { getInterviewsFromFirestore } from '../../utils/firestore';
import LoadingRing from '../Loading/LoadingRing';
import { capitalizeWords, convertToRelativeTime } from '../../utils/general';
import SquareButton from '../Buttons/SquareButton';
import { FiPlus } from 'react-icons/fi';
import PaymentSuccessModal from '../Modals/PaymentSuccessModal';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [interviews, setInterviews] = useState<any[]>([]);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentSuccess = searchParams.get('payment_success') === 'true';

  useEffect(() => {
    if (paymentSuccess) {
      setShowPaymentSuccessModal(true);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    const fetchInterviews = async () => {
      if (user) {
        setLoading(true);
        try {
          const fetchedInterviews = await getInterviewsFromFirestore(user.uid);
          // Sort interviews by date in descending order
          const sortedInterviews = fetchedInterviews.sort(
            (a, b) =>
              new Date(b.dateAndTimeCreated).getTime() -
              new Date(a.dateAndTimeCreated).getTime()
          );
          // Add default titles if needed
          const interviewsWithTitles = sortedInterviews.map(
            (interview, index) => ({
              ...interview,
              displayTitle:
                interview.title ||
                interview.studyTitle ||
                interview.topic ||
                `Interview #${sortedInterviews.length - index}`,
            })
          );
          setInterviews(interviewsWithTitles);
        } catch (error) {
          console.error('Error fetching interviews:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchInterviews();
  }, [user]);

  const handleAddInterview = () => {
    navigate('/interview-type');
  };

  const handleInterviewClick = (interviewId: string) => {
    navigate(`/dashboard/${interviewId}`);
  };

  if (loading) return <LoadingRing />;

  return (
    <div className="bg-primary min-h-screen">
      <div className="pt-14 pb-20 px-5 sm:px-0 sm:pt-10 sm:w-11/12 xl:w-10/12 sm:m-auto">
        <div className="flex justify-between sm:pl-5">
          <Header text="My Interviews" />
          <SquareButton
            text="New Interview"
            onClick={handleAddInterview}
            icon={<FiPlus />}
          />
        </div>
        <div className="flex flex-wrap pt-10 justify-start">
          {interviews.map((interview) => (
            <InterviewCard
              key={interview.id}
              title={capitalizeWords(interview.displayTitle)}
              createdAt={convertToRelativeTime(interview.dateAndTimeCreated)}
              participants={interview.participants}
              onClick={() => handleInterviewClick(interview.id)}
            />
          ))}
        </div>
      </div>
      {showPaymentSuccessModal && (
        <PaymentSuccessModal
          onClose={() => setShowPaymentSuccessModal(false)}
        />
      )}
    </div>
  );
};

export default Dashboard;
