import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  ComboboxItem,
  Input,
  NumberInput,
  Radio,
  RadioGroup,
  SimpleGrid,
  Slider,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import useGetIndustries from 'src/hooks/useGetIndustries';
import useGetLookups from 'src/hooks/useGetLookups';
import useGetJobTitles from '../../hooks/useGetJobTitles';
import Header from '../Text/Header';
import SearchSelect from './SearchSelect';
import useUpdateRespondentInterviewOnFirebase from 'src/hooks/useUpdateRespondentInterviewOnFirebase';
import { useAuth } from '../Authentication/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../ErrorPages/PageNotFound';
import useProcessedInterviewData from 'src/hooks/useProcessedInterviewData';
import useGetInterview from 'src/hooks/useGetInterview';
import Loader from '../Loader/Loader';
import { capitalizeWords } from 'src/utils/general';
import { ProbeSeniorityType } from '@functions/common/types';

export interface ProbeRespondentInterview {
  jobTitles: ComboboxItem[];
  industries: ComboboxItem[];
  seniority: string;
  companySize: string[];
  countries: ComboboxItem[];
  targetNumberOfParticipants: number;
}

const seniorityOptions: { label: string; value: ProbeSeniorityType }[] = [
  { label: 'Entry Level', value: 'entrylevel' },
  { label: 'Senior', value: 'senior' },
  { label: 'Director+', value: 'director' },
];

const companySizeOptions = [
  { label: 'Self Employed', value: 'selfemployed' },
  { label: '1 - 10 employees', value: '1-10' },
  { label: '11 - 50 employees', value: '11-50' },
  { label: '51 - 200 employees', value: '51-200' },
  { label: '201 - 500 employees', value: '201-500' },
  { label: '501 - 1,000 employees', value: '501-1,000' },
  { label: '1,001 - 5,000 employees', value: '1001-5000' },
  { label: '5,001 - 10,000 employees', value: '5001-10000' },
  { label: '10000+ employees', value: '10000+' },
  { label: 'All company sizes', value: 'all' },
];

const B2BDemographics: React.FC = () => {
  const [jobSearch, setJobSearch] = useState('');
  const [industrySearch, setIndustrySearch] = useState('');
  const [countrySearch, setCountrySearch] = useState('');
  const [debouncedCountrySearch] = useDebouncedValue(countrySearch, 100);
  const [debouncedJobSearch] = useDebouncedValue(jobSearch, 500);
  const navigate = useNavigate();
  const { interviewId } = useParams<{ interviewId: string }>();
  const { data: interviewInstance, isLoading } = useGetInterview(interviewId!, {
    enabled: !!interviewId,
  });

  const form = useForm<ProbeRespondentInterview>({
    initialValues: {
      jobTitles: [],
      seniority: 'entrylevel',
      industries: [],
      companySize: ['all'],
      countries: [],
      targetNumberOfParticipants: 1,
    },
    validate: {
      jobTitles: (value) => {
        return value.length === 0 ? 'Select at least one job title' : null;
      },
      seniority: (value) => (!value ? 'Select a target seniority level' : null),
      industries: (value) =>
        value.length === 0 ? 'Select at least one industry' : null,
      companySize: (value) =>
        value.length === 0 ? 'Select at least one company size' : null,
      countries: (value) =>
        value.length === 0 ? 'Select at least one country' : null,
    },
  });

  useEffect(() => {
    if (!interviewInstance) return;
    form.setValues({
      ...form.values,
      ...interviewInstance,
    });
  }, [interviewInstance]);

  const { data: jobTitlesData, isLoading: jobTitlesLoading } = useGetJobTitles(
    { query: debouncedJobSearch, pageSize: 50 },
    {
      enabled: !!debouncedJobSearch && debouncedJobSearch.length >= 3,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retryOnMount: false,
      retryDelay: 3000,
    }
  );

  const { data: industriesData, isLoading: industriesLoading } =
    useGetIndustries(
      { pageSize: 1000 },
      {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        refetchInterval: false,
        retry: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retryOnMount: false,
        retryDelay: 3000,
      }
    );

  const { data: countriesData, isLoading: countriesLoading } = useGetLookups(
    [['country']],
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retryOnMount: false,
      retryDelay: 3000,
    }
  );

  const jobOptions =
    jobTitlesData?.results.map((job) => ({
      value: job.id,
      label: job.name,
    })) || [];

  const updateRespondentInterviewMutation =
    useUpdateRespondentInterviewOnFirebase({
      onSuccess: () => {
        toast.success('Form submitted successfully!');
        navigate(`/builder/${interviewId}`);
      },
      onError: () => {
        toast.error('Failed to save, please try again');
      },
    });

  const industriesOptions =
    industriesData?.results
      .map((industry) => ({
        value: industry.id,
        label: industry.name,
      }))
      .filter((industry) =>
        industry.label.toLowerCase().includes(industrySearch.toLowerCase())
      ) || [];

  const countriesOptions = useMemo(() => {
    if (!debouncedCountrySearch) return [];
    return (
      countriesData?.country
        ?.map((country) => ({
          value: country.code,
          label: country.value,
        }))
        .filter((country) =>
          country.label
            .toLowerCase()
            .includes(debouncedCountrySearch.toLowerCase())
        ) || []
    );
  }, [countriesData?.country, debouncedCountrySearch]);

  const handleSubmit = async (values: typeof form.values) => {
    if (!interviewId) {
      toast.error('Unable to find interview');
      return;
    }
    const result = form.validate();

    if (result.hasErrors) {
      toast.error('Missing required fields');
      return;
    }

    updateRespondentInterviewMutation.mutate({
      interviewId: interviewId!,
      data: { ...values },
    });
  };

  const handleSeniorityChange = (value: string) => {
    form.setFieldValue('seniority', value);
  };

  const handleCompanySizeChange = (value: string[]) => {
    if (value.at(-1) === 'all') {
      form.setFieldValue('companySize', ['all']);
    } else {
      form.setFieldValue(
        'companySize',
        value.filter((v) => v !== 'all')
      );
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!interviewId || !interviewInstance) {
    return <PageNotFound />;
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <Box className="max-w-screen-md w-2/3">
        <Box className="p-6 space-y-6">
          <Title order={4}>Demographic Information</Title>

          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              <div>
                <Input.Label>Target Number of Participants</Input.Label>
                <Slider
                  {...form.getInputProps('targetNumberOfParticipants')}
                  onChangeEnd={(value) => {
                    form.setFieldValue('targetNumberOfParticipants', value);
                  }}
                  color="black"
                  min={1}
                  max={150}
                  marks={[
                    { value: 5, label: '5' },
                    { value: 50, label: '50' },
                    { value: 100, label: '100' },
                    { value: 150, label: '150' },
                  ]}
                />
              </div>
              <div className="mt-4">
                <SearchSelect
                  initialOptions={interviewInstance.industries}
                  error={form.errors.industries}
                  label="Industry"
                  placeholder="Search for industries"
                  maxValues={5}
                  onSelectionChange={(industries) =>
                    form.setFieldValue('industries', industries)
                  }
                  loading={industriesLoading}
                  search={industrySearch}
                  setSearch={setIndustrySearch}
                  options={industriesLoading ? [] : industriesOptions}
                  required
                />
                <Text mt={4} size="xs" c="dimmed">
                  Select 1 to 5 industries or "All Industries"
                </Text>
              </div>
              <div>
                <SearchSelect
                  initialOptions={interviewInstance.jobTitles}
                  error={form.errors.jobTitles}
                  label="Job Title"
                  placeholder="Search for job titles"
                  maxValues={10}
                  onSelectionChange={(jobs) =>
                    form.setFieldValue('jobTitles', jobs)
                  }
                  loading={jobTitlesLoading}
                  search={jobSearch}
                  setSearch={setJobSearch}
                  options={
                    debouncedJobSearch && !jobTitlesLoading ? jobOptions : []
                  }
                  required
                />
                <Text mt={4} size="xs" c="dimmed">
                  Select 1 to 10 job titles
                </Text>
              </div>

              <RadioGroup
                label="Seniority"
                description="Select target seniority level"
                error={form.errors.seniority}
                value={form.values.seniority}
                onChange={handleSeniorityChange}
                required
              >
                <SimpleGrid cols={3} spacing="xs" my={6}>
                  {seniorityOptions.map((option) => (
                    <Radio
                      key={option.value}
                      value={option.value}
                      label={option.label}
                    />
                  ))}
                </SimpleGrid>
              </RadioGroup>

              <CheckboxGroup
                label="Company Size"
                error={form.errors.companySize}
                description="Select applicable company sizes"
                value={form.values.companySize}
                onChange={handleCompanySizeChange}
                required
              >
                <SimpleGrid cols={2} spacing="xs" my={6}>
                  {companySizeOptions.map((option) => (
                    <Checkbox
                      key={option.value}
                      value={option.value}
                      label={option.label}
                    />
                  ))}
                </SimpleGrid>
              </CheckboxGroup>

              <div>
                <SearchSelect
                  label="Country"
                  initialOptions={interviewInstance.countries}
                  error={form.errors.countries}
                  placeholder="Search for countries"
                  maxValues={25}
                  onSelectionChange={(countries) =>
                    form.setFieldValue('countries', countries)
                  }
                  loading={countriesLoading}
                  search={countrySearch}
                  setSearch={setCountrySearch}
                  options={countriesOptions ?? []}
                  required
                />
                <Text mt={4} size="xs" c={'dimmed'}>
                  Select 1 to 25 countries
                </Text>
              </div>

              {/* <NumberInput
                label="Participant Time (minutes)"
                {...form.getInputProps('participantTimeRequiredMinutes')}
                allowDecimal={false}
                min={5}
                step={5}
                required
              /> */}
              <Button type="submit">Continue</Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default B2BDemographics;
