import React from 'react';
import { LucideIcon } from 'lucide-react';

interface SectionHeaderProps {
  icon: LucideIcon;
  title: string;
  subtitle: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  icon: Icon,
  title,
  subtitle,
}) => {
  return (
    <div className="mb-6 flex items-start">
      <div
        className="bg-secondary-accent/10 p-2 rounded-md mr-4 flex items-center justify-center mt-1"
        style={{ width: '45px', height: '45px' }}
      >
        <Icon className="text-secondary-accent/70" size={28} />
      </div>
      <div>
        <h2 className="text-2xl font-semibold text-primary-contrast">
          {title}
        </h2>
        <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
      </div>
    </div>
  );
};

export default SectionHeader;
