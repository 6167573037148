import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getShortenedGoalsFromFirestore,
  getAllInterviewInstanceGoalSummariesFromFirestore,
} from '../../utils/firestore.ts';
import { ArrowUpRight } from 'lucide-react';

const TruncatedText: React.FC<{ text: string }> = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const characterLimit = 80;

  const textString = typeof text === 'string' ? text : '';

  if (textString.length <= characterLimit) {
    return <span>{textString}</span>;
  }

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        setExpanded((prev) => !prev);
      }}
      className="cursor-pointer"
    >
      {expanded ? (
        <>
          {text}
          <button
            className="ml-1 text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(false);
            }}
          >
            Show Less
          </button>
        </>
      ) : (
        <span className="cursor-pointer">
          {text.slice(0, characterLimit)}
          <span className="text-gray-600 cursor-pointer font-medium">...</span>
        </span>
      )}
    </span>
  );
};

const InterviewTable: React.FC = () => {
  const { interviewId } = useParams();
  const navigate = useNavigate();
  const [interviewSummaries, setInterviewSummaries] = useState<
    Array<{
      interviewInstanceId: string;
      goalSummaries: string[];
      interviewNumber: number;
    }>
  >([]);
  const [goals, setGoals] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (interviewId) {
        const fetchedGoals = await getShortenedGoalsFromFirestore(interviewId);
        setGoals(
          fetchedGoals && fetchedGoals.length > 0
            ? fetchedGoals
            : ['Goal 1', 'Goal 2', 'Goal 3']
        );

        const fetchedInterviewSummaries =
          await getAllInterviewInstanceGoalSummariesFromFirestore(interviewId);

        const convertedInterviewSummaries = fetchedInterviewSummaries.map(
          (summary) => ({
            ...summary,
            dateAndTimeCreated: summary.dateAndTimeCreated
              ? new Date(summary.dateAndTimeCreated)
              : null,
          })
        );

        const sortedInterviewSummaries = convertedInterviewSummaries.sort(
          (a, b) => {
            const dateA = a.dateAndTimeCreated
              ? a.dateAndTimeCreated.getTime()
              : 0;
            const dateB = b.dateAndTimeCreated
              ? b.dateAndTimeCreated.getTime()
              : 0;
            return dateB - dateA;
          }
        );

        const numberedInterviewSummaries = sortedInterviewSummaries.map(
          (summary, index) => ({
            ...summary,
            interviewNumber: index + 1,
          })
        );

        setInterviewSummaries(numberedInterviewSummaries);
      }
    };

    fetchData();
  }, [interviewId]);

  const handleViewInterview = (interviewNumber: number) => {
    navigate(`/dashboard/${interviewId}/${interviewNumber}`);
  };

  return (
    <div className="bg-primary">
      <div className="shadow-lg overflow-hidden rounded-3xl border border-gray-200">
        <div className="overflow-x-auto">
          <table className="table-auto w-full md:table-fixed">
            <colgroup>
              <col style={{ width: '110px' }} />
              {goals.map((_, index) => (
                <col key={index} />
              ))}
            </colgroup>
            <thead>
              <tr className="border-b border-gray-200 bg-gray-50">
                <th className="pl-4 pr-6 py-3 text-left text-sm font-medium text-gray-600 rounded-tl-3xl">
                  Interview
                </th>
                {goals.map((goal, index) => (
                  <th
                    key={index}
                    className="px-2 py-3 text-left text-sm font-medium text-gray-600"
                  >
                    <span className="block text-xs text-gray-400">Goal</span>
                    {goal}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {interviewSummaries.map((interviewSummary, index) => (
                <tr
                  key={interviewSummary.interviewInstanceId}
                  className={`${
                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  } hover:bg-gray-100 cursor-pointer transition-colors duration-150 ease-in-out`}
                  onClick={() =>
                    handleViewInterview(interviewSummary.interviewNumber)
                  }
                >
                  <td className="pl-4 pr-6 py-5 whitespace-nowrap text-sm text-primary-contrast  border-y border-gray-200">
                    <div className="flex items-center">
                      <span>{interviewSummary.interviewNumber}</span>
                      <button
                        className="text-blue-800 hover:text-gray-700 focus:outline-none font-medium ml-2 mr-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewInterview(interviewSummary.interviewNumber);
                        }}
                      >
                        View
                      </button>
                      {/* <ArrowUpRight className="text-blue-800 w-3.5 mt-0.5" /> */}
                    </div>
                  </td>
                  {goals.map((_, columnIndex) => (
                    <td
                      key={columnIndex}
                      className="px-2 py-5 text-sm align-top text-left text-primary-contrast border-y border-gray-200"
                    >
                      {interviewSummary.goalSummaries[columnIndex] &&
                      interviewSummary.goalSummaries[columnIndex] !== 'N/A' ? (
                        <TruncatedText
                          text={interviewSummary.goalSummaries[columnIndex]}
                        />
                      ) : (
                        <span className="text-gray-400">
                          No summary available
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InterviewTable;
