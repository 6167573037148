import { uploadImage } from './firestore.ts';
import { Question, QuestionType } from '@functions/common/types.ts';

const convertToRelativeTime = (dateTimeString: string): string => {
  const dateTime = new Date(dateTimeString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - dateTime.getTime()) / 1000);

  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2628000; // Approximate
  const secondsInYear = 31536000; // Approximate

  const pluralize = (count: number, singular: string, plural: string) =>
    `${count} ${count === 1 ? singular : plural} ago`;

  if (diffInSeconds < secondsInHour) {
    return pluralize(Math.floor(diffInSeconds / 60), 'minute', 'minutes');
  } else if (diffInSeconds < secondsInDay) {
    return pluralize(
      Math.floor(diffInSeconds / secondsInHour),
      'hour',
      'hours'
    );
  } else if (diffInSeconds < secondsInWeek) {
    return pluralize(Math.floor(diffInSeconds / secondsInDay), 'day', 'days');
  } else if (diffInSeconds < secondsInMonth) {
    return pluralize(
      Math.floor(diffInSeconds / secondsInWeek),
      'week',
      'weeks'
    );
  } else if (diffInSeconds < secondsInYear) {
    return pluralize(
      Math.floor(diffInSeconds / secondsInMonth),
      'month',
      'months'
    );
  } else {
    return pluralize(
      Math.floor(diffInSeconds / secondsInYear),
      'year',
      'years'
    );
  }
};

const insertVariablesIntoPrompt = (
  prompt: string,
  variables: Record<string, string>
) => {
  let updatedPrompt = prompt;

  for (const key in variables) {
    updatedPrompt = updatedPrompt.split(`{${key}}`).join(variables[key]);
  }

  return updatedPrompt;
};

const validateEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

const handleImageUpload =
  <T extends Question>(
    interviewId: string,
    questionId: string,
    onUpdate: (updates: Partial<T>) => void
  ) =>
  async (file: File): Promise<string> => {
    try {
      const imageUrl = await uploadImage(file, interviewId, questionId);
      onUpdate({ image: imageUrl } as Partial<T>);
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

const getDisplayName = (type: string): string => {
  switch (type) {
    case 'open-ended':
      return 'Open-Ended';
    case 'multiple-choice':
      return 'Multiple Choice';
    case 'single-select':
      return 'Single Select';
    case 'rating':
      return 'Rating';
    case 'ai-follow-ups':
      return 'AI Follow-ups';
    case 'text-block':
      return 'Text Block';
    default:
      return type.charAt(0).toUpperCase() + type.slice(1);
  }
};

const capitalizeWords = (str: string | undefined): string => {
  if (!str) return '';
  return str.replace(/\b(?<!\w[''‛‵′`])\w/g, (char) => char.toUpperCase());
};

function roundUpToNearest5Minutes(minutes: number): number {
  return Math.ceil(minutes / 5) * 5;
}

export {
  roundUpToNearest5Minutes,
  capitalizeWords,
  convertToRelativeTime,
  getDisplayName,
  handleImageUpload,
  insertVariablesIntoPrompt,
  validateEmail,
};
