import { InterviewInstance } from '@functions/common/types';
import {
  Box,
  Button,
  Card,
  Container,
  Group,
  rem,
  Stack,
  Text,
  Title
} from '@mantine/core';
import { format, parseISO } from 'date-fns';
import { ArrowLeft, Calendar, Check, Clock, List } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaChevronDown, FaChevronUp, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { deleteInterviewInstance } from '../../utils/firestore';
import SquareButton from '../Buttons/SquareButton';
import Loader from '../Loader/Loader';
import DeleteInterviewModal from '../Modals/DeleteInterviewModal';

interface CompletedInterviewProps {
  interview: InterviewInstance;
  interviewNumber: string;
  handleBack: () => void;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  handlePreviousInterview: () => void;
  handleNextInterview: () => void;
}

const CompletedInterview: React.FC<CompletedInterviewProps> = ({
  interview,
  interviewNumber,
  handleNextInterview,
  handleBack,
  handlePreviousInterview,
  isNextDisabled,
  isPreviousDisabled,
}) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    console.log('CompletedInterview rendered with interview:', interview);
  }, [interview]);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteInterviewInstance(interview.interviewId, interview.id);
      toast.success('Interview deleted successfully');
      setShowDeleteModal(false);
      navigate(`/dashboard/${interview.interviewId}`);
    } catch (error) {
      console.error('Error deleting interview instance:', error);
      toast.error(
        "Sorry, the interview wasn't successfully deleted. Please try again later."
      );
    }
  };

  if (!interview || !interview.answers) {
    console.error('Interview or answers are undefined');
    return (
      <Container size="md" py="xl">
        <Text c="primary-contrast">No interview data available.</Text>
      </Container>
    );
  }

  const answers = interview.answers;

  const renderAnswer = (answer: any) => {
    if (!answer) {
      console.error('Answer is undefined');
      return null;
    }

    switch (answer.type) {
      case 'open-ended':
      case 'ai-follow-ups':
        return (
          <Card p="sm" radius="md" className="primary-muted" w="100%">
            <Text className="primary-contrast">{answer.answer}</Text>
          </Card>
        );
      case 'multiple-choice':
      case 'single-select':
        return (
          <Stack gap="md" w="100%">
            {answer.originalQuestion.options.map(
              (choice: any, index: number) => (
                <Button
                  key={index}
                  variant="outline"
                  className="py-16 min-h-12"
                  color={
                    answer.selectedOptions?.includes(choice) ||
                    answer.selectedOption === choice
                      ? 'dark'
                      : 'gray'
                  }
                  fullWidth
                  styles={(theme) => ({
                    root: {
                      backgroundColor:
                        answer.selectedOptions?.includes(choice) ||
                        answer.selectedOption === choice
                          ? 'var(--mantine-color-dark-9)'
                          : 'var(--mantine-color-gray-0)',
                      color:
                        answer.selectedOptions?.includes(choice) ||
                        answer.selectedOption === choice
                          ? 'var(--mantine-color-gray-0)'
                          : 'var(--mantine-color-dark-9)',
                      '&:hover': {
                        backgroundColor:
                          answer.selectedOptions?.includes(choice) ||
                          answer.selectedOption === choice
                            ? 'var(--mantine-color-dark-8)'
                            : 'var(--mantine-color-gray-1)',
                      },
                    },
                    label: {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    },
                  })}
                >
                  <span>
                    <Text span fw={700} mr="xs">
                      {String.fromCharCode(65 + index)}
                    </Text>
                    {choice}
                  </span>
                  {(answer.selectedOptions?.includes(choice) ||
                    answer.selectedOption === choice) && (
                    <Check style={{ width: rem(18), height: rem(18) }} />
                  )}
                </Button>
              )
            )}
            {answer.otherAnswer && (
              <Button
                variant="outline"
                color="dark"
                fullWidth
                py={16}
                styles={{
                  root: {
                    backgroundColor: 'var(--mantine-color-dark-9)',
                    color: 'var(--mantine-color-gray-0)',
                  },
                  label: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  },
                }}
              >
                <span>
                  <Text span fw={700} mr="xs">
                    {String.fromCharCode(
                      65 + answer.originalQuestion.options.length
                    )}
                  </Text>
                  Other: {answer.otherAnswer}
                </span>
                <Check style={{ width: rem(18), height: rem(18) }} />
              </Button>
            )}
          </Stack>
        );
      case 'rating':
        return (
          <Group w="100%">
            {[...Array(answer.originalQuestion.maxRating || 5)].map(
              (_, index) => (
                <Button
                  key={index}
                  variant="outline"
                  color={index + 1 === answer.selectedRating! ? 'dark' : 'gray'}
                  styles={(theme) => ({
                    root: {
                      backgroundColor:
                        index + 1 === answer.selectedRating!
                          ? 'var(--mantine-color-dark-9)'
                          : 'var(--mantine-color-gray-0)',
                      color:
                        index + 1 === answer.selectedRating!
                          ? 'var(--mantine-color-gray-0)'
                          : 'var(--mantine-color-dark-9)',
                    },
                  })}
                >
                  {index + 1}
                </Button>
              )
            )}
          </Group>
        );
      default:
        console.warn(`Unknown answer type: ${answer.type}`);
        return null;
    }
  };

  return (
    <Container size="md" py="xl">
      <Box>
        <Group justify="space-between" align="center" mb="md">
          <Title order={2} className="primary-contrast">
            Interview #{interviewNumber}
          </Title>
          <Group gap="lg">
            <Group gap="xs">
              <Calendar size={16} />
              <Text size="sm" className="primary-contrast">
                {format(parseISO(interview.dateAndTimeCreated), 'PPP')}
              </Text>
            </Group>
            <Group gap="xs">
              <Clock size={16} />
              <Text size="sm" className="primary-contrast">
                {format(parseISO(interview.dateAndTimeCreated), 'p')}
              </Text>
            </Group>
            <Group gap="xs">
              <List size={16} />
              <Text size="sm" className="primary-contrast">
                {answers.length} Questions
              </Text>
            </Group>
          </Group>
        </Group>
        <Group justify="space-between">
          <Button
            leftSection={<ArrowLeft size={16} />}
            variant="subtle"
            onClick={handleBack}
            mb="md"
            styles={(theme) => ({
              root: {
                color: theme.colors.dark[9],
                '&:hover': {
                  backgroundColor: theme.colors.gray[1],
                },
              },
            })}
          >
            Back to Interviews
          </Button>
          <div className="flex items-center space-x-2">
            <button
              className={`bg-primary text-primary-contrast px-3 py-2 h-10 rounded-xl border border-gray-200 shadow-sm hover:bg-primary-dark transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-light ${
                isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleNextInterview}
              disabled={isNextDisabled}
            >
              <FaChevronUp
                size={16}
                className="transition-transform duration-200"
              />
            </button>
            <button
              className={`bg-primary text-primary-contrast px-3 py-2 h-10 rounded-xl border border-gray-200 shadow-sm hover:bg-primary-dark transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-light ${
                isPreviousDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousInterview}
              disabled={isPreviousDisabled}
            >
              <FaChevronDown
                size={16}
                className="transition-transform duration-200"
              />
            </button>
          </div>
        </Group>
      </Box>
      <Stack gap="xl" align="stretch" w="100%">
        {answers.map((answer, index) => {
          if (!answer) {
            console.error(`Answer at index ${index} is undefined`);
            return null;
          }
          const formattedTime = format(parseISO(answer.timestamp), 'h:mm a');
          return (
            <Box
              key={index}
              w="100%"
              style={(theme) => ({
                border: `1px solid ${theme.colors.gray[3]}`,
                borderRadius: theme.radius.md,
                overflow: 'hidden',
              })}
            >
              <Box p="xs" className="primary-muted">
                <Group justify="space-between">
                  <Text fw={500} className="primary-contrast">
                    Question {index + 1}
                  </Text>
                  <Text size="sm" c="dimmed">
                    {formattedTime}
                  </Text>
                </Group>
              </Box>

              <Box p="md" className="primary">
                <Text size="lg" className="primary-contrast" mb="md">
                  {answer.originalQuestion.question}
                </Text>
                {renderAnswer(answer)}
              </Box>
            </Box>
          );
        })}
      </Stack>

      <Box mt="xl" className="flex justify-end">
        <SquareButton
          text="Delete Interview"
          onClick={handleDeleteClick}
          icon={<FaTrash />}
        />
      </Box>

      {showDeleteModal && (
        <DeleteInterviewModal
          interviewId={interview.interviewId}
          interviewInstanceId={interview.id}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleDeleteConfirm}
        />
      )}
    </Container>
  );
};

export default CompletedInterview;
