import { OpenEndedQuestion, Question } from '@functions/common/types.ts';
import { Alert, LoadingOverlay, Skeleton } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useClaude } from '../../../hooks/useClaude.ts';
import useGetInterviewInstance from '../../../hooks/useGetInterviewInstance.ts';
import { useInterviewAnswers } from '../../../hooks/useInterviewAnswers.ts';
import { useInterviewQuestions } from '../../../hooks/useInterviewQuestions.ts';
import {
  createInterviewInstanceOnFirestore,
  getInterviewInstanceByRespondentId,
  updateInterviewInstance,
} from '../../../utils/firestore.ts';
import SquareButton from '../../Buttons/SquareButton';
import ProbeButton from '../ProbeButton.tsx';
import InterviewNavigation from './InterviewNavigation.tsx';
import InterviewQuestion from './InterviewQuestion/InterviewQuestion.tsx';
import QuestionText from './InterviewQuestion/QuestionText';
import useMarkInterviewAsAttended from '../../../hooks/useMarkInterviewAsAttended.ts';
import toast from 'react-hot-toast';
import useCreateOrGetInterviewInstanceByRespondentId from '../../../hooks/useGetInterviewByRespondentId.ts';
import useParticipantProfile from '../../../hooks/useParticipantProfile.ts';

interface InterviewProps {
  interviewId?: string;
}

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen bg-primary">
    <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-primary-contrast"></div>
  </div>
);

const DEFAULT_AI_INSTRUCTIONS =
  "Ask a follow-up question that digs deeper into the respondent's answer, aiming to uncover more detailed insights or underlying motivations.";

const B2BInterview: React.FC<InterviewProps> = () => {
  const { interviewId: paramInterviewId, interviewInstanceId } = useParams<{
    interviewId: string;
    interviewInstanceId?: string;
  }>();
  const [searchParams] = useSearchParams();

  const participantId = searchParams.get('respondent_screener_response_id');
  const profileId = searchParams.get('respondent_profile_id');

  const { data: profile, isLoading: profileLoading } = useParticipantProfile(
    { participantId: profileId! },
    { enabled: !!profileId, retry: false }
  );

  const interviewId = paramInterviewId;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentBaseQuestionIndex, setCurrentBaseQuestionIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isOtherFieldActive, setIsOtherFieldActive] = useState(false);
  const [isGeneratingFollowUp, setIsGeneratingFollowUp] = useState(false);
  const [remainingFollowUps, setRemainingFollowUps] = useState(0);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  // New state for respondent ID collection
  const [respondentId, setRespondentId] = useState(participantId || '');
  const [respondentError, setRespondentError] = useState<string | null>(null);
  const [showRespondentText, setShowRespondentText] = useState(false);
  const [showRespondentInput, setShowRespondentInput] = useState(false);

  const navigate = useNavigate();
  const { askClaude } = useClaude();

  const createOrGetMutation = useCreateOrGetInterviewInstanceByRespondentId({
    onSuccess: (fetchedInterviewInstanceId) => {
      navigate({
        pathname: `/chat/${interviewId}/${fetchedInterviewInstanceId}`,
        search: searchParams.toString(),
      });
    },
  });

  const { data: interviewInstance, isLoading: isLoadingInterviewInstance } =
    useGetInterviewInstance(
      {
        interviewId: interviewId!,
        interviewInstanceId: interviewInstanceId!,
      },
      {
        enabled: !!interviewId && !!interviewInstanceId,
      }
    );

  const {
    questions: allQuestions,
    isLoading,
    setQuestions,
  } = useInterviewQuestions(interviewId);

  const markInterviewAsAttendedMutation = useMarkInterviewAsAttended({
    onSuccess: () => {
      navigate(`/completed/${interviewId}`);
    },
    onError: (error) => {
      console.error('Error marking interview as attended:', error);
      toast.error('Error saving interview, please try again');
    },
  });

  const questions =
    allQuestions?.filter(
      (q: Question) => q.question && q.question.trim() !== ''
    ) || [];

  const {
    answers,
    handleAnswer,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    saveAnswers,
    isPreview,
  } = useInterviewAnswers(
    interviewId,
    questions,
    interviewInstanceId,
    !!interviewInstanceId
  );

  useEffect(() => {
    if (!interviewInstanceId) {
      const textTimer = setTimeout(() => setShowRespondentText(true), 200);
      const inputTimer = setTimeout(() => setShowRespondentInput(true), 1000);

      return () => {
        clearTimeout(textTimer);
        clearTimeout(inputTimer);
      };
    }
  }, [interviewInstanceId]);

  useEffect(() => {
    console.log('asdf', participantId);
    console.log('createOrGetMutation.isIdle', createOrGetMutation.isIdle);
    console.log('interviewId', interviewId);
    if (participantId && createOrGetMutation.isIdle && interviewId) {
      createOrGetMutation.mutate({ interviewId, respondentId: participantId });
    }
  }, [participantId]);

  const handleRespondentSubmit = async () => {
    if (interviewId && respondentId) {
      createOrGetMutation.mutate({ interviewId, respondentId });
    }
  };

  useEffect(() => {
    if (questions && questions.length > 0) {
      const currentQuestion = questions[currentQuestionIndex];
      if (
        (currentQuestion.type === 'open-ended' ||
          currentQuestion.type === 'ai-follow-ups') &&
        currentQuestion.aiFollowUps &&
        currentQuestionIndex === currentBaseQuestionIndex
      ) {
        setRemainingFollowUps(currentQuestion.maxFollowUps || 0);
      }
    }
  }, [questions, currentQuestionIndex, currentBaseQuestionIndex]);

  const hasCurrentAnswer = useCallback(() => {
    if (!questions) return false;
    const currentQuestion = questions[currentQuestionIndex];
    const answer = answers[currentQuestion.id];

    if (currentQuestion.type === 'rating') {
      return typeof answer === 'number';
    }
    if (currentQuestion.type === 'multiple-choice') {
      return Array.isArray(answer) && answer.length > 0;
    }
    if (
      currentQuestion.type === 'single-select' ||
      currentQuestion.type === 'open-ended' ||
      currentQuestion.type === 'ai-follow-ups'
    ) {
      return typeof answer === 'string' && answer.trim() !== '';
    }

    return false;
  }, [questions, currentQuestionIndex, answers]);

  const moveToNextBaseQuestion = useCallback(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentQuestionIndex((prev) => {
        let nextIndex = prev + 1;

        if (nextIndex < questions.length) {
          const nextQuestion = questions[nextIndex];
          setCurrentBaseQuestionIndex(nextIndex);

          if (
            (nextQuestion.type === 'open-ended' ||
              nextQuestion.type === 'ai-follow-ups') &&
            nextQuestion.aiFollowUps
          ) {
            setRemainingFollowUps(nextQuestion.maxFollowUps || 0);
          } else {
            setRemainingFollowUps(0);
          }
        }

        return Math.min(nextIndex, questions.length - 1);
      });
      setIsTransitioning(false);
    }, 500);
  }, [questions, currentQuestionIndex, currentBaseQuestionIndex]);

  const generateFollowUpQuestion = useCallback(
    async (question: OpenEndedQuestion, previousAnswer: string) => {
      setIsGeneratingFollowUp(true);
      const aiInstructions = question.aiInstructions || DEFAULT_AI_INSTRUCTIONS;
      const systemPrompt = `You are an AI assistant helping to conduct an interview. Based on the previous question and answer, generate a follow-up question. Only return the question itself with no other text or context. 1 sentence response is best, max two sentences. DO NOT ask more than one question per response, even if combined into a single sentence. ${aiInstructions}`;
      const messages = [
        {
          role: 'user',
          content: `Previous question: ${question.question}\nPrevious answer: ${previousAnswer}\nGenerate a follow-up question.`,
        },
      ];

      try {
        const followUpQuestion = await askClaude(systemPrompt, messages);

        if (followUpQuestion && questions) {
          const newQuestion: OpenEndedQuestion = {
            id: `${question.id}_followup_${
              (question.maxFollowUps || 0) - remainingFollowUps + 1
            }`,
            type: 'open-ended',
            question: followUpQuestion,
            image: null,
            aiFollowUps: false,
            aiInstructions: question.aiInstructions,
            maxFollowUps: 0,
            allowSkip: question.allowSkip ?? false,
          };
          const updatedQuestions = [...questions];
          updatedQuestions.splice(currentQuestionIndex + 1, 0, newQuestion);
          setQuestions(updatedQuestions);
          return newQuestion;
        }
      } catch (error) {
        console.error('Error generating follow-up question:', error);
      } finally {
        setIsGeneratingFollowUp(false);
      }
      return null;
    },
    [
      askClaude,
      questions,
      currentQuestionIndex,
      remainingFollowUps,
      setQuestions,
    ]
  );

  const handleNext = useCallback(async () => {
    if (!questions || !hasCurrentAnswer()) return;

    const currentQuestion = questions[currentQuestionIndex];
    const currentAnswer = answers[currentQuestion.id];

    if (remainingFollowUps > 0) {
      const followUpQuestion = await generateFollowUpQuestion(
        questions[currentBaseQuestionIndex],
        currentAnswer as string
      );
      if (followUpQuestion) {
        await handleAnswer(followUpQuestion.id, '');
        setCurrentQuestionIndex((prev) => prev + 1);
        setRemainingFollowUps((prev) => prev - 1);
      }
    } else {
      moveToNextBaseQuestion();
    }
  }, [
    questions,
    currentQuestionIndex,
    currentBaseQuestionIndex,
    hasCurrentAnswer,
    answers,
    remainingFollowUps,
    generateFollowUpQuestion,
    handleAnswer,
    moveToNextBaseQuestion,
  ]);

  const handleFinishInterview = useCallback(async () => {
    if (isPreview) {
      navigate(`/completed/${interviewId}`);
      return;
    }

    if (
      !interviewId ||
      !interviewInstanceId ||
      !interviewInstance?.respondentId
    ) {
      console.error('InterviewId or instanceId is missing');
      return;
    }

    try {
      if (hasUnsavedChanges && !isPreview) {
        await updateInterviewInstance(
          interviewId,
          interviewInstanceId,
          answers,
          questions!,
          true
        );
      }
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Error submitting final answer:', error);
    }

    try {
      markInterviewAsAttendedMutation.mutate({
        interviewId,
        screenerResponseId: interviewInstance?.respondentId,
      });
    } catch (error) {
      console.error('Error submitting final answer:', error);
      return;
    }
  }, [
    interviewId,
    interviewInstanceId,
    answers,
    questions,
    hasUnsavedChanges,
    navigate,
    setHasUnsavedChanges,
    isPreview,
  ]);

  const handleBack = useCallback(() => {
    if (currentQuestionIndex > 0 && !isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentQuestionIndex((prev) => {
          let newIndex = prev - 1;
          let baseIndex = currentBaseQuestionIndex;
          while (newIndex > 0 && !questions[newIndex].aiFollowUps) {
            newIndex--;
            if (questions[newIndex].aiFollowUps) {
              baseIndex = newIndex;
            }
          }
          setCurrentBaseQuestionIndex(baseIndex);
          const prevQuestion = questions[baseIndex];
          if (
            (prevQuestion.type === 'open-ended' ||
              prevQuestion.type === 'ai-follow-ups') &&
            prevQuestion.aiFollowUps
          ) {
            const askedFollowUps = questions
              .slice(baseIndex + 1, prev)
              .filter((q: Question) => !q.aiFollowUps).length;
            setRemainingFollowUps(
              (prevQuestion.maxFollowUps || 0) - askedFollowUps
            );
          }
          return newIndex;
        });
        setIsTransitioning(false);
      }, 500);
    }
  }, [
    currentQuestionIndex,
    currentBaseQuestionIndex,
    isTransitioning,
    questions,
  ]);

  const handleSkip = useCallback(() => {
    if (!questions || !questions[currentQuestionIndex].allowSkip) return;

    if (currentQuestionIndex === questions.length - 1) {
      handleFinishInterview();
    } else {
      moveToNextBaseQuestion();
    }
  }, [
    questions,
    currentQuestionIndex,
    handleFinishInterview,
    moveToNextBaseQuestion,
  ]);

  const debouncedHandleNextOrSubmit = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(async () => {
      if (remainingFollowUps > 0) {
        await handleNext();
      } else if (currentQuestionIndex === questions.length - 1) {
        await saveAnswers();
        handleFinishInterview();
      } else {
        await saveAnswers();
        moveToNextBaseQuestion();
      }
    }, 300); // 300ms debounce time
  }, [
    questions,
    currentQuestionIndex,
    remainingFollowUps,
    handleNext,
    saveAnswers,
    handleFinishInterview,
    moveToNextBaseQuestion,
  ]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        hasCurrentAnswer() &&
        !isImageModalOpen &&
        !isOtherFieldActive &&
        !isGeneratingFollowUp
      ) {
        event.preventDefault();
        debouncedHandleNextOrSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [
    hasCurrentAnswer,
    debouncedHandleNextOrSubmit,
    isImageModalOpen,
    isOtherFieldActive,
    isGeneratingFollowUp,
  ]);

  if (isLoading || !questions || isLoadingInterviewInstance) {
    return <LoadingSpinner />;
  }

  const currentQuestion =
    questions && currentQuestionIndex < questions.length
      ? questions[currentQuestionIndex]
      : null;

  if (!currentQuestion) {
    return <div className="text-primary-contrast">Interview completed</div>;
  }

  if (interviewInstance?.isFinished) {
    navigate(`/completed/${interviewId}`);
    return null;
  }

  if (!interviewInstanceId) {
    return (
      <div className="flex flex-col min-h-screen bg-primary p-8">
        <div className="flex-grow flex items-center justify-center">
          <div className="w-full max-w-xl">
            <QuestionText
              questionNumber=""
              questionText="Please enter your Interviewer ID"
              show={showRespondentText}
              isTyping={false}
            />
            <div
              className={`transition-opacity duration-300 ${
                showRespondentInput ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <input
                type="text"
                id="respondentId"
                value={respondentId}
                onChange={(e) => setRespondentId(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-contrast focus:border-primary-contrast text-primary-contrast bg-primary"
                required
                placeholder="Enter your Interviewer ID"
              />
            </div>
            {respondentError && (
              <p className="text-red-500 text-sm">{respondentError}</p>
            )}
            <div className="flex justify-end">
              <LoadingOverlay visible={createOrGetMutation.isPending} />
              <SquareButton
                onClick={handleRespondentSubmit}
                text={
                  createOrGetMutation.isPending
                    ? 'Starting...'
                    : 'Start Interview'
                }
                icon={<FiArrowRight />}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!interviewInstance?.respondentId) {
    navigate(`/chat/${interviewId}/${interviewInstanceId}`);
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen bg-primary p-8">
      <Alert
        variant="light"
        color="grape"
        title={<div>Interviewer Info</div>}
        icon={<IconInfoCircle size="1.5rem" />}
        className="mb-6"
      >
        {profileLoading && <Skeleton height={20} />}
        {profile && (
          <div>
            <p>ID: {interviewInstance.respondentId}</p>
            <p>
              Name: {profile.firstName} {profile.lastInitial}
            </p>
          </div>
        )}
      </Alert>
      <div className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-xl">
          <InterviewQuestion
            question={currentQuestion}
            onAnswer={handleAnswer}
            selectedAnswer={answers[currentQuestion.id]}
            isTransitioning={isTransitioning}
            questionNumber={currentQuestionIndex + 1}
            totalQuestions={questions.length}
            setIsImageModalOpen={setIsImageModalOpen}
            setIsOtherFieldActive={setIsOtherFieldActive}
            onBlur={saveAnswers}
            followUpQuestion={
              currentQuestion.type === 'ai-follow-ups'
                ? currentQuestion.question
                : undefined
            }
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex-shrink-0 pt-7">{<ProbeButton />}</div>
        <div className="flex-shrink-0">
          <InterviewNavigation
            currentQuestionIndex={currentBaseQuestionIndex}
            totalQuestions={questions.filter((q) => !q.aiFollowUps).length}
            isTransitioning={isTransitioning}
            isGeneratingFollowUp={isGeneratingFollowUp}
            allowSkip={currentQuestion.allowSkip}
            hasCurrentAnswer={hasCurrentAnswer()}
            handleBack={handleBack}
            handleSkip={handleSkip}
            handleNextOrSubmit={debouncedHandleNextOrSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default B2BInterview;
