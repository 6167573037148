import React from 'react';
import BaseQuestion from './BaseQuestion.tsx';
import ToggleSwitch from './Shared/ToggleSwitch.tsx';
import ImageUploader from './Shared/ImageUploader.tsx';
import AIFollowUps from './Shared/AIFollowUps.tsx';
import { OpenEndedQuestion as OpenEndedQuestionType } from '../../../../utils/types/questionTypes.ts';
import { handleImageUpload } from '../../../../utils/general.ts';

interface OpenEndedQuestionProps {
  question: OpenEndedQuestionType;
  questionNumber: number;
  onDelete: () => void;
  onUpdate: (updates: Partial<OpenEndedQuestionType>) => void;
  interviewId: string;
}

const OpenEndedQuestion: React.FC<OpenEndedQuestionProps> = ({
  question,
  questionNumber,
  onDelete,
  onUpdate,
  interviewId,
}) => {
  const handleQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate({ question: e.target.value });
  };

  const toggleAIFollowUps = () => {
    onUpdate({ aiFollowUps: !question.aiFollowUps });
  };

  const updateAIInstructions = (instructions: string) => {
    onUpdate({ aiInstructions: instructions });
  };

  const updateMaxFollowUps = (max: number) => {
    onUpdate({ maxFollowUps: max });
  };

  const handleImageDelete = () => {
    onUpdate({ image: null });
  };

  return (
    <BaseQuestion<OpenEndedQuestionType>
      question={question}
      questionNumber={questionNumber}
      onDelete={onDelete}
      onUpdate={onUpdate}
    >
      <textarea
        value={question.question}
        onChange={handleQuestionChange}
        placeholder="Type your question here..."
        className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-shadow bg-primary text-primary-contrast mb-4 resize-none"
        rows={3}
      />
      <div className="flex items-center space-x-8 mb-4">
        <ToggleSwitch
          label="AI Follow-Ups"
          checked={question.aiFollowUps}
          onChange={toggleAIFollowUps}
        />

        <ToggleSwitch
          label="Include Image"
          checked={!!question.image}
          onChange={() =>
            onUpdate({
              image: question.image ? null : new File([], 'placeholder'),
            })
          }
        />
        <ToggleSwitch
          label="Allow Skip"
          checked={question.allowSkip}
          onChange={() => onUpdate({ allowSkip: !question.allowSkip })}
        />
      </div>
      {question.aiFollowUps && (
        <AIFollowUps
          aiInstructions={question.aiInstructions}
          setAiInstructions={updateAIInstructions}
          maxFollowUps={question.maxFollowUps}
          setMaxFollowUps={updateMaxFollowUps}
        />
      )}
      {question.image && (
        <ImageUploader
          onUpload={handleImageUpload<OpenEndedQuestionType>(
            interviewId,
            question.id,
            onUpdate
          )}
          initialImage={
            typeof question.image === 'string' ? question.image : null
          }
          onDelete={handleImageDelete}
        />
      )}
    </BaseQuestion>
  );
};

export default OpenEndedQuestion;
