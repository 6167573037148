import React from 'react';
import { Link } from 'react-router-dom';
import { FaBolt } from 'react-icons/fa';

const ProbeButton: React.FC = () => {
  return (
    <Link
      to="https://probelabs.ai"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center bg-white text-gray-800 text-sm font-semibold py-2 px-3 rounded-md shadow-md hover:bg-gray-100 transition-colors duration-200 border border-gray-300"
    >
      <FaBolt className="text-gray-800 w-4 h-4 mr-2" />
      Built with Probe
    </Link>
  );
};

export default ProbeButton;
