import { InterviewQuestions } from '@functions/common/types.ts';
import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  createInterviewInstance,
  updateInterviewInstance,
  createInterviewInstanceOnFirestore,
} from '../utils/firestore.ts';

export const useInterviewAnswers = (
  interviewId: string | undefined,
  questions: InterviewQuestions | null,
  interviewInstanceId?: string,
  enabled = true
) => {
  const [answers, setAnswers] = useState<
    Record<string, string | string[] | number>
  >({});
  const [instanceId, setInstanceId] = useState<string | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const isPreview = useLocation().pathname.includes('preview');

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const fetchAnswers = async () => {
      if (interviewId && !isPreview) {
        let fetchedInstanceId = interviewInstanceId;
        if (!fetchedInstanceId) {
          try {
            const newInstance =
              await createInterviewInstanceOnFirestore(interviewId);
            fetchedInstanceId = newInstance.id;
          } catch (error) {
            console.error('Error creating interview instance:', error);
            // Handle the error appropriately
          }
        }
        if (fetchedInstanceId) {
          setInstanceId(fetchedInstanceId);
        }
      }
    };

    fetchAnswers();
  }, [interviewId, isPreview, interviewInstanceId, enabled]);

  const handleAnswer = useCallback(
    (questionId: string, answer: string | string[] | number) => {
      const newAnswers = { ...answers, [questionId]: answer };
      setAnswers(newAnswers);
      setHasUnsavedChanges(true);
    },
    [answers]
  );

  const saveAnswers = useCallback(async () => {
    if (!interviewId || !questions || !hasUnsavedChanges || isPreview) return;

    try {
      if (!instanceId) {
        const newInstanceId = await createInterviewInstance(
          interviewId,
          answers,
          questions
        );
        if (newInstanceId) {
          setInstanceId(newInstanceId);
        } else {
          throw new Error('Failed to create interview instance');
        }
      } else {
        await updateInterviewInstance(
          interviewId,
          instanceId,
          answers,
          questions
        );
      }
      setHasUnsavedChanges(false);
      return instanceId;
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  }, [answers, instanceId, interviewId, questions, hasUnsavedChanges]);

  return {
    answers,
    handleAnswer,
    instanceId,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    saveAnswers,
    isPreview,
  };
};
