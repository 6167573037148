import {
  collection,
  addDoc,
  arrayUnion,
  setDoc,
  doc,
  DocumentReference,
  getDoc,
  updateDoc,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { FiltersSelected } from '../components/Study/Filters/Filters';

const createStudyOnFirestore = async (interviewId: string) => {
  const studyData = {
    // empty for now
  };

  const studiesCollectionRef = collection(
    db,
    `interviews/${interviewId}/studies`
  );

  try {
    const docRef = await addDoc(studiesCollectionRef, studyData);
    return docRef.id;
  } catch (error) {
    console.error('Error creating study: ', error);
    throw error;
  }
};

interface SelectedFilterData {
  title: string;
  question: string;
  choicesSelected?: number[];
  sliderSelected?: [number, number];
  choices?: { [key: string]: string };
  min?: number;
  max?: number;
}

const addFilterToStudyOnFirestore = async (
  interviewId: string,
  studyId: string,
  filterId: string,
  choicesSelected: number[]
) => {
  const filterRef = doc(
    db,
    `interviews/${interviewId}/studies/${studyId}/filters`,
    filterId
  );

  try {
    await setDoc(filterRef, choicesSelected);
  } catch (error) {
    console.error('Error adding filter to study: ', error);
    throw error;
  }
};

const getFiltersSelectedFromFirestore = async (interviewId, studyId) => {
  try {
    // Access the study document first
    const studyRef = doc(db, `interviews/${interviewId}/studies/${studyId}`);
    const studySnapshot = await getDoc(studyRef);

    // Check if the study document exists
    if (!studySnapshot.exists()) {
      console.error('No such study!');
      return;
    }

    // Retrieve the filtersSelected field
    const filtersSelected = studySnapshot.data().filtersSelected;

    return filtersSelected;
  } catch (error) {
    console.error('Error getting filters: ', error);
    throw error;
  }
};

const addFiltersSelectedToStudyOnFirestore = async (
  interviewId: string,
  studyId: string,
  filtersSelected: FiltersSelected
) => {
  const studyRef = doc(db, `interviews/${interviewId}/studies/${studyId}`);
  try {
    await updateDoc(studyRef, {
      filtersSelected: filtersSelected,
    });
  } catch (error) {
    console.error('Error adding filters to study: ', error);
    throw error;
  }
};

const getStudyCompletionCodeFromFirestore = async (
  interviewId: string,
  studyId: string
) => {
  try {
    const studyRef = doc(db, `interviews/${interviewId}/studies/${studyId}`);
    const studySnapshot = await getDoc(studyRef);

    if (!studySnapshot.exists()) {
      console.error('No such study!');
      return;
    }

    const completionCode = studySnapshot.data().completionCode;
    return completionCode;
  } catch (error) {
    console.error('Error getting study completion code: ', error);
    throw error;
  }
};

const addStudyTitleAndDescriptionToFirestore = async (
  interviewId: string,
  title: string,
  description: string
) => {
  console.log('Adding study title and description to firestore!');
  try {
    const interviewRef = doc(db, `interviews/${interviewId}`);
    await updateDoc(interviewRef, {
      studyTitle: title,
      studyDescription: description,
    });
  } catch (error) {
    console.error(
      'Error adding interview title and description to firestore: ',
      error
    );
    throw error;
  }
};

const getMostRecentApiStudyIdFromFirestore = async (interviewId: string) => {
  // apiStudyIds are in apiStudyIds collection. Each document contains two fields: timeCreated and apiStudyId
  try {
    const apiStudyIdsCollectionRef = collection(
      db,
      `interviews/${interviewId}/apiStudyIds`
    );
    const apiStudyIdsSnapshot = await getDocs(apiStudyIdsCollectionRef);

    console.log('API studies snapshot: ', apiStudyIdsSnapshot.docs);
    let mostRecentApiStudyId = '';
    let mostRecentTimeCreated = '2/5/2023, 3:03:13 PM';
    apiStudyIdsSnapshot.forEach((doc) => {
      const data = doc.data();
      const timeCreated = data.timeCreated;
      if (timeCreated > mostRecentTimeCreated) {
        mostRecentTimeCreated = timeCreated;
        mostRecentApiStudyId = data.apiStudyId;
      }
    });
    console.log('Most recent api study id: ', mostRecentApiStudyId);

    return mostRecentApiStudyId;
  } catch (error) {
    console.error('Error getting most recent api study id: ', error);
    throw error;
  }
};

const storeEmbeddingInFirestore = async (
  filterId: string,
  embedding: number[]
): Promise<void> => {
  try {
    const embeddingsCollection = collection(db, 'filterEmbeddings');
    const filterDoc = doc(embeddingsCollection, filterId);
    await setDoc(filterDoc, { embedding });
    console.log('Embedding stored in Firebase');
  } catch (error) {
    console.error('Error storing embedding in Firebase:', error);
  }
};

const getFilterEmbeddingsFromFirestore = async (): Promise<number[][]> => {
  try {
    const embeddingsCollection = collection(db, 'filterEmbeddings');
    const querySnapshot = await getDocs(embeddingsCollection);
    const filterEmbeddings: number[][] = [];

    querySnapshot.forEach((doc) => {
      const embedding = doc.data().embedding;
      filterEmbeddings.push(embedding);
    });

    return filterEmbeddings;
  } catch (error) {
    console.error('Error retrieving filter embeddings from Firebase:', error);
    return [];
  }
};

export {
  createStudyOnFirestore,
  addFilterToStudyOnFirestore,
  SelectedFilterData,
  getFiltersSelectedFromFirestore,
  addFiltersSelectedToStudyOnFirestore,
  getStudyCompletionCodeFromFirestore,
  addStudyTitleAndDescriptionToFirestore,
  getMostRecentApiStudyIdFromFirestore,
  storeEmbeddingInFirestore,
  getFilterEmbeddingsFromFirestore,
};
