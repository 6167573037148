import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addPersonalizedPromptToFirestore,
  addShortenedGoalsToFirestore,
  editInterviewPreferencesOnFirestore,
  getInterviewFromFirestoreWithInterviewId,
  getInterviewGoalsFromFirestore,
  getInterviewRef,
  getPromptFromFirestore,
  getTopicFromFirestore,
} from '../../utils/firestore.ts';
import { insertVariablesIntoPrompt } from '../../utils/general.ts';
import { useAuth } from '../Authentication/AuthProvider.tsx';
import Button from '../Buttons/Button.tsx';
import GradientButton from '../Buttons/GradientButton.tsx';
import Header from '../Text/Header.tsx';
import Subheader from '../Text/Subheader.tsx';
import GoalsHeaderBlock from './GoalsHeaderBlock.tsx';
import InputGoal from './InputGoal.tsx';
import { FirebaseFunctionsClient } from 'src/utils/firebaseFunctionsClient.ts';

export type GoalType = {
  text: string;
  askingFollowUps: boolean;
};

export const generateUpdatedPrompt = async (
  interviewId: string,
  goals: GoalType[] | [],
  topic: string
) => {
  const interviewRef = getInterviewRef(interviewId);

  // format goals
  const typeOfElementsInGoals = goals.map((goal) => typeof goal);

  let goalsWithAskingFollowUps: GoalType[] = [];
  if (typeOfElementsInGoals.includes('string')) {
    // format into GoalType with all askingFollowUps set to true
    goalsWithAskingFollowUps = goals.map((goal) => {
      return {
        text: goal,
        askingFollowUps: true,
      } as any;
    });
  } else if (typeOfElementsInGoals.includes('object')) {
    goalsWithAskingFollowUps = goals;
  }

  // format goals into:
  //   - 1. Goal text + [Ask follow-ups] or [Do not ask follow-ups] depending on askingFollowUps, and
  //   - 2. Add number in front of each goal
  const reformattedGoals = goalsWithAskingFollowUps.map((goal, index) => {
    const goalNumber = index + 1;
    const askingFollowUpsText = goal.askingFollowUps
      ? '[Ask follow-ups]'
      : '[Do not ask follow-ups]';
    return `${goalNumber}. ${goal.text} ${askingFollowUpsText}`;
  });

  const goalsString = reformattedGoals.join('\n');

  // format interview overview
  const interviewOverviewString = `The interview is about ${topic}.`;

  const variablesObject = {
    interviewGoals: goalsString,
    interviewOverview: interviewOverviewString,
  };

  const prompt = await getPromptFromFirestore();
  const updatedPrompt = insertVariablesIntoPrompt(prompt, variablesObject);
  await addPersonalizedPromptToFirestore(interviewRef, updatedPrompt);
};

const InterviewGoals = () => {
  const [goals, setGoals] = useState<GoalType[]>([
    {
      text: 'Collect the profession and education level of interviewee.',
      askingFollowUps: false,
    },
  ]);
  const [collectUsersEmail, setCollectUsersEmail] = useState<boolean>(false);
  const navigate = useNavigate();
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const { interviewId } = useParams();
  const { user } = useAuth();
  const MAX_QUESTIONS = 10;

  const placeholderText = [
    "Learn about interviewee's experience doing...",
    "Understand interviewee's pain points with...",
    "Understand interviewee's workflow for...",
    'Validate the hypothesis that...',
    "Understand the interviewee's goals when...",
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => {
    const value = e.target.value;
    const newGoals = [...goals];
    newGoals[key].text = value;
    setGoals(newGoals);
  };

  const handleAddGoal = () => {
    const newGoals = [...goals];
    newGoals.push({
      text: '',
      askingFollowUps: true,
    });
    setGoals(newGoals);
  };

  const handleDelete = (key: number) => {
    const newGoals = [...goals];
    newGoals.splice(key, 1);
    setGoals(newGoals);
  };

  const handleSubmit = async () => {
    if (!validateGoals()) return;

    try {
      editInterviewPreferencesOnFirestore(
        interviewId,
        goals,
        collectUsersEmail
      );
      const { topic } = await getTopicFromFirestore(interviewId);

      if (interviewId) {
        await generateUpdatedPrompt(interviewId, goals, topic);
        shortenGoals(interviewId);
      }
      navigate(`/confirm/${interviewId}`);
    } catch (error) {
      console.log(error);
      toast('Something went wrong. Please try again.');
    }
  };

  const validateGoals = () => {
    const isTwoOrMoreFilledGoals =
      goals.filter((goal) => goal.text !== '').length >= 2;
    if (!isTwoOrMoreFilledGoals) {
      toast('You need to add at least 2 goals.');
      return false;
    }
    if (totalQuestions > MAX_QUESTIONS) {
      toast(
        `You've exceeded the maximum number of questions for the interview. Please remove some or untoggle "asking follow-ups."`
      );
      return false;
    }

    return true;
  };

  const populateData = async () => {
    try {
      let data;
      if (interviewId) {
        data = await getInterviewFromFirestoreWithInterviewId(interviewId);
      }

      if (data === undefined) {
        return;
      }

      const { goals } = data;
      if (!goals) return;

      // check if goals is array of GoalType (if is array of strings, convert to objects of GoalType)
      if (goals.length > 0 && typeof goals[0] === 'string') {
        const goalsWithAskingFollowUps = goals.map((goal: any) => {
          return {
            text: goal,
            askingFollowUps: true,
          };
        });
        setGoals(goalsWithAskingFollowUps);
      } else {
        setGoals(goals);
      }
    } catch (error) {
      console.log(error);
      toast('Something went wrong. Please try again.');
    }
  };

  const calculateNumberOfQuestions = () => {
    // for each goal with askingFollowUps, add 3; for each goal without askingFollowUps, add 1
    let totalQuestions = 0;
    goals.forEach((goal) => {
      if (goal.text && goal.askingFollowUps) {
        totalQuestions += 3;
      } else if (goal.text) {
        totalQuestions += 1;
      }
    });
    return totalQuestions;
  };

  const toggleFollowUp = (key: number) => {
    const newGoals = [...goals];
    const isCurrentlyAskingFollowUps = newGoals[key].askingFollowUps;
    const goalNumber = key + 1;

    if (isCurrentlyAskingFollowUps) {
      toast(`Not asking follow-up questions for goal ${goalNumber}.`);
    } else {
      toast(`Asking follow-up questions for goal ${goalNumber}.`);
    }

    newGoals[key].askingFollowUps = !isCurrentlyAskingFollowUps;
    setGoals(newGoals);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.shiftKey) {
      handleSubmit();
    }
  };

  const shortenGoals = async (interviewId: string) => {
    try {
      // Retrieve goals from the database
      const goalsData = await getInterviewGoalsFromFirestore(interviewId);

      // Extract the text field from each goal object
      const goals = goalsData.map((goalData: any) => goalData.text);

      // Add numbers in front of each goal
      const orderedGoals = goals.map(
        (goal: any, index: any) => `${index + 1}. ${goal}`
      );
      const joinedGoals = orderedGoals.join('\n');

      // Generate the prompt with the ordered goals
      const prompt = `Please take the following list of goals and provide a JSON object where each goal is summarized into a two-word description. For each goal, create a key-value pair where the key is labeled as \`goalX\` (replacing X with the goal number) and the value is the two-word summary of the corresponding goal.
  
  Here are the goals:
  
  ${joinedGoals}
  
  As an example, the JSON output format should look like this, but with the appropriate two-word summaries for the given goals, and not necessarily 5 goals (but matching the number of goals provided):
  
  \`\`\`
  {
    "goal1": "Pain Points",
    "goal2": "Team Efficiency",
    "goal3": "User Workflows",
    "goal4": "Market Expansion",
    "goal5": "Biggest Challenges"
  }
  \`\`\`
  
  Please note that the two-word summaries should be relevant and concise representations of the original goals.`;

      // Make a call to ChatGPT with the prompt
      const response =
        await new FirebaseFunctionsClient().getChatGPTJSONResponse(prompt);

      // Parse the JSON string into an object
      const shortenedGoalsJSON = JSON.parse(response);

      // Convert shortened goals JSON object to an array
      const shortenedGoalsArray = Object.values(shortenedGoalsJSON);

      // Add the shortened goals array to the database
      await addShortenedGoalsToFirestore(interviewId, shortenedGoalsArray);
    } catch (error) {
      console.error('Error shortening goals:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // cleanup on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const numberOfQuestions = calculateNumberOfQuestions();
    setTotalQuestions(numberOfQuestions);
  }, [goals]);

  return (
    <div className="py-10 xl:mr-12 bg-primary text-primary-contrast">
      <div className="px-5 sm:pt-10 sm:w-10/12 lg:w-[62%] 2xl:w-5/12 sm:m-auto">
        <div className="text-center sm:text-left">
          <Header text="Let's add your interview goals." />
          <Subheader text="Add interview goals and select which you want Probe to ask follow-up questions about." />
        </div>

        <GoalsHeaderBlock
          MAX_QUESTIONS={MAX_QUESTIONS}
          totalQuestions={totalQuestions}
        />

        <div className="w-full">
          <div className="flex justify-between mt-11 text-sm md:text-base">
            <p className="font-semibold">Interview Goals</p>
            <p className="font-thin mr-2">Asking follow-ups</p>
          </div>
          <hr className="border-primary-muted mt-3" />
        </div>
        <div>
          <div className="mt-6 mb-10 sm:mb-5">
            {goals.map((goal, index) => (
              <InputGoal
                key={index}
                index={index}
                placeholder={placeholderText[index]}
                goalValue={goal.text}
                handleChange={handleChange}
                handleDelete={handleDelete}
                isAskingFollowUps={goal.askingFollowUps}
                toggleFollowUp={toggleFollowUp}
              />
            ))}
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mt-10">
            <div className="mt-1 sm:mt-5 mb-6 ml-1 sm:ml-0">
              {/* Checkbox component remains commented out */}
            </div>
            <div className="sm:flex gap-2">
              <Button
                label="+ Add more goals"
                onClick={() => handleAddGoal()}
                buttonType="secondary"
                rounding="rounded-3xl"
              />
              <div className="mt-3 sm:mt-0">
                <Button
                  label="Submit"
                  onClick={() => handleSubmit()}
                  rounding="rounded-3xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default InterviewGoals;
