import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Pause, Play, Mic } from 'lucide-react';
import { MicrophonePermissionRequest } from './MicrophonePermissionRequest.tsx';
import InterviewProgressBar from './InterviewProgressBar.tsx';
import StartingScreen from './StartingScreen.tsx';
import { useParams } from 'react-router-dom';
import {
  getPersonalizedPromptFromFirestore,
  addInterviewInstanceMessageToFirestore,
  createInterviewInstanceOnFirestore,
} from '../../../utils/firestore.ts';
import { getStudyCompletionCodeFromFirestore } from '../../../utils/firestoreStudy.ts';

type ConversationState =
  | 'thinking'
  | 'speaking'
  | 'listening'
  | 'paused'
  | 'initial';

interface ConversationTurn {
  userTranscript: string;
  aiTextResponse: string;
  aiAudioResponse: string;
}

const VoiceInterview: React.FC = () => {
  const [conversationState, setConversationState] =
    useState<ConversationState>('initial');
  const [conversationHistory, setConversationHistory] = useState<
    ConversationTurn[]
  >([]);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [micPermissionState, setMicPermissionState] = useState<
    'checking' | 'granted' | 'denied'
  >('checking');
  const { interviewId, studyId } = useParams();
  const [personalizedPrompt, setPersonalizedPrompt] = useState<string>('');
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [interviewInstanceId, setInterviewInstanceId] = useState<string>('');
  const [firstQuestion, setFirstQuestion] = useState<string | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const isProcessingRef = useRef<boolean>(false);
  const retryCountRef = useRef<number>(0);
  const maxRetries = 3;
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const pauseStateRef = useRef<boolean>(false);
  const systemPromptSentRef = useRef<boolean>(false);

  const INTERVIEW_DURATION_SECONDS = 360;

  useEffect(() => {
    const fetchPersonalizedPrompt = async () => {
      if (interviewId) {
        const prompt = await getPersonalizedPromptFromFirestore(interviewId);
        setPersonalizedPrompt(prompt);
        setConversationHistory([
          { userTranscript: '', aiTextResponse: prompt, aiAudioResponse: '' },
        ]);
      }
    };

    const fetchCompletionCode = async () => {
      if (interviewId && studyId) {
        const completionCode = await getStudyCompletionCodeFromFirestore(
          interviewId,
          studyId
        );
        setRedirectUrl(
          `https://app.prolific.com/submissions/complete?cc=${completionCode}`
        );
      }
    };

    const setupAudioStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setStream(stream);
        setMicPermissionState('granted');
      } catch (error) {
        setMicPermissionState('denied');
      }
    };

    fetchPersonalizedPrompt();
    fetchCompletionCode();
    setupAudioStream();
  }, [interviewId, studyId]);

  const startRecording = useCallback(() => {
    if (!stream || isProcessingRef.current || pauseStateRef.current) return;
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.onstart = () => {};

    mediaRecorderRef.current.onerror = (event) => {};

    mediaRecorderRef.current.start(100);
    setConversationState('listening');
  }, [stream]);

  const playAudioResponse = useCallback(
    async (audioBase64: string) => {
      setConversationState('speaking');
      const audioBlob = await fetch(
        `data:audio/mp3;base64,${audioBase64}`
      ).then((r) => r.blob());
      const audioUrl = URL.createObjectURL(audioBlob);
      audioElementRef.current = new Audio(audioUrl);
      audioElementRef.current.onended = () => {
        URL.revokeObjectURL(audioUrl);
        if (!pauseStateRef.current) {
          setConversationState('listening');
          startRecording();
        }
      };
      if (!pauseStateRef.current) {
        await audioElementRef.current.play();
      }
    },
    [startRecording]
  );

  const processConversationTurn = useCallback(
    async (
      userAudio: Blob | null,
      isFirstMessage: boolean
    ): Promise<ConversationTurn> => {
      if (isProcessingRef.current) {
        throw new Error('Already processing a conversation turn');
      }
      isProcessingRef.current = true;

      try {
        const isDevelopment = process.env.NODE_ENV === 'development';
        const url = isDevelopment
          ? 'http://127.0.0.1:5001/joinprobe/us-central1/processConversationTurn'
          : 'https://us-central1-joinprobe.cloudfunctions.net/processConversationTurn';

        let audioBase64 = '';
        if (userAudio && userAudio.size > 0) {
          audioBase64 = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              if (typeof reader.result === 'string') {
                resolve(reader.result.split(',')[1]);
              } else {
                reject(new Error('Failed to convert audio to base64'));
              }
            };
            reader.onerror = reject;
            reader.readAsDataURL(userAudio);
          });
        }

        const requestData = {
          audioBase64,
          isFirstMessage,
          conversationHistory: conversationHistory,
          systemPrompt: !systemPromptSentRef.current
            ? personalizedPrompt
            : undefined,
        };

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        retryCountRef.current = 0;
        systemPromptSentRef.current = true;
        return result;
      } catch (error) {
        throw error;
      } finally {
        isProcessingRef.current = false;
      }
    },
    [conversationHistory, personalizedPrompt]
  );

  const saveInitialInterviewMessages = async (
    interviewId: string,
    instanceId: string,
    firstQuestion: string,
    firstAnswer: string,
    secondQuestion: string
  ) => {
    const currentTimestamp = new Date().toISOString();

    await addInterviewInstanceMessageToFirestore(interviewId, instanceId, {
      type: 'question',
      content: firstQuestion,
      timestamp: currentTimestamp,
    });

    await addInterviewInstanceMessageToFirestore(interviewId, instanceId, {
      type: 'answer',
      content: firstAnswer,
      timestamp: currentTimestamp,
    });

    await addInterviewInstanceMessageToFirestore(interviewId, instanceId, {
      type: 'question',
      content: secondQuestion,
      timestamp: currentTimestamp,
    });
  };

  const handleConversationTurn = async (userAudio: Blob) => {
    if (isProcessingRef.current) return;
    setConversationState('thinking');
    try {
      const result = await processConversationTurn(userAudio, false);
      setConversationHistory((prev) => [...prev, result]);

      if (!interviewInstanceId && interviewId) {
        const newInstanceRef = await createInterviewInstanceOnFirestore(
          interviewId,
          false,
          false,
          true
        );
        if (newInstanceRef) {
          const newInstanceId = newInstanceRef.id;
          setInterviewInstanceId(newInstanceId);

          if (firstQuestion) {
            await saveInitialInterviewMessages(
              interviewId,
              newInstanceId,
              firstQuestion,
              result.userTranscript,
              result.aiTextResponse
            );
          } else {
            throw new Error('First question is missing');
          }
        } else {
          throw new Error('Failed to create interview instance');
        }
      } else if (interviewId && interviewInstanceId) {
        await addInterviewInstanceMessageToFirestore(
          interviewId,
          interviewInstanceId,
          {
            type: 'answer',
            content: result.userTranscript,
            timestamp: new Date().toISOString(),
          }
        );

        await addInterviewInstanceMessageToFirestore(
          interviewId,
          interviewInstanceId,
          {
            type: 'question',
            content: result.aiTextResponse,
            timestamp: new Date().toISOString(),
          }
        );
      }

      if (!pauseStateRef.current) {
        await playAudioResponse(result.aiAudioResponse);
      }
    } catch (error) {
      setConversationState(pauseStateRef.current ? 'paused' : 'thinking');
    }
  };

  const startInterview = useCallback(async () => {
    if (isProcessingRef.current) return;
    setConversationState('thinking');
    try {
      if (!stream) {
        const newStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setStream(newStream);
        setMicPermissionState('granted');
      }

      if (!stream) {
        throw new Error('Failed to obtain audio stream');
      }

      const initialTurn = await processConversationTurn(null, true);
      setConversationHistory((prev) => [...prev, initialTurn]);
      setFirstQuestion(initialTurn.aiTextResponse);

      if (!pauseStateRef.current) {
        await playAudioResponse(initialTurn.aiAudioResponse);
      }
    } catch (error) {
      if (retryCountRef.current < maxRetries) {
        retryCountRef.current++;
        setTimeout(startInterview, 1000 * retryCountRef.current);
      } else {
        setConversationState(pauseStateRef.current ? 'paused' : 'thinking');
        alert('Failed to start the interview. Please try again later.');
      }
    }
  }, [processConversationTurn, playAudioResponse, stream]);

  const stopRecording = useCallback(() => {
    return new Promise<void>((resolve) => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === 'recording'
      ) {
        mediaRecorderRef.current.onstop = () => {
          resolve();
        };
        mediaRecorderRef.current.stop();
      } else {
        resolve();
      }
    });
  }, []);

  const handleDoneTalking = async () => {
    try {
      await stopRecording();

      if (audioChunksRef.current.length === 0) {
        return;
      }

      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });

      if (audioBlob.size > 0) {
        await handleConversationTurn(audioBlob);
      }

      audioChunksRef.current = [];
    } catch (error) {
      // Handle error
    }
  };

  const handlePause = () => {
    pauseStateRef.current = !pauseStateRef.current;
    if (pauseStateRef.current) {
      setConversationState('paused');
      if (audioElementRef.current && !audioElementRef.current.paused) {
        audioElementRef.current.pause();
      }
      stopRecording();
    } else {
      if (audioElementRef.current && audioElementRef.current.paused) {
        audioElementRef.current
          .play()
          .then(() => {
            setConversationState('speaking');
          })
          .catch(() => {
            setConversationState('listening');
            startRecording();
          });
      } else {
        setConversationState('listening');
        startRecording();
      }
    }
  };

  if (conversationState === 'initial') {
    return <StartingScreen onStartInterview={startInterview} />;
  }

  if (micPermissionState === 'checking') {
    return (
      <div className="text-gray-800">Checking microphone permissions...</div>
    );
  }

  if (micPermissionState === 'denied') {
    return <MicrophonePermissionRequest />;
  }

  return (
    <div className="bg-[#faf9f6] p-4">
      <InterviewProgressBar
        totalSeconds={INTERVIEW_DURATION_SECONDS}
        redirectUrl={redirectUrl}
        isPaused={conversationState === 'paused'}
      />
      <div className="flex flex-col items-center justify-center min-h-screen">
        <motion.div
          animate={{
            scale: [1, 1.05, 1],
            opacity: [0.8, 1, 0.8],
          }}
          transition={{
            duration: 4,
            ease: 'easeInOut',
            times: [0, 0.5, 1],
            repeat: Infinity,
          }}
          className="w-48 h-48 bg-gray-200 bg-opacity-50 rounded-full mb-8 flex items-center justify-center shadow-lg"
        >
          <motion.div
            animate={{
              scale: conversationState === 'listening' ? [1, 1.1, 1] : 1,
            }}
            transition={{
              duration: 1.5,
              ease: 'easeInOut',
              repeat: conversationState === 'listening' ? Infinity : 0,
            }}
            className="w-32 h-32 bg-white bg-opacity-70 rounded-full flex items-center justify-center shadow-inner"
          >
            <Mic size={32} className="text-gray-600" />
          </motion.div>
        </motion.div>
        <p className="text-gray-800 text-2xl font-light mb-12">
          {conversationState === 'thinking' && 'Thinking...'}
          {conversationState === 'speaking' && 'Speaking...'}
          {conversationState === 'listening' && 'Listening...'}
          {conversationState === 'paused' && 'Paused'}
        </p>
        <div className="flex space-x-4">
          {conversationState === 'listening' &&
            !pauseStateRef.current &&
            stream && (
              <button
                onClick={handleDoneTalking}
                className="bg-white bg-opacity-70 hover:bg-opacity-90 text-gray-800 font-medium py-2 px-6 rounded-full transition-all duration-300 ease-in-out shadow-md hover:shadow-lg"
              >
                Done Talking
              </button>
            )}
          <button
            onClick={handlePause}
            className="bg-white bg-opacity-70 hover:bg-opacity-90 text-gray-800 p-3 rounded-full transition-all duration-300 ease-in-out shadow-md hover:shadow-lg"
          >
            {pauseStateRef.current ? <Play size={24} /> : <Pause size={24} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VoiceInterview;
