import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { FirebaseFunctionsClient } from '../utils/firebaseFunctionsClient';

type GenerateInterviewOverviewRequest = {
  interviewId: string;
};

const useGenerateInterviewSummary = (
  options?: Partial<
    UseMutationOptions<any, Error, GenerateInterviewOverviewRequest>
  >
) => {
  return useMutation<
    GenerateInterviewOverviewRequest,
    Error,
    GenerateInterviewOverviewRequest
  >({
    // @ts-ignore
    mutationFn: (data: GenerateInterviewOverviewRequest) => {
      return new FirebaseFunctionsClient().generateInterviewOverview(data);
    },
    ...options,
  });
};

export default useGenerateInterviewSummary;
