import React from 'react';

interface PaymentCardProps {
  productName: string;
  amount: number;
  timestamp: any;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  productName,
  amount,
  timestamp,
}) => {
  const date = timestamp.toDate();
  const formattedDate = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const amountInDollars = amount / 100;
  const amountFixed = amountInDollars.toFixed(2);

  return (
    <div className="bg-primary border border-gray-200 rounded-lg p-6 shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-medium text-primary-contrast">
          {productName}
        </h2>
        <div className="text-right">
          <span className="text-xl text-primary-contrast">${amountFixed}</span>
          <span className="text-xs text-gray-600 ml-1">USD</span>
        </div>
      </div>
      <div className="flex items-center text-gray-600 text-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clipRule="evenodd"
          />
        </svg>
        <span>Purchased on {formattedDate}</span>
      </div>
    </div>
  );
};

export default PaymentCard;
