import React from 'react';
import BaseQuestion from './BaseQuestion.tsx';
import ToggleSwitch from './Shared/ToggleSwitch.tsx';
import ImageUploader from './Shared/ImageUploader.tsx';
import QuestionTextArea from './Shared/QuestionTextArea.tsx';
import OptionInput from './Shared/SelectComponents/OptionInput.tsx';
import AddOptionButton from './Shared/SelectComponents/AddOptionButton.tsx';
import { SingleChoiceQuestion as SingleChoiceQuestionType } from '../../../../utils/types/questionTypes.ts';
import { handleImageUpload } from '../../../../utils/general.ts';

interface SingleChoiceQuestionProps {
  question: SingleChoiceQuestionType;
  questionNumber: number;
  onDelete: () => void;
  onUpdate: (updates: Partial<SingleChoiceQuestionType>) => void;
  interviewId: string;
}

const SingleChoiceQuestion: React.FC<SingleChoiceQuestionProps> = ({
  question,
  questionNumber,
  onDelete,
  onUpdate,
  interviewId,
}) => {
  const addOption = () => {
    onUpdate({ options: [...question.options, ''] });
  };

  const updateOption = (index: number, value: string) => {
    const newOptions = [...question.options];
    newOptions[index] = value;
    onUpdate({ options: newOptions });
  };

  const removeOption = (index: number) => {
    const newOptions = question.options.filter((_, i) => i !== index);
    onUpdate({ options: newOptions });
  };

  const toggleOtherOption = () => {
    onUpdate({ includeOther: !question.includeOther });
  };

  const handleImageDelete = () => {
    onUpdate({ image: null });
  };

  return (
    <BaseQuestion<SingleChoiceQuestionType>
      question={question}
      questionNumber={questionNumber}
      onDelete={onDelete}
      onUpdate={onUpdate}
    >
      <div className="space-y-6">
        <QuestionTextArea
          value={question.question}
          onChange={(value) => onUpdate({ question: value })}
        />
        <div className="space-y-3">
          {question.options.map((option, index) => (
            <OptionInput
              key={index}
              value={option}
              onChange={(value) => updateOption(index, value)}
              onRemove={() => removeOption(index)}
              placeholder={`Option ${index + 1}`}
            />
          ))}
          {question.includeOther && (
            <OptionInput
              value="Other"
              onChange={() => {}}
              onRemove={() => {}}
              placeholder="Other"
              readOnly={true}
            />
          )}
        </div>
        <AddOptionButton
          onClick={addOption}
          includeOther={question.includeOther}
          onToggleOther={toggleOtherOption}
        />
        <div className="flex items-center space-x-8">
          <ToggleSwitch
            label="Include Image"
            checked={!!question.image}
            onChange={() =>
              onUpdate({
                image: question.image ? null : new File([], 'placeholder'),
              })
            }
          />
          <ToggleSwitch
            label="Allow Skip"
            checked={question.allowSkip}
            onChange={() => onUpdate({ allowSkip: !question.allowSkip })}
          />
        </div>
        {question.image && (
          <ImageUploader
            onUpload={handleImageUpload<SingleChoiceQuestionType>(
              interviewId,
              question.id,
              onUpdate
            )}
            initialImage={
              typeof question.image === 'string' ? question.image : null
            }
            onDelete={handleImageDelete}
          />
        )}
      </div>
    </BaseQuestion>
  );
};

export default SingleChoiceQuestion;
