import React from 'react';
import starsIcon from '../../assets/icons/starsIcon.svg';

const LoadingFiltersAI = () => {
  return (
    <div className="mt-8 rounded-xl text-gray-700">
      <div className="flex">
        <img src={starsIcon} alt="stars icon" className="h-5 mt-1 mr-3" />
        <span className="text-lg font-medium text-gray-700">
          AI searching for filters
        </span>
        <div className="ml-2 mt-1">
          <span className="loading loading-dots loading-sm bg-gray-700"></span>
        </div>
      </div>
    </div>
  );
};

export default LoadingFiltersAI;
