import { QuestionSummary } from '@/src/utils/firebaseFunctionsClient';
import {
  Alert,
  Button,
  Grid,
  List,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import React from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import useGenerateInterviewSummary from 'src/hooks/useGenerateInterviewSummary';
import useInterviewSummary from 'src/hooks/useInterviewSummary';
import useProcessedInterviewData from 'src/hooks/useProcessedInterviewData';
import SquareButton from '../Buttons/SquareButton';
import LoadingRing from '../Loading/LoadingRing';
import { CustomBar } from '../shadcn/Bar';
import { Card, CardContent, CardHeader, CardTitle } from '../shadcn/Card';
import { CustomPie } from '../shadcn/Pie';

const InterviewSummary: React.FC = () => {
  const { interviewId } = useParams<{ interviewId: string }>();

  const { data, isLoading, error } = useProcessedInterviewData(
    { interviewId: interviewId! },
    { enabled: !!interviewId }
  );

  const {
    data: summaryData,
    isLoading: summaryLoading,
    error: summaryError,
    refetch: refetchSummary,
  } = useInterviewSummary(interviewId!, { enabled: !!interviewId });

  const generateInterviewSummaryMutation = useGenerateInterviewSummary();

  if (isLoading || summaryLoading) return <LoadingRing />;
  if (error) return <Text c="red">Error: {error.toString()}</Text>;
  if (summaryError)
    return (
      <Text c="red">Error loading summary: {summaryError.toString()}</Text>
    );
  if (!data || !summaryData) return null;

  const { questionSummaries } = data;
  const { overview, questions: openEndedSummaries } = summaryData;

  const handleGenerateSummary = async () => {
    try {
      await generateInterviewSummaryMutation.mutateAsync({
        interviewId: interviewId!,
      });
      refetchSummary();
    } catch (error) {
      console.error('Failed to generate summary:', error);
    }
  };

  const renderSummary = (summary: QuestionSummary & { questionId: string }) => {
    switch (summary.type) {
      case 'rating':
        const totalRating = Object.entries(
          summary.ratingDistribution ?? {}
        ).reduce((acc, [value, count]) => acc + Number(value) * count, 0);
        const totalResponses = summary.totalResponses ?? 0;

        const averageRating = totalRating / totalResponses;

        return (
          <Stack gap="md">
            <div className="flex justify-between">
              <Text size="sm" fw={500}>
                Average Rating: {averageRating.toFixed(2)}
              </Text>
              <Text size="sm" fw={500}>
                Total Responses: {totalResponses}
              </Text>
            </div>
            <CustomBar
              maxVal={summary.maxRating ?? 5}
              data={Object.entries(summary.ratingDistribution ?? {}).map(
                ([value, count]) => ({ value: Number(value), count })
              )}
              title={summary.question}
            />
          </Stack>
        );
      case 'single-select':
      case 'multiple-choice':
        return (
          <CustomPie
            totalResponses={summary.totalResponses ?? 0}
            data={summary.optionDistribution}
            title={summary.question}
            showLegend={true}
          />
        );
      case 'open-ended':
      case 'ai-follow-ups':
        const aiSummary = openEndedSummaries.find(
          (q) => q.id === summary.questionId
        )?.aiSummary;
        return (
          <Stack gap="md">
            <Text size="sm" fw={500}>
              Total Responses: {summary?.totalResponses || 0}
            </Text>
            {aiSummary ? (
              <>
                <Text size="sm" fw={500}>
                  AI Summary:
                </Text>
                <Text size="sm">{aiSummary.summary}</Text>

                <Text size="sm" fw={500}>
                  Notable Quotes:
                </Text>
                <List size="sm">
                  {aiSummary.notableQuotes.map(
                    (quote: string, index: number) => (
                      <List.Item key={index} style={{ fontStyle: 'italic' }}>
                        "{quote}"
                      </List.Item>
                    )
                  )}
                </List>
              </>
            ) : (
              <Text size="sm" fs="italic">
                No AI summary available
              </Text>
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  const renderOverviewSection = () => {
    if (Object.values(questionSummaries).length === 0) {
      return (
        <Alert
          variant="light"
          color="grape"
          title="No summary available"
          icon={<IconAlertCircle size="1.5rem" />}
          className="mb-6"
        >
          <Text size="sm" mb="md">
            Your interview is empty. Please add questions and responses to get
            started.
          </Text>
        </Alert>
      );
    }

    if (!overview) {
      return (
        <Alert
          variant="light"
          color="grape"
          title="No summary available"
          icon={<IconAlertCircle size="1.5rem" />}
          className="mb-6"
        >
          <Text size="sm" mb="md">
            Generate a summary to get insights from this interview.
          </Text>
          <Button
            onClick={handleGenerateSummary}
            loading={generateInterviewSummaryMutation.isPending}
            color="dark"
            variant="filled"
          >
            Generate Summary
          </Button>
        </Alert>
      );
    }

    return (
      <Card className="mb-6 rounded-2xl">
        <CardContent>
          <Stack gap="lg">
            <div className="mt-4">
              <Title order={3} className="font-bold text-xl mb-2">
                Overview
              </Title>
              <Text size="sm">{overview.summary}</Text>
            </div>

            <div>
              <Title order={3} className="font-bold text-xl mb-2">
                Main Insights
              </Title>
              <ul className="list-disc pl-5">
                {overview.mainInsights.map((insight: any, index: number) => (
                  <li key={index} className="text-sm mb-1">
                    {insight.insight}
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <Title order={3} className="font-bold text-xl mb-2">
                Recommendations
              </Title>
              <ul className="list-disc pl-5">
                {overview.recommendations.map(
                  (recommendation: string, index: number) => (
                    <li key={index} className="text-sm mb-1">
                      {recommendation}
                    </li>
                  )
                )}
              </ul>
            </div>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  console.log(Object.values(questionSummaries).length > 0);

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-4">
        <div>
          {overview && (
            <>
              <LoadingOverlay
                visible={generateInterviewSummaryMutation.isPending}
              />
              <SquareButton
                icon={<FiRefreshCw />}
                text="Regenerate Summary"
                onClick={handleGenerateSummary}
              ></SquareButton>
            </>
          )}
        </div>
      </div>

      {renderOverviewSection()}

      <Grid>
        {Object.entries(questionSummaries).map(([questionId, summary]) => (
          <Grid.Col key={questionId} span={{ base: 12, md: 6 }}>
            <Card className="w-full h-full rounded-2xl">
              <CardHeader>
                <CardTitle>{summary.question}</CardTitle>
              </CardHeader>
              <CardContent>
                {renderSummary({ ...summary, questionId })}
              </CardContent>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
};

export default InterviewSummary;
