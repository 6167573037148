import React from 'react';
import starsIconWhite from '../../assets/icons/starsIconWhite.svg';

interface AIButtonProps {
  onClick: () => void;
  label: string;
  className?: string;
}

const AIButton: React.FC<AIButtonProps> = ({ onClick, label, className }) => {
  return (
    <button
      className={`flex items-center space-x-2 px-6 py-2.5 mt-6 text-white bg-gray-800 hover:bg-gray-700 rounded-xl shadow-md transition duration-300 transform hover:scale-105 ${className}`}
      onClick={onClick}
    >
      <img src={starsIconWhite} alt="Icon" className="w-4 h-4 mr-0.5" />
      <span>{label}</span>
    </button>
  );
};

export default AIButton;
