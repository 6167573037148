import React from 'react';
import { Shield, Star, UserCheck } from 'lucide-react';
import SectionHeader from './SectionHeader';

const QualityCard = ({
  title,
  description,
  icon: Icon,
}: {
  title: string;
  description: string;
  icon: React.ElementType;
}) => (
  <div className="bg-white rounded-lg p-8 transition-all duration-300 border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] flex flex-col h-full min-h-[280px]">
    <div className="flex items-center mb-6">
      <div className="bg-primary-light bg-opacity-20 p-3 rounded-full mr-4">
        <Icon className="text-primary-contrast" size={28} />
      </div>
      <h3 className="text-2xl font-semibold text-primary-contrast">{title}</h3>
    </div>
    <p className="text-primary-contrast text-lg flex-grow">{description}</p>
  </div>
);

const ParticipantQuality = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-24 sm:py-36">
      <SectionHeader
        title="Choose from 200,000 verified participants."
        subtitle="Access a diverse pool of vetted, high-quality respondents"
        isLong={true}
      />
      <div className="grid md:grid-cols-3 gap-8 md:gap-10 mt-16">
        <QualityCard
          icon={Shield}
          title="Rigorously Vetted"
          description="Each participant undergoes bank-grade ID verification, IP address checks and 2 levels of screening."
        />
        <QualityCard
          icon={Star}
          title="Quality Responses"
          description="Participant attention, honesty and response quality are regularly evaluated to maintain a top-tier participant pool."
        />
        <QualityCard
          icon={UserCheck}
          title="Verified Human Input"
          description="Over 30 monitoring algorithms detect any bots to ensure responses only come from real people."
        />
      </div>
      {/* <div className="mt-16 text-center">
        <div className="relative inline-block group">
          <span className="inline-block bg-white text-primary-contrast px-8 py-4 rounded-full text-base font-medium border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] transition-all duration-300 cursor-pointer">
            100% Satisfaction Guarantee
          </span>
          <div className="absolute z-10 invisible group-hover:visible bg-white border-2 border-black rounded-lg p-4 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] text-sm text-primary-contrast w-64 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2">
            If an interview doesn't meet your expectations, we'll refund you, no
            questions asked.
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-3 h-3 bg-white border-r-2 border-b-2 border-black"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ParticipantQuality;
