// MultiSelect.tsx
import React, { useEffect, useState } from 'react';
import { Check } from 'lucide-react';
import OtherInput from './Shared/OtherInput.tsx';

interface MultiSelectProps {
  choices: string[];
  show: boolean;
  selectedChoices: string[];
  onSelect: (choices: string[]) => void;
  disabled: boolean;
  includeOther: boolean;
  setIsOtherFieldActive?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  choices,
  show,
  selectedChoices,
  onSelect,
  disabled,
  includeOther,
  setIsOtherFieldActive,
}) => {
  const [isOtherActive, setIsOtherActive] = useState(false);
  const [otherValue, setOtherValue] = useState('');

  const handleSelect = (choice: string) => {
    if (!disabled) {
      const updatedChoices = selectedChoices.includes(choice)
        ? selectedChoices.filter((c) => c !== choice)
        : [...selectedChoices, choice];
      onSelect(updatedChoices);
    }
  };

  const handleOtherChange = (value: string) => {
    setOtherValue(value);
    const updatedChoices = selectedChoices.filter(
      (c) => !c.startsWith('Other: ')
    );
    if (value !== '') {
      updatedChoices.push(`Other: ${value}`);
    }
    onSelect(updatedChoices);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (isOtherActive) return;
      const key = event.key.toLowerCase();
      const index = key.charCodeAt(0) - 97; // Convert a-z to 0-25
      if (
        index >= 0 &&
        index < choices.length &&
        !disabled &&
        key.length === 1 &&
        key !== ' ' // Allow space key
      ) {
        event.preventDefault();
        handleSelect(choices[index]);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [choices, disabled, selectedChoices, isOtherActive, handleSelect]);

  useEffect(() => {
    const otherChoice = selectedChoices.find((c) => c.startsWith('Other: '));
    if (otherChoice) {
      setOtherValue(otherChoice.slice(7));
    } else {
      setOtherValue('');
    }
  }, [selectedChoices]);

  useEffect(() => {
    if (setIsOtherFieldActive) {
      setIsOtherFieldActive(isOtherActive);
    }
  }, [isOtherActive, setIsOtherFieldActive]);

  return (
    <div
      className={`space-y-4 transition-opacity duration-500 ease-in-out ${
        show ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {choices.map((choice, index) => (
        <button
          key={index}
          disabled={disabled}
          className={`w-full px-6 py-4 text-left text-base font-medium border-2 rounded-2xl transition-all duration-300 ease-in-out flex items-center justify-between
            ${
              selectedChoices.includes(choice)
                ? 'bg-primary-contrast text-primary border-primary-contrast'
                : 'bg-primary text-primary-contrast border-gray-200 hover:border-primary-contrast hover:shadow-md'
            }
                  ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                `}
          onClick={() => handleSelect(choice)}
        >
          <span>
            <span className="mr-3 font-bold">
              {String.fromCharCode(65 + index)}
            </span>
            {choice}
          </span>
          {selectedChoices.includes(choice) && (
            <Check className="h-6 w-6 text-primary" />
          )}
        </button>
      ))}
      {includeOther && (
        <OtherInput
          disabled={disabled}
          selectedChoices={selectedChoices}
          onSelect={handleOtherChange}
          index={choices.length}
          isOtherActive={isOtherActive}
          setIsOtherActive={setIsOtherActive}
          otherValue={otherValue}
          setOtherValue={setOtherValue}
          isMultiSelect={true}
        />
      )}
    </div>
  );
};

export default MultiSelect;
