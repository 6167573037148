import React, { useEffect, useRef, useState } from 'react';
import startupKillers from '../../assets/landing/startupKillers.svg';
import whiteXIcon from '../../assets/icons/whiteXIcon.svg';
import whiteDownArrow from '../../assets/icons/whiteDownArrow.svg';
import YouTube from 'react-youtube';
import PricingCardLifetime from '../Pricing/PricingCardLifetime.tsx';
import LandingPageButton from '../Buttons/LandingPageButton.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import NavbarLandingPage from '../Navbar/NavbarLandingPage.tsx';
import curlyArrowSmall from '../../assets/landing/curlyArrowSmall.svg';
import curlyArrowLarge from '../../assets/landing/curlyArrowLarge.svg';
import InterviewInterface from '../Interview/InterviewInterface.tsx';
import conversationSummaryWhite from '../../assets/landing/conversationSummaryWhite.png';
import demoArrow from '../../assets/landing/demoArrow.svg';
import Footer from '../Footer/Footer.tsx';
import TestimonialCard from './TestimonialCard.tsx';
import BeforeAfter from './BeforeAfter.tsx';
import HowItWorks from './HowItWorks.tsx';
import leifProfilePic from '../../assets/landing/leif-profile-pic.jpg';
import FAQ from './FAQ.tsx';
import GradientLandingPageButton from '../Buttons/GradientLandingPageButton.tsx';
import PricingCard from '../Pricing/PricingCard.tsx';
import ParticipantQuality from './ParticipantQuality.tsx';
import HeroSection from './HeroSection.tsx';
import Filters from '../Study/Filters/Filters.tsx';
import FilterCheck from './FilterCheck.tsx';
import { ArrowUpRight } from 'lucide-react';
import { FaBuilding } from 'react-icons/fa';
import SectionHeader from './SectionHeader';

function WistiaPlayer({ videoId, wrapper }) {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    const div = document.createElement('div');
    div.innerHTML = `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_${videoId} seo=false videoFoam=true" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/${videoId}/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>`;

    const container = document.getElementById(wrapper);
    container.appendChild(script1);
    container.appendChild(script2);
    container.appendChild(div);

    return () => {
      container.innerHTML = '';
    };
  }, []);

  return <div id={`${wrapper}`}></div>;
}

const LandingPage = () => {
  const probeSectionRef = useRef<HTMLDivElement>(null);
  const pricingSectionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [cycleWord, setCycleWord] = useState<string>('pain points');
  const { affiliateId } = useParams();

  const scrollToProbeSection = () => {
    probeSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const navigateToPricing = () => {
    pricingSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const openDemoLink = () => {
    window.open('https://cal.com/savannahfeder/probe-demo', '_blank');
  };

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
      controls: 1,
      modestbranding: 1,
      fs: 1,
      cc_load_policy: 0,
      iv_load_policy: 3,
      autohide: 0,
    },
  };

  const headerLineHeightStyle = {
    lineHeight: '1.3',
  };

  const lineHeightStyle = {
    lineHeight: '1.2',
  };

  const headerStyle = {
    lineHeight: '1.2',
  };

  const navigateAuth = () => {
    if (affiliateId) {
      navigate(`/auth/${affiliateId}`);
    } else {
      navigate('/auth');
    }
  };

  useEffect(() => {
    const cycleWords = [
      'behaviours',
      'frustrations',
      'problems',
      'challenges',
      'pain points',
    ];

    let index = 0;
    const interval = setInterval(() => {
      setCycleWord(cycleWords[index]);
      index = (index + 1) % cycleWords.length;
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const openBookingLinkInNewTab = () => {
    window.open('https://cal.com/savannahfeder/probe-demo', '_blank');
  };

  const primaryButtonText =
    affiliateId === 'buildspace'
      ? 'Get Started for Free'
      : 'Start Interviewing';

  const scrollToPricing = () => {
    pricingSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-primary-muted text-primary-contrast">
      <NavbarLandingPage />
      <HeroSection
        headerLineHeightStyle={headerLineHeightStyle}
        linearText="text-primary-contrast"
        navigateAuth={navigateAuth}
        openBookingLinkInNewTab={openBookingLinkInNewTab}
        primaryButtonText={primaryButtonText}
      />

      <div className="sm:w-9/12 mx-auto">
        <WistiaPlayer videoId="t9uzsdvelo" wrapper="wistia-player-wrapper" />
      </div>

      <div className="mt-20">
        <BeforeAfter />
      </div>
      <div className="mt-14 ">
        <HowItWorks />
      </div>

      <div className="pt-32 sm:pt-44">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12">
            <img
              src={leifProfilePic}
              alt="Testimonial"
              className="w-36 h-36 rounded-lg md:w-44 md:h-44 border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
            />
            <div className="max-w-2xl">
              <p className="text-lg sm:text-1.8xl font-light text-left sm:font-normal text-primary-contrast mb-6 px-4 sm:px-0 md:leading-8">
                Probe delivers the perfect solution and makes the process of
                market research easy. It's already helped us make our product
                even better and define our USP's.
                <span className="font-semibold bg-primary-light bg-opacity-20 px-2 py-1 rounded-md">
                  I use many AI tools, and I have to say that Probe has helped
                  me the most of all.
                </span>
              </p>
              <div className="text-center md:text-left">
                <p className="text-md text-primary-contrast font-semibold mb-1">
                  Leif Sawitzki
                </p>
                <p className="text-primary-light text-sm">
                  Co-Founder, LesaCommerce
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ParticipantQuality />

      {/* Commented out FilterCheck section
      <FilterCheck />
      */}

      <div ref={pricingSectionRef} className="mx-auto text-center">
        <div className="pt-25 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <SectionHeader
            title="Be ready to build, tomorrow."
            subtitle="Choose the plan that fits your needs."
          />
          <div className="flex flex-col xl:flex-row gap-8 justify-center items-center mx-auto mt-10 max-w-full">
            <PricingCard
              price={0}
              tag="Your Participants"
              subtext="Use your own participants"
              features={[
                'Create unlimited interviews',
                'Unlimited responses',
                'Unlimited interview length',
              ]}
              buttonText="Get Started"
              handleClick={navigateAuth}
              highlightedBorder={true}
              highlightedTag={true}
              isB2B={false}
            />
            <PricingCard
              tag="B2C Participants"
              price={4}
              subtext="Per 7-min interview"
              features={[
                'Interview verified consumers',
                '2 hrs average completion time',
                'Customizable participant filters',
              ]}
              buttonText="Get Started"
              handleClick={navigateAuth}
              highlightedBorder={true}
              highlightedTag={true}
              isB2B={false}
            />
            <PricingCard
              tag="B2B Participants"
              price={10}
              subtext="Per 7-min interview"
              features={[
                'Interview verified professionals',
                '12 hrs average completion time',
                'Customizable participant filters',
              ]}
              buttonText="Book Demo"
              handleClick={openBookingLinkInNewTab}
              // startingAt={true}
              isB2B={false}
              highlightedBorder={true}
              highlightedTag={true}
            />
          </div>
        </div>
        <FAQ />

        {/* Testimonials section */}
        <div className="pb-4 max-w-6xl mx-auto mt-14 md:mt-32">
          <div className="flex flex-wrap justify-center px-4 md:px-0 md:gap-8">
            <TestimonialCard
              quote="Probe is pretty amazing. I used it to conduct 75 customer interviews with people in my ICP."
              name="Lopsii Olagoke"
              position="Co-Founder"
              company="Ooreva"
            />
            <TestimonialCard
              quote="Instead of spending hours finding users, I can jump straight to results. This is a no brainer for early founders."
              name="Addy Bhatia"
              position="Founder"
              company="Suno"
            />
            <TestimonialCard
              quote="Probe saved me a ton of time, energy and money to interview several people."
              name="Sabit Ali"
              position="Founder"
              company=""
            />
          </div>
        </div>

        <div className="max-w-6xl mx-auto text-center py-20 md:py-36 px-4 sm:px-6 lg:px-8">
          <SectionHeader
            title="Cut your time to product-market fit."
            subtitle="Let Probe learn about your customers for you, so you can start shipping products they love."
            isLong={true}
          />
          <div className="mt-5 sm:mt-5">
            <div className="flex justify-center">
              <button
                onClick={navigateAuth}
                className="bg-black text-white px-8 py-4 rounded-2xl font-medium text-lg sm:text-2xl flex items-center justify-center hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
              >
                Start Interviewing
                <ArrowUpRight className="ml-2 mt-1 h-5 w-5 animate-pulse" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer scrollToPricing={navigateToPricing} />
    </div>
  );
};

export default LandingPage;
