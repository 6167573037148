import { ProbeScreenerQuestion } from '@functions/common/types';
import { GripVertical, X } from 'lucide-react';
import React from 'react';

interface BaseScreenerQuestionProps {
  question: ProbeScreenerQuestion;
  index: number;
  onDelete: (id: string) => void;
  onUpdate: (id: string, updates: Partial<ProbeScreenerQuestion>) => void;
  children: React.ReactNode;
}

const BaseScreenerQuestion: React.FC<BaseScreenerQuestionProps> = ({
  question,
  index,
  onDelete,
  onUpdate,
  children,
}) => {
  return (
    <div className="bg-white rounded-2xl p-6 mb-4 relative group shadow-md border border-gray-muted">
      <div className="flex items-center mb-4">
        <div className="cursor-move mr-3 text-gray-400 hover:text-gray-600 flex items-center">
          <GripVertical size={20} />
        </div>
        <div className="flex-grow flex items-center">
          <span className="text-sm font-semibold text-primary-contrast">
            Q{index + 1}{' '}
            <span className="text-md text-gray-600 font-medium ml-2">
              {question.questionType === 'radio'
                ? 'Single Choice'
                : 'Multiple Choice'}{' '}
              Question
            </span>
          </span>
        </div>
        <button
          onClick={() => onDelete(question.id!)}
          className="text-gray-400 hover:text-gray-600 transition-colors ml-4"
          aria-label="Delete question"
        >
          <X size={20} />
        </button>
      </div>
      <input
        type="text"
        value={question.text}
        onChange={(e) => onUpdate(question.id!, { text: e.target.value })}
        className="w-full p-2 mb-4 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-all bg-white text-primary-contrast"
        placeholder="Type your question here..."
      />
      {children}
    </div>
  );
};

export default BaseScreenerQuestion;
