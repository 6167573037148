import React, { useRef, useState } from 'react';
import Question from '../Interview/Question.tsx';
import PromptScreen from '../PromptScreen.tsx';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar.tsx';
import Animate from '../ui/Animate.tsx';
import Button from '../Buttons/Button.tsx';

const WelcomeScreen = () => {
  const questionTextRef = useRef(null);
  const [isQuestionRendered, setisQuestionRendered] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate('/interview-type');
  };

  return (
    <div className="bg-primary text-primary-contrast">
      <PromptScreen>
        <div className="py-6 px-5 sm:w-10/12 lg:w-7/12 2xl:w-6/12 sm:m-auto pt-14 text-2xl">
          <Question
            ref={questionTextRef}
            currentQuestion={`Hi there! 👋\n\nI'm Probe, your AI interviewing assistant. I'll talk to customers for you, so you can build a product they love in a fraction of the time.\n\nAre you ready to create your first interview?`}
            setisQuestionRendered={setisQuestionRendered}
            isLightMode={true}
          />
          {isQuestionRendered && (
            <Animate className={'mt-6'}>
              <Button
                label="Get Started"
                onClick={handleSubmit}
                buttonType="primary"
              />
            </Animate>
          )}
        </div>
      </PromptScreen>
    </div>
  );
};

export default WelcomeScreen;
