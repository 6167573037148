import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useCreateFirebaseInterview from 'src/hooks/useCreateFirebaseInterview';
import { FirebaseFunctionsClient } from '../../utils/firebaseFunctionsClient';
import { updateTopicOnFirestore } from '../../utils/firestore';
import { addStudyTitleAndDescriptionToFirestore } from '../../utils/firestoreStudy';
import { useAuth } from '../Authentication/AuthProvider';
import LoadingRing from '../Loading/LoadingRing';
import { ArrowUpRight } from 'lucide-react';

const B2BOverview: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [topic, setTopic] = useState<string>('');
  const createFirebaseInterviewMutation = useCreateFirebaseInterview({
    onSuccess: async (data) => {
      console.log(data.id);
      const interviewId = data.id;
      const results = await Promise.allSettled([
        updateTopicOnFirestore(interviewId, topic),
        rephraseTopicForGrammaticalCorrectness(topic),
        generateStudyTitleAndDescription(interviewId, topic),
      ]);
      if (results.every((res) => res.status === 'fulfilled')) {
        navigate(`/b2b_demographics/${interviewId}`);
      }
    },
    onError: () => {},
  });

  const handleSubmit = async () => {
    if (!user || !user.uid) {
      toast.error('Failed to get user');
      return;
    }
    if (topic.trim() === '') {
      toast.error('Please add your topic for the interview before submitting.');
      return;
    }

    try {
      createFirebaseInterviewMutation.mutate({ userId: user.uid });
    } catch (error) {
      console.error('Error creating interview: ', error);
      toast.error('An error occurred while creating the interview.');
    }
  };

  const rephraseTopicForGrammaticalCorrectness = async (topic: string) => {
    const originalPrompt = `I'm going to train you on how to rewrite a part of a sentence to ensure its grammatical correctness in a broader context.

    Your task is to rephrase a piece of text I provide you so that it's grammatically correct when it follows the phrase "I'm going to ask you a few questions about:"
    
    For context, the sentence I will give you is a user's response to the question "what is your interview about?"
    
    Here are some good examples of how to appropriately rephrase a sentence for grammatical correctness:
    
    - The initial phrase "about understanding how they get survey and interview participants." becomes "how you find survey and interview participants"
    - The initial phrase "understanding how to best price my product" becomes "how best to price my product"
    - The initial phrase "understand how they edit short term videos" becomes "how you edit short form videos"
    - The initial phrase "understanding how respondents choose their chair if they can't sit for longer than 4 hours" becomes "how you choose your chair if you can't sit for more than 4 hours"
    
    Rules:
    
    - IMPORTANT: DO NOT INCLUDE THE INTRODUCTORY PHRASE "I'm going to ask you a few questions about:" ANYWHERE IN YOUR RESPONSE. ONLY RETURN THE REPHRASED VERSION OF THE ORIGINAL PHRASE I PROVIDED.
    - If the original sentence is grammatically correct when it comes after the phrase "I'm going to ask you a few questions about:", simply return the original phrase in the JSON format specified below.
    - ENSURE THE PHRASE YOU RETURN DOES NOT END WITH A PERIOD OR ANY PUNCTUATION.
    
    Return the sentence as a JSON object, with the key "rephrased" and the corresponding rephrased phrase that is grammatically correct in the provided context as the value.
    
    The text you are to rephrase is:`;
    const updatedPrompt = originalPrompt + topic;

    try {
      const rephrasedTopicJSON =
        await new FirebaseFunctionsClient().getChatGPTJSONResponse(
          updatedPrompt
        );
      console.log(rephrasedTopicJSON);
      return getFirstValue(rephrasedTopicJSON);
    } catch (error) {
      console.error('Error rephrasing topic: ', error);
      throw error;
    }
  };

  const generateStudyTitleAndDescription = async (
    interviewId: string,
    topic: string
  ) => {
    const rawPrompt = `I am going to train you to write the title and description for a study. I am going to give you the topic of an interview. In response, you are to create a JSON object with two fields: - The first, "title" which generates a title for the study given the provided information. The title should be something like: "A study about {input study topic here}". The title must be MAX 6 words long. - The second, "description" in which you will generate a description for the study given the provided information. It should follow a format like: "This study will ask 10 questions about {input relevant end of sentence here}". Make sure it is short, and do not add any information you do not know about here. An example of a properly formatted response: {"title": "Study on Tenant Acquisition Methods","description": "This study will ask 10 questions about strategies property managers use to find tenants."} The topic and audience that you are to generate a study title and description from are as follows:`;
    const prompt = `${rawPrompt} Topic:${topic} `;

    try {
      const response =
        await new FirebaseFunctionsClient().getChatGPTJSONResponse(prompt);
      const responseObject = JSON.parse(response.response);

      const keys = Object.keys(responseObject);
      const studyName = responseObject[keys[0]];
      const description = responseObject[keys[1]];

      await addStudyTitleAndDescriptionToFirestore(
        interviewId,
        studyName,
        description
      );
    } catch (error) {
      console.error('Error generating study title and description: ', error);
    }
  };

  const getFirstValue = (json: any): string => {
    const parsedJson = JSON.parse(json.response);
    const keys = Object.keys(parsedJson);
    if (keys.length === 0) {
      throw new Error('Parsed JSON object is empty.');
    }
    return String(parsedJson[keys[0]]);
  };

  if (createFirebaseInterviewMutation.isPending) {
    return <LoadingRing />;
  }

  return (
    <div className="bg-white text-gray-900 min-h-screen">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <div className="mb-8">
          <h1 className="text-3xl font-bold mb-2">Interview Overview</h1>
          <p className="text-lg text-gray-600">
            Describe your interview goal in a short sentence.
          </p>
        </div>
        <div className="relative mb-6">
          <div className="bg-gray-50 rounded-xl shadow-sm overflow-hidden border border-gray-200">
            <input
              type="text"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Understand how copywriters conduct user research"
              className="w-full px-4 py-3 text-base focus:outline-none bg-transparent"
            />
          </div>
        </div>
        <button
          onClick={handleSubmit}
          disabled={createFirebaseInterviewMutation.isPending}
          className="px-6 py-3 bg-black text-white rounded-xl flex items-center justify-center hover:bg-gray-800 transition-all duration-300 ease-in-out text-base font-medium"
        >
          {createFirebaseInterviewMutation.isPending ? (
            <LoadingRing />
          ) : (
            <>
              Continue <ArrowUpRight size={20} className="ml-2" />
            </>
          )}
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default B2BOverview;
