import { useState, useEffect } from 'react';
import { InterviewQuestions } from '../utils/types/questionTypes.ts';
import { fetchInterviewQuestions } from '../utils/firestore.ts';

export const useInterviewQuestions = (interviewId: string | undefined) => {
  const [questions, setQuestions] = useState<InterviewQuestions | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadQuestions = async () => {
      setIsLoading(true);
      if (interviewId) {
        const fetchedQuestions = await fetchInterviewQuestions(interviewId);
        if (fetchedQuestions) {
          setQuestions(fetchedQuestions);
        }
        setIsLoading(false);
      }
    };

    loadQuestions();
  }, [interviewId]);

  return { questions, isLoading, setQuestions };
};
