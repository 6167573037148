import React, { useRef, useState, useEffect } from 'react';
import PromptScreen from '../PromptScreen.tsx';
import Animate from '../ui/Animate.tsx';
import Loader from '../Loader/Loader.tsx';
import {
  createInterviewInstanceOnFirestore,
  addQuestionAndAnswerToFirestore,
  addEmailToFirestoreInterviewInstance,
  getIsCollectUsersEmailFromFirestore,
  getPersonalizedPromptFromFirestore,
  getCreditsFromFirestoreGivenInterviewId,
  getTopicFromFirestore,
  getRephrasedTopicOnFirestoreGivenInterviewId,
  flagInterviewInstanceAsFinished,
} from '../../utils/firestore.ts';
import { getStudyCompletionCodeFromFirestore } from '../../utils/firestoreStudy.ts';
import Button from '../Buttons/Button.tsx';
import toast from 'react-hot-toast';
import Question from './Question.tsx';
import InterviewResponse from './InterviewResponse.tsx';
import EmailCapture from './EmailCapture.tsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { validateEmail } from '../../utils/general.ts';
import sunIcon from '../../assets/icons/sunIcon.svg';
import moonIcon from '../../assets/icons/moonIcon.svg';
import logoFull from '../../assets/icons/logoFull.svg';

type Message = {
  role: string;
  content: string;
};

interface InterviewInterfaceProps {
  defaultInterviewId?: string;
  showProgressBar?: boolean;
  isDemo?: boolean;
  isPreview?: boolean;
}

const InterviewInterface = ({
  defaultInterviewId,
  showProgressBar = true,
  isDemo = false,
  isPreview = false,
}: InterviewInterfaceProps) => {
  const questionTextRef = useRef<any>(null);
  const textAreaRef = useRef<any>(null);
  const emailInputRef = useRef<any>(null);
  const [isQuestionRendered, setisQuestionRendered] = useState(false);
  const [userResponse, setUserResponse] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [messageHistory, setMessageHistory] = useState<Message[]>([]);
  const [prompt, setPrompt] = useState<string>('');
  const params = useParams();
  const location = useLocation();
  const interviewId = defaultInterviewId ?? params.interviewId;
  const studyId = params.studyId;
  const [isFirstQuestion, setIsFirstQuestion] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [questionsAsked, setQuestionsAsked] = useState<number>(0);
  const [questionsAnswered, setQuestionsAnswered] = useState<number>(0);
  const MAX_QUESTIONS = interviewId === 'xw3iAqL2Y8a9aRSs7WTa' ? 19 : 10;
  const progress = showProgressBar
    ? Math.round((questionsAsked / MAX_QUESTIONS) * 100)
    : undefined;
  const [interviewInstanceRef, setInterviewInstanceRef] = useState<any>(null);
  const [isConversationEnded, setIsConversationEnded] =
    useState<boolean>(false);
  const [isCollectUsersEmail, setIsCollectUsersEmail] =
    useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [credits, setCredits] = useState<number>(0);
  const [interviewIntroText, setInterviewIntroText] = useState<string>('');
  const isStudy = location.pathname.includes('study');
  const [completionCode, setCompletionCode] = useState<string>('');
  const [lightMode, setLightMode] = useState<boolean>(false);
  const isDemoOrPreview = isDemo || isPreview;
  const navigate = useNavigate();
  const isMockInterview = location.pathname.includes('mock');
  const isLandingPage = location.pathname.includes('home');

  const developmentUrl =
    'http://127.0.0.1:5001/joinprobe/us-central1/getChatGPT4NextQuestion';
  const productionUrl =
    'https://us-central1-joinprobe.cloudfunctions.net/getChatGPT4NextQuestion';

  const getChatGPTNextQuestion = async (localPrompt?: string) => {
    const promptToUse = localPrompt ? localPrompt : prompt;
    const isDevelopment = process.env.NODE_ENV === 'development';
    const url = isDevelopment ? developmentUrl : productionUrl;

    try {
      const responseData = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userResponse,
          messageHistory,
          isFirstQuestion,
          prompt: promptToUse,
        }),
      });

      if (!responseData.ok) {
        throw new Error(`HTTP error! status: ${responseData.status}`);
      }

      const data = await responseData.json();
      const response = data.response;
      return response;
    } catch (error) {
      // toast('Sorry, there was an error! Please reload the page.');
      console.error('Error calling function:', error);
    }
  };

  const handleSubmit = async () => {
    setisQuestionRendered(false);

    // handles hard interview end to prevent unlimited length conversations
    if (questionsAsked >= MAX_QUESTIONS * 1.5) {
      renderEndConversationWithLoadingWrapper();
      return;
    }

    // handles interview end for study participants
    if (isStudy && questionsAsked >= MAX_QUESTIONS) {
      if (interviewId && interviewInstanceRef) {
        flagInterviewInstanceAsFinished(interviewId, interviewInstanceRef.id);
      }
      redirectToProlificCompletionScreen();
      return;
    }

    // local variables so we can use here even after state is reset
    const localCurrentQuestion = currentQuestion;
    const localUserResponse = userResponse;
    const localQuestionsAnswered = questionsAnswered + 1;
    setQuestionsAnswered(localQuestionsAnswered);

    // initiates interview if first response
    let localInterviewInstanceRef;
    if (!interviewInstanceRef && !isMockInterview && !isPreview) {
      localInterviewInstanceRef = await initializeInterviewInstance();
    }

    renderLoadingUI();
    addUserResponseToHistory(localUserResponse);

    if (!isMockInterview && !isPreview) {
      addQuestionAndAnswerToFirestore(
        localInterviewInstanceRef // if localInterviewInstanceRef, interview was just created and state is not updated
          ? localInterviewInstanceRef
          : interviewInstanceRef,
        localCurrentQuestion,
        localUserResponse
      );
    }

    const nextQuestion = await getChatGPTNextQuestion();
    renderQuestion(nextQuestion);
  };

  const redirectToProlificCompletionScreen = () => {
    console.log('redirecting');
    window.location.href = `https://app.prolific.com/submissions/complete?cc=${completionCode}`;
  };

  const renderLoadingUI = () => {
    setCurrentQuestion('');
    setUserResponse('');
    setLoading(true);
  };

  const addUserResponseToHistory = (response: string) => {
    const messageObj = { role: 'user', content: response };
    setMessageHistory((prev) => [...prev, messageObj]);
  };

  const renderInterviewIntro = async (topic: string) => {
    const localInterviewIntroText = `Hi there! 👋\n\nI'm Probe, an AI designed to make interviews engaging and informative. I'll be asking you a few questions about yourself and your experiences to gather insights for a research project.\n\n Are you ready to get started?`;
    setInterviewIntroText(localInterviewIntroText);
    renderQuestion(localInterviewIntroText, true);
    addAssistantMessageToHistory(localInterviewIntroText);
  };

  const renderInsufficientCredits = async () => {
    renderQuestion(
      `Hi there! 👋 \n\n I'm Probe, an autonomous interviewer. I appreciate the visit, but it looks like the creator of this interview isn't accepting responses at the moment. \n\n Have a great rest of your day 🙌`
    );
  };

  const renderQuestion = async (question: string, isNotQuestion = false) => {
    setLoading(true);
    setisQuestionRendered(false);
    setCurrentQuestion(question);
    setLoading(false);
    if (!isNotQuestion) {
      setQuestionsAsked((prev) => prev + 1);
    }
    addAssistantMessageToHistory(question);
  };

  const renderFirstQuestion = async (prompt?: string) => {
    setLoading(true);
    const firstQuestion = await getChatGPTNextQuestion(prompt);
    renderQuestion(firstQuestion);
    setIsFirstQuestion(false);
    addPromptToHistory(prompt);
  };

  const renderCollectEmail = () => {
    renderQuestion(
      'Before we kick things off, could you please provide your email address?'
    );
  };

  const initializeInterviewInstance = async () => {
    console.log('initialize!');

    if (!isMockInterview && !isPreview) {
      const localInterviewInstanceRef =
        await createInterviewInstanceOnFirestore(
          interviewId as string,
          isPreview,
          isStudy
        );
      setInterviewInstanceRef(localInterviewInstanceRef);
      return localInterviewInstanceRef;
    }
    return null;
  };

  const addQuestionAndAnswerToHistory = async (
    question: string,
    answer: string
  ) => {
    const previousQuestionObj = { role: 'assistant', content: question };
    const userResponseObj = { role: 'user', content: answer };
    setMessageHistory((prev) => [
      ...prev,
      previousQuestionObj,
      userResponseObj,
    ]);
  };

  const addAssistantMessageToHistory = (message: string) => {
    const messageObj = { role: 'assistant', content: message };
    setMessageHistory((prev) => [...prev, messageObj]);
  };

  const addPromptToHistory = (demoPrompt?: string) => {
    const promptToUse = demoPrompt ? demoPrompt : prompt;
    const firstQuestionObj = { role: 'system', content: promptToUse };
    setMessageHistory((prev) => [...prev, firstQuestionObj]);
  };

  const handleTextAreaChange = (event: any) => {
    setUserResponse(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleEndConversation = async () => {
    if (studyId && interviewId) {
      flagInterviewInstanceAsFinished(interviewId, interviewInstanceRef.id);
      redirectToProlificCompletionScreen();
      return;
    }

    renderEndConversationWithLoadingWrapper();
  };

  const renderEndConversationWithLoadingWrapper = async () => {
    // loading wrappers prevent <Animate> from rendering before state is asynchronously updated
    setLoading(true);
    await renderEndConversation();
    setLoading(false);
  };

  const renderEndConversation = async () => {
    if (studyId) {
    }
    setIsConversationEnded(true);
    setCurrentQuestion(
      'Thanks, I had a great time chatting with you. Have a great day!'
    );
  };

  const handleBeginInterview = async () => {
    await getPrompt(); // aim is to prevent error where in interview preview goals are not yet loaded bc prompt was fetched before they were added to db. not sure if actually works.
    if (isCollectUsersEmail) {
      renderCollectEmail();
    } else {
      renderFirstQuestion();
    }
  };

  const handleBeginInterviewDemo = async () => {
    const localPrompt = await getPrompt();
    addPromptToHistory(localPrompt);
    addAssistantMessageToHistory(interviewIntroText);
    renderFirstQuestion(localPrompt);
  };

  const handleSubmitEmail = async () => {
    if (!validateEmail(userEmail)) {
      toast('Please enter a valid email.');
      return;
    }

    const localInterviewInstanceRef = await initializeInterviewInstance();
    setInterviewInstanceRef(localInterviewInstanceRef);
    addEmailToFirestoreInterviewInstance(localInterviewInstanceRef, userEmail);
    addQuestionAndAnswerToHistory(currentQuestion, userEmail);
    renderFirstQuestion();
  };

  const handleEmailFieldKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      handleSubmitEmail();
    }
  };

  const handleSkipEmail = () => {
    renderFirstQuestion();
  };

  const getPrompt = async () => {
    const prompt = await getPersonalizedPromptFromFirestore(interviewId);
    setPrompt(prompt);
    return prompt;
  };

  const addInterviewIntroToHistory = () => {
    const messageObj = { role: 'assistant', content: interviewIntroText };
    setMessageHistory((prev) => [...prev, messageObj]);
  };

  const questionClass = userResponse ? 'text-gray-400' : 'text-white';

  const showStartChattingButton =
    isQuestionRendered &&
    !loading &&
    questionsAsked === 0 &&
    credits > 0 &&
    (!isDemo || isPreview);
  const showEmailCapture =
    isCollectUsersEmail &&
    questionsAsked === 1 &&
    !loading &&
    isQuestionRendered &&
    credits > 0 &&
    !isDemo;
  const showInterviewResponse =
    isQuestionRendered &&
    !loading &&
    !isConversationEnded &&
    !showEmailCapture &&
    questionsAsked >= 1 &&
    credits > 0;

  useEffect(() => {
    const getImmediatelyRequiredDataFromFirestore = async () => {
      setLoading(true);
      getPrompt(); //!!! should actually do this after click "start chatting"
      const localCredits =
        await getCreditsFromFirestoreGivenInterviewId(interviewId);
      setCredits(localCredits);
      if (localCredits <= 0) {
        renderInsufficientCredits();
      } else if (isDemo && !isPreview) {
        handleBeginInterviewDemo();
      } else {
        await handleRenderInterviewIntro();
      }
      setLoading(false);
    };
    getImmediatelyRequiredDataFromFirestore();
  }, []);

  const handleRenderInterviewIntro = async () => {
    let topicToRender;
    const rephrasedTopic =
      await getRephrasedTopicOnFirestoreGivenInterviewId(interviewId);
    if (rephrasedTopic !== undefined && rephrasedTopic !== '') {
      topicToRender = rephrasedTopic;
    } else {
      const topicData = await getTopicFromFirestore(interviewId);
      topicToRender = topicData.topic;
    }
    renderInterviewIntro(topicToRender);
  };

  useEffect(() => {
    const getOtherDataFromFirestore = async () => {
      const localPrompt = await getPrompt();
      setPrompt(localPrompt);
      updateIsCollectUsersEmail();
    };
    getOtherDataFromFirestore();
  }, []);

  const updateIsCollectUsersEmail = async () => {
    const localIsCollectUsersEmail =
      await getIsCollectUsersEmailFromFirestore(interviewId);
    setIsCollectUsersEmail(localIsCollectUsersEmail);
  };

  const navigateHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (isDemo) return;
    if (textAreaRef.current && isQuestionRendered && !isConversationEnded) {
      textAreaRef.current.focus();
    }
    if (emailInputRef.current && isQuestionRendered && !isConversationEnded) {
      emailInputRef.current.focus();
    }
  }, [isQuestionRendered]);

  useEffect(() => {
    const getAndSetCompletionCode = async () => {
      if (isStudy && interviewId && studyId) {
        const completionCode = await getStudyCompletionCodeFromFirestore(
          interviewId,
          studyId
        );
        setCompletionCode(completionCode);
      }
    };

    getAndSetCompletionCode();
  }, [interviewId, studyId]);

  useEffect(() => {
    if (isLandingPage) {
      setLightMode(true);
    }
  }, [isLandingPage]);

  const containerStyling = isDemo
    ? 'w-full'
    : 'sm:w-10/12 lg:w-[45%] 2xl:w-5/12';
  const containerPadding = isDemoOrPreview ? '' : 'py-20';

  return (
    <div className={lightMode ? 'bg-white h-screen' : ''}>
      <div
        className={`relative min-h-screen h-full ${
          isDemo || isPreview ? '' : 'w-screen'
        }`}
      >
        {/* Logo added here, visible only on md screens and larger */}
        {!isDemoOrPreview && (
          <div className="absolute top-0 left-0 m-4 hidden md:block">
            <img
              src={logoFull}
              alt="Probe logo"
              className="btn btn-ghost"
              onClick={navigateHome}
            />
          </div>
        )}

        {!isDemo && (
          <button
            className="absolute top-11 right-2 sm:top-0 sm:right-0 m-4 btn btn-circle btn-ghost"
            onClick={() => setLightMode(!lightMode)}
          >
            {lightMode ? (
              <img src={sunIcon} alt="sun" className="w-7 h-7" />
            ) : (
              <img src={moonIcon} alt="moon" className="w-7 h-7" />
            )}
          </button>
        )}

        <PromptScreen
          percentComplete={progress}
          isDemo={isDemo}
          isLightMode={lightMode}
        >
          <div
            className={`px-5 sm:m-auto text-2xl ${containerStyling} ${containerPadding}`}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className={questionClass}>
                  <Question
                    ref={questionTextRef}
                    currentQuestion={currentQuestion}
                    key={currentQuestion}
                    setisQuestionRendered={setisQuestionRendered}
                    hasUserResponded={userResponse !== ''}
                    isDemo={isDemo}
                    isLightMode={lightMode}
                  />
                </div>
                {showInterviewResponse && (
                  <InterviewResponse
                    ref={textAreaRef}
                    value={userResponse}
                    handleChange={handleTextAreaChange}
                    handleKeyDown={handleKeyDown}
                    isConversationEnded={isConversationEnded}
                    hasUserResponded={userResponse !== ''}
                    progress={progress}
                    handleSubmit={handleSubmit}
                    handleEndConversation={handleEndConversation}
                    isDemo={isDemo}
                    isLightMode={lightMode}
                  />
                )}
                {showStartChattingButton && (
                  <Animate className={'mt-8'}>
                    <Button
                      label="Start Chatting"
                      onClick={handleBeginInterview}
                      buttonType="secondary"
                    />
                  </Animate>
                )}

                {showEmailCapture && (
                  <EmailCapture
                    ref={emailInputRef}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    handleSubmitEmail={handleSubmitEmail}
                    handleSkipEmail={handleSkipEmail}
                    handleEmailFieldKeyDown={handleEmailFieldKeyDown}
                  />
                )}
              </>
            )}
          </div>
        </PromptScreen>
      </div>
    </div>
  );
};

export default InterviewInterface;
