import React from 'react';
import BaseQuestion from './BaseQuestion.tsx';
import ToggleSwitch from './Shared/ToggleSwitch.tsx';
import ImageUploader from './Shared/ImageUploader.tsx';
import { RatingQuestion as RatingQuestionType } from '../../../../utils/types/questionTypes.ts';
import { handleImageUpload } from '../../../../utils/general.ts';

interface RatingQuestionProps {
  question: RatingQuestionType;
  questionNumber: number;
  onDelete: () => void;
  onUpdate: (updates: Partial<RatingQuestionType>) => void;
  interviewId: string;
}

const RatingQuestion: React.FC<RatingQuestionProps> = ({
  question,
  questionNumber,
  onDelete,
  onUpdate,
  interviewId,
}) => {
  const RatingScale = ({ max }: { max: number }) => (
    <div className="mt-4">
      <div className="flex items-end">
        {Array.from({ length: max }, (_, i) => i + 1).map((num) => (
          <div key={num} className="flex flex-col items-center mr-2 last:mr-0">
            <div className="w-8 h-8 rounded-md bg-gray-100 flex items-center justify-center text-gray-700 font-semibold text-sm border border-gray-300 mb-1">
              {num}
            </div>
            <div className="h-5 flex items-center">
              {num === 1 && (
                <span className="text-xs text-gray-500">Lowest</span>
              )}
              {num === max && (
                <span className="text-xs text-gray-500">Highest</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const handleImageDelete = () => {
    onUpdate({ image: null });
  };

  return (
    <BaseQuestion<RatingQuestionType>
      question={question}
      questionNumber={questionNumber}
      onDelete={onDelete}
      onUpdate={onUpdate}
    >
      <div className="space-y-6">
        <textarea
          value={question.question}
          onChange={(e) => onUpdate({ question: e.target.value })}
          placeholder='Type your instructions here. For example, "How likely would you be to buy this product?"'
          className="w-full p-4 border border-gray-200 rounded-2xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-all bg-white text-gray-800 shadow-sm resize-none"
          rows={3}
        />
        <div className="flex items-center space-x-4">
          <label htmlFor="maxRating" className="text-gray-700">
            Maximum Rating:
          </label>
          <select
            id="maxRating"
            value={question.maxRating}
            onChange={(e) => onUpdate({ maxRating: Number(e.target.value) })}
            className="p-2 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-all bg-white text-gray-800 shadow-sm"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="max-w-sm">
          <RatingScale max={question.maxRating} />
        </div>
        <div className="flex items-center space-x-8">
          <ToggleSwitch
            label="Include Image"
            checked={!!question.image}
            onChange={() =>
              onUpdate({
                image: question.image ? null : new File([], 'placeholder'),
              })
            }
          />
          <ToggleSwitch
            label="Allow Skip"
            checked={question.allowSkip}
            onChange={() => onUpdate({ allowSkip: !question.allowSkip })}
          />
        </div>
        {question.image && (
          <ImageUploader
            onUpload={handleImageUpload<RatingQuestionType>(
              interviewId,
              question.id,
              onUpdate
            )}
            initialImage={
              typeof question.image === 'string' ? question.image : null
            }
            onDelete={handleImageDelete}
          />
        )}
      </div>
    </BaseQuestion>
  );
};

export default RatingQuestion;
