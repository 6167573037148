import React from 'react';

interface QuestionTextProps {
  questionNumber: string;
  questionText: string;
  show: boolean;
  isTyping: boolean;
  hideQuestionNumber: boolean;
}

const QuestionText: React.FC<QuestionTextProps> = ({
  questionNumber,
  questionText,
  show,
  isTyping,
  hideQuestionNumber,
}) => {
  return (
    <div
      className={`transition-opacity duration-1000 ease-in-out ${
        show ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div
        className={`text-lg font-bold mb-2 transition-colors duration-350 ${
          isTyping ? 'text-gray-500' : 'text-primary-contrast'
        }`}
      >
        {hideQuestionNumber ? '' : questionNumber}
      </div>
      <h1
        className={`text-2xl mb-6 transition-colors duration-350 ${
          isTyping ? 'text-gray-600' : 'text-primary-contrast'
        }`}
      >
        {questionText}
      </h1>
    </div>
  );
};

export default QuestionText;
