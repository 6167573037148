import React from 'react';
import InterviewInstanceCard from './InterviewInstanceCard';
import { InterviewCardData } from '@functions/common/types';

interface InterviewListProps {
  interviews: InterviewCardData[];
  totalQuestions: number;
}

export const InterviewList: React.FC<InterviewListProps> = ({
  interviews,
  totalQuestions,
}) => {
  return (
    <div className="bg-primary p-4 sm:p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 sm:gap-6">
        {interviews.map((interview, i) => (
          <InterviewInstanceCard
            key={interview.id}
            index={i + 1}
            id={interview.id}
            dateAndTimeCreated={interview.dateAndTimeCreated}
            link={interview.link}
            firstQuestion={interview.firstQuestion}
            firstAnswer={interview.firstAnswer}
            answeredQuestions={interview.answeredQuestions}
            totalQuestions={totalQuestions}
          />
        ))}
      </div>
    </div>
  );
};
