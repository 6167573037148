import {
  collection,
  addDoc,
  arrayUnion,
  setDoc,
  doc,
  DocumentReference,
  getDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  orderBy,
  query,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';

const addAffiliateId = async (affiliateId: string, userId: string) => {
  try {
    const affiliateRef = doc(db, 'affiliate_ids', affiliateId);

    // check if the affiliate id exists
    const affiliateSnap = await getDoc(affiliateRef);
    if (affiliateSnap.exists()) {
      return true; // affiliate id already exists and belongs to the user (assumes validation is done before calling this function)
    }

    // create a document in affiliate collection with affiliate id and, as a field, the userId
    await setDoc(affiliateRef, {
      userId,
    });
    return true;
  } catch (error) {
    console.error(error);
  }
};

const checkAffiliateChangeAllowed = async (
  affiliateId: string,
  userId: string
) => {
  try {
    const affiliateRef = doc(db, 'affiliate_ids', affiliateId);

    // return true if the affiliate id doesn't exist AND is not claimed by a DIFFERENT user
    const affiliateSnap = await getDoc(affiliateRef);
    if (!affiliateSnap.exists()) {
      return true;
    }

    const affiliateData = affiliateSnap.data();
    if (affiliateData?.userId !== userId) {
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
  }
};

const addReferrerToNewUser = async (userId: string, referrerId: string) => {
  try {
    const userRef = doc(db, 'users', userId);

    await updateDoc(userRef, {
      referrerId,
    });
  } catch (error) {
    console.error(error);
  }
};

const getAffiliateBalance = async (userId: string) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    const affiliateBalance = userData?.affiliateBalance;
    return affiliateBalance;
  } catch (error) {
    console.error(error);
  }
};

export {
  addAffiliateId,
  checkAffiliateChangeAllowed,
  addReferrerToNewUser,
  getAffiliateBalance,
};
