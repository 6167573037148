import { UseQueryResult } from '@tanstack/react-query';
import {
  B2CInterviewInstance,
  getInterviewInstanceDataFromFirestore,
} from '../utils/firestore';
import createQueryHook from '../queries/createQueryHook';
import { InterviewInstanceResponse } from '../utils/firestore';
import { InterviewInstanceWithQuestions } from '@functions/common/types';

type InterviewInstanceDataRequest = {
  interviewId: string;
  interviewInstanceId: string;
};

const useGetInterviewInstances = createQueryHook<
  InterviewInstanceDataRequest,
  InterviewInstanceWithQuestions | B2CInterviewInstance | undefined
>(
  (params) =>
    getInterviewInstanceDataFromFirestore(
      params.interviewId,
      params.interviewInstanceId
    ),
  (params) => [
    'getInterviewInstances',
    params.interviewId,
    params.interviewInstanceId,
  ]
);

export default useGetInterviewInstances;

export type UseGetInterviewResult = UseQueryResult<
  InterviewInstanceResponse,
  Error
>;
