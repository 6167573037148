import React from 'react';
import { X } from 'lucide-react';

interface OptionInputProps {
  value: string;
  onChange: (value: string, answerValue?: number) => void;
  onRemove: () => void;
  placeholder: string;
  readOnly?: boolean;
  isScreener?: boolean;
  answerValue?: number;
  questionType?: 'radio' | 'checkbox';
}

const OptionInput: React.FC<OptionInputProps> = ({
  value,
  onChange,
  onRemove,
  placeholder,
  readOnly = false,
  isScreener = false,
  answerValue = 2,
  questionType = 'radio',
}) => {
  const getAnswerValueOptions = () => {
    if (questionType === 'radio') {
      return [
        { value: 1, label: 'Qualify' },
        { value: 2, label: 'Disqualify' },
      ];
    } else {
      return [
        { value: 1, label: 'May Select' },
        { value: 2, label: 'Must Select' },
        { value: 3, label: 'Disqualify' },
      ];
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="flex-grow max-w-xs relative">
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value, answerValue)}
          placeholder={placeholder}
          readOnly={readOnly}
          className={`w-full p-3 pr-10 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-all ${
            readOnly
              ? 'bg-primary-muted text-primary-contrast'
              : 'bg-white text-primary-contrast'
          } shadow-sm`}
        />
        {!readOnly && (
          <button
            onClick={onRemove}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1.5 text-gray-400 hover:text-gray-600 hover:bg-primary-muted rounded-full transition-all"
            aria-label="Remove option"
          >
            <X size={16} />
          </button>
        )}
        {readOnly && (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            (Fill-in)
          </div>
        )}
      </div>
      {isScreener && (
        <select
          value={answerValue}
          onChange={(e) => onChange(value, parseInt(e.target.value))}
          className="p-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-primary-muted focus:border-transparent outline-none transition-all bg-white text-primary-contrast shadow-sm"
        >
          {getAnswerValueOptions().map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default OptionInput;
