import React, { useState, useEffect } from 'react';
import QuestionText from './QuestionText.tsx';
import QuestionImage from './QuestionImage.tsx';
import SingleSelect from './SingleSelect.tsx';
import MultiSelect from './MultiSelect.tsx';
import Rating from './Rating.tsx';
import OpenEnded from './OpenEnded.tsx';
import TextBlock from './TextBlock.tsx';
import {
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
  RatingQuestion,
  OpenEndedQuestion,
  TextBlockQuestion,
  Question,
} from '@functions/common/types.ts';

interface InterviewQuestionProps {
  question: Question;
  onAnswer: (
    questionId: string,
    answer: string | string[] | number,
    isOther: boolean
  ) => void;
  selectedAnswer: string | string[] | number | undefined;
  isTransitioning: boolean;
  questionNumber: number;
  totalQuestions: number;
  setIsImageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOtherFieldActive: React.Dispatch<React.SetStateAction<boolean>>;
  followUpQuestion: string | null;
  onBlur: () => void;
  onContinue: () => void;
}

const InterviewQuestion: React.FC<InterviewQuestionProps> = ({
  question,
  onAnswer,
  selectedAnswer,
  isTransitioning,
  questionNumber,
  totalQuestions,
  setIsImageModalOpen,
  setIsOtherFieldActive,
  followUpQuestion,
  onBlur,
  onContinue,
}) => {
  const [showText, setShowText] = useState(false);
  const [showChoices, setShowChoices] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const textTimer = setTimeout(() => setShowText(true), 200);
    const choicesTimer = setTimeout(() => setShowChoices(true), 1000);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(choicesTimer);
    };
  }, [question.id, followUpQuestion]);

  useEffect(() => {
    setIsTyping(false);
  }, [question.id, followUpQuestion]);

  const handleOpenEndedAnswer = (answer: string) => {
    onAnswer(question.id, answer, false);
    setIsTyping(answer.length > 0);
  };

  const getQuestionText = () => {
    if (followUpQuestion) {
      return followUpQuestion;
    }
    let suffix = '';
    if (question.type === 'single-select') {
      suffix = ' (Select one)';
    } else if (question.type === 'multiple-choice') {
      suffix = ' (Select all that apply)';
    }
    return `${question.question}${suffix}`;
  };

  console.log('question type: ', question.type);

  return (
    <div
      className={`transition-opacity duration-300 ${
        isTransitioning ? 'opacity-50' : 'opacity-100'
      }`}
    >
      <QuestionText
        questionNumber={`Q${questionNumber}`}
        questionText={getQuestionText()}
        show={showText}
        isTyping={isTyping}
        hideQuestionNumber={question.type === 'text-block'}
      />
      {question.image && !followUpQuestion && (
        <div className="mb-6 flex justify-center">
          <QuestionImage
            src={question.image}
            alt="Question Image"
            setIsImageModalOpen={setIsImageModalOpen}
          />
        </div>
      )}
      {question.type === 'single-select' && !followUpQuestion && (
        <SingleSelect
          choices={question.options}
          show={showChoices}
          selectedChoice={selectedAnswer as string | undefined}
          onSelect={(choice, isOther) => onAnswer(question.id, choice, isOther)}
          disabled={isTransitioning}
          includeOther={question.includeOther || false}
          setIsOtherFieldActive={setIsOtherFieldActive}
        />
      )}
      {question.type === 'multiple-choice' && !followUpQuestion && (
        <MultiSelect
          choices={question.options}
          show={showChoices}
          selectedChoices={(selectedAnswer as string[]) || []}
          onSelect={(choices) => onAnswer(question.id, choices, false)}
          disabled={isTransitioning}
          includeOther={question.includeOther || false}
        />
      )}
      {question.type === 'rating' && !followUpQuestion && (
        <Rating
          maxRating={question.maxRating}
          selectedRating={selectedAnswer as number | undefined}
          onSelect={(rating) => onAnswer(question.id, rating, false)}
          disabled={isTransitioning}
          show={showChoices}
          isTransitioning={isTransitioning}
        />
      )}
      {(question.type === 'open-ended' ||
        question.type === 'ai-follow-ups' ||
        followUpQuestion) && (
        <OpenEnded
          show={showChoices}
          onAnswer={handleOpenEndedAnswer}
          selectedAnswer={selectedAnswer as string | undefined}
          disabled={isTransitioning}
          onBlur={() => {}}
        />
      )}
    </div>
  );
};

export default InterviewQuestion;
