import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import useGetInterviewInstances from 'src/hooks/useGetInterviewInstances';
import {
  InterviewInstance,
  InterviewInstanceWithQuestions,
} from '@functions/common/types';
import {
  B2CInterviewInstance,
  deleteInterviewInstanceOnFirestore,
  updateInterviewInstanceFinishedStatus,
} from '../../utils/firestore';
import PageNotFound from '../ErrorPages/PageNotFound';
import LoadingRing from '../Loading/LoadingRing';
import CompletedInterview from './CompletedInterview';
import InterviewInstanceReview from './InterviewInstanceReview';
import { getInterviewTypeFromFirestore } from '../../utils/firestore';
import { InterviewType } from '@functions/common/types';

const InterviewInstanceReviewController: React.FC = () => {
  const { interviewId, interviewNumber } = useParams<{
    interviewId: string;
    interviewNumber: string;
  }>();
  const navigate = useNavigate();
  const [interviewType, setInterviewType] = useState<InterviewType>('CUSTOM');
  const {
    data: interviewData,
    isLoading: loading,
    isError,
  } = useGetInterviewInstances(interviewId!, {
    enabled: !!interviewId,
  });

  console.log('interview data', interviewData);

  const queryClient = useQueryClient();
  const interviewInstance = interviewData?.instances?.at(
    Number(interviewNumber) - 1
  ) as
    | InterviewInstanceWithQuestions
    | InterviewInstance
    | B2CInterviewInstance;

  const [summarizeConversation, setSummarizeConversation] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const isB2B = interviewType === 'B2B';

  useEffect(() => {
    const fetchInterviewType = async () => {
      const type = await getInterviewTypeFromFirestore(interviewId!);
      setInterviewType(type);
    };
    fetchInterviewType();
  }, [interviewId]);

  const handleBack = useCallback(
    () => navigate(`/dashboard/${interviewId}`),
    [interviewId, navigate]
  );

  const handleInterviewComplete = useCallback(async () => {
    try {
      if (!isB2B && interviewInstance) {
        queryClient.setQueryData(['interview', interviewId], () => {
          return {
            ...interviewInstance,
            isFinished: true,
          };
        });
        if (interviewId) {
          await updateInterviewInstanceFinishedStatus(
            interviewId,
            interviewInstance.id,
            true
          );
        }
      }
    } catch (error) {
      console.error('Error updating interview instance state:', error);
      toast('An error occurred while updating the interview instance.');
    }
  }, [isB2B, interviewInstance, queryClient, interviewId]);

  const handlePreviousInterview = useCallback(() => {
    const previousInterviewNumber = Number(interviewNumber) - 1;
    if (previousInterviewNumber >= 1) {
      navigate(`/dashboard/${interviewId}/${previousInterviewNumber}`);
    }
  }, [interviewId, interviewNumber, navigate]);

  const handleNextInterview = useCallback(() => {
    const nextInterviewNumber = Number(interviewNumber) + 1;
    navigate(`/dashboard/${interviewId}/${nextInterviewNumber}`);
  }, [interviewId, interviewNumber, navigate]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        handlePreviousInterview();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        handleNextInterview();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        handleBack();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [
    handleBack,
    handleNextInterview,
    handlePreviousInterview,
    interviewId,
    interviewNumber,
  ]);

  const isPreviousDisabled = Number(interviewNumber) === 1;

  const isNextDisabled =
    Number(interviewNumber) === interviewData?.instances?.length;

  console.log(interviewType);

  if (loading) {
    return <LoadingRing />;
  }

  if (!interviewInstance || isError) {
    return <PageNotFound />;
  }

  if (interviewType === 'OLD_TYPE') {
    return (
      <InterviewInstanceReview
        isPreviousDisabled={isPreviousDisabled}
        isNextDisabled={isNextDisabled}
        interviewData={interviewInstance as B2CInterviewInstance}
        loading={loading}
        interviewNumber={interviewNumber!}
        summarizeConversation={summarizeConversation}
        loadingSummary={loadingSummary}
        handleBack={handleBack}
        handleInterviewComplete={handleInterviewComplete}
        handlePreviousInterview={handlePreviousInterview}
        handleNextInterview={handleNextInterview}
      />
    );
  }

  if (
    interviewType === 'B2B' ||
    interviewType === 'B2C' ||
    interviewType === 'CUSTOM'
  ) {
    return (
      <CompletedInterview
        interviewNumber={interviewNumber ?? ''}
        interview={interviewInstance as InterviewInstance}
        handleBack={handleBack}
        isNextDisabled={isNextDisabled}
        isPreviousDisabled={isPreviousDisabled}
        handlePreviousInterview={handlePreviousInterview}
        handleNextInterview={handleNextInterview}
      />
    );
  }

  return (
    <CompletedInterview
      interviewNumber={interviewNumber ?? ''}
      interview={interviewInstance as InterviewInstance}
      handleBack={handleBack}
      isNextDisabled={isNextDisabled}
      isPreviousDisabled={isPreviousDisabled}
      handlePreviousInterview={handlePreviousInterview}
      handleNextInterview={handleNextInterview}
    />
  );
};

export default InterviewInstanceReviewController;
