import React from 'react';
import AIButton from '../../Buttons/AIButton.tsx';

interface FiltersSearchBarProps {
  searchQuery: string;
  onChange: (query: string) => void;
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isLandingPage?: boolean;
}

const FiltersSearchBar: React.FC<FiltersSearchBarProps> = ({
  searchQuery,
  onChange,
  onClick,
  onKeyDown,
  isLandingPage,
}) => {
  const inputClassName = `
    flex-1 px-4 py-3 pr-4 md:pr-40 focus:outline-none rounded-xl w-full
    bg-primary border border-gray-300 text-primary-contrast placeholder-gray-500
  `;

  const buttonClassName = `
    py-3 w-full md:w-auto
    ${
      isLandingPage
        ? 'border border-primary-contrast text-primary bg-primary-contrast hover:bg-gray-100'
        : 'border border-gray-300 bg-primary-contrast text-primary hover:bg-gray-100'
    }
  `;

  return (
    <div className="mt-12 md:w-11/12 mx-auto">
      <div className="flex items-center relative flex-wrap">
        <div className="w-full md:w-11/12">
          <input
            type="text"
            placeholder="College students in the US and UK who use ChatGPT"
            value={searchQuery}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={onKeyDown}
            className={inputClassName}
          />
        </div>
        <div className="mx-auto md:w-auto md:absolute md:right-0 md:top-0 md:bottom-6 flex justify-center md:items-center">
          <AIButton
            onClick={onClick}
            label="Find Relevant Filters"
            className={buttonClassName}
          />
        </div>
      </div>
    </div>
  );
};

export default FiltersSearchBar;
