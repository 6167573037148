import React, { ReactNode } from "react";
import { useSpring, animated } from "@react-spring/web";

interface AnimateProps {
  className?: string;  
  children: ReactNode;
}

function Animate({ className = '', children }: AnimateProps) {
  const springs = useSpring({
    from: { opacity: 0, transform: 'translateX(-50px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  });

  return (
    <animated.div className={className} style={springs}>
      {children}
    </animated.div>
  );
}

export default Animate;
