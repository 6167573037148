import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthProvider.tsx';
import { FiList, FiPlus, FiLogOut, FiCreditCard } from 'react-icons/fi';

const DropdownMenu = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const userEmail = user?.email;

  const navigateTo404 = () => {
    navigate('/404');
  };

  const navigateToDashboard = async () => {
    if (user === null) {
      navigateTo404();
      return;
    }
    navigate(`/dashboard`);
  };

  const navigateToNewInterview = async () => {
    navigate(`/overview`);
  };

  const navigateToPayments = async () => {
    navigate(`/settings/payments`);
  };

  return (
    <div className="relative inline-block text-left z-50">
      <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5 divide-y divide-gray-800 border border-gray-500">
        <div className="py-1">
          <div className="text-gray-400 px-4 py-2 text-sm">{userEmail}</div>
        </div>
        <div className="py-1 hover:cursor-pointer">
          <a
            onClick={navigateToDashboard}
            className="text-white block px-4 py-2 text-sm hover:bg-gray-700"
          >
            <FiList className="inline-block mr-2" />
            My Interviews
          </a>
          <a
            onClick={navigateToNewInterview}
            className="text-white block px-4 py-2 text-sm hover:bg-gray-700"
          >
            <FiPlus className="inline-block mr-2" />
            New Interview
          </a>
          <a
            onClick={navigateToPayments}
            className="text-white block px-4 py-2 text-sm hover:bg-gray-700"
          >
            <FiCreditCard className="inline-block mr-2" />
            My Purchases
          </a>
        </div>

        <div className="py-1 hover:cursor-pointer">
          <a
            onClick={logout}
            className="text-white block px-4 py-2 text-sm hover:bg-gray-700"
          >
            <FiLogOut className="inline-block mr-2" />
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
