import { ProbeScreenerQuestion } from '@functions/common/types.ts';
import { AlertCircle, ArrowUpRight, Plus, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from 'src/components/ErrorPages/PageNotFound.tsx';
import Loader from 'src/components/Loader/Loader.tsx';
import useGetInterview from 'src/hooks/useGetInterview.ts';
import useSaveScreenerData from 'src/hooks/useSaveScreenerData.ts';
import SaveStatus from '../SaveStatus.tsx';
import BaseScreenerQuestion from './BaseScreenerQuestion.tsx';
import MultipleChoiceScreener from './MultipleChoiceScreener.tsx';
import SingleChoiceScreener from './SingleChoiceScreener.tsx';

const ScreenerQuestions: React.FC = () => {
  const [screenerQuestions, setScreenerQuestions] = useState<
    ProbeScreenerQuestion[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { interviewId } = useParams<{ interviewId: string }>();

  const navigate = useNavigate();
  const saveScreenerDataMutation = useSaveScreenerData();

  const saveStatus = saveScreenerDataMutation.isPending ? 'saving' : 'saved';
  const { data: interview, isLoading: interviewLoading } = useGetInterview(
    interviewId!,
    { enabled: !!interviewId }
  );

  useEffect(() => {
    const screeners = interview?.screener;
    setScreenerQuestions(screeners ?? []);
  }, [interview]);

  console.log(screenerQuestions, interviewLoading, interview);

  const handleUpdateQuestion = (
    questionId: string,
    updates: Partial<ProbeScreenerQuestion>
  ) => {
    setScreenerQuestions((old) => {
      const newQuestions = old.map((question) =>
        question.id === questionId ? { ...question, ...updates } : question
      );
      return newQuestions as ProbeScreenerQuestion[];
    });
  };

  const handleDeleteQuestion = (questionId: string) => {
    setScreenerQuestions((prevData) => [
      ...prevData.filter((q) => q.id !== questionId),
    ]);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(screenerQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setScreenerQuestions(items);
  };

  const addNewQuestion = (type: 'radio' | 'checkbox') => {
    const newQuestion: ProbeScreenerQuestion = {
      id: `${Date.now()}`,
      questionType: type,
      text: '',
      answers: [{ text: '', answerValue: 2 }],
      includeOtherOption: false,
    };
    setScreenerQuestions((prevData) => [...prevData, newQuestion]);
    setIsModalOpen(false);
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
    }
  };

  const handleSubmit = async () => {
    if (screenerQuestions.length <= 1) {
      toast.error('You must include at least 2 Screener Questions.');
      return;
    }

    if (!interviewId) {
      toast.error('Interview not found');
      return;
    }

    await saveScreenerDataMutation.mutateAsync({
      interviewId,
      screenerQuestions,
    });

    navigate(`/dashboard/${interviewId}`);
  };

  useEffect(() => {
    if (!interviewId) {
      return;
    }

    if (screenerQuestions?.length > 0) {
      saveScreenerDataMutation.mutate({ interviewId, screenerQuestions });
    }
  }, [screenerQuestions]);

  if (interviewLoading) {
    return <Loader />;
  }

  if (!interview) {
    return <PageNotFound />;
  }

  return (
    <div className="mx-auto w-10/12 py-10">
      <div className="relative mb-8">
        <div className="absolute -right-5 top-2 md:-right-24">
          <SaveStatus status={saveStatus} />
        </div>
        <div className="bg-primary rounded-lg pb-6 px-4">
          <h1 className="text-3xl font-bold text-primary-contrast mb-4">
            Qualification Questions
          </h1>
          <p className="text-primary-contrast mb-4 text-lg">
            These questions will be used to qualify or disqualify potential
            respondents for your interview. Only candidates matching your
            initial job title and filters will see these questions, and only
            those who pass will participate in your interview.
          </p>

          <div className="flex items-center bg-primary-muted p-4 rounded-md">
            <AlertCircle className="text-primary-contrast mr-2" size={20} />
            <p className="text-primary-contrast text-md">
              Note: The more screener questions you add, the longer it may take
              to find interview participants.
            </p>
          </div>
          <p className="text-primary-contrast font-medium mt-4">
            Requirements: Add a minimum of 2 questions and a maximum of 10.
          </p>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="screener-questions">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-8"
            >
              {screenerQuestions.map((question, index) => (
                <Draggable
                  key={question.id!}
                  draggableId={question.id!}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <BaseScreenerQuestion
                        question={question}
                        index={index}
                        onDelete={handleDeleteQuestion}
                        onUpdate={handleUpdateQuestion}
                      >
                        {question.questionType === 'radio' ? (
                          <SingleChoiceScreener
                            question={question}
                            onUpdate={handleUpdateQuestion}
                          />
                        ) : (
                          <MultipleChoiceScreener
                            question={question}
                            onUpdate={handleUpdateQuestion}
                          />
                        )}
                      </BaseScreenerQuestion>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex space-x-4">
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-7 py-3 bg-primary text-primary-contrast border border-gray-300 rounded-2xl flex items-center justify-center hover:bg-primary-muted transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg text-lg font-medium"
        >
          <Plus size={18} className="mr-1" /> Add Question
        </button>
        {screenerQuestions.length >= 2 && (
          <button
            onClick={handleSubmit}
            className="px-7 py-3 bg-primary-contrast text-primary border border-gray-300 rounded-2xl flex items-center justify-center hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg text-lg font-medium"
          >
            Submit <ArrowUpRight size={18} className="ml-1" />
          </button>
        )}
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center p-4 z-[9999]"
          onClick={handleOverlayClick}
        >
          <div className="bg-white rounded-3xl p-8 w-full max-w-md relative z-[10000] border border-gray-300">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
            <h2 className="text-2xl font-semibold mb-6 text-black pr-8 text-center">
              Select Question Type
            </h2>
            <div className="grid grid-cols-1 gap-4">
              <button
                onClick={() => addNewQuestion('radio')}
                className="px-6 py-4 bg-gray-50 text-black rounded-2xl hover:bg-gray-100 transition-colors text-lg font-medium"
              >
                Single Select Question
              </button>
              <button
                onClick={() => addNewQuestion('checkbox')}
                className="px-6 py-4 bg-gray-50 text-black rounded-2xl hover:bg-gray-100 transition-colors text-lg font-medium"
              >
                Multiple Choice Question
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenerQuestions;
