import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProbeLogo from '../Interview/Brand/ProbeLogo';

const NavbarLandingPage = () => {
  const navigate = useNavigate();
  const { affiliateId } = useParams();

  const navigateHome = () => {
    if (affiliateId) {
      navigate(`/share/${affiliateId}`);
    } else {
      navigate('/');
    }
  };

  const handleSignUp = () => {
    if (affiliateId) {
      navigate(`/auth/${affiliateId}`);
    } else {
      navigate('/auth');
    }
  };

  const handleSignIn = () => {
    if (affiliateId) {
      navigate(`/auth/${affiliateId}`, { state: { mode: 'SignIn' } });
    } else {
      navigate('/auth', { state: { mode: 'SignIn' } });
    }
  };

  return (
    <div className="navbar bg-white shadow-sm px-4 sm:px-6 py-2 sm:py-4">
      <div className="flex-1">
        <ProbeLogo
          onClick={navigateHome}
          fontWeight="font-bold"
          iconSize="w-4 h-4 sm:w-5 sm:h-5"
          textSize="text-xl sm:text-2xl"
          textClassName="text-gray-800"
        />
      </div>
      <div className="flex items-center space-x-2 sm:space-x-4">
        <div
          className="text-sm sm:text-md cursor-pointer hover:text-gray-400 transition duration-200"
          onClick={handleSignIn}
        >
          Log In
        </div>
        <button
          className="rounded-full px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-md font-medium text-primary bg-primary-contrast hover:bg-gray-800 transition duration-300"
          onClick={handleSignUp}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default NavbarLandingPage;
