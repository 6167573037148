import React from 'react';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/material';

interface SliderComponentProps {
  min: number;
  max: number;
  value: number[];
  onChangeCommitted: (event: any, newValue: number | number[]) => void;
}

const SliderComponent: React.FC<SliderComponentProps> = ({
  min,
  max,
  value,
  onChangeCommitted,
}) => {
  return (
    <div className="mt-12 ml-2 mr-2">
      <Slider
        value={value}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="on"
        min={min}
        max={max}
        sx={{
          color: '#7C3FFE', // thumb color when not active
          '& .MuiSlider-thumb': {
            color: '#7C3FFE', // thumb color
            '&:hover, &.Mui-active, &.Mui-focusVisible': {
              // Styles when thumb is hovered, active, or focus-visible
              boxShadow: `0px 0px 0px 8px rgba(124, 63, 254, 0.16)`,
            },
            '& .MuiSlider-valueLabel': {
              // Styles for the value label
              backgroundColor: 'rgba(124, 63, 254, 0.5)', // background color of the value label
              color: 'white', // text color of the value label
            },
          },
          '& .MuiSlider-rail': {
            color: '#7C3FFE', // color of the rail
            opacity: 0.5,
          },
          '& .MuiSlider-track': {
            color: '#7C3FFE', // color of the track
          },
          '& .MuiSlider-mark': {
            color: '#7C3FFE', // color of the marks
          },
          '& .MuiSlider-markLabel': {
            color: '#7C3FFE', // color of the mark labels
          },
        }}
      />
    </div>
  );
};

export default SliderComponent;
