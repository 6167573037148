import React, { useState, useEffect, useRef } from 'react';

interface OpenEndedProps {
  show: boolean;
  onAnswer: (answer: string) => void;
  selectedAnswer: string | undefined;
  disabled: boolean;
  onBlur: () => void;
}

const OpenEnded: React.FC<OpenEndedProps> = ({
  show,
  onAnswer,
  selectedAnswer,
  disabled,
  onBlur,
}) => {
  const [answer, setAnswer] = useState(selectedAnswer || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setAnswer(selectedAnswer || '');
  }, [selectedAnswer]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [answer]);

  useEffect(() => {
    if (show && textareaRef.current && !disabled) {
      textareaRef.current.focus();
    }
  }, [show, disabled]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setAnswer(newValue);
    onAnswer(newValue);
  };

  const handleBlur = () => {
    onBlur();
  };

  return (
    <div
      className={`transition-opacity duration-500 ease-in-out mt-10 ml-0.5 ${
        show ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <textarea
        ref={textareaRef}
        value={answer}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder="Type here..."
        className={`w-full text-2xl bg-transparent border-none resize-none overflow-hidden focus:outline-none font-normal text-gray-800 tracking-tighter
          ${
            disabled
              ? 'text-primary-contrast/50 cursor-not-allowed'
              : 'text-primary-contrast placeholder-primary-contrast/50'
          }
        `}
        rows={1}
        style={{ minHeight: '1.5em' }}
      />
      <div className="w-full h-px bg-primary-contrast/20 mt-0.5"></div>
    </div>
  );
};

export default OpenEnded;
