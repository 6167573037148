import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { PencilIcon, Check } from 'lucide-react';
import Subheader from '../Text/Subheader';
import Button from '../Buttons/Button';
import GradientButton from '../Buttons/GradientButton';
import InterviewInterface from '../Interview/InterviewInterface';
import Animate from '../ui/Animate';
import expandIcon from '../../assets/icons/expandIcon.svg';

const InterviewConfirm = () => {
  const navigate = useNavigate();
  const { interviewId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEditMode = queryParams.get('mode') === 'edit';
  const [isInterviewCompleted, setIsInterviewCompleted] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isDevelopment = process.env.NODE_ENV === 'development';

  useEffect(() => {
    if (!isEditMode) {
      const timer = setTimeout(() => {
        setShowButtons(true);
      }, 6500);

      return () => clearTimeout(timer);
    } else {
      setShowButtons(true);
    }
  }, [isEditMode]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navigateToAudienceSelection = () => {
    navigate(`/filters/${interviewId}`);
  };

  const navigateToInterviewOverview = () => {
    navigate(`/overview/${interviewId}`);
    setIsDropdownOpen(false);
  };

  const navigateToInterviewGoals = () => {
    navigate(`/edit/goals/${interviewId}`);
    setIsDropdownOpen(false);
  };

  const navigateToDashboard = () => {
    if (isEditMode || isInterviewCompleted) {
      navigate(`/dashboard/${interviewId}`);
    } else {
      toast.error(
        'Please check the box to confirm that you are satisfied with the interview before proceeding.'
      );
    }
  };

  const openInterviewInNewTab = () => {
    const interviewLink = `https://probelabs.ai/interview/mock/${interviewId}`;
    window.open(interviewLink, '_blank');
  };

  const handleInterviewCompletion = () => {
    setIsInterviewCompleted(!isInterviewCompleted);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="bg-primary">
      <div className="py-10 px-5 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-7/12 2xl:w-5/12 sm:m-auto">
        <div className="text-2xl sm:text-3xl font-medium text-primary-contrast">
          Preview your interview
        </div>
        <Subheader text="Have a conversation with Probe to make sure it's asking questions just the way you want." />
        <div className="relative overflow-auto -mx-4 h-[390px] hide-scrollbar mt-5">
          <InterviewInterface
            defaultInterviewId={`${interviewId}`}
            showProgressBar={false}
            isDemo={true}
            isPreview={true}
            isLightMode={true}
          />
          <img
            src={expandIcon}
            alt="expand icon"
            className="absolute top-0 right-0 m-2 w-4.5 h-4.5 cursor-pointer hover:scale-110"
            onClick={openInterviewInNewTab}
          />
        </div>{' '}
        <Animate>
          {showButtons && (
            <>
              {!isEditMode && (
                <div className="mt-4">
                  <label className="flex items-center">
                    <div className="relative flex items-center justify-center">
                      <input
                        type="checkbox"
                        checked={isInterviewCompleted}
                        onChange={handleInterviewCompletion}
                        className="appearance-none w-4 h-4 border border-gray-300 rounded-sm bg-primary checked:bg-primary-contrast checked:border-primary-contrast focus:outline-none focus:ring-2 focus:ring-primary-contrast"
                      />
                      {isInterviewCompleted && (
                        <Check
                          className="absolute w-3 h-3 text-primary pointer-events-none"
                          strokeWidth={3}
                        />
                      )}
                    </div>
                    <span className="ml-2 text-sm text-gray-500">
                      I've run through the interview and I'm happy with the
                      results.
                    </span>
                  </label>
                </div>
              )}
              <div className="mt-6 flex items-center gap-3">
                <div className="relative" ref={dropdownRef}>
                  <Button
                    label="Edit Interview"
                    onClick={toggleDropdown}
                    buttonType="secondary"
                    icon={<PencilIcon className="w-4 h-5 text-gray-500" />}
                    rounding="rounded-3xl"
                    iconPosition="left"
                  />
                  {isDropdownOpen && (
                    <ul className="absolute top-full left-0 mt-2 p-2 shadow menu z-[1] bg-primary rounded-box w-52">
                      <li>
                        <a
                          className="text-primary-contrast hover:bg-primary-muted"
                          onClick={navigateToInterviewOverview}
                        >
                          Interview Details
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-primary-contrast hover:bg-primary-muted"
                          onClick={navigateToInterviewGoals}
                        >
                          Interview Goals
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
                <Button
                  label={isEditMode ? 'View Responses' : 'Find Participants'}
                  onClick={
                    isEditMode
                      ? navigateToDashboard
                      : navigateToAudienceSelection
                  }
                  disabled={!isEditMode && !isInterviewCompleted}
                />
              </div>
            </>
          )}
        </Animate>
      </div>
      <Toaster />
    </div>
  );
};

export default InterviewConfirm;
