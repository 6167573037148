import { ArrowUpRight } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthProvider.tsx';
import LoadingRing from '../Loading/LoadingRing.tsx';
import Button from '../Buttons/Button.tsx';

const ParticipantCounter = () => {
  const { user } = useAuth();
  const { interviewId, studyId } = useParams();
  const navigate = useNavigate();
  const [participantCount, setParticipantCount] = useState(20);
  const timeSaved = Math.round((participantCount * 50) / 60);
  const [loading, setLoading] = useState(false);
  const [participantCredits, setParticipantCredits] = useState(0);
  const INTERVIEW_PRICE = 4;
  const development = process.env.NODE_ENV === 'development';

  const generatePaymentLink = async (selectedPlan: string) => {
    try {
      if (user) {
        const idToken = await user.getIdToken();

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idToken,
            quantity: participantCount - participantCredits,
            interviewId,
            studyId,
            selectedPlan,
            participantCredits,
          }),
        };

        const emulatorUrl =
          'http://127.0.0.1:5001/joinprobe/us-central1/createCheckoutSession';
        const productionUrl =
          'https://us-central1-joinprobe.cloudfunctions.net/createCheckoutSession';

        const url = development ? emulatorUrl : productionUrl;

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result);

        return result.url;
      } else {
        console.error('User not authenticated');
      }
    } catch (error) {
      console.error('Error calling function:', error);
    }
  };

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setParticipantCount(value);
    }
  };

  const handleFreeInterviews = async () => {
    try {
      if (user) {
        const idToken = await user.getIdToken();

        const isDevelopment = process.env.NODE_ENV === 'development';

        const emulatorUrl =
          'http://127.0.0.1:5001/joinprobe/us-central1/publishFreeStudies';
        const productionUrl =
          'https://us-central1-joinprobe.cloudfunctions.net/publishFreeStudies';

        const url = isDevelopment ? emulatorUrl : productionUrl;

        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idToken,
            studyId,
            interviewId,
            quantity: participantCredits,
          }),
        });
      }
    } catch (error) {
      console.error('Error calling function:', error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const link = await generatePaymentLink('custom');
    window.location.href = link;
  };

  // !!! TODO: uncomment
  // useEffect(() => {
  //   const fetchParticipantCredits = async () => {
  //     if (user) {
  //       const credits = await getParticipantCreditsFromFirestore(user.uid);
  //       setParticipantCredits(credits);
  //     }
  //   };

  //   fetchParticipantCredits();
  // }, [user]);

  if (loading) return <LoadingRing />;

  return (
    <div className="bg-primary text-primary-contrast">
      <div className="py-20 pt-28 px-5 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-[34%] 2xl:w-[28%] sm:m-auto">
        <div className=" flex flex-col pt-5 pb-8 mt-10 rounded-xl border border-[#7C3FFE] border-opacity-20">
          <div className="text-xl md:text-2xl font-medium text-center px-10 text-gray-800">
            Select the number of participants.
          </div>
          <div className="flex justify-center items-center mt-6">
            <div className="flex flex-col items-center mx-4">
              <input
                type="number"
                className="text-6xl font-light text-center bg-transparent w-24 outline-none text-gray-800"
                value={participantCount}
                onChange={handleInputChange}
                min="0"
              />
              <span className="font-thin text-gray-500 text-xs md:text-sm mt-2 md:mt-3">
                interviews at{' '}
                {participantCredits >= participantCount
                  ? '$0'
                  : `$${INTERVIEW_PRICE}`}{' '}
                each
              </span>
            </div>
          </div>
          <div
            className=" mx-auto mt-5 text-sm md:text-base font-light px-2.5 py-1.5 rounded-lg border tooltip cursor-pointer text-gray-700"
            data-tip="Assuming each interview would otherwise take: 15 mins to find a participant, 25 mins to conduct the interview, and 10 mins to write a summary."
          >
            You're saving <span className="font-bold">{timeSaved}</span>{' '}
            {participantCount === 1 ? 'hour' : 'hours'}! 🎉
          </div>
        </div>
        {participantCredits > 0 && (
          <div className="text-center mt-4 text-sm md:text-base font-normal text-gray-600">
            {participantCredits === 1
              ? `Your first interview is free and doesn't require you to enter payment details.`
              : `Your first ${participantCredits} interviews are free and don't require your payment details.`}
          </div>
        )}
        <div className="mt-10 md:w-11/12 md:mx-auto flex flex-col items-center">
          <Button
            label={
              participantCount <= participantCredits
                ? 'Send to Participants'
                : 'Continue to Payment'
            }
            icon={
              <div className="mt-1">
                <ArrowUpRight size={20} />
              </div>
            }
            onClick={handleSubmit}
            fullWidth={true}
            rounding="rounded-2xl"
          />
          {participantCount > participantCredits && (
            <p className="text-xs sm:text-sm font-extralight text-gray-500 text-center mt-3">
              Interviews send on payment completion.
            </p>
          )}
        </div>
      </div>
      <style jsx>{`
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          opacity: 1;
        }
      `}</style>
    </div>
  );
};

export default ParticipantCounter;
