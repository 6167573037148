import React, { useState, useRef, useEffect } from 'react';

interface Props {
  onSubmit: (answer: string) => void;
  disabled: boolean;
}

const AnswerInput: React.FC<Props> = ({ onSubmit, disabled }) => {
  const [input, setInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim() && !disabled) {
      onSubmit(input.trim());
      setInput('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative w-full bg-[#1d102f]">
      <input
        ref={inputRef}
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder={disabled ? 'Waiting for response...' : 'Type here...'}
        className={`w-full bg-transparent text-white text-lg rounded-md py-3 px-4 pr-12 focus:outline-none border tracking-tighter ${
          disabled ? 'border-gray-600 text-gray-500' : 'border-gray-700'
        }`}
        disabled={disabled}
      />
      <button
        type="submit"
        className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${
          disabled ? 'text-gray-600' : 'text-gray-400 hover:text-white'
        }`}
        disabled={disabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </button>
    </form>
  );
};

export default AnswerInput;
