import React, { useState, useEffect } from 'react';

const InterviewProgressBar = ({ totalSeconds, redirectUrl, isPaused }) => {
  const [secondsLeft, setSecondsLeft] = useState(totalSeconds);

  useEffect(() => {
    let timer;
    if (!isPaused) {
      timer = setInterval(() => {
        setSecondsLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            window.location.href = redirectUrl;
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [redirectUrl, isPaused]);

  const progress = ((totalSeconds - secondsLeft) / totalSeconds) * 100;
  const minutesLeft = Math.ceil(secondsLeft / 60);

  return (
    <div className="w-full bg-transparent">
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative h-3 bg-gray-300 bg-opacity-20 rounded-full">
          <div
            className="absolute top-0 left-0 h-full bg-gray-400 bg-opacity-30 rounded-full transition-all duration-1000 ease-linear"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="text-right text-xs text-gray-500 mt-1">
          {minutesLeft} minute{minutesLeft !== 1 ? 's' : ''} left
        </p>
      </div>
    </div>
  );
};

export default InterviewProgressBar;
