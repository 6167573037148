import React, { useState } from 'react';
import { ChevronDown, Check } from 'lucide-react';

interface StatusTagProps {
  isInterviewFinished: boolean;
  onComplete: () => void;
}

const StatusTag: React.FC<StatusTagProps> = ({
  isInterviewFinished,
  onComplete,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusChange = async () => {
    handleDropdownToggle();
    onComplete();
  };

  return (
    <div className="relative inline-block mt-2">
      <div
        className={`flex items-center space-x-2 px-3 py-1.5 rounded-full text-sm font-medium cursor-pointer transition-colors duration-200 ${
          isInterviewFinished
            ? 'bg-green-100 text-green-700'
            : 'bg-yellow-100 text-yellow-700'
        }`}
        onClick={handleDropdownToggle}
      >
        <span>{isInterviewFinished ? 'Completed' : 'In Progress'}</span>
        {!isInterviewFinished && <ChevronDown className="w-4 h-4" />}
      </div>
      {isDropdownOpen && !isInterviewFinished && (
        <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-1">
              <button
                className="flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={handleStatusChange}
              >
                <Check className="mr-3 h-4 w-4 text-green-500" />
                Mark as Completed
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusTag;
