import React from 'react';
import { GripVertical, X } from 'lucide-react';
import { getDisplayName } from '../../../../utils/general.ts';
import { Question } from '@functions/common/types.ts';

export interface BaseQuestionProps<T extends Question> {
  question: T;
  questionNumber: number;
  onDelete: () => void;
  onUpdate: (updates: Partial<T>) => void;
  children: React.ReactNode;
}

const BaseQuestion = <T extends Question>({
  question,
  questionNumber,
  onDelete,
  onUpdate,
  children,
}: BaseQuestionProps<T>) => {
  return (
    <div className="bg-gray-50 rounded-2xl p-6 mb-4 relative group">
      <div className="flex items-center mb-4">
        <div className="cursor-move mr-3 text-gray-400 hover:text-gray-600 flex items-center">
          <GripVertical size={20} />
        </div>
        <div className="flex-grow flex items-center">
          <span className="text-sm font-semibold text-black">
            Q{questionNumber}{' '}
            <span className="text-md text-gray-600 font-medium ml-2">
              {getDisplayName(question.type)} Question
            </span>
          </span>
        </div>
        <button
          onClick={onDelete}
          className="text-gray-400 hover:text-gray-600 transition-colors ml-4"
          aria-label="Delete question"
        >
          <X size={20} />
        </button>
      </div>
      {children}
    </div>
  );
};

export default BaseQuestion;
