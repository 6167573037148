import React from 'react';
import Filter from './Filter.tsx';

const FilterList = ({ filteredFilters, openModalWithFilter }) => {
  return (
    <div className="mt-7 grid grid-cols-1 sm:grid-cols-2 gap-4">
      {filteredFilters &&
        filteredFilters.length > 0 &&
        filteredFilters.map((filter) => (
          <Filter
            key={filter.filter_id}
            title={filter.title}
            question={filter.question}
            onArrowClick={() => openModalWithFilter(filter)}
          />
        ))}
    </div>
  );
};

export default FilterList;
