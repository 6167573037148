import React from 'react';
import Checkbox from '../../InputFields/Checkbox.tsx';
import CheckboxRight from '../../InputFields/CheckboxRight.tsx';
import { CheckedStateType } from './Filters.tsx';

interface CheckboxListProps {
  filterId: string;
  choices: [string, string][];
  checkedState: CheckedStateType;
  handleCheckboxToggle: (filterId: string, value: number) => void;
  isVertical?: boolean; // Optional prop to determine layout
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  filterId,
  choices,
  checkedState,
  handleCheckboxToggle,
  isVertical = false, // Default to horizontal layout
}) => {
  return (
    <div className={isVertical ? 'flex flex-wrap gap-2 mt-5' : 'mt-2'}>
      {choices.map(([key, text]) => {
        const keyAsNumber = parseInt(key);
        const isChecked = checkedState.includes(keyAsNumber);

        // Use CheckboxRight for vertical layout and Checkbox for horizontal layout
        const CheckboxComponent = isVertical ? CheckboxRight : Checkbox;

        return (
          <CheckboxComponent
            filterId={filterId}
            choiceNumber={keyAsNumber}
            key={key}
            text={text}
            isChecked={isChecked}
            handleCheckboxToggle={handleCheckboxToggle}
          />
        );
      })}
    </div>
  );
};

export default CheckboxList;
