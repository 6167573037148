import React, { useEffect, useState } from 'react';
import {
  Sparkles,
  Edit,
  Copy,
  Rocket,
  DollarSign,
  Users,
  Clock,
  HelpCircle,
} from 'lucide-react';
import PayoutModal from './PayoutModal.tsx';
import Subheader from '../../Text/Subheader.tsx';
import toast from 'react-hot-toast';
import {
  addAffiliateId,
  checkAffiliateChangeAllowed,
  getAffiliateBalance,
} from '../../../utils/firestoreAffiliates.tsx';
import { useAuth } from '../../Authentication/AuthProvider.tsx';

const Affiliate: React.FC = () => {
  const [affiliateId, setAffiliateId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [balance, setBalance] = useState(0);

  const { user } = useAuth();

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAffiliateId(e.target.value);
  };

  const toggleEditing = () => {
    setIsClaimed(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClaim = async () => {
    setIsClaimed(true);

    try {
      if (!user) {
        throw new Error('User not authenticated');
      }

      const affiliateClaimAllowed = await checkAffiliateChangeAllowed(
        affiliateId,
        user.uid
      );

      if (affiliateClaimAllowed) {
        const successfullyAdded = await addAffiliateId(affiliateId, user.uid);
        if (!successfullyAdded) {
          throw new Error('Failed to add affiliate ID');
        }
        toast.success('Affiliate link claimed successfully!');
      } else {
        toast.error('Affiliate link already claimed');
        setIsClaimed(false);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      setIsClaimed(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleClaim();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://probelabs.ai/share/${affiliateId}`);
    toast.success('Affiliate link copied to clipboard!');
  };

  const handleSubmit = (
    paymentMethod: string,
    currency: string,
    email: string
  ) => {
    const emailText = `Payout request: ${balance} ${currency} via ${paymentMethod}, from user id: ${user?.uid}, user email: ${email}.`;
    const subjectLine = `Probe - Payout request from user id: ${user?.uid}`;

    const productionUrl =
      'https://us-central1-joinprobe.cloudfunctions.net/sendEmailToMyself';

    fetch(productionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: emailText,
        subject: subjectLine,
      }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Payout request sent!');
        } else {
          toast.error('Failed to send payout request. Please try again later.');
        }
      })
      .catch(() => {
        toast.error('Failed to send payout request. Please try again later.');
      });
  };

  useEffect(() => {
    const getBalance = async () => {
      if (user) {
        const affiliateBalance = await getAffiliateBalance(user.uid);
        if (affiliateBalance) {
          setBalance(affiliateBalance);
        }
      }
    };
    getBalance();
  }, [user]);

  const formatInDollars = (balance: number) => {
    return balance.toFixed(2);
  };

  return (
    <div className="primary min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-10">
          <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-primary-contrast">
            Affiliate Program
          </h1>
          <Subheader
            text="Earn money for every new person you invite to Probe."
            className="text-xl font-medium text-primary-contrast"
          />
        </div>

        <div className="bg-white rounded-2xl p-6 sm:p-10 mb-12 max-w-3xl mx-auto shadow-lg">
          <div className="flex flex-col sm:flex-row items-center w-full border-2 border-gray-200 rounded-xl py-2 sm:py-3 px-4 sm:px-6 mb-8">
            <div className="flex items-center w-full mb-3 sm:mb-0 overflow-hidden">
              <span className="text-lg sm:text-xl font-light whitespace-nowrap mr-0.5 text-primary-contrast">
                probelabs.ai/share/
              </span>
              <input
                type="text"
                value={affiliateId}
                onChange={handleLinkChange}
                placeholder="arthur-dent"
                className="text-lg sm:text-xl font-normal bg-transparent focus:outline-none w-full text-primary-contrast placeholder-gray-400"
                disabled={isClaimed}
                onKeyDown={handleKeyDown}
              />
            </div>
            {!isClaimed ? (
              <button
                onClick={handleClaim}
                className="bg-primary-contrast text-primary hover:bg-gray-800 py-2 px-6 text-lg font-semibold transition duration-300 flex items-center w-full sm:w-auto justify-center rounded-xl mt-2 sm:mt-0"
              >
                <Sparkles className="mr-2 w-5 h-5" />
                Claim
              </button>
            ) : (
              <div className="flex items-center w-full sm:w-auto justify-center sm:justify-end mt-2 sm:mt-0">
                <button
                  onClick={handleCopy}
                  className="bg-gray-100 hover:bg-gray-200 text-primary-contrast rounded-xl p-2 mr-2 transition duration-300"
                >
                  <Copy className="w-5 h-5" />
                </button>
                <button
                  onClick={toggleEditing}
                  className="bg-gray-100 hover:bg-gray-200 text-primary-contrast rounded-xl p-2 transition duration-300"
                >
                  <Edit className="w-5 h-5" />
                </button>
              </div>
            )}
          </div>
          <p className="text-xl mb-10 text-center text-primary-contrast">
            Earn <span className="font-semibold">40%</span> of whatever they
            spend — forever.
          </p>

          <div className="text-center mb-8">
            <h2 className="text-2xl font-semibold mb-2 text-primary-contrast">
              Your Current Balance
            </h2>
            <p className="text-4xl sm:text-5xl font-bold text-primary-contrast">
              ${formatInDollars(balance)}
            </p>
          </div>

          <div className="flex justify-center">
            <button
              onClick={openModal}
              className="bg-primary-contrast text-primary hover:bg-gray-800 py-3 px-8 rounded-xl text-lg font-semibold transition duration-300 shadow-md"
            >
              Request a Payout
            </button>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-8 text-center text-primary-contrast">
            Affiliate Program Details
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              {
                title: 'Earnings',
                content:
                  "As an affiliate, you will earn 40% of the total spending of anyone who signs up using your unique affiliate link. This percentage is recurring, meaning you'll continue to earn as long as they keep spending.",
                icon: <DollarSign className="w-8 h-8 text-primary-contrast" />,
              },
              {
                title: 'Payments',
                content:
                  'To receive your payout, simply click the "Request Payout" button above. Your payment will be processed within 7 days. You can choose your preferred payment method and currency.',
                icon: <Users className="w-8 h-8 text-primary-contrast" />,
              },
              {
                title: 'Tracking',
                content:
                  'You can track your referrals and earnings in real-time through your affiliate dashboard above.',
                icon: <Clock className="w-8 h-8 text-primary-contrast" />,
              },
              {
                title: 'Support',
                content:
                  "If you have any questions or need assistance, email us anytime. We'll typically get back to you within 24 hours, but may take a bit longer on rare occasions.",
                icon: <HelpCircle className="w-8 h-8 text-primary-contrast" />,
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl p-6 shadow-md flex"
              >
                <div className="mr-4 flex-shrink-0">{item.icon}</div>
                <div>
                  <h3 className="text-xl font-bold mb-2 text-primary-contrast">
                    {item.title}
                  </h3>
                  <p className="text-base text-gray-600">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center">
          <p className="text-lg text-primary-contrast">
            Any questions? Email{' '}
            <a
              href="mailto:savannah@talktoprobe.com"
              className="font-semibold hover:underline text-primary-contrast"
            >
              savannah@talktoprobe.com
            </a>
          </p>
        </div>
      </div>

      <PayoutModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSubmit}
        balance={balance}
      />
    </div>
  );
};

export default Affiliate;
