import React, { useEffect, useState } from 'react';
import { getUserIdGivenUserEmailFromFirestore } from '../../utils/firestore.ts';
import { useParams } from 'react-router-dom';

const FindUserId = () => {
  const { email } = useParams();
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    const findUserId = async () => {
      const userId = await getUserIdGivenUserEmailFromFirestore(email);
      if (userId) {
        setUserId(userId);
      }
    };

    findUserId();
  }, [email]);

  return <div>{userId}</div>;
};

export default FindUserId;
