import React, { useState, useRef, useEffect } from 'react';
import {
  Sparkles,
  ChevronDown,
  ChevronUp,
  HelpCircle,
  Plus,
  Minus,
  Wand2,
  Loader2,
} from 'lucide-react';
import SectionHeader from './SectionHeader';
import { generateInterviewQuestions } from '../../../lib/firebase/functions/generateInterviewQuestions';
import { Question } from '@functions/common/types';
import { processRawQuestions } from '../../../utils/questionUtils';

interface Option {
  label: string;
  value: string;
}

const styleOptions: Option[] = [
  { label: 'Conversational', value: 'conversational' },
  { label: 'Formal', value: 'formal' },
  { label: 'Humorous', value: 'humorous' },
];

const categoryOptions: Option[] = [
  { label: 'Customer Discovery', value: 'customer_discovery' },
  { label: 'Product Market Fit Engine', value: 'product_market_fit' },
  { label: 'Landing Page Test', value: 'landing_page_test' },
  { label: 'Churn Survey', value: 'churn_survey' },
];

const lengthOptions: Option[] = [
  { label: '5 Questions', value: '5' },
  { label: '10 Questions', value: '10' },
  { label: '15 Questions', value: '15' },
];

interface AIQuestionGeneratorProps {
  onQuestionsGenerated: (
    questions: Question[],
    instructions: {
      description: string;
      style: string;
      length: number;
      category?: string;
    }
  ) => void;
}

const AIQuestionGenerator: React.FC<AIQuestionGeneratorProps> = ({
  onQuestionsGenerated,
}) => {
  const [description, setDescription] = useState('');
  const [selectedStyle, setSelectedStyle] = useState<string>('conversational');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedLength, setSelectedLength] = useState<string>('5');
  const [showOptions, setShowOptions] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [isExamplePinned, setIsExamplePinned] = useState(false);
  const exampleRef = useRef<HTMLDivElement>(null);
  const [expandedCategories, setExpandedCategories] = useState<
    Record<string, boolean>
  >({});

  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState<Question[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleGenerateQuestions();
    }
  };

  const handleGenerateQuestions = async () => {
    if (!description || !selectedStyle || !selectedLength) {
      setError('Please fill in all required fields');
      return;
    }

    setIsGenerating(true);
    setShowOptions(false);
    setError(null);

    try {
      const result = await generateInterviewQuestions({
        description,
        style: selectedStyle,
        length: parseInt(selectedLength),
        category: selectedCategory,
      });

      const processedQuestions = processRawQuestions(result.questions);
      if (processedQuestions.length === 0) {
        throw new Error('No valid questions were generated. Please try again.');
      }

      onQuestionsGenerated(processedQuestions, {
        description,
        style: selectedStyle,
        length: parseInt(selectedLength),
        category: selectedCategory || undefined,
      });
      setGeneratedQuestions(processedQuestions);
    } catch (error) {
      console.error('Error generating questions:', error);
      setError(
        error instanceof Error
          ? error.message
          : 'An unexpected error occurred. Please try again.'
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const exampleDescription = `I want to create an interview to understand how people use productivity apps. 
  I'm interested in learning about their daily routines, the challenges they face in staying organized, 
  and what features they find most useful in productivity tools. The interview should also explore their 
  preferences for mobile vs desktop apps and any pain points they experience with current solutions.`;

  const toggleExample = () => {
    setShowExample(!showExample);
    setIsExamplePinned(!showExample);
  };

  const toggleCategoryExpansion = (category: string) => {
    setExpandedCategories((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const renderOptions = (
    options: Option[],
    selectedValue: string | null,
    setSelectedValue: (value: string | null) => void,
    category: string
  ) => {
    const isExpanded = expandedCategories[category];
    const hasMoreOptions = options.length > 4;
    const visibleOptions =
      hasMoreOptions && !isExpanded ? options.slice(0, 4) : options;

    return (
      <>
        <div className="flex flex-wrap gap-3">
          {visibleOptions.map((option) => (
            <button
              key={option.value}
              onClick={() =>
                setSelectedValue(
                  selectedValue === option.value ? null : option.value
                )
              }
              className={`px-4 py-2 rounded-xl text-sm transition-all duration-300 ease-in-out ${
                selectedValue === option.value
                  ? 'bg-white border-2 border-purple-400 text-gray-900 font-medium shadow-sm'
                  : 'bg-white border border-gray-200 text-gray-700 hover:border-purple-300 hover:shadow-sm'
              }`}
            >
              {option.label}
            </button>
          ))}
          {hasMoreOptions && (
            <button
              onClick={() => toggleCategoryExpansion(category)}
              className="px-4 py-2 rounded-xl text-sm transition-all duration-300 ease-in-out bg-white border border-gray-200 text-gray-500 hover:border-purple-300 hover:shadow-sm flex items-center"
            >
              {isExpanded ? (
                <>
                  <Minus size={16} className="mr-1" /> Fewer options
                </>
              ) : (
                <>
                  <Plus size={16} className="mr-1" /> More options
                </>
              )}
            </button>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        exampleRef.current &&
        !exampleRef.current.contains(event.target as Node)
      ) {
        setShowExample(false);
        setIsExamplePinned(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mb-6">
      <div className="px-4 pt-4">
        <SectionHeader
          icon={Sparkles}
          title="Generate Questions with AI"
          subtitle="Describe your interview to generate questions"
        />
      </div>
      <div className="px-4 pb-4">
        <div className="mb-6 relative">
          <div className="relative">
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onFocus={() => setShowOptions(true)}
              onKeyDown={handleKeyDown}
              placeholder="Describe the interview you want to create..."
              className="w-full p-4 border-2 border-gray-200 rounded-lg focus:outline-none focus:border-secondary-accent focus:ring-2 focus:ring-secondary-accent/20 transition-all duration-300 ease-in-out h-32 text-primary-contrast bg-white resize-none relative z-10"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-secondary-accent via-purple-500 to-secondary-accent rounded-lg opacity-50 blur-sm animate-pulse"></div>
          </div>
          <div
            className="absolute top-3 right-3 z-20"
            onMouseEnter={() => !isExamplePinned && setShowExample(true)}
            onMouseLeave={() => !isExamplePinned && setShowExample(false)}
          >
            <button
              onClick={toggleExample}
              className="text-gray-400 hover:text-secondary-accent transition-colors p-1 rounded-full hover:bg-gray-100"
              title="See example"
            >
              <HelpCircle size={20} />
            </button>
          </div>
          {showExample && (
            <div
              ref={exampleRef}
              className="absolute z-10 top-full left-0 right-0 mt-2 p-6 bg-white border border-gray-200 rounded-lg shadow-xl transition-all duration-300 ease-in-out"
            >
              <h4 className="text-lg font-semibold text-primary-contrast mb-3">
                Example Description
              </h4>
              <p className="text-sm leading-relaxed text-gray-700 bg-gray-50 p-4 rounded-md border border-gray-100">
                {exampleDescription}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => setShowOptions(!showOptions)}
            className="flex items-center text-sm text-gray-600 hover:text-gray-800 transition-colors px-3 py-2 rounded-xl hover:bg-gray-50"
          >
            {showOptions ? (
              <ChevronUp className="mr-2" size={18} />
            ) : (
              <ChevronDown className="mr-2" size={18} />
            )}
            {showOptions ? 'Hide options' : 'Show more options'}
          </button>
          <button
            onClick={handleGenerateQuestions}
            disabled={isGenerating}
            className="px-5 py-3 rounded-xl bg-transparent flex items-center justify-center transition-all duration-300 ease-in-out text-sm font-medium hover:scale-105 hover:shadow-lg relative group"
          >
            <span className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-600 rounded-xl opacity-50 group-hover:opacity-100 transition-opacity duration-300"></span>
            <span className="absolute inset-[1px] bg-white rounded-[10px]"></span>
            <span className="relative flex items-center bg-gradient-to-r from-indigo-500 to-purple-600 bg-clip-text text-transparent">
              {isGenerating ? (
                <Loader2
                  size={16}
                  className="mr-2 animate-spin text-indigo-500"
                />
              ) : (
                <Wand2 size={16} className="mr-2 text-indigo-500" />
              )}
              <span className="font-medium">
                {isGenerating ? 'Generating...' : 'Generate Questions'}
              </span>
            </span>
          </button>
        </div>
        <div
          className={`space-y-8 overflow-hidden transition-all duration-700 ease-in-out ${
            showOptions ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          {/* Style options */}
          <div>
            <h3 className="text-lg font-semibold text-primary-contrast mb-3">
              Style
            </h3>
            {renderOptions(
              styleOptions,
              selectedStyle,
              (value) => setSelectedStyle(value || 'conversational'),
              'style'
            )}
          </div>

          {/* Length options */}
          <div>
            <h3 className="text-lg font-semibold text-primary-contrast mb-3">
              Length
            </h3>
            {renderOptions(
              lengthOptions,
              selectedLength,
              (value) => setSelectedLength(value || '10'),
              'length'
            )}
          </div>

          {/* Category options */}
          <div>
            <h3 className="text-lg font-semibold text-primary-contrast mb-3">
              Category{' '}
              <span className="text-sm font-normal text-gray-500">
                (Optional)
              </span>
            </h3>
            {renderOptions(
              categoryOptions,
              selectedCategory,
              setSelectedCategory,
              'category'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIQuestionGenerator;
