import React from 'react';

interface SquareButtonProps {
  text: string;
  onClick: () => void;
  icon: React.ReactNode;
  iconPosition?: 'left' | 'right';
  className?: string;
}

const SquareButton: React.FC<SquareButtonProps> = ({
  text,
  onClick,
  icon,
  iconPosition = 'left',
  className,
}) => {
  return (
    <button
      className={`
        bg-white text-primary-contrast px-2 py-2 rounded-md mr-2 mb-2 
        flex items-center border border-gray-300 
        hover:bg-gray-100 hover:border-gray-400
        transition-all duration-300 ease-in-out
        transform hover:scale-105 hover:shadow-md
        sm:px-4 sm:py-2
        group
        ${className}
      `}
      onClick={onClick}
    >
      <div
        className={`flex items-center ${
          iconPosition === 'right' ? 'flex-row-reverse' : 'flex-row'
        }`}
      >
        <div
          className={`transition-transform duration-300 ease-in-out group-hover:scale-110 ${
            iconPosition === 'left' ? 'mr-1 sm:mr-2' : 'ml-1 sm:ml-2'
          }`}
        >
          {icon}
        </div>
        <span className="text-sm sm:text-base">{text}</span>
      </div>
    </button>
  );
};

export default SquareButton;
