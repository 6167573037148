// Import necessary functions from Firebase SDKs
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAGdV3-RI295-1LW_Pu_963D5y7_pJDJNI',
  authDomain: 'joinprobe.firebaseapp.com',
  projectId: 'joinprobe',
  storageBucket: 'joinprobe.appspot.com',
  messagingSenderId: '1098357651045',
  appId: '1:1098357651045:web:122532dd9d022a27cb788c',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Initialize Firestore
const db = getFirestore();

// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Firebase Functions
const functions = getFunctions(app);

// Check if running on localhost and use Functions emulator
if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

// Optionally export Firestore database for use in other parts of your app
export { db, storage, auth, provider, functions };
