import React, { useEffect, useState } from 'react';
import purpleXIcon from '../../../assets/icons/purpleXIcon.svg';
import { CheckedStateType } from './Filters.tsx';
import CheckboxList from './CheckboxList.tsx';
import SliderComponent from './SliderComponent.tsx';
import SearchInput from './SearchInput.tsx';
import purpleCheckmarkBackground from '../../../assets/icons/purpleCheckmarkBackground.svg';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';

interface FilterModalProps {
  onClose: () => void;
  currentFilter: {
    filter_id: string;
    title?: string;
    question?: string;
    choices?: { [key: string]: string };
    min?: number;
    max?: number;
  };
  handleCheckboxToggle: (filterId: string, value: number) => void;
  toggleAllChoices: (
    filterId: string,
    choices: { [key: string]: string },
    action: 'select' | 'deselect'
  ) => void;
  checkedState: CheckedStateType;
  sliderValue: number[];
  handleSliderChange: (event: any, newValue: number | number[]) => void;
  changesSaved: boolean;
  setSliderValue: (value: number[]) => void;
  deleteFilter: (
    filterId: string,
    type: 'choicesSelected' | 'sliderSelections'
  ) => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  onClose,
  currentFilter,
  handleCheckboxToggle,
  checkedState,
  toggleAllChoices,
  sliderValue,
  handleSliderChange,
  changesSaved,
  setSliderValue,
  deleteFilter,
}) => {
  const { filter_id, title, question, choices, min, max } = currentFilter;
  const [searchQuery, setSearchQuery] = useState('');
  const isSavedFilter = changesSaved === null;
  const type = choices ? 'choicesSelected' : 'sliderSelections';

  let numberOfChoices = choices ? Object.keys(choices).length : 0;

  const filteredChoices = choices
    ? Object.entries(choices).filter(([key, value]) =>
        value.toLowerCase().includes(searchQuery)
      )
    : [];

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    // Initialize slider value based on currentFilter min and max
    if (currentFilter && min !== undefined && max !== undefined) {
      setSliderValue([min, max]);
    }
  }, []);

  useEffect(() => {
    // reset searchQuery on cleanup
    return () => {
      setSearchQuery('');
    };
  }, [currentFilter]);

  const allCheckboxesSelected = (): boolean => {
    if (choices) {
      const choicesLength = Object.keys(choices).length;
      const checkedStateLength = checkedState.length;
      return choicesLength === checkedStateLength;
    }
    return false;
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleEscapeKeyPress = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    // Attach event listener
    document.addEventListener('keydown', handleEscapeKeyPress);

    // Clean up the event listener
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [onClose]);

  return (
    <div
      className="fixed inset-0  flex justify-center items-center xl:ml-36"
      onMouseDown={handleBackgroundClick}
    >
      <div className="modal-box border border-gray-300 bg-primary relative">
        <div className="flex justify-end">
          <img
            src={purpleXIcon}
            alt="Close"
            className="w-4 h-4 cursor-pointer hover:opacity-70 absolute right-5 top-6"
            onClick={onClose}
          />
        </div>
        <div>
          <div className="w-full flex justify-between">
            <div>
              {changesSaved !== null && changesSaved === false && (
                <span className="text-2xs sm:text-sm font-light text-primary-contrast bg-violet-500 bg-opacity-30 rounded-full px-2 pt-0.5 pb-1 -ml-1 sm:ml-0 mr-3">
                  Select Filter
                </span>
              )}
              {changesSaved !== null && changesSaved === true && (
                <span className="text-2xs sm:text-sm font-regular text-gray-700">
                  <img
                    src={purpleCheckmarkBackground}
                    alt="Checkmark"
                    className="w-3 h-3 sm:w-3.5 sm:h-3.5 inline-block mb-1 mr-1.5"
                  />
                  Changes saved
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <h3
              className={`font-bold text-lg text-gray-800 ${
                isSavedFilter ? '' : 'mt-3'
              }`}
            >
              {title}
            </h3>
            {isSavedFilter && (
              <FaTrash
                className="w-5 h-5 cursor-pointer hover:opacity-70 text-gray-600"
                onClick={() => deleteFilter(filter_id, type)}
              />
            )}
          </div>
          <p className="text-gray-700 text-sm mt-2">{question}</p>
          {numberOfChoices > 7 && (
            <div>
              <SearchInput
                placeholder="Search"
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
              />
              <div
                className="mt-4 cursor-pointer font-medium text-gray-700"
                onClick={() =>
                  toggleAllChoices(
                    filter_id,
                    choices,
                    allCheckboxesSelected() ? 'deselect' : 'select'
                  )
                }
              >
                {allCheckboxesSelected() ? 'Unselect All' : 'Select All'}
              </div>
            </div>
          )}
          {numberOfChoices > 3 && (
            <CheckboxList
              filterId={filter_id}
              choices={filteredChoices}
              checkedState={checkedState}
              handleCheckboxToggle={handleCheckboxToggle}
            />
          )}
          {numberOfChoices > 0 && numberOfChoices <= 3 && (
            <CheckboxList
              filterId={currentFilter.filter_id}
              choices={Object.entries(choices)}
              isVertical={true}
              checkedState={checkedState}
              handleCheckboxToggle={handleCheckboxToggle}
            />
          )}
          {currentFilter && min !== undefined && max !== undefined && (
            <SliderComponent
              min={min}
              max={max}
              value={sliderValue}
              onChangeCommitted={(event, newValue) =>
                handleSliderChange(
                  currentFilter.filter_id,
                  newValue as number[]
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
