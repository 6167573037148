import React from 'react';
import { ArrowLeft, CornerDownLeft, ChevronRight } from 'lucide-react';

interface InterviewNavigationProps {
  currentQuestionIndex: number;
  totalQuestions: number;
  isTransitioning: boolean;
  isGeneratingFollowUp: boolean;
  allowSkip: boolean;
  hasCurrentAnswer: boolean;
  handleBack: () => void;
  handleSkip: () => void;
  handleNextOrSubmit: () => void;
}

const InterviewNavigation: React.FC<InterviewNavigationProps> = ({
  currentQuestionIndex,
  totalQuestions,
  isTransitioning,
  isGeneratingFollowUp,
  allowSkip,
  hasCurrentAnswer,
  handleBack,
  handleSkip,
  handleNextOrSubmit,
}) => {
  return (
    <div className="flex justify-between items-center w-full mt-8">
      {/* Back button (commented out)
      <button
        onClick={handleBack}
        disabled={
          currentQuestionIndex === 0 || isTransitioning || isGeneratingFollowUp
        }
        className="p-2 text-primary-contrast rounded-2xl transition-all duration-300 ease-in-out disabled:opacity-25 disabled:cursor-not-allowed hover:bg-primary-contrast/10"
        aria-label="Go back to previous question"
      >
        <ArrowLeft className="h-6 w-6" />
      </button>
      */}
      <div className="flex-1"></div> {/* This empty div maintains the space */}
      <div className="flex items-center space-x-4">
        {allowSkip && (
          <button
            onClick={handleSkip}
            disabled={isTransitioning || isGeneratingFollowUp}
            className="pl-4 pr-3 py-3 text-primary-contrast rounded-lg hover:text-gray-600 transition-all duration-300 ease-in-out flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Skip
            <ChevronRight className="ml-1 h-4 w-4" />
          </button>
        )}
        <button
          onClick={handleNextOrSubmit}
          disabled={
            !hasCurrentAnswer || isTransitioning || isGeneratingFollowUp
          }
          className="px-6 py-2.5 bg-primary-contrast text-primary font-bold rounded-lg hover:bg-primary-muted hover:text-primary-contrast transition-all duration-300 ease-in-out flex items-center hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isGeneratingFollowUp ? (
            'Thinking...'
          ) : (
            <>
              Next
              <CornerDownLeft className="ml-2 h-4 w-4" />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default InterviewNavigation;
