import React from 'react';

interface ButtonProps {
  label: string;
  onClick: () => void;
  buttonType?: 'primary' | 'secondary';
  fullWidth?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  rounding?: 'rounded-full' | 'rounded-xl' | 'rounded-2xl' | 'rounded-3xl';
  className?: string;
  disabled?: boolean;
}

const Button = ({
  label,
  onClick,
  buttonType = 'primary',
  fullWidth = false,
  icon,
  iconPosition = 'right',
  rounding = 'rounded-full',
  className = '',
  disabled = false,
}: ButtonProps) => {
  const baseClasses =
    'px-6 py-3 font-medium text-base sm:text-lg flex items-center justify-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg';
  const buttonClasses =
    buttonType === 'primary'
      ? `${baseClasses} bg-primary-contrast text-primary hover:bg-gray-800`
      : `${baseClasses} bg-primary text-primary-contrast border border-gray-300 hover:bg-gray-100`;
  const widthClass = fullWidth ? 'w-full' : 'w-auto';
  const disabledClasses = disabled
    ? 'opacity-50 cursor-not-allowed hover:scale-100 hover:shadow-none'
    : '';

  const renderContent = () => {
    if (icon) {
      return iconPosition === 'left' ? (
        <>
          <span className="mr-2">{icon}</span>
          {label}
        </>
      ) : (
        <>
          {label}
          <span className="ml-2">{icon}</span>
        </>
      );
    }
    return label;
  };

  return (
    <button
      className={`${buttonClasses} ${widthClass} ${className} ${rounding} ${disabledClasses}`}
      onClick={onClick}
      disabled={disabled}
    >
      {renderContent()}
    </button>
  );
};

export default Button;
