import React from 'react';
import QuestionTextArea from '../Questions/Shared/QuestionTextArea.tsx';
import OptionInput from '../Questions/Shared/SelectComponents/OptionInput.tsx';
import AddOptionButton from '../Questions/Shared/SelectComponents/AddOptionButton.tsx';
import { SingleChoiceScreenerQuestion } from 'utils/types/screenerTypes.ts';

interface SingleChoiceScreenerProps {
  question: SingleChoiceScreenerQuestion;
  onUpdate: (
    id: string,
    updates: Partial<SingleChoiceScreenerQuestion>
  ) => void;
}

const SingleChoiceScreener: React.FC<SingleChoiceScreenerProps> = ({
  question,
  onUpdate,
}) => {
  const addOption = () => {
    onUpdate(question.id, {
      answers: [...question.answers, { text: '', answerValue: 2 }],
    });
  };

  const updateOption = (index: number, text: string, answerValue: number) => {
    const newAnswers = [...question.answers];
    newAnswers[index] = { ...newAnswers[index], text, answerValue };
    onUpdate(question.id, { answers: newAnswers });
  };

  const removeOption = (index: number) => {
    const newAnswers = question.answers.filter((_, i) => i !== index);
    onUpdate(question.id, { answers: newAnswers });
  };

  const toggleOtherOption = () => {
    onUpdate(question.id, { includeOtherOption: !question.includeOtherOption });
  };

  return (
    <div className="space-y-6">
      <div className="space-y-3">
        {question.answers.map((answer, index) => (
          <OptionInput
            key={index}
            value={answer.text}
            onChange={(text, answerValue) =>
              updateOption(index, text, answerValue || 2)
            }
            onRemove={() => removeOption(index)}
            placeholder={`Option ${index + 1}`}
            isScreener={true}
            answerValue={answer.answerValue}
            questionType="radio"
          />
        ))}
        {question.includeOtherOption && (
          <OptionInput
            value="Other"
            onChange={() => {}}
            onRemove={() => {}}
            placeholder="Other"
            readOnly={true}
            isScreener={true}
            answerValue={2}
            questionType="radio"
          />
        )}
      </div>
      <AddOptionButton
        onClick={addOption}
        includeOther={question.includeOtherOption}
        onToggleOther={toggleOtherOption}
      />
    </div>
  );
};

export default SingleChoiceScreener;
