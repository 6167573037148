import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQ = () => {
  const [openQuestions, setOpenQuestions] = useState<number[]>([]);

  const toggleQuestion = (index: number) => {
    if (openQuestions.includes(index)) {
      setOpenQuestions(openQuestions.filter((i) => i !== index));
    } else {
      setOpenQuestions([...openQuestions, index]);
    }
  };

  const questions = [
    {
      question:
        'Does Probe interview real people, or is it roleplaying with AI?',
      answer:
        'Probe interviews real people, from a pool of over 200,000 vetted participants.',
    },
    {
      question: "Where do Probe's interview respondents come from?",
      answer: `We collaborate with two research platforms that connect us with screened and verified participants for studies.`,
    },
    {
      question: 'Why do people participate in Probe interviews?',
      answer:
        'All participants receive fair financial compensation for their time and insights.',
    },
    {
      question: 'How long do Probe interviews typically take?',
      answer:
        'It generally takes between 20 minutes and 3 hours to complete a group of B2C interviews, and approximately 1 day for B2B interviews. The duration depends on the number of interview participants and the size of your target audience. For smaller audiences, interviews might take longer.',
    },
  ];

  return (
    <div>
      <div className="mx-auto max-w-3xl pt-24 sm:pt-32 px-4 sm:px-6 lg:px-8">
        <h2 className="text-2.5xl sm:text-4xl font-semibold text-center mb-8 sm:mb-12 text-black">
          Frequently Asked Questions
        </h2>
        <div className="space-y-5">
          {questions.map((item, index) => (
            <div
              key={index}
              className="bg-white border-2 border-black rounded-lg transition-all duration-300 ease-in-out"
            >
              <div
                className="p-6 cursor-pointer flex items-center justify-between"
                onClick={() => toggleQuestion(index)}
              >
                <h3 className="text-lg sm:text-xl font-medium text-black text-left">
                  {item.question}
                </h3>
                {openQuestions.includes(index) ? (
                  <ChevronUp className="w-6 h-6 text-black flex-shrink-0" />
                ) : (
                  <ChevronDown className="w-6 h-6 text-black flex-shrink-0" />
                )}
              </div>
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openQuestions.includes(index) ? 'max-h-screen' : 'max-h-0'
                }`}
              >
                <p className="text-gray-700 px-6 pb-6 text-base sm:text-lg text-left">
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-20 text-center">
        <p className="text-2xl sm:text-3xl font-medium text-black">
          Questions? Text Savannah at{' '}
          <span className="inline-block bg-secondary-accent bg-opacity-10 text-black px-3 py-1 rounded-lg border border-secondary-accent">
            628-203-2690
          </span>
        </p>
      </div>
    </div>
  );
};

export default FAQ;
