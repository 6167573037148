import React, { ChangeEvent, ReactElement, KeyboardEvent } from 'react';
import { LucideIcon } from 'lucide-react';

interface InputFieldIconProps {
  placeholder: string;
  icon: ReactElement<LucideIcon>;
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isPassword?: boolean;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const InputFieldIcon = ({
  placeholder,
  icon,
  handleChange,
  value,
  isPassword,
  onKeyPress,
}: InputFieldIconProps) => {
  return (
    <div className="w-full mx-auto font-extralight mt-9">
      <div className="flex">
        <div className="flex flex-col w-full">
          <div className="flex w-full items-center">
            {React.cloneElement(icon, { className: 'w-4 h-4 text-gray-500' })}
            <input
              value={value}
              onChange={(e) => handleChange(e)}
              onKeyPress={onKeyPress}
              className="w-full bg-transparent text-md sm:text-lg focus:outline-none ml-4 text-primary-contrast placeholder-gray-600"
              placeholder={placeholder}
              type={isPassword ? 'password' : 'text'}
            />
          </div>
          <hr className="border-gray-300 mt-3" />
        </div>
      </div>
    </div>
  );
};

export default InputFieldIcon;
