import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ProbeRespondentInterview } from 'src/components/B2BOverview/B2BDemographics';
import { updateRespondentInterview } from 'src/utils/firestore';

export type UpdateRespondentInterviewInFirebaseRequest = {
  interviewId: string;
  data: ProbeRespondentInterview;
};

const useUpdateRespondentInterviewOnFirebase = (
  options?: Partial<
    UseMutationOptions<
      UpdateRespondentInterviewInFirebaseRequest,
      Error,
      UpdateRespondentInterviewInFirebaseRequest
    >
  >
) => {
  return useMutation<
    UpdateRespondentInterviewInFirebaseRequest,
    Error,
    UpdateRespondentInterviewInFirebaseRequest
  >({
    // @ts-ignore
    mutationFn: (data: UpdateRespondentInterviewInFirebaseRequest) => {
      return updateRespondentInterview(data);
    },
    ...options,
  });
};

export default useUpdateRespondentInterviewOnFirebase;
