import React from 'react';
import { Link } from 'react-router-dom';

const ThankYouPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-primary p-8 text-primary-contrast">
      <div className="text-center">
        <p className="text-xl mb-8">
          Thank you! Your response has been successfully submitted.
        </p>
      </div>
    </div>
  );
};

export default ThankYouPage;
