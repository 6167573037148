import React, {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
} from 'react';
import Animate from '../ui/Animate.tsx';
import CommandButton from '../Buttons/CommandButton.tsx';
import Button from '../Buttons/Button.tsx';

interface InterviewResponseProps {
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isConversationEnded?: boolean;
  hasUserResponded: boolean;
  progress?: number;
  handleSubmit: () => void;
  handleEndConversation?: () => void;
  isDemo?: boolean;
  isLightMode?: boolean;
}

const InterviewResponse: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  InterviewResponseProps
> = (
  {
    value,
    handleChange,
    handleKeyDown,
    isConversationEnded = false,
    hasUserResponded,
    progress,
    handleSubmit,
    handleEndConversation,
    isDemo,
    isLightMode = false,
  },
  ref
) => {
  const autoExpand = () => {
    // Check if ref is a MutableRefObject and then access current, to remove Typescript error
    if (
      (ref as MutableRefObject<HTMLTextAreaElement>).current &&
      !isConversationEnded
    ) {
      const currentRef = (ref as MutableRefObject<HTMLTextAreaElement>).current;
      currentRef.style.height = 'auto';
      currentRef.style.height = `${currentRef.scrollHeight}px`;
    }
  };

  const demoAdditionalClass = isDemo
    ? 'text-xl sm:text-normal sm:text-2xl'
    : '';

  const lightModeAdditionalClass = isLightMode ? 'text-black' : '';

  return (
    <div>
      <Animate className="flex flex-col">
        <textarea
          ref={ref}
          value={value}
          onChange={handleChange}
          placeholder="Type here..."
          onInput={autoExpand}
          onKeyDown={handleKeyDown}
          className={`w-full mb-4 text-shadow-lg antialiased bg-transparent outline-none resize-none focus:outline-none mt-9 ${demoAdditionalClass} ${lightModeAdditionalClass}`}
        />
      </Animate>
      {hasUserResponded && (
        <Animate>
          <div className="flex flex-col sm:flex-row">
            <CommandButton onClick={handleSubmit} isLightMode={isLightMode} />
            {progress && progress >= 100 && handleEndConversation && (
              <div className="mt-2 sm:mt-0">
                <Button
                  label="End Conversation"
                  onClick={handleEndConversation}
                  buttonType="tertiary"
                />
              </div>
            )}
          </div>
        </Animate>
      )}
    </div>
  );
};

export default forwardRef(InterviewResponse);
