import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Filter from './Filter.tsx';

interface FilterGroupProps {
  group: {
    characteristic: string;
    filters: Array<{
      id: string;
      title: string;
      question: string;
    }>;
  };
  openModalWithFilter: (filter: any) => void;
  selectedFilters: string[]; // New prop to track selected filters
}

const FilterGroup: React.FC<FilterGroupProps> = ({
  group,
  openModalWithFilter,
  selectedFilters,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="mb-8">
      <h2 className="text-lg md:text-1.5xl font-medium mb-4 text-black ml-0.5">
        {group.characteristic}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {(isExpanded ? group.filters : group.filters.slice(0, 2)).map(
          (filter) => (
            <Filter
              key={filter.id}
              title={filter.title}
              question={filter.question}
              onArrowClick={() => openModalWithFilter(filter)}
              isSelected={selectedFilters.includes(filter.id)} // Check if the filter is selected
            />
          )
        )}
      </div>
      {group.filters.length > 2 && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={toggleExpansion}
            className="flex items-center justify-center text-gray-600 hover:text-gray-300 transition-colors duration-200"
          >
            {isExpanded ? (
              <>
                View Less <FaChevronUp className="ml-1" />
              </>
            ) : (
              <>
                View More <FaChevronDown className="ml-1" />
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterGroup;
