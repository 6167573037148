import React from 'react';
import { Undo, Redo } from 'lucide-react';

interface UndoRedoButtonsProps {
  canUndo: boolean;
  canRedo: boolean;
  onUndo: () => void;
  onRedo: () => void;
}

const UndoRedoButtons: React.FC<UndoRedoButtonsProps> = ({
  canUndo,
  canRedo,
  onUndo,
  onRedo,
}) => {
  return (
    <div className="flex space-x-1">
      <button
        onClick={onUndo}
        disabled={!canUndo}
        className={`p-1.5 rounded transition-all duration-300 ease-in-out ${
          canUndo
            ? 'text-gray-600 hover:bg-gray-100'
            : 'text-gray-300 cursor-not-allowed'
        }`}
        title="Undo"
      >
        <Undo size={18} />
      </button>
      <button
        onClick={onRedo}
        disabled={!canRedo}
        className={`p-1.5 rounded transition-all duration-300 ease-in-out ${
          canRedo
            ? 'text-gray-600 hover:bg-gray-100'
            : 'text-gray-300 cursor-not-allowed'
        }`}
        title="Redo"
      >
        <Redo size={18} />
      </button>
    </div>
  );
};

export default UndoRedoButtons;
