import React from 'react';
import { Plus } from 'lucide-react';

interface AddOptionButtonProps {
  onClick: () => void;
  includeOther?: boolean;
  onToggleOther?: () => void;
}

const AddOptionButton: React.FC<AddOptionButtonProps> = ({
  onClick,
  includeOther = false,
  onToggleOther,
}) => {
  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={onClick}
        className="inline-flex items-center px-4 py-2 bg-primary text-primary-contrast rounded-xl hover:bg-gray-50 transition-all shadow-sm border border-primary-muted"
      >
        <Plus size={18} className="mr-2" />
        Add Option
      </button>
      {onToggleOther && (
        <button
          onClick={onToggleOther}
          className="text-primary-contrast hover:text-gray-500 font-normal focus:outline-none"
        >
          {includeOther ? "Remove 'Other' option" : "Add 'Other' option"}
        </button>
      )}
    </div>
  );
};

export default AddOptionButton;
