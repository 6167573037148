import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { FirebaseFunctionsClient } from 'src/utils/firebaseFunctionsClient';
import Stripe from 'stripe';

type PublishB2BInterviewRequest = {
  interviewId: string;
};

const usePublishB2BInterview = (
  options?: Partial<
    UseMutationOptions<
      Stripe.Response<Stripe.Checkout.Session>,
      Error,
      PublishB2BInterviewRequest
    >
  >
) => {
  return useMutation<
    PublishB2BInterviewRequest,
    Error,
    PublishB2BInterviewRequest
  >({
    // @ts-ignore
    mutationFn: (data: PublishB2BInterviewRequest) => {
      return new FirebaseFunctionsClient().createStripeB2BInterviewCheckout({
        interviewId: data.interviewId,
      });
    },
    ...options,
  });
};

export default usePublishB2BInterview;
