import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { ArrowLeft } from 'lucide-react';
import { FaChevronDown, FaChevronUp, FaTrash } from 'react-icons/fa';
import SquareButton from '../Buttons/SquareButton';
import LoadingRing from '../Loading/LoadingRing';
import Header from '../Text/Header';
import Subheader from '../Text/Subheader';
import InterviewQADisplay from './InterviewQADisplay';
import StatusTag from './StatusTag';
import { convertToRelativeTime } from '../../utils/general';
import {
  B2CInterviewInstance,
  deleteInterviewInstanceOnFirestore,
} from '../../utils/firestore';
import DeleteInterviewModal from '../Modals/DeleteInterviewModal';
import { getInterviewTypeFromFirestore } from '../../utils/firestore';

interface InterviewInstanceReviewProps {
  interviewData: B2CInterviewInstance | null;
  loading: boolean;
  interviewNumber: string;
  summarizeConversation: boolean;
  loadingSummary: boolean;
  handleBack: () => void;
  handleInterviewComplete: () => void;
  handlePreviousInterview: () => void;
  handleNextInterview: () => void;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
}

const InterviewInstanceReview: React.FC<InterviewInstanceReviewProps> = ({
  isPreviousDisabled,
  isNextDisabled,
  interviewData,
  loading,
  interviewNumber,
  handleBack,
  handleInterviewComplete,
  handlePreviousInterview,
  handleNextInterview,
}) => {
  const navigate = useNavigate();
  const { interviewId } = useParams<{ interviewId: string }>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [interviewType, setInterviewType] = useState<string | null>(null);

  console.log('interview data', interviewData);
  console.log('interview id', interviewId);

  useEffect(() => {
    const fetchInterviewType = async () => {
      if (interviewId) {
        const type = await getInterviewTypeFromFirestore(interviewId);
        setInterviewType(type);
      }
    };
    fetchInterviewType();
  }, [interviewId]);

  const deleteInterviewInstance = async () => {
    if (interviewData?.id && interviewId) {
      try {
        await deleteInterviewInstanceOnFirestore(interviewId, interviewData.id);
        toast.success('Interview deleted successfully');
        navigate(`/dashboard/${interviewId}`);
      } catch (error) {
        console.error('Error deleting interview instance:', error);
        toast.error(
          "Sorry, the interview wasn't successfully deleted. Please try again later."
        );
      }
    }
    setIsDeleteModalOpen(false);
  };

  if (loading) return <LoadingRing />;

  if (!interviewData) {
    console.error('No interview data available');
    return <div>No interview data available</div>;
  }

  const subheaderText = convertToRelativeTime(interviewData.dateAndTimeCreated);

  return (
    <div className="pt-14 pb-20 px-5 sm:pt-10 sm:w-10/12 lg:w-9/12 2xl:w-7/12 sm:m-auto text-primary-contrast">
      <div className="flex justify-between items-center">
        <div className="flex">
          <ArrowLeft
            className="hover:cursor-pointer mr-4 mb-20 mt-4 sm:mt-2"
            onClick={handleBack}
          />
          <div>
            <Header text={`Interview #${interviewNumber}`} />
            <Subheader text={subheaderText} />
            {interviewType === 'B2C' && (
              <StatusTag
                isInterviewFinished={interviewData.isFinished}
                onComplete={handleInterviewComplete}
              />
            )}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className={`bg-primary text-primary-contrast px-3 py-2 h-10 rounded-xl border border-gray-200 shadow-sm hover:bg-primary-dark transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-light ${
              isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isNextDisabled}
            onClick={handleNextInterview}
          >
            <FaChevronUp
              size={16}
              className="transition-transform duration-200"
            />
          </button>
          <button
            className={`bg-primary text-primary-contrast px-3 py-2 h-10 rounded-xl border border-gray-200 shadow-sm hover:bg-primary-dark transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-light ${
              isPreviousDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePreviousInterview}
            disabled={isPreviousDisabled}
          >
            <FaChevronDown
              size={16}
              className="transition-transform duration-200"
            />
          </button>
        </div>
      </div>

      <div className="rounded-lg mt-10 mb-8 sm:mt-0 md:p-6 bg-white">
        <InterviewQADisplay interviewMessages={interviewData.data} />
      </div>
      <div className="flex justify-end mt-8">
        <SquareButton
          text="Delete Interview"
          onClick={() => setIsDeleteModalOpen(true)}
          icon={<FaTrash />}
        />
      </div>
      {isDeleteModalOpen && (
        <DeleteInterviewModal
          interviewId={interviewId || ''}
          interviewInstanceId={interviewData.id}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={deleteInterviewInstance}
        />
      )}
      <Toaster />
    </div>
  );
};

export default InterviewInstanceReview;
