import React from 'react';
import { FaBolt } from 'react-icons/fa';

interface ProbeLogoProps {
  onClick?: () => void;
  className?: string;
  iconSize?: number | string;
  textClassName?: string;
  textSize?: string;
  fontWeight?: 'font-normal' | 'font-medium' | 'font-semibold' | 'font-bold';
}

const ProbeLogo: React.FC<ProbeLogoProps> = ({
  onClick,
  className = '',
  iconSize = 'w-5 h-5',
  textClassName = 'text-primary-contrast',
  textSize = 'text-2xl',
  fontWeight = 'font-medium',
}) => {
  return (
    <div
      className={`flex items-center cursor-pointer ${className}`}
      onClick={onClick}
    >
      <FaBolt
        className={`text-primary-contrast mr-2 mt-0.5 ${
          typeof iconSize === 'string' ? iconSize : ''
        }`}
        size={typeof iconSize === 'number' ? iconSize : undefined}
      />
      <span className={`font-mono ${fontWeight} ${textClassName} ${textSize}`}>
        Probe
      </span>
    </div>
  );
};

export default ProbeLogo;
