import { UseQueryResult } from '@tanstack/react-query';
import { getInterviewInstancesFromFirestore } from '../utils/firestore';
import createQueryHook from '../queries/createQueryHook';
import { InterviewInstanceResponse } from '../utils/firestore';

const useGetInterviewInstances = createQueryHook<
  string,
  InterviewInstanceResponse
>(getInterviewInstancesFromFirestore, (params) => [
  'interviewInstances',
  params,
]);

export default useGetInterviewInstances;

export type UseGetInterviewResult = UseQueryResult<
  InterviewInstanceResponse,
  Error
>;
