import React from 'react';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import purpleCheckmarkBackground from '../../../assets/icons/purpleCheckmarkBackground.svg';
import { CheckedStateType } from './Filters';

interface SelectedFilterProps {
  currentFilter: {
    filter_id: string;
    title?: string;
    description?: string;
    choices?: { [key: string]: string };
    min?: number;
    max?: number;
  };
  checkedState: CheckedStateType;
  sliderValue: [number, number];
  openModalWithFilter: (filter: any) => void;
  deleteFilter: (
    filterId: string,
    type: 'choicesSelected' | 'sliderSelections'
  ) => void;
}

const SelectedFilter: React.FC<SelectedFilterProps> = ({
  currentFilter,
  checkedState,
  sliderValue,
  deleteFilter,
  openModalWithFilter,
}) => {
  const { filter_id, title, description, choices, min, max } = currentFilter;
  const isSavedFilter = true; // Assuming the filter is always saved in this version
  const type = choices ? 'choicesSelected' : 'sliderSelections';

  const getSelectedChoices = () => {
    if (choices) {
      const selectedChoices = Object.entries(choices)
        .filter(([key]) => checkedState.includes(parseInt(key)))
        .map(([_, value]) => value);
      return selectedChoices.join(', ');
    }
    return '';
  };

  const handleEdit = () => {
    openModalWithFilter(currentFilter);
  };

  return (
    <div className="bg-primary border border-gray-300 shadow-md rounded-2xl p-5 relative min-h-[125px] flex flex-col">
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-bold text-lg text-gray-800 truncate">{title}</h3>
        <div className="flex items-center">
          <FaPencilAlt
            className="w-4 h-4 mr-0.5 cursor-pointer hover:opacity-70 text-gray-600"
            onClick={handleEdit}
          />
        </div>
      </div>
      <p className="text-gray-600 text-sm mb-4">{description}</p>
      <div className="flex-grow">
        {choices && (
          <div className="flex items-center mb-2">
            <img
              src={purpleCheckmarkBackground}
              alt="Checkmark"
              className="w-4 h-4 mr-2"
            />
            <span className="text-gray-700 text-sm">
              {getSelectedChoices()}
            </span>
          </div>
        )}
        {min !== undefined && max !== undefined && (
          <div className="flex items-center">
            <img
              src={purpleCheckmarkBackground}
              alt="Checkmark"
              className="w-4 h-4 mr-2"
            />
            <span className="text-gray-700 text-sm">
              {sliderValue[0]} - {sliderValue[1]}
            </span>
          </div>
        )}
      </div>
      {isSavedFilter && (
        <FaTrash
          className="w-4 h-4 mr-1 cursor-pointer hover:opacity-70 text-gray-600 absolute bottom-4 right-4"
          onClick={() => deleteFilter(filter_id, type)}
        />
      )}
    </div>
  );
};

export default SelectedFilter;
