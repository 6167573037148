import React from 'react';

interface Props {
  message: string;
}

const FeedbackDisplay: React.FC<Props> = ({ message }) => {
  return (
    <div className="p-4 bg-[#4d2b7c] text-white text-center">
      <p>{message}</p>
    </div>
  );
};

export default FeedbackDisplay;
