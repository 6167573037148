import React from 'react';
import { Button, Text, Group, Modal } from '@mantine/core';

interface DeleteInterviewModalProps {
  interviewId: string;
  interviewInstanceId: string;
  onClose: () => void;
  onDelete: () => void;
  opened: boolean;
}

const DeleteInterviewModal: React.FC<DeleteInterviewModalProps> = ({
  interviewId,
  interviewInstanceId,
  onClose,
  onDelete,
  opened,
}) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Delete Interview"
      centered
      transitionProps={{ transition: 'fade', duration: 200 }}
    >
      <Text size="lg" fw={500} mb="md">
        Are you sure you want to delete this interview?
      </Text>
      <Text size="sm" c="dimmed" mb="xl">
        This action cannot be undone.
      </Text>
      <Group justify="flex-end" mt="xl">
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button onClick={onDelete} color="red">
          Delete
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteInterviewModal;
