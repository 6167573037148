import {
  AzureOpenAIChatCompletionResponse,
  ChatCompletionRequest,
  InterviewOverview,
} from '@functions/common/types';
import {
  CreateB2bProjectDto,
  IndustriesPaginatedDto,
  LookupsMinimalDto,
  Profile,
  Project,
  SimpleJobTitlesPaginatedDto,
} from '@functions/respondent/api/models/';
import { RespondentClient } from '@functions/respondent/client';
import JobTitlesMethods from '@functions/respondent/client/jobTitles';
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions';

const functions = getFunctions();

function createAsyncFunction<
  T extends (...args: any[]) => Promise<HttpsCallableResult<any>>,
>(
  methodName: string,
  internalMethod: T
): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>['data']> {
  return async (...args: Parameters<T>) => {
    try {
      const result = await internalMethod(...args);
      return result.data;
    } catch (error) {
      console.error(`Error in ${methodName}:`, error);
      throw error;
    }
  };
}

export interface QuestionSummary {
  question: string;
  type: string;
  averageRating?: number;
  totalResponses?: number;
  maxRating?: number;
  ratingDistribution?: { [key: number]: number };
  optionDistribution?: { [key: string]: number };
}

export class FirebaseFunctionsClient {
  private _searchRespondentJobTitles = httpsCallable<
    Parameters<typeof JobTitlesMethods.prototype.search>[0],
    SimpleJobTitlesPaginatedDto
  >(functions, 'searchRespondentJobTitles');
  private _getAllRespondentIndustries = httpsCallable<
    Parameters<typeof RespondentClient.prototype.industries.findAll>[0],
    IndustriesPaginatedDto
  >(functions, 'getAllRespondentIndustries');
  private _getLookups = httpsCallable<
    Parameters<typeof RespondentClient.prototype.lookups.findAll>,
    LookupsMinimalDto
  >(functions, 'getRespondentLookups');
  private _createRespondentProject = httpsCallable<
    CreateB2bProjectDto,
    Project
  >(functions, 'createRespondentProject');
  private _getChatGPTJSONResponse = httpsCallable<any, any>(
    functions,
    'getChatGPTJSONResponse'
  );
  private _getInterviewDashboardCards = httpsCallable<
    { interviewId: string },
    any
  >(functions, 'getInterviewDashboardCards');
  private _processInterviewData = httpsCallable<
    { interviewId: string },
    { questionSummaries: { [key: string]: QuestionSummary } }
  >(functions, 'processInterviewData');
  private _generateInterviewOverview = httpsCallable<
    { interviewId: string },
    InterviewOverview
  >(functions, 'generateInterviewOverview');
  private _markInterviewAsAttended = httpsCallable<
    { interviewId: string; screenerResponseId: string },
    InterviewOverview
  >(functions, 'markInterviewAsAttended');
  private _createStripeB2BInterviewCheckout = httpsCallable<
    { interviewId: string },
    InterviewOverview
  >(functions, 'createStripeB2BInterviewCheckout');
  private _getRespondentProfile = httpsCallable<
    { participantId: string },
    Profile
  >(functions, 'getRespondentProfile');

  private _callAzureOpenAI = httpsCallable<
    ChatCompletionRequest,
    AzureOpenAIChatCompletionResponse
  >(functions, 'callAzureOpenAI');

  searchJobs = createAsyncFunction(
    'searchJobs',
    this._searchRespondentJobTitles
  );

  callAzureOpenAI = createAsyncFunction(
    'callAzureOpenAI',
    this._callAzureOpenAI
  );

  markInterviewAsAttended = createAsyncFunction(
    'markInterviewAsAttended',
    this._markInterviewAsAttended
  );

  createStripeB2BInterviewCheckout = createAsyncFunction(
    'createStripeB2BInterviewCheckout',
    this._createStripeB2BInterviewCheckout
  );

  getRespondentProfile = createAsyncFunction(
    'getRespondentProfile',
    this._getRespondentProfile
  );

  getIndustries = createAsyncFunction(
    'getIndustries',
    this._getAllRespondentIndustries
  );
  getLookups = createAsyncFunction('getLookups', this._getLookups);
  createRespondentProject = createAsyncFunction(
    'createRespondentProject',
    this._createRespondentProject
  );
  getChatGPTJSONResponse = createAsyncFunction(
    'getChatGPTJSONResponse',
    this._getChatGPTJSONResponse
  );
  getInterviewDashboardCards = createAsyncFunction(
    'getInterviewDashboardCards',
    this._getInterviewDashboardCards
  );
  processInterviewData = createAsyncFunction(
    'processInterviewData',
    this._processInterviewData
  );
  generateInterviewOverview = createAsyncFunction(
    'generateInterviewOverview',
    this._generateInterviewOverview
  );
}

export const firebaseFunctionsClient = new FirebaseFunctionsClient();
